import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
// @material-ui/core components
import { Collapse } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";
import CardBody from "dep/Card/CardBody";
import Table from "dep/Table/Table";
// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import cardStyles from "assets/jss/jpt/cardStyles";

const useStyles = makeStyles(cardStyles);
export default function CardMigrasiMahasiswaVerified(props) {
  const classes = useStyles();
  useEffect(() => {
    if (props.open) handleFetchData();
  }, [props.open, props.toggleReFetchMigrasi]);
  // var: values
  const [tableData, setTableData] = useState([]);
  // handle: API fetch
  const handleFetchData = async () => {
    let res = await Auth.fetch(
      `${BASE_URL}/api/manajemen/migrasi/list/verified?paginate=5&page=1`
    );
    let json = await res.json();
    setTableData(
      _.get(json, "users.data", []).map(e => {
        return [
          _.get(e, "nim_baru", ""),
          _.get(e, "verified.nama", ""),
          `${_.get(e, "prodi_baru.nama_prodi", "")} (${_.get(
            e,
            "prodi_baru.jenjang",
            ""
          )})`,
          _.get(e, "tanggal_masuk", "").split("-")[0]
        ];
      })
    );
  };

  return (
    <Collapse in={props.open}>
      <Card>
        <CardHeader color={"success"}>
          <h4 className={classes.cardTitleWhite}>Belum lama diverifikasi</h4>
        </CardHeader>
        <CardBody>
          <Table
            tableHeaderColor="primary"
            tableHead={["NIM", "Nama", "Prodi", "Angkatan"]}
            tableData={tableData}
          />
        </CardBody>
      </Card>
    </Collapse>
  );
}

CardMigrasiMahasiswaVerified.propTypes = {
  open: PropTypes.bool
};
