import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  Collapse,
  Typography,
  Tooltip,
  IconButton
} from "@material-ui/core";

// @material-ui/icons
import IconEdit from "@material-ui/icons/Edit";
import IconAdd from "@material-ui/icons/Add";
import IconDeleteForever from "@material-ui/icons/DeleteForever";

// dep components
import GridItem from "dep/Grid/GridItem.js";
import GridContainer from "dep/Grid/GridContainer.js";
import CustomInput from "dep/CustomInput/CustomInput.js";
import Button from "dep/CustomButtons/Button.js";
import Card from "dep/Card/Card.js";
import CardHeader from "dep/Card/CardHeader.js";
import CardBody from "dep/Card/CardBody.js";
import CardFooter from "dep/Card/CardFooter.js";

// components
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";

// styling
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";

// For API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import formStyles from "assets/jss/jpt/formStyles";
import cardStyles from "assets/jss/jpt/cardStyles";

const defaultStateFakultas = {
  id_fakultas: null,
  nama_fakultas: "",
  nama_fakultas_inggris: "",
  nama_dekan: "",
  nomor_pegawai_dekan: "",
  is_active: undefined
};

//styling
const useStyles = makeStyles({
  ...formStyles,
  ...cardStyles,
  ...tasksStyle
});

export default function CardManajemenFakultas() {
  const classes = useStyles();

  //var : listSelect
  const [listFakultas, setListFakultas] = useState([]);

  //var : values
  const [selectFakultas, setSelectFakultas] = useState("");
  const [fakultasState, setFakultasState] = useState(defaultStateFakultas);

  //var : open-close
  const [showCardFakultas, setShowCardFakultas] = useState(false);
  const [openSelectFakultas, setOpenSelectFakultas] = useState(false);
  const [openDialogUbah, setOpenDialogUbah] = useState(false);
  const [openDialogTambah, setOpenDialogTambah] = useState(false);
  const [openDialogHapus, setOpenDialogHapus] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });

  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };

  useEffect(() => {
    handleFetchListFakultas();
  }, []);

  // handle: [API] fetch
  const handleFetchListFakultas = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/fakultas/all");
    let data = await res.json();
    setListFakultas(data.fakultas || []);
  };

  const HandleFetchDataFakultas = async id_fakultas => {
    if (id_fakultas === "") return setFakultasState(defaultStateFakultas);
    let res = await Auth.fetch(BASE_URL + "/api/fakultas/" + id_fakultas);
    let data = await res.json();
    setFakultasState(data.fakultas);
  };

  //handle : [API] submit
  const handleAPITambah = async () => {
    try {
      openSnackbar("info", "Adding...");
      let res = await Auth.fetch(BASE_URL + "/api/fakultas/add", {
        method: "POST",
        body: JSON.stringify(fakultasState)
      });

      let data = await res.json();

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data fakultas berhasil ditambahkan!");
        handleFetchListFakultas();
      } else {
        let errors = "";
        if (typeof data.message == "string") {
          errors += data.message;
        } else {
          for (let e of data.message) {
            for (let key in e) {
              for (let err of e[key]) errors += err;
            }
          }
        }
        openSnackbar(
          "warning",
          errors || "Terjadi kesalahan saat melakukan penambahan!"
        );
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan saat melakukan penambahan!");
    }
    handleCloseDialogTambah();
  };

  const handleAPIUbah = async () => {
    try {
      openSnackbar("info", "Updating...");
      let res = await Auth.fetch(
        BASE_URL + "/api/fakultas/" + fakultasState.id_fakultas + "/update",
        {
          method: "PUT",
          body: JSON.stringify(fakultasState)
        }
      );

      let data = await res.json();

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data fakultas berhasil diubah!");
        handleFetchListFakultas();
      } else {
        let errors = "";
        if (typeof data.message == "string") {
          errors += data.message;
        } else {
          for (let e of data.message) {
            for (let key in e) {
              for (let err of e[key]) errors += err;
            }
          }
        }
        openSnackbar(
          "warning",
          errors || "Terjadi kesalahan saat melakukan update!"
        );
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan saat melakukan update!");
    }
    handleCloseDialogUbah();
  };

  const handleAPIHapus = async () => {
    try {
      openSnackbar("info", "Deleting...");
      let res = await Auth.fetch(
        BASE_URL + "/api/fakultas/" + fakultasState.id_fakultas + "/delete",
        {
          method: "DELETE"
        }
      );

      let data = await res.json();

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data fakultas berhasil dihapus!");
        handleFetchListFakultas();
      } else {
        let errors = "";
        if (typeof data.message == "string") {
          errors += data.message;
        } else {
          for (let e of data.message) {
            for (let key in e) {
              for (let err of e[key]) errors += err;
            }
          }
        }
        openSnackbar("warning", errors || "Terjadi kesalahan!");
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
    handleCloseDialogHapus();
  };

  //handle : values
  const handleChangeSelectFakultas = event => {
    setSelectFakultas(event.target.value);
    HandleFetchDataFakultas(event.target.value);
    event.preventDefault();
  };

  const handleChangeFakultasState = e => {
    setFakultasState({ ...fakultasState, [e.target.name]: e.target.value });
  };

  const handleChangeFakultasStatus = value => {
    setFakultasState({ ...fakultasState, is_active: value });
  };

  //handle : open-close
  const handleCloseSelectFakultas = () => {
    setOpenSelectFakultas(false);
  };

  const handleOpenSelectFakultas = () => {
    setOpenSelectFakultas(true);
  };

  const handleClickOpenDialogUbah = () => {
    setOpenDialogUbah(true);
  };

  const handleCloseDialogUbah = () => {
    setOpenDialogUbah(false);
  };

  const handleClickOpenDialogTambah = () => {
    setOpenDialogTambah(true);
  };

  const handleCloseDialogTambah = () => {
    setOpenDialogTambah(false);
  };

  const handleClickOpenDialogHapus = () => {
    setOpenDialogHapus(true);
  };

  const handleCloseDialogHapus = () => {
    setOpenDialogHapus(false);
  };

  return (
    <Card plain={!showCardFakultas}>
      <div
        onClick={() => {
          setShowCardFakultas(!showCardFakultas);
        }}
      >
        <CustomSnackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarData.message}
          severity={snackbarData.severity}
        />
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Manajemen Fakultas</h4>
          <p className={classes.cardCategoryWhite}>
            Ubah atau tambah Fakultas di sini
          </p>
        </CardHeader>
      </div>
      <div>
        <Collapse in={showCardFakultas}>
          <CardBody>
            <GridContainer justify={"space-between"} alignItems={"center"}>
              <GridItem xs={10} sm={10} md={7}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="controlled-open-select-label">
                    Pilih Fakultas
                  </InputLabel>
                  <Select
                    labelId="controlled-open-select-label"
                    id="controlled-open-select"
                    open={openSelectFakultas}
                    onClose={handleCloseSelectFakultas}
                    onOpen={handleOpenSelectFakultas}
                    value={selectFakultas}
                    onChange={handleChangeSelectFakultas}
                  >
                    <MenuItem key="none" value="">
                      None
                    </MenuItem>
                    {listFakultas.map((row, key) => (
                      <MenuItem key={key} value={row.id_fakultas}>
                        {row.nama_fakultas}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={2}>
                <GridContainer justify={"flex-end"}>
                  <GridItem>
                    <Tooltip
                      hidden={!selectFakultas}
                      id="tooltip-top"
                      title="Hapus Fakultas"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label="Hapus Fakultas"
                        className={classes.tableActionButton}
                        onClick={handleClickOpenDialogHapus}
                      >
                        <IconDeleteForever
                          className={
                            classes.tableActionButtonIcon + " " + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={7}>
                <GridContainer justify={"space-between"} alignItems={"center"}>
                  <GridItem>
                    <Typography>Status</Typography>
                  </GridItem>
                  <GridItem>
                    <GridContainer>
                      <GridItem>
                        <Button
                          color={
                            fakultasState.is_active ||
                            fakultasState.is_active === undefined
                              ? "danger"
                              : "success"
                          }
                          onClick={event => {
                            handleChangeFakultasStatus(false);
                            event.preventDefault();
                          }}
                        >
                          Non-Aktif
                        </Button>
                      </GridItem>
                      <GridItem>
                        <Button
                          color={fakultasState.is_active ? "success" : "danger"}
                          onClick={event => {
                            handleChangeFakultasStatus(true);
                            event.preventDefault();
                          }}
                        >
                          Aktif
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={7}>
                <CustomInput
                  labelText="Nama Fakultas"
                  id="nama-fakultas"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "nama_fakultas",
                    value: fakultasState.nama_fakultas || "",
                    onChange: handleChangeFakultasState
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={7}>
                <CustomInput
                  labelText="Nama Fakultas (Inggris)"
                  id="nama-fakultas-inggris"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "nama_fakultas_inggris",
                    value: fakultasState.nama_fakultas_inggris || "",
                    onChange: handleChangeFakultasState
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={7}>
                <CustomInput
                  labelText="Nama Dekan"
                  id="nama-dekan"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "nama_dekan",
                    value: fakultasState.nama_dekan || "",
                    onChange: handleChangeFakultasState
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={7}>
                <CustomInput
                  labelText="Nomor Pegawai Dekan"
                  id="nomor-pegawai-dekan"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "nomor_pegawai_dekan",
                    value: fakultasState.nomor_pegawai_dekan || "",
                    onChange: handleChangeFakultasState
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <Button
              color="danger"
              disabled={!selectFakultas}
              onClick={handleClickOpenDialogUbah}
            >
              <IconEdit />
              Ubah
            </Button>
            <Button
              color="danger"
              disabled={
                selectFakultas !== "" ||
                fakultasState.nama_fakultas === "" ||
                fakultasState.is_active === undefined
              }
              onClick={handleClickOpenDialogTambah}
            >
              <IconAdd />
              Tambah
            </Button>
          </CardFooter>
          <Dialog
            open={openDialogUbah}
            onClose={handleCloseDialogUbah}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Apakah Anda Yakin?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {"Anda akan melakukan pengubahan informasi Fakultas"}
                <br />
                {"ID fakultas: " + selectFakultas}
                <br />
                {"Status : "}
                {fakultasState.is_active ? "Aktif" : "Non-Aktif"}
                <br />
                {fakultasState.nama_fakultas === ""
                  ? "Nama fakultas tidak berubah"
                  : "Nama fakultas: " + fakultasState.nama_fakultas}
                <br />
                {fakultasState.nama_dekan === ""
                  ? "Nama dekan tidak berubah"
                  : "Nama dekan: " + fakultasState.nama_dekan}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogUbah} color="danger">
                Tidak
              </Button>
              <Button onClick={handleAPIUbah} color="success" autoFocus>
                Ya
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openDialogTambah}
            onClose={handleCloseDialogTambah}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Apakah Anda Yakin?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {"Anda akan melakukan penambahan Fakultas"}
                <br />
                {"Status : "}
                {fakultasState.is_active ? "Aktif" : "Non-Aktif"}
                <br />
                {"Nama fakultas: " + fakultasState.nama_fakultas}
                <br />
                {"Nama fakultas (inggris): " +
                  fakultasState.nama_fakultas_inggris}
                <br />
                {"Nama dekan: " + fakultasState.nama_dekan}
                <br />
                {"Nomor pegawai dekan: " + fakultasState.nomor_pegawai_dekan}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogTambah} color="danger">
                Tidak
              </Button>
              <Button onClick={handleAPITambah} color="success" autoFocus>
                Ya
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openDialogHapus}
            onClose={handleCloseDialogHapus}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Apakah Anda Yakin?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {"Anda akan melakukan penghapusan Fakultas"}
                <br />
                {"Status : "}
                {fakultasState.is_active ? "Aktif" : "Non-Aktif"}
                <br />
                {"Nama fakultas: " + fakultasState.nama_fakultas}
                <br />
                {
                  "Pastikan tidak ada mahasiswa di fakultas ini. Pastikan juga sudah ada keputusan Universitas mengenai penghapusan fakultas ini"
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogHapus} color="danger">
                Tidak
              </Button>
              <Button onClick={handleAPIHapus} color="success" autoFocus>
                Ya
              </Button>
            </DialogActions>
          </Dialog>
        </Collapse>
      </div>
    </Card>
  );
}
