import React, { useState, useEffect } from "react";

// Dep
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";

// Components
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";
import CardManajemenAdminProdi from "components/Admin/ManajemenAdmin/Card/CardManajemenAdminProdi";
import CardManajemenAdminVerifikator from "components/Admin/ManajemenAdmin/Card/CardManajemenAdminVerifikator";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function ManajemenAdmin() {
  const [listProdi, setListProdi] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: "",
    timeout: 2000
  });

  const openSnackbar = (severity, message, timeout = 2000) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message, timeout });
  };

  useEffect(() => {
    const fetchProdi = async () => {
      let res = await Auth.fetch(BASE_URL + "/api/prodi");
      let data = await res.json();
      setListProdi(data.prodi || []);
    };
    fetchProdi();
  }, []);

  return (
    <div>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
        timeout={snackbarData.timeout}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          {listProdi ? (
            <>
              <CardManajemenAdminProdi
                listProdi={listProdi}
                openSnackbar={openSnackbar}
              />
              <CardManajemenAdminVerifikator
                listProdi={listProdi}
                openSnackbar={openSnackbar}
              />
            </>
          ) : (
            <></>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}
