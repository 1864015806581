import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons components
import InfoIcon from "@material-ui/icons/Info";

import Button from "dep/CustomButtons/Button";
// styling
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle";
import buttonsStyles from "assets/jss/jpt/buttonsStyles";
// import PropTypes from "prop-types";
const useStyles = makeStyles({
  ...taskStyles,
  ...buttonsStyles
});

export default function ButtonVerifikasiMigrasiMahasiswa(props) {
  const classes = useStyles();

  //handle: hook
  const handleInfoButton = () => {
    props.handleChangePreviewID(props.NIM);
    props.setPreviewData(props.dataMahasiwa);
  };

  const handleOpenDialogVerifikasiMigrasiMahasiswa = () => {
    props.handleChangeDialogMigrasiType(props.migrasiType);
    props.handleChangeMigrasiData(props.migrasiData);
    props.setPreviewData(props.dataMahasiwa);
  };

  return (
    <>
      <Tooltip
        id="tooltip-top"
        title="info"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="info"
          className={classes.tableActionButton}
          onClick={handleInfoButton}
        >
          <InfoIcon
            className={classes.tableActionButtonIcon + " " + classes.buttonInfo}
          />
        </IconButton>
      </Tooltip>
      <Button
        color={"info"}
        size={"sm"}
        round
        onClick={handleOpenDialogVerifikasiMigrasiMahasiswa}
      >
        Verifikasi
      </Button>
    </>
  );
}

ButtonVerifikasiMigrasiMahasiswa.propTypes = {
  NIM: PropTypes.string,
  dataMahasiwa: PropTypes.object,
  handleChangeDialogMigrasiType: PropTypes.func,
  handleChangeMigrasiData: PropTypes.func,
  handleChangePreviewID: PropTypes.func,
  migrasiData: PropTypes.object,
  migrasiType: PropTypes.string,
  setPreviewData: PropTypes.func
};
