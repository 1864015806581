import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Collapse,
  Dialog,
  DialogContent,
  Tooltip,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import Button from "dep/CustomButtons/Button.js";
import GridItem from "dep/Grid/GridItem";
import GridContainer from "dep/Grid/GridContainer";
// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

// @material-ui/icons
import EjectIcon from "@material-ui/icons/Eject";
import IconButton from "@material-ui/core/IconButton";
// styling
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle.js";

const useStyle = makeStyles({
  ...tasksStyle,
  button: {
    width: "100%"
  }
});

export default function DialogProdiSelector(props) {
  const classes = useStyle();
  useEffect(() => {
    if (
      props.selectedProdi === "" ||
      props.openDialog ||
      !props.selectedProdi
    ) {
      fetchListFakultas();
      setOpenDialog(true);
    }
  }, [props.openDialog, props.selectedProdi]);

  // var: open-close
  const [openDialog, setOpenDialog] = useState(false);

  // var: values
  const [selectedFakultas, setSelectedFakultas] = useState("");
  const [selectedProdi, setSelectedProdi] = useState("");
  const [listFakultas, setListFakultas] = useState([]);
  const [listProdi, setListProdi] = useState([]);

  // handle: open-close
  const handleBackButton = () => {
    setSelectedFakultas("");
    setListProdi([]);
  };

  // handle: API fetch
  const fetchListFakultas = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/fakultas");
    let data = await res.json();
    setListFakultas(data.fakultas || []);
  };

  const fetchListProdi = async id_fakultas => {
    let res = await Auth.fetch(
      BASE_URL + "/api/fakultas/" + id_fakultas + "/prodi"
    );
    let data = await res.json();
    setListProdi(data.prodi || []);
  };

  // handle: values
  const handleChangeFakultas = value => {
    setSelectedFakultas(value);
    props.handleChangeSelectedFakultas(value);
    fetchListProdi(value);
  };
  // handle: values -> props
  const handleChangeProdi = value => {
    setSelectedProdi(value);
    setOpenDialog(false);
    props.handleChangeSelectedProdi(value);
    props.handleChangeOpenDialog(false);
  };
  // handle: open-close -> props
  const handleCloseDialog = () => {
    if (selectedProdi) {
      setOpenDialog(false);
      props.handleChangeOpenDialog(false);
    }
  };

  const CreateGridOfButtonsFakultas = () => {
    const row =
      listFakultas.length < 16 ? Math.ceil(Math.sqrt(listFakultas.length)) : 4;
    const column = listFakultas.length / row;
    let newListArray = [];
    for (let i = 0; i < row; i++) {
      newListArray.push(listFakultas.slice(i * column, (i + 1) * column));
    }
    return (
      <React.Fragment>
        {newListArray.map((items, idx) => (
          <GridContainer key={idx} justify={"space-between"}>
            {items.map((item, idx) => (
              <GridItem key={idx} xs>
                <Button
                  className={classes.button}
                  color={"white"}
                  onClick={event => {
                    handleChangeFakultas(item.id_fakultas);
                    event.preventDefault();
                  }}
                >
                  {item.nama_fakultas}
                </Button>
              </GridItem>
            ))}
          </GridContainer>
        ))}
      </React.Fragment>
    );
  };

  const CreateGridOfButtonsProdi = () => {
    const row =
      listProdi.length < 16 ? Math.ceil(Math.sqrt(listProdi.length)) : 4;
    const column = listProdi.length / row;
    let newListArray = [];
    for (let i = 0; i < row; i++) {
      newListArray.push(listProdi.slice(i * column, (i + 1) * column));
    }
    return (
      <React.Fragment>
        {newListArray.map((items, idx) => (
          <GridContainer key={idx} justify={"space-evenly"}>
            {items.map((item, idx) => (
              <GridItem key={idx} xs>
                <Button
                  className={classes.button}
                  color={"white"}
                  onClick={event => {
                    handleChangeProdi(item.id_prodi);
                    event.preventDefault();
                  }}
                >
                  {`${item.nama_prodi} (${item.jenjang})`}
                </Button>
              </GridItem>
            ))}
          </GridContainer>
        ))}
      </React.Fragment>
    );
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="dialog-title"
    >
      <DialogTitle>
        {selectedFakultas ? "Pilih Program Studi" : "Pilih Fakultas"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Anda adalah super-admin, anda akan melakukan manajemen di level admin
          prodi. Pastikan admin prodi yang bersangkutan sudah menyetujuinya
        </DialogContentText>
        <Collapse in={!selectedFakultas}>
          <CreateGridOfButtonsFakultas />
        </Collapse>
        <Collapse in={Boolean(selectedFakultas)}>
          <GridContainer justify={"center"}>
            <GridItem>
              <Tooltip
                id="tooltip-top"
                title="Pilih Fakultas"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="Back"
                  className={classes.tableActionButton}
                  onClick={handleBackButton}
                >
                  <EjectIcon
                    className={
                      classes.tableActionButtonIcon + " " + classes.close
                    }
                  />
                </IconButton>
              </Tooltip>
            </GridItem>
          </GridContainer>
          <CreateGridOfButtonsProdi />
        </Collapse>
      </DialogContent>
    </Dialog>
  );
}

DialogProdiSelector.propTypes = {
  handleChangeOpenDialog: PropTypes.func,
  handleChangeSelectedProdi: PropTypes.func,
  openDialog: PropTypes.bool,
  selectedProdi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChangeSelectedFakultas: PropTypes.func
};
