import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
// dep components
import Button from "dep/CustomButtons/Button.js";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";
// @material-ui/icons
import IconAdd from "@material-ui/icons/Add";
// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function ButtonTambahKategoriCPL(props) {
  // props
  const { openSnackbar, selectedProdi } = props;

  // var: values
  const [openDialog, setOpenDialog] = useState(false);
  const [dataBidangCPL, setDataBidangCPL] = useState({
    nama_bidang_cpl_indonesia: "",
    nama_bidang_cpl_inggris: "",
    urutan_di_skpi: ""
  });

  // handle: API Submit
  const handleAPISubmit = async () => {
    try {
      openSnackbar("info", "Menambahkan..");
      let res = await Auth.fetch(BASE_URL + "/api/bidangcpl/add", {
        method: "POST",
        body: JSON.stringify({ ...dataBidangCPL, id_prodi: selectedProdi })
      });

      let data = await res.json();
      let errorMessages = "";
      if (typeof data.message === "string") {
        errorMessages = data.message;
      } else if (typeof data.message === "object") {
        errorMessages = "";
        if (Array.isArray(data.message)) {
          for (let err of data.message) {
            errorMessages +=
              typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
          }
        } else {
          for (let key in data.message) errorMessages += data.message[key][0];
        }
      }

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data bidang CPL berhasil ditambahkan!");
        props.handleFetchCategories();
        handleCloseDialog();
      } else if (res.status >= 400 && res.status < 500) {
        openSnackbar("warning", errorMessages);
      } else {
        openSnackbar("error", errorMessages);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  const changeDataBidangCPL = e => {
    setDataBidangCPL({
      ...dataBidangCPL,
      [e.target.name]: e.target.value
    });
  };

  // handle: open-close
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDataBidangCPL({
      nama_bidang_cpl_indonesia: "",
      nama_bidang_cpl_inggris: "",
      urutan_di_skpi: ""
    });
  };

  return (
    <>
      <Button color={"white"} size={"sm"} round onClick={handleOpenDialog}>
        <IconAdd />
        Tambah
      </Button>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="dialog-title">Tambahkan Kategori CPL</DialogTitle>
        <DialogContent>
          <DialogContentText>Tambahkan Kategori CPL Baru</DialogContentText>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <CustomInput
                labelText="Urutan"
                id="order"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "number",
                  name: "urutan_di_skpi",
                  value: dataBidangCPL.urutan_di_skpi,
                  onChange: changeDataBidangCPL
                }}
              />
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <CustomInput
                labelText="Nama Kategori"
                id="nama-bidang"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "nama_bidang_cpl_indonesia",
                  value: dataBidangCPL.nama_bidang_cpl_indonesia,
                  onChange: changeDataBidangCPL
                }}
              />
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <CustomInput
                labelText="Nama Kategori (EN)"
                id="nama-bidang-EN"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "nama_bidang_cpl_inggris",
                  value: dataBidangCPL.nama_bidang_cpl_inggris,
                  onChange: changeDataBidangCPL
                }}
              />
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="warning">
            Discard
          </Button>
          <Button
            onClick={handleAPISubmit}
            color="success"
            autoFocus
            disabled={
              !dataBidangCPL.urutan_di_skpi ||
              !dataBidangCPL.nama_bidang_cpl_indonesia ||
              !dataBidangCPL.nama_bidang_cpl_inggris
            }
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ButtonTambahKategoriCPL.propTypes = {
  handleFetchCategories: PropTypes.func,
  openSnackbar: PropTypes.func,
  selectedProdi: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
