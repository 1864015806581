import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

// dep components
import Button from "dep/CustomButtons/Button.js";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function DialogUbahHapusKategoriCPL(props) {
  const { openSnackbar, selectedProdi } = props;

  // State
  const [dataBidangCPL, setDataBidangCPL] = useState({
    urutan_di_skpi: "",
    nama_bidang_cpl_indonesia: "",
    nama_bidang_cpl_inggris: ""
  });

  //buat update karena dialog dimasukin di card card kecil
  useEffect(() => {
    if (props.open) setDataBidangCPL(props.selectedBidangCPL);
  }, [props.selectedBidangCPL, props.open]);

  // handle: API Submit
  const handleAPISubmit = async () => {
    try {
      openSnackbar("info", `${props.hapus ? "Deleting" : "Updating"}...`);

      let res = await Auth.fetch(
        `${BASE_URL}/api/bidangcpl/${props.selectedBidangCPL.id_bidang_cpl}/${
          props.hapus ? "delete" : "update"
        }`,
        {
          method: props.hapus ? "DELETE" : "PUT",
          body: JSON.stringify({ ...dataBidangCPL, id_prodi: selectedProdi })
        }
      );

      let data = await res.json();
      let errorMessages = "";
      if (typeof data.message === "string") {
        errorMessages = data.message;
      } else if (typeof data.message === "object") {
        errorMessages = "";
        if (Array.isArray(data.message)) {
          for (let err of data.message) {
            errorMessages +=
              typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
          }
        } else {
          for (let key in data.message) errorMessages += data.message[key][0];
        }
      }

      if (res.status >= 200 && res.status < 300) {
        openSnackbar(
          "success",
          `Data bidang CPL berhasil di${props.hapus ? "hapus" : "update"}!`
        );
        props.handleFetchCategories();
        handleCloseDialog();
      } else if (res.status >= 400 && res.status < 500) {
        openSnackbar("warning", errorMessages);
      } else {
        openSnackbar("error", errorMessages);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  const changeDataBidangCPL = e => {
    setDataBidangCPL({
      ...dataBidangCPL,
      [e.target.name]: e.target.value
    });
  };

  // handle: open-close
  const handleCloseDialog = () => {
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleCloseDialog}
      aria-labelledby="dialog-title"
    >
      <DialogTitle id="dialog-title">
        {props.hapus ? "Hapus" : "Ubah"} Kategori CPL
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.hapus ? "Menghapus" : "Mengubah"} Informasi Kategori CPL
        </DialogContentText>
        <GridContainer style={{ minWidth: "400px" }}>
          <GridItem xs={2} />
          <GridItem xs={8}>
            <CustomInput
              labelText="Urutan"
              id="order"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "urutan_di_skpi",
                type: "number",
                value: dataBidangCPL.urutan_di_skpi,
                onChange: changeDataBidangCPL,
                disabled: props.hapus
              }}
            />
          </GridItem>
          <GridItem xs={2} />
        </GridContainer>
        <GridContainer style={{ minWidth: "400px" }}>
          <GridItem xs={2} />
          <GridItem xs={8}>
            <CustomInput
              labelText="Nama Kategori"
              id="item-name-ID"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "nama_bidang_cpl_indonesia",
                value: dataBidangCPL.nama_bidang_cpl_indonesia,
                onChange: changeDataBidangCPL,
                disabled: props.hapus
              }}
            />
          </GridItem>
          <GridItem xs={2} />
        </GridContainer>
        <GridContainer style={{ minWidth: "400px" }}>
          <GridItem xs={2} />
          <GridItem xs={8}>
            <CustomInput
              labelText="Nama Kategori (EN)"
              id="nama-bidang-EN"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "nama_bidang_cpl_inggris",
                value: dataBidangCPL.nama_bidang_cpl_inggris,
                onChange: changeDataBidangCPL,
                disabled: props.hapus
              }}
            />
          </GridItem>
          <GridItem xs={2} />
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="warning">
          Discard
        </Button>
        <Button
          onClick={handleAPISubmit}
          color="success"
          autoFocus
          disabled={
            !dataBidangCPL.nama_bidang_cpl_indonesia ||
            !dataBidangCPL.urutan_di_skpi ||
            !dataBidangCPL.nama_bidang_cpl_inggris
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogUbahHapusKategoriCPL.propTypes = {
  hapus: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  openSnackbar: PropTypes.func,
  selectedBidangCPL: PropTypes.object,
  selectedProdi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleFetchCategories: PropTypes.func
};
