import React, { useState } from "react";

// Dep
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";

// Components
import CardProdiSelectorPreview from "components/Admin/ProdiSelector/Card/CardProdiSelectorPreview";
import DialogProdiSelector from "components/Admin/ProdiSelector/Dialog/DialogProdiSelector";
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";
import auth from "helpers/auth";
import CardManajemenCPLMain from "components/Admin/ManajemenCPL/Card/CardManajemenCPLMain";

export default function ManajemenCPL() {
  const [selectedProdi, setSelectedProdi] = useState(auth.getIdProdi());
  const [selectedFakultas, setSelectedFakultas] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });

  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };

  const handleChangeSelectedProdi = value => {
    setSelectedProdi(value);
    setOpenDialog(false);
  };

  const handleChangeOpenDialog = value => {
    setOpenDialog(value);
  };

  return (
    <div>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
      />
      <GridContainer direction="row-reverse">
        <GridItem xs={12} sm={12} md={4}>
          <CardProdiSelectorPreview
            selectedProdi={selectedProdi}
            handleChangeOpenDialog={handleChangeOpenDialog}
            selectedFakultas={selectedFakultas}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <CardManajemenCPLMain
            selectedProdi={selectedProdi}
            selectedFakultas={selectedFakultas}
            openSnackbar={openSnackbar}
          />
        </GridItem>
        <DialogProdiSelector
          openDialog={openDialog}
          handleChangeOpenDialog={handleChangeOpenDialog}
          selectedProdi={selectedProdi}
          handleChangeSelectedProdi={handleChangeSelectedProdi}
          handleChangeSelectedFakultas={setSelectedFakultas}
        />
      </GridContainer>
    </div>
  );
}
