/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
// import Dashboard from "@material-ui/icons/Dashboard";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

// core components/views/Admin for Admin layout
// import DashboardPage from "views/Verifikator/Dashboard/Dashboard.js";
import Portofolio from "views/Verifikator/Portofolio/Portofolio";
import Profile from "views/Verifikator/Profile/Profile";

const dashboardRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: Dashboard,
  //   component: DashboardPage,
  //   layout: "/verifikator"
  // },
  {
    path: "/portofolio",
    name: "Portofolio",
    icon: LibraryBooks,
    layout: "/verifikator",
    component: Portofolio
  },
  {
    path: "/profile",
    name: "Profil Verifikator",
    icon: AccountCircleIcon,
    layout: "/verifikator",
    component: Profile
  }
];

export default dashboardRoutes;
