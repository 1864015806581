import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
// @material-ui/core components
import { IconButton, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
// core components
import GridItem from "dep/Grid/GridItem.js";
import GridContainer from "dep/Grid/GridContainer.js";
import Table from "dep/Table/Table.js";
import Card from "dep/Card/Card.js";
import CardHeader from "dep/Card/CardHeader.js";
import CardBody from "dep/Card/CardBody.js";
import CustomInput from "dep/CustomInput/CustomInput";
import Button from "dep/CustomButtons/Button";
// @material-ui/icons components
import IconSearch from "@material-ui/icons/Search";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
// components
import ButtonVerifikasiMigrasiMahasiswa from "components/Admin/ManajemenMahasiswa/Button/ButtonVerifikasiMigrasiMahasiswa";
// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import cardStyles from "assets/jss/jpt/cardStyles";

//styling
const useStyles = makeStyles(cardStyles);

export default function CardManajemenMahasiswa(props) {
  const classes = useStyles();

  const { openSnackbar } = props;
  //var: values
  const [values, setValues] = useState({
    search: ""
  });
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    const init = async () => {
      openSnackbar("info", "Memuat...");
      await handleFetchListMigrasi();
      openSnackbar("success", "Ditemukan...");
    };
    init();
    // eslint-disable-next-line
  }, [page, props.listProdi, values, props.toggleReFetchMigrasi]);

  //handle: [API]fetch
  const handleFetchListMigrasi = async () => {
    let res = await Auth.fetch(
      `${BASE_URL}/api/manajemen/migrasi/list?paginate=10&page=${page}&keyword=${values.search}`
    );
    let json = await res.json();

    let data = _.get(json, "users.data", []);
    data = _.sortBy(data, values => values.updated_at);

    setPageCount(_.get(json, "users.last_page", 1));
    setTableData(
      data.map((element, key) => {
        const NIM = _.get(element, "nim", "");
        const NIM_BARU = _.get(element, "nim_baru", "");
        const NAMA = _.get(element, "user.nama", "");
        const PRODI_LAMA = _.get(element, "prodi_lama.nama_prodi", "");
        const PRODI_LAMA_JENJANG = _.get(element, "prodi_lama.jenjang", "");
        const PRODI_BARU = _.get(element, "prodi_baru.nama_prodi", "");
        const PRODI_BARU_JENJANG = _.get(element, "prodi_baru.jenjang", "");
        const TANGGAL_MASUK = _.get(element, "tanggal_masuk", "").split(" ")[0];
        const NOMOR_IJAZAH = _.get(element, "nomor_ijazah", "");

        return [
          NIM,
          NIM_BARU,
          NAMA,
          `${PRODI_LAMA} (${PRODI_LAMA_JENJANG})`,
          PRODI_BARU ? `${PRODI_BARU} (${PRODI_BARU_JENJANG})` : "-",
          TANGGAL_MASUK.split("-")[0] || "-",
          <ButtonVerifikasiMigrasiMahasiswa
            key={key}
            NIM={_.get(element, "nim")}
            dataMahasiwa={{
              ...element.user,
              nama_prodi: `${PRODI_LAMA} (${PRODI_LAMA_JENJANG})`
            }}
            migrasiType={element.type}
            migrasiData={{
              nim_baru: NIM_BARU,
              prodi_baru: PRODI_BARU,
              tanggal_masuk: TANGGAL_MASUK,
              nomor_ijazah: NOMOR_IJAZAH,
              link_bukti: `${BASE_URL + "/" + element.bukti}`
            }}
            {...props}
          />
        ];
      })
    );
  };
  //handle: values
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangePage = (_e, page) => {
    setPage(page);
  };
  // handle: open-close -> hook
  const handleChangeCard = () => {
    props.handleChangeCardType("manajemen");
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <GridContainer justify={"space-between"} alignItems={"flex-end"}>
              <GridItem>
                <h4 className={classes.cardTitleWhite}>
                  Manajemen Migrasi Mahasiswa
                </h4>
                <p className={classes.cardCategoryWhite}>
                  Verifikasi Permintaan Migrasi dan Readmisi Mahasiswa
                </p>
              </GridItem>
              <GridItem>
                <Button
                  round
                  size="sm"
                  color={"white"}
                  onClick={handleChangeCard}
                >
                  <KeyboardArrowRightIcon />
                  Mahasiswa
                </Button>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridContainer alignItems="flex-end">
              <GridItem xs={10} sm={10} md={8}>
                <CustomInput
                  onChange={handleChange("search")}
                  labelText="Cari mahasiswa"
                  id="mahasiswa"
                  onSubmit={handleFetchListMigrasi}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleChange("search"),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          type={"submit"}
                          aria-label={"search"}
                          onClick={handleFetchListMigrasi}
                        >
                          <IconSearch />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    "NIM Lama",
                    "NIM Baru",
                    "Nama",
                    "Prodi Lama",
                    "Prodi Baru",
                    "Angkatan",
                    "OPSI"
                  ]}
                  tableData={tableData}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs />
              <GridItem>
                <Pagination
                  count={pageCount}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  onChange={handleChangePage}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

CardManajemenMahasiswa.propTypes = {
  handleChangeCardType: PropTypes.func,
  listProdi: PropTypes.array,
  openSnackbar: PropTypes.func
};
