import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "dep/Grid/GridItem.js";
import GridContainer from "dep/Grid/GridContainer.js";
import CustomInput from "dep/CustomInput/CustomInput.js";
import Button from "dep/CustomButtons/Button.js";
import Card from "dep/Card/Card.js";
import CardHeader from "dep/Card/CardHeader.js";
import CardBody from "dep/Card/CardBody.js";
import CardFooter from "dep/Card/CardFooter.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { Collapse } from "@material-ui/core";
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";

// To Call API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import cardStyles from "assets/jss/jpt/cardStyles";

//styling
const useStyles = makeStyles(cardStyles);

export default function CardManajemenUniversitas() {
  const classes = useStyles();
  useEffect(() => {
    handleFetch();
  }, []);

  //var : values
  const [values, setValues] = useState({
    status_akreditasi: "Memuat..",
    no_sk_akreditasi_universitas: "Memuat..",
    alamat_universitas: "Memuat..",
    nama_rektor: "Memuat.."
  });

  //var : open-close
  const [showCardUniversitas, setShowCardUniversitas] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });

  // handle : [API] fetch
  const handleFetch = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/universitas");
    if (res.status >= 200 && res.status < 300) {
      let { universitas } = await res.json();
      setValues(universitas);
    } else {
      setValues({
        status_akreditasi: "Data tidak ditemukan!",
        no_sk_akreditasi_universitas: "",
        alamat_universitas: "",
        nama_rektor: ""
      });
    }
  };

  //handle : [API] post
  const handleAPIUpdate = async () => {
    openSnackbar("info", "Mengupdate..");
    let res = await Auth.fetch(BASE_URL + "/api/universitas/update", {
      method: "PUT",
      body: JSON.stringify(values)
    });

    let data = await res.json();
    let errorMessages = "";
    if (typeof data.message === "string") {
      errorMessages = data.message;
    } else if (typeof data.message === "object") {
      errorMessages = "";
      if (Array.isArray(data.message)) {
        for (let err of data.message) {
          errorMessages +=
            typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
        }
      } else {
        for (let key in data.message) errorMessages += data.message[key][0];
      }
    }

    if (res.status >= 200 && res.status < 300) {
      openSnackbar("success", "Data universitas berhasil diupdate!");
    } else if (res.status >= 400 && res.status < 500) {
      openSnackbar("warning", errorMessages);
    } else {
      openSnackbar("error", errorMessages);
    }

    handleCloseDialog();
  };

  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };

  //handle : values
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  //handle : open-close
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Card plain={!showCardUniversitas}>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
      />
      <div
        onClick={() => {
          setShowCardUniversitas(!showCardUniversitas);
        }}
      >
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Manajemen Universitas</h4>
          <p className={classes.cardCategoryWhite}>
            Ubah informasi mengenai universitas
          </p>
        </CardHeader>
      </div>
      <div>
        <Collapse in={showCardUniversitas}>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                <CustomInput
                  labelText="Status Akreditasi"
                  id="status-akreditas"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: values.status_akreditasi,
                    onChange: handleChange("status_akreditasi")
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                <CustomInput
                  labelText="Nomor SK Akreditasi"
                  id="sk-akreditasi"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: values.no_sk_akreditasi_universitas,
                    onChange: handleChange("no_sk_akreditasi_universitas")
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Alamat"
                  id="alamat_universitas-universitas"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: values.alamat_universitas,
                    onChange: handleChange("alamat_universitas"),
                    multiline: true,
                    rows: 3
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                <CustomInput
                  labelText="Nama Rektor"
                  id="nama-nama_rektor"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: values.nama_rektor,
                    onChange: handleChange("nama_rektor")
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <Button color="primary" onClick={handleClickOpenDialog}>
              Update Universitas
            </Button>
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Apakah Anda Yakin?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {"Anda akan merubah informasi Universitas"}
                  <br />
                  {"Akreditasi : " + values.status_akreditasi}
                  <br />
                  {"SK Akreditasi : " + values.no_sk_akreditasi_universitas}
                  <br />
                  {"Alamat : " + values.alamat_universitas}
                  <br />
                  {"Rektor : " + values.nama_rektor}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color={"danger"}>
                  Tidak
                </Button>
                <Button onClick={handleAPIUpdate} color={"success"} autoFocus>
                  Ya
                </Button>
              </DialogActions>
            </Dialog>
          </CardFooter>
        </Collapse>
      </div>
    </Card>
  );
}
