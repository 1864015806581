import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
// @material-ui/core components
import {
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import GridItem from "dep/Grid/GridItem.js";
import GridContainer from "dep/Grid/GridContainer.js";
import Button from "dep/CustomButtons/Button.js";
import CustomInput from "dep/CustomInput/CustomInput";
import CustomSelect from "dep/CustomInput/CustomSelect";
// @material-ui/icons
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
// Style
import customStyles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
// Input date
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// files
import { DropzoneDialog } from "material-ui-dropzone";
// for API
import Auth from "helpers/auth";
import { formatDate } from "helpers/inputFormatting";
import { BASE_URL } from "const";
// components
import ChipFileSingle from "components/Global/Chip/ChipFileSingle";
import cardStyles from "assets/jss/jpt/cardStyles";

const MAX_FILE_SIZE = 2000000;
const ACCEPT_FILE_TYPE = [
  "application/pdf"
];

const useStyles = makeStyles({
  ...cardStyles,
  ...customStyles
});

export default function DialogEditPortofolio(props) {
  const classes = useStyles();

  // var: values
  const [selectedCategory, setSelectedCategory] = useState({
    id_bidang_portofolio: null,
    nama_bidang_portofolio: "",
    nama_bidang_portofolio_inggris: ""
  });
  const [modalData, setModalData] = useState({
    id_portofolio: "",
    judul: "",
    judul_inggris: "",
    rubrik: "",
    anak_rubrik: "",
    tanggal: null,
    bukti_portofolio: null,
    id_kegiatan_ppk: "",
    link: "",
    skp: ""
  });
  const [errors, setErrors] = useState({
    judul_error: "",
    judul_inggris_error: "",
    rubrik_error: "",
    anak_rubrik_error: "",
    tanggal_error: "",
    bukti_portofolio_error: "",
    id_kegiatan_error: "",
    skp_error: ""
  });
  // var: values -> list
  const [listRubrik, setListRubrik] = useState([]);
  const [listAnakRubriks, setListAnakRubriks] = useState([]);
  const [listKegiatanInternal, setListKegiatanInternal] = useState([]);
  // var: open-close
  const [kegiatanInternalEnabled, setKegiatanInternalEnabled] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  useEffect(() => {
    if (props.open) handlePortofolioInit();
  }, [props.portofolio]);

  const tanggalDidMountRef = useRef(false);
  useEffect(() => {
    if (tanggalDidMountRef.current) {
      // Component has just been updated
      setErrors({ ...errors, tanggal_error: validateTanggal() });
    } else {
      // onMount
      setErrors({ ...errors, tanggal_error: "" });
      tanggalDidMountRef.current = true;
    }
  }, [modalData.tanggal]);

  const buktiPortofolioDidMountRef = useRef(false);
  useEffect(() => {
    if (buktiPortofolioDidMountRef.current) {
      // Component has just been updated
      setErrors({
        ...errors,
        bukti_portofolio_error: validateBuktiPortofolio()
      });
    } else {
      // onMount
      setErrors({ ...errors, bukti_portofolio_error: "" });
      buktiPortofolioDidMountRef.current = true;
    }
  }, [modalData.bukti_portofolio]);

  // Validation
  const validateJudul = () => {
    let judul_error = "";
    if (!kegiatanInternalEnabled && !modalData.judul) {
      judul_error = "This field is required";
    }
    return judul_error;
  };
  const validateJudulInggris = () => {
    return "";
  };
  const validateRubrik = () => {
    let rubrik_error = "";
    if (!modalData.rubrik) {
      rubrik_error = "This field is required";
    }
    return rubrik_error;
  };
  const validateAnakRubrik = () => {
    let anak_rubrik_error = "";
    if (!modalData.anak_rubrik) {
      anak_rubrik_error = "This field is required";
    }
    return anak_rubrik_error;
  };
  const validateTanggal = () => {
    let tanggal_error = "";
    if (!kegiatanInternalEnabled && !modalData.tanggal) {
      tanggal_error = "This field is required";
    }
    return tanggal_error;
  };
  const validateBuktiPortofolio = () => {
    let bukti_portofolio_error = "";
    if (
      !kegiatanInternalEnabled &&
      !modalData.bukti_portofolio &&
      !modalData.link
    ) {
      bukti_portofolio_error = "This field is required";
    }
    return bukti_portofolio_error;
  };
  const validateIdKegiatan = () => {
    let id_kegiatan_error = "";
    if (kegiatanInternalEnabled && !modalData.id_kegiatan_ppk) {
      id_kegiatan_error = "This field is required";
    }
    return id_kegiatan_error;
  };
  const validateSkp = () => {
    let skp_error = "";
    if (!kegiatanInternalEnabled && !modalData.skp) {
      skp_error = "This field is required";
    }
    return skp_error;
  };
  const validateAllInputs = () => {
    return {
      judul_error: validateJudul(),
      judul_inggris_error: validateJudulInggris(),
      rubrik_error: validateRubrik(),
      anak_rubrik_error: validateAnakRubrik(),
      tanggal_error: validateTanggal(),
      bukti_portofolio_error: validateBuktiPortofolio(),
      id_kegiatan_error: validateIdKegiatan(),
      skp_error: validateSkp()
    };
  };

  const handlePortofolioInit = async () => {
    const { portofolio } = props;
    //const { selectedCategory } = props;
    fetchRubrik(portofolio.id_bidang_portofolio);
    fetchAnakRubrik(portofolio.id_rubrik);

    if (portofolio.id_kegiatan_ppk) {
      setKegiatanInternalEnabled(true);
      handleFetchListKegiatan(portofolio.id_anak_rubrik);
    }
    setModalData({
      id_portofolio: portofolio.id_portofolio,
      judul: portofolio.judul,
      judul_inggris: portofolio.judul_inggris,
      rubrik: portofolio.id_rubrik,
      anak_rubrik: portofolio.id_anak_rubrik,
      tanggal: portofolio.tanggal_terbit,
      bukti_portofolio: null,
      id_kegiatan_ppk: portofolio.id_kegiatan_ppk,
      link: portofolio.link,
      skp: portofolio.bobot_skp_portofolio
    });
  };
  // handle : API fetch
  const fetchRubrik = async id_bidang_portofolio => {
    let id_prodi = Auth.getToken().id_prodi;
    let res = await Auth.fetch(
      BASE_URL + `/api/prodi/${id_prodi}/rubrik/bidang/${id_bidang_portofolio}`
    );
    let data = await res.json();
    const rubrik = _.get(data, "rubrik", []).map(row => {
      return {
        value: _.get(row, "id_rubrik"),
        text: _.get(row, "nama_rubrik")
      };
    });
    setListRubrik(rubrik);
  };
  const fetchAnakRubrik = async id_rubrik => {
    let res = await Auth.fetch(BASE_URL + `/api/rubrik/${id_rubrik}`);
    let data = await res.json();
    const anak_rubrik = _.get(data, "rubrik.anak_rubrik", []).map(row => {
      return {
        value: _.get(row, "id_anak_rubrik"),
        text: _.get(row, "nama_anak_rubrik"),
        max_point: _.get(row, "bobot_maksimal_skp_tiap_portofolio")
      };
    });

    setListAnakRubriks(anak_rubrik);
  };
  const handleFetchListKegiatan = async id_anak_rubrik => {
    let res = await Auth.fetch(
      BASE_URL + `/api/ppk/?id_anak_rubrik=${id_anak_rubrik}`
    );
    let data = await res.json();
    const kegiatan_ppk = _.get(data, "ppk", []).map(row => {
      return {
        value: _.get(row, "id_kegiatan_ppk"),
        text: _.get(row, "judul", ""),
        text_en: _.get(row, "judul_inggris", ""),
        skp: _.get(row, "bobot_skp_portofolio")
      };
    });
    setListKegiatanInternal(kegiatan_ppk);
  };

  // handle : API submit
  const handleUpdatePortofolio = async () => {
    let allErrors = validateAllInputs();
    setErrors({ ...errors, ...allErrors });
    let isValid = true;
    for (var err in allErrors) {
      if (allErrors[err]) {
        isValid = false;
        break;
      }
    }
    if (isValid) {
      let nim = Auth.getToken().nim;

      const formData = new FormData();
      formData.append("nim", nim);
      formData.append("id_anak_rubrik", modalData.anak_rubrik);
      if (modalData.id_kegiatan_ppk) {
        formData.append("id_kegiatan_ppk", modalData.id_kegiatan_ppk);
      } else {
        formData.append("judul", modalData.judul);
        formData.append("judul_inggris", modalData.judul_inggris);
        formData.append("bobot_skp_portofolio", modalData.skp);
        formData.append("tanggal_terbit", formatDate(modalData.tanggal));
      }
      formData.append("dokumen_portofolio", modalData.bukti_portofolio);

      props.openSnackbar("info", "Uploading");
      let res = await Auth.fetchMultipart(
        BASE_URL +
          `/api/portofolio/user/${nim}/${modalData.id_portofolio}/update`,
        {
          method: "POST",
          body: formData
        }
      );

      if (res.status >= 200 && res.status < 300) {
        props.setSnackbarOpen(false);
        props.openSnackbar("success", "Berhasil mengubah portofolio");
        props.fetchPortofolio();
        handleCloseDialog();
      } else {
        props.setSnackbarOpen(false);
        props.openSnackbar("error", (await res.json()).message);
      }
    }
  };

  // handle: values
  const changeFieldData = event => {
    setModalData({ ...modalData, [event.target.name]: event.target.value });
  };
  const handleChangeAnakRubrik = event => {
    setModalData({ ...modalData, [event.target.name]: event.target.value });
    if (kegiatanInternalEnabled) handleFetchListKegiatan(event.target.value);
  };
  const handleResetData = () => {
    setModalData({
      id_portofolio: "",
      judul: "",
      judul_inggris: "",
      rubrik: "",
      anak_rubrik: "",
      tanggal: new Date(),
      bukti_portofolio: null,
      id_kegiatan_ppk: "",
      link: ""
    });
    setErrors({
      judul_error: "",
      judul_inggris_error: "",
      rubrik_error: "",
      anak_rubrik_error: "",
      tanggal_error: "",
      bukti_portofolio_error: "",
      id_kegiatan_error: "",
      skp_error: ""
    });
    setSelectedCategory({
      id_bidang_portofolio: null,
      nama_bidang_portofolio: "",
      nama_bidang_portofolio_inggris: ""
    });
    setKegiatanInternalEnabled(false);
  };
  const changeRubrik = e => {
    setModalData({ ...modalData, [e.target.name]: e.target.value });
    fetchAnakRubrik(e.target.value);
  };
  const handleChangeKegiatanInternal = event => {
    const kegiatan = listKegiatanInternal.find(
      object => object.value === event.target.value
    );
    setModalData({
      ...modalData,
      id_kegiatan_ppk: event.target.value,
      judul: kegiatan.text,
      judul_inggris: kegiatan.text_en
    });
  };
  const handleChangeTanggal = date => {
    setModalData({ ...modalData, tanggal: date });
  };
  const handleEnableKegiatanInternal = () => {
    setKegiatanInternalEnabled(true);
    handleFetchListKegiatan(modalData.anak_rubrik);
  };
  const handleDisableKegiatanInternal = () => {
    setKegiatanInternalEnabled(false);
    setModalData({
      ...modalData,
      id_kegiatan_ppk: "",
      judul: "",
      judul_inggris: ""
    });
  };

  // handle: render
  const renderMaxPoint = idAnakRubrik => {
    return _.get(
      _.find(listAnakRubriks, { value: idAnakRubrik }),
      "max_point",
      0
    );
  };

  // handle: files
  const onSaveUpload = files => {
    setModalData({ ...modalData, bukti_portofolio: files[0] });
    setUploadDialogOpen(false);
  };
  const handleDiscardFile = () => {
    setModalData({ ...modalData, bukti_portofolio: null, link: "" });
  };

  // handle: open-close
  const handleOpenDropzone = () => {
    setUploadDialogOpen(true);
  };
  const handleCloseDropzone = () => {
    setUploadDialogOpen(false);
  };
  const handleCloseDialog = () => {
    props.onClose();
    handleResetData();
  };

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={props.open}
      onClose={handleCloseDialog}
    >
      <DialogTitle>Ubah Portofolio</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Anda akan mengubah portofolio di kategori{" "}
          {selectedCategory.nama_bidang_portofolio || ""}
        </DialogContentText>
        <GridContainer justify={"center"}>
          <GridItem xs={10} md={8}>
            <CustomSelect
              labelText="Rubrik"
              id="rubrik"
              formControlProps={{
                fullWidth: true
              }}
              error={!!errors.rubrik_error}
              helperText={errors.rubrik_error}
              selectProps={{
                name: "rubrik",
                onChange: changeRubrik,
                onBlur: () => {
                  setErrors({
                    ...errors,
                    rubrik_error: validateRubrik()
                  });
                },
                onFocus: () => {
                  setErrors({
                    ...errors,
                    rubrik_error: ""
                  });
                }
              }}
              selectItems={listRubrik}
              selectValue={modalData.rubrik}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify={"center"}>
          <GridItem xs={10} md={8}>
            <Collapse in={Boolean(modalData.rubrik)}>
              <CustomSelect
                labelText="Anak Rubrik"
                id="anak_rubrik"
                formControlProps={{
                  fullWidth: true
                }}
                error={!!errors.anak_rubrik_error}
                helperText={errors.anak_rubrik_error}
                selectProps={{
                  name: "anak_rubrik",
                  onChange: handleChangeAnakRubrik,
                  onBlur: () => {
                    setErrors({
                      ...errors,
                      anak_rubrik_error: validateAnakRubrik()
                    });
                  },
                  onFocus: () => {
                    setErrors({
                      ...errors,
                      anak_rubrik_error: ""
                    });
                  }
                }}
                selectItems={listAnakRubriks}
                selectValue={modalData.anak_rubrik}
              />
            </Collapse>
          </GridItem>
        </GridContainer>
        <div style={{ padding: "2vh" }} />
        <GridContainer justify={"center"}>
          <GridItem xs={10} md={8}>
            <GridContainer justify={"space-between"} alignItems={"center"}>
              <GridItem>
                <label>Jenis Kegiatan</label>
              </GridItem>
              <GridItem>
                <GridContainer justify={"space-between"} alignItems={"center"}>
                  <GridItem>
                    <Button
                      disabled={!modalData.anak_rubrik}
                      size={"sm"}
                      color={kegiatanInternalEnabled ? "danger" : "success"}
                      onClick={handleDisableKegiatanInternal}
                    >
                      Eksternal
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      disabled={!modalData.anak_rubrik}
                      size={"sm"}
                      color={kegiatanInternalEnabled ? "success" : "danger"}
                      onClick={handleEnableKegiatanInternal}
                    >
                      Internal
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer justify={"center"}>
          <GridItem xs={10} md={8}>
            <Collapse in={kegiatanInternalEnabled}>
              <CustomSelect
                labelText="Nama Kegiatan"
                id="id_kegiatan_ppk"
                formControlProps={{
                  fullWidth: true
                }}
                error={!!errors.id_kegiatan_error}
                helperText={errors.id_kegiatan_error}
                selectProps={{
                  name: "id_kegiatan_ppk",
                  onChange: handleChangeKegiatanInternal,
                  onBlur: () => {
                    setErrors({
                      ...errors,
                      id_kegiatan_error: validateIdKegiatan()
                    });
                  },
                  onFocus: () => {
                    setErrors({
                      ...errors,
                      id_kegiatan_error: ""
                    });
                  }
                }}
                selectItems={listKegiatanInternal}
                selectValue={modalData.id_kegiatan_ppk}
              />
            </Collapse>
          </GridItem>
        </GridContainer>
        <GridContainer justify={"center"}>
          <GridItem xs={10} md={8}>
            <Collapse in={!kegiatanInternalEnabled}>
              <CustomInput
                labelText="Judul"
                id="judul"
                formControlProps={{
                  fullWidth: true
                }}
                error={!!errors.judul_error}
                helperText={errors.judul_error}
                inputProps={{
                  name: "judul",
                  value: modalData.judul,
                  onChange: changeFieldData,
                  onBlur: () => {
                    setErrors({
                      ...errors,
                      judul_error: validateJudul()
                    });
                  },
                  onFocus: () => {
                    setErrors({
                      ...errors,
                      judul_error: ""
                    });
                  }
                }}
              />
            </Collapse>
          </GridItem>
        </GridContainer>
        <GridContainer justify={"center"}>
          <GridItem xs={10} md={8}>
            <Collapse in={!kegiatanInternalEnabled}>
              <CustomInput
                labelText="Judul dalam Bahasa Inggris"
                id="judul_inggris"
                formControlProps={{
                  fullWidth: true
                }}
                error={!!errors.judul_inggris_error}
                helperText={errors.judul_inggris_error}
                inputProps={{
                  name: "judul_inggris",
                  value: modalData.judul_inggris,
                  onChange: changeFieldData,
                  onBlur: () => {
                    setErrors({
                      ...errors,
                      judul_inggris_error: validateJudulInggris()
                    });
                  },
                  onFocus: () => {
                    setErrors({
                      ...errors,
                      judul_inggris_error: ""
                    });
                  }
                }}
              />
            </Collapse>
          </GridItem>
        </GridContainer>
        <Collapse in={kegiatanInternalEnabled}>
          <GridContainer justify={"center"}>
            <GridItem xs={10} md={4}>
              <GridContainer justify={"center"}>
                <GridItem>
                  {modalData.bukti_portofolio || modalData.link ? (
                    <ChipFileSingle
                      fileName={
                        modalData.bukti_portofolio === null
                          ? ""
                          : modalData.bukti_portofolio.name
                      }
                      link={modalData.link}
                      discardFile={handleDiscardFile}
                    />
                  ) : (
                    <Button
                      color={"info"}
                      onClick={handleOpenDropzone}
                      round
                      size={"sm"}
                    >
                      <CloudUploadIcon />
                      Upload Bukti
                    </Button>
                  )}
                </GridItem>
                <FormHelperText style={{ color: "red" }}>
                  {errors.bukti_portofolio_error}
                </FormHelperText>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Collapse>
        <Collapse in={!kegiatanInternalEnabled}>
          <GridContainer justify={"center"} alignItems={"center"}>
            <GridItem xs={10} md={4}>
              <FormControl
                className={classes.formControl}
                error={!!errors.tanggal_error}
                style={{ marginBottom: "20px" }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    id="tanggal"
                    label="Tanggal"
                    format="dd/MM/yyyy"
                    value={modalData.tanggal}
                    onChange={date => handleChangeTanggal(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText>{errors.tanggal_error}</FormHelperText>
              </FormControl>
            </GridItem>
            <GridItem xs={10} md={4}>
              <GridContainer justify={"center"}>
                <GridItem>
                  {modalData.bukti_portofolio || modalData.link ? (
                    <ChipFileSingle
                      fileName={
                        modalData.bukti_portofolio === null
                          ? ""
                          : modalData.bukti_portofolio.name
                      }
                      link={modalData.link}
                      discardFile={handleDiscardFile}
                    />
                  ) : (
                    <Button
                      color={"info"}
                      onClick={handleOpenDropzone}
                      round
                      size={"sm"}
                    >
                      <CloudUploadIcon />
                      Upload Bukti
                    </Button>
                  )}
                </GridItem>
                <FormHelperText style={{ color: "red" }}>
                  {errors.bukti_portofolio_error}
                </FormHelperText>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem xs={8} md={5}>
              <CustomInput
                labelText={`Point SKP (max ${renderMaxPoint(
                  modalData.anak_rubrik
                )})`}
                id="skp"
                formControlProps={{
                  fullWidth: true
                }}
                error={!!errors.skp_error}
                helperText={errors.skp_error}
                inputProps={{
                  value: modalData.skp,
                  name: "skp",
                  type: "number",
                  onChange: changeFieldData,
                  onBlur: () => {
                    setErrors({
                      ...errors,
                      skp_error: validateSkp()
                    });
                  },
                  onFocus: () => {
                    setErrors({
                      ...errors,
                      skp_error: ""
                    });
                  }
                }}
              />
            </GridItem>
          </GridContainer>
        </Collapse>
        <GridContainer justify="space-evenly">
          <GridItem>
            <Button
              type="button"
              color="danger"
              round
              onClick={handleCloseDialog}
            >
              Batal
            </Button>
          </GridItem>
          <GridItem>
            <Button
              disabled={
                (!modalData.bukti_portofolio && !modalData.link) ||
                !modalData.rubrik ||
                !modalData.anak_rubrik ||
                !modalData.judul ||
                !modalData.skp
              }
              type="button"
              color="success"
              round
              //style={buttonStyle}
              onClick={handleUpdatePortofolio}
            >
              Update
            </Button>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem>
            <DropzoneDialog
              open={uploadDialogOpen}
              onSave={onSaveUpload}
              onClose={handleCloseDropzone}
              filesLimit={1}
              maxFileSize={MAX_FILE_SIZE}
              acceptedFiles={ACCEPT_FILE_TYPE}
              showFileNames={true}
              dropzoneText={"Upload bukti portofolio (pdf)"}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
}

DialogEditPortofolio.propTypes = {
  fetchPortofolio: PropTypes.func,
  handleClose: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  openSnackbar: PropTypes.func,
  portofolio: PropTypes.object,
  selectedCategory: PropTypes.object,
  setSnackbarOpen: PropTypes.func
};
