import React, { useState } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  DialogContentText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// dep components
import Button from "dep/CustomButtons/Button";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";

// @material-ui/icons
import IconAdd from "@material-ui/icons/Add";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import formStyles from "assets/jss/jpt/formStyles";

// Constant
const ID_ROLE_ADMIN_JURUSAN = 2;

//styling
const useStyles = makeStyles(formStyles);

export default function ButtonTambahManajemenAdminProdi({
  listProdi,
  openSnackbar,
  handleFetchUserData
}) {
  const classes = useStyles;

  // var: values
  const [userState, setUserState] = useState({
    nim: "",
    email: "",
    nama: "",
    id_prodi: "",
    password: "",
    password_confirmation: "",
    id_role: ID_ROLE_ADMIN_JURUSAN,
    agama: "-",
    alamat: "-",
    jenis_kelamin: 1,
    kewarganegaraan: 102,
    id_provinsi: 12,
    id_kota: 1271,
    tanggal_lahir: "1900-01-01",
    tanggal_masuk: "1900-01-01",
    tempat_lahir: "-"
  });

  // var: open-close
  const [openSelectProdi, setOpenSelectProdi] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  //handle: [API] Submit
  const handleAPISubmit = async () => {
    try {
      openSnackbar("info", "Menambahkan..");
      let res = await Auth.fetch(BASE_URL + "/api/manajemen/akun/add", {
        method: "POST",
        body: JSON.stringify({ ...userState, nik: userState.nim })
      });

      let data = await res.json();
      let errorMessages = "";
      if (typeof data.message === "string") {
        errorMessages = data.message;
      } else if (typeof data.message === "object") {
        errorMessages = "";
        if (Array.isArray(data.message)) {
          for (let err of data.message) {
            errorMessages +=
              typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
          }
        } else {
          for (let key in data.message) errorMessages += data.message[key][0];
        }
      }

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data user berhasil ditambahkan!");
        handleFetchUserData();
        handleCloseDialog();
      } else if (res.status >= 400 && res.status < 500) {
        openSnackbar("warning", errorMessages);
      } else {
        openSnackbar("error", errorMessages);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  const handleChangeUserState = e => {
    setUserState({ ...userState, [e.target.name]: e.target.value });
  };

  const handleCheckPassword = () => {
    return userState.password === userState.password_confirmation;
  };

  // handle: open-close
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseSelectProdi = () => {
    setOpenSelectProdi(false);
  };

  const handleOpenSelectProdi = () => {
    setOpenSelectProdi(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Button color="white" size={"sm"} round onClick={handleClickOpenDialog}>
        <IconAdd />
        Tambah
      </Button>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Tambah Admin Prodi"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"Silahkan masukkan informasi mengenai Admin Program Studi"}
          </DialogContentText>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <CustomInput
                labelText="Username"
                id="nim"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "nim",
                  value: userState.nim,
                  onChange: handleChangeUserState
                }}
              />
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <CustomInput
                labelText="Nama"
                id="nama"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "nama",
                  value: userState.nama,
                  onChange: handleChangeUserState
                }}
              />
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <CustomInput
                labelText="Email"
                id="email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "email",
                  value: userState.email,
                  onChange: handleChangeUserState
                }}
              />
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <CustomInput
                labelText="Password"
                id="password"
                error={!handleCheckPassword()}
                success={handleCheckPassword()}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "password",
                  value: userState.password,
                  type: "password",
                  onChange: handleChangeUserState
                }}
              />
              <CustomInput
                labelText="Re-Enter New Password"
                id="password_confirmation"
                error={!handleCheckPassword()}
                success={handleCheckPassword()}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "password_confirmation",
                  value: userState.password_confirmation,
                  type: "password",
                  onChange: handleChangeUserState
                }}
              />
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <FormControl
                className={classes.formControl}
                fullWidth
                style={{ marginTop: "30px", marginBottom: "30px" }}
              >
                <InputLabel id="controlled-open-select-label">
                  Pilih Program Studi
                </InputLabel>
                <Select
                  labelId="controlled-open-select-label"
                  id="controlled-open-select"
                  open={openSelectProdi}
                  onClose={handleCloseSelectProdi}
                  onOpen={handleOpenSelectProdi}
                  value={userState.id_prodi}
                  onChange={handleChangeUserState}
                  name="id_prodi"
                >
                  {listProdi.map((row, key) => (
                    <MenuItem key={key} value={row.id_prodi}>
                      {row.nama_prodi + ` (${row.jenjang})`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="warning">
            Discard
          </Button>
          <Button
            onClick={handleAPISubmit}
            color="success"
            autoFocus
            disabled={
              !userState.nim ||
              !userState.email ||
              !userState.id_prodi ||
              !handleCheckPassword()
            }
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ButtonTambahManajemenAdminProdi.propTypes = {
  listProdi: PropTypes.array,
  openSnackbar: PropTypes.func,
  handleFetchUserData: PropTypes.func
};
