import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

// styling
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";
import buttonsStyles from "assets/jss/jpt/buttonsStyles";

const useStyles = makeStyles({
  ...tasksStyle,
  ...buttonsStyles
});

export default function ButtonsManajemenCPL(props) {
  const handleButtonAdd = () => {
    props.handleChangeSelectedCPL(props.cplData);
    props.handleChangeNewDialogType("add");
  };
  const handleButtonEdit = () => {
    props.handleChangeSelectedCPL(props.cplData);
    props.handleChangeNewDialogType("edit");
  };
  const handleButtonDelete = () => {
    props.handleChangeSelectedCPL(props.cplData);
    props.handleChangeNewDialogType("delete");
  };
  const classes = useStyles();
  return (
    <>
      <Tooltip
        id="tooltip-top"
        title="Tambah Anak CPL"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="Add"
          className={classes.tableActionButton}
          onClick={handleButtonAdd}
        >
          <AddIcon
            className={classes.tableActionButtonIcon + " " + classes.buttonAdd}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        id="tooltip-top"
        title="Ubah CPL"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="Edit"
          className={classes.tableActionButton}
          onClick={handleButtonEdit}
        >
          <EditIcon
            className={classes.tableActionButtonIcon + " " + classes.buttonEdit}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        id="tooltip-top"
        title="Hapus CPL"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="Delete"
          className={classes.tableActionButton}
          onClick={handleButtonDelete}
        >
          <DeleteForeverIcon
            className={classes.tableActionButtonIcon + " " + classes.close}
          />
        </IconButton>
      </Tooltip>
      {props.cplData.is_expanded ? (
        <Tooltip
          id="tooltip-collapse-rubrik"
          title="Collapse"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            aria-label="collapse"
            className={classes.tableActionButton}
            onClick={() => props.collapseRow(props.cplData.id_cpl)}
          >
            <ExpandLessIcon className={classes.tableActionButtonIcon} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          id="tooltip-expand-rubrik"
          title="Expand"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            aria-label="expand"
            className={classes.tableActionButton}
            onClick={() => props.expandRow(props.cplData.id_cpl)}
          >
            <ExpandMoreIcon className={classes.tableActionButtonIcon} />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

ButtonsManajemenCPL.propTypes = {
  handleChangeSelectedAnakCPL: PropTypes.func,
  cplData: PropTypes.object,
  handleChangeSelectedCPL: PropTypes.func,
  handleChangeNewDialogType: PropTypes.func
};
