import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";
import PropTypes from "prop-types";
// @material-ui/core components
import { Avatar, Container, Divider, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
// components
import Navbar from "components/Public/Navbar/Navbar";
import Footer from "components/Public/Footer/Footer";
import ProfileTimelineStepper from "components/Public/Profile/ProfileTimelineStepper";
import ProfileTimeline from "components/Public/Profile/ProfileTimeline";

import { BASE_URL, LANDING_PAGE_BG_DESKTOP } from "const";

import Auth from "helpers/auth";

const useStyles = makeStyles(theme => ({
  background: {
    backgroundImage: "url(" + LANDING_PAGE_BG_DESKTOP + ")",
    backgroundPosition: "left top",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  container: {
    paddingTop: "10vh",
    paddingBottom: "10vh"
  },
  avatar: { width: theme.spacing(17), height: theme.spacing(17) },
  textSocmedValue: {
    margin: 0,
    fontSize: "13px",
    fontStyle: "italic",
    textAlign: "right"
  },
  textSocmed: {
    margin: 0,
    fontSize: "13px",
    color: "#4f4f4f"
  },
  textName: {
    textAlign: "center"
  },
  textNIM: {
    textAlign: "center",
    color: "#4f4f4f",
    fontSize: "18px",
    fontStyle: "italic"
  },
  bio: {
    margin: 0,
    fontSize: "13px",
    color: "#4f4f4f",
    textAlign: "center"
  },
  dataTitle: {
    margin: 0,
    fontSize: "16px",
    fontStyle: "italic",
    marginTop: "1vh",
    marginBottom: "1vh"
  }
}));

export default function Profile(props) {
  const { nim } = props.match.params;
  const classes = useStyles();
  useEffect(() => {
    handleFetch();
  }, []);

  // var: values
  const [data, setData] = useState({
    nama: "",
    NIM: "",
    bio: "",
    photo: ""
  });
  const [contactInfo, setContactInfo] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [timelineYears, setTimelineYears] = useState([]);
  const [timelineData, setTimelineData] = useState([]);
  const [timeline, setTimeline] = useState([]);

  // handle: API
  const handleFetch = async () => {
    let res = await Auth.fetch(`${BASE_URL}/api/mahasiswa/show?nim=${nim}`);
    let data = await res.json();

    setData({
      nama: _.get(data, "data.biodata.nama", ""),
      NIM: nim,
      bio: _.get(data, "data.biodata.bio", ""),
      photo: BASE_URL + "/" + _.get(data, "data.biodata.foto_profil", "")
    });

    setContactInfo(
      _.get(data, "data.sosmed", []).map(e => ({
        title: _.get(e, "jenis_sosmed", ""),
        name: _.get(e, "nama", ""),
        link: _.get(e, "link_sosmed", "")
      }))
    );

    const fetchTimelineData = _.get(data, "data.portofolio", [])
      .map(e => ({
        title_id: _.get(e, "judul", ""),
        title_en: _.get(e, "judul_inggris", ""),
        date: _.get(e, "tanggal_terbit", ""),
        year: _.get(e, "tahun", "") + ""
      }))
      .sort((a, b) => (a.date < b.date ? -1 : 1));

    setTimelineData(fetchTimelineData);
    setTimeline(fetchTimelineData);
    setTimelineYears(_.uniq(fetchTimelineData.map(value => value.year)).sort());
  };
  // handle: values
  const handleChangeTimelineYear = year => {
    if (year) setTimeline(timelineData.filter(value => value.year === year));
    else setTimeline(timelineData);
    setSelectedYear(year);
  };

  return (
    <>
      <Helmet>
        <title>{data.nama} - Janabadra Professional Tracker</title>
        <meta
          name="description"
          content={`${data.nama}, ${data.bio}, ${contactInfo.map(
            value => value.title
          )}`}
        />
      </Helmet>
      <div className={classes.background}>
        <Navbar {...props} />
      </div>
      <Container maxWidth={"lg"} className={classes.container}>
        <GridContainer>
          <GridItem xs={12} md={4}>
            <Paper elevation={2}>
              <br />
              <GridContainer justify={"center"}>
                <GridItem>
                  <Avatar
                    alt={data.nama}
                    src={data.photo}
                    className={classes.avatar}
                  />
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer justify={"center"}>
                <GridItem xs={9}>
                  <h4 className={classes.textName}>{data.nama}</h4>
                </GridItem>
              </GridContainer>
              <GridContainer justify={"center"}>
                <GridItem>
                  <p className={classes.textNIM}>{data.NIM}</p>
                </GridItem>
              </GridContainer>
              <GridContainer justify={"center"}>
                <GridItem xs={4}>
                  <Divider />
                </GridItem>
              </GridContainer>
              <GridContainer justify={"center"}>
                <GridItem>
                  <p className={classes.dataTitle}>About me</p>
                </GridItem>
              </GridContainer>
              <GridContainer justify={"center"}>
                <GridItem xs={9}>
                  <p className={classes.bio}>{data.bio}</p>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer justify={"center"}>
                <GridItem xs={4}>
                  <Divider />
                </GridItem>
              </GridContainer>
              <GridContainer justify={"center"}>
                <GridItem>
                  <p className={classes.dataTitle}>Social Media</p>
                </GridItem>
              </GridContainer>
              <GridContainer justify={"center"}>
                <GridItem xs={8}>
                  {contactInfo.map((values, key) => (
                    <GridContainer key={key} justify={"space-between"}>
                      <GridItem>
                        <p className={classes.textSocmed}>{values.title}</p>
                      </GridItem>
                      <GridItem>
                        <p className={classes.textSocmedValue}>
                          <a
                            target="_blank"
                            href={values.link}
                            rel="noopener noreferrer"
                          >
                            {values.name}
                          </a>
                        </p>
                      </GridItem>
                    </GridContainer>
                  ))}
                </GridItem>
              </GridContainer>
              <br />
            </Paper>
            <br />
          </GridItem>
          <GridItem xs={12} md={8}>
            <ProfileTimelineStepper
              onChange={handleChangeTimelineYear}
              years={timelineYears}
              selectedYear={selectedYear}
            />
            <ProfileTimeline timeline={timeline} />
          </GridItem>
        </GridContainer>
      </Container>
      <Footer />
    </>
  );
}

Profile.propTypes = {
  match: PropTypes.any
};
