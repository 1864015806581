import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
// dep components
import GridItem from "dep/Grid/GridItem";
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";
import CardBody from "dep/Card/CardBody";
import CardFooter from "dep/Card/CardFooter";
import GridContainer from "dep/Grid/GridContainer";
import Table from "dep/Table/Table";

// components
import ButtonTambahManajemenCPL from "components/Admin/ManajemenCPL/Button/ButtonTambahManajemenCPL";
import ButtonsManajemenCPL from "components/Admin/ManajemenCPL/Button/ButtonsManajemenCPL";
import ButtonsManajemenAnakCPL from "components/Admin/ManajemenCPL/Button/ButtonsManajemenAnakCPL";
import DialogEditManajemenCPL from "components/Admin/ManajemenCPL/Dialog/DialogEditManajemenCPL";
import DialogTambahChildrenManajemenCPL from "components/Admin/ManajemenCPL/Dialog/DialogTambahChildrenManajemenCPL";
import DialogHapusManajemenCPL from "components/Admin/ManajemenCPL/Dialog/DialogHapusManajemenCPL";
import DialogHapusAnakCPL from "components/Admin/ManajemenCPL/Dialog/DialogHapusAnakCPL";
import DialogEditAnakCPL from "components/Admin/ManajemenCPL/Dialog/DialogEditAnakCPL";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
// styles
import cardStyles from "assets/jss/jpt/cardStyles";
import CloseIcon from "@material-ui/icons/Close";
import buttonsStyles from "assets/jss/jpt/buttonsStyles";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle";

const ITEM_PER_PAGE = 10;

//styling
const useStyles = makeStyles({
  ...cardStyles,
  ...buttonsStyles,
  ...taskStyles
});

export default function CardManajemenCPLCategory(props) {
  const classes = useStyles();

  const {
    openSnackbar,
    selectedProdi,
    selectedCategory,
    handleBackButton
  } = props;

  const prevSelectedProdi = useRef();

  // var: values
  const [tableData, setTableData] = useState([]);
  const [cplDatas, setCplDatas] = useState({});
  const [selectedCPL, setSelectedCPL] = useState();
  const [selectedAnakCPL, setSelectedAnakCPL] = useState();
  const [newDialogType, setNewDialogType] = useState("");

  // Pagination
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    if (
      selectedProdi !== prevSelectedProdi.current &&
      !!prevSelectedProdi.current
    )
      handleBackButton();

    if (selectedProdi) fetchDataCPL();

    prevSelectedProdi.current = selectedProdi;
    // eslint-disable-next-line
  }, [selectedProdi, page]);

  // handle: values -> hook
  const handleChangeSelectedCPL = cpl => {
    setSelectedCPL(cpl);
  };

  const handleChangeSelectedAnakCPL = cpl => {
    setSelectedAnakCPL(cpl);
  };

  const handleChangeNewDialogType = type => {
    setNewDialogType(type);
  };

  const handleChangePage = (_e, page) => {
    setPage(page);
  };

  // handle: [API] fetch
  const fetchDataCPL = async () => {
    let res = await Auth.fetch(
      `${BASE_URL}/api/bidangcpl/${selectedCategory.id_bidang_cpl}?paginate=${ITEM_PER_PAGE}&page=${page}`
    );
    let data = await res.json();

    console.log(data);
    setPageCount(_.get(data, "bidang_cpl.cpl.last_page"));

    setCplDatas(
      _.get(data, "bidang_cpl.cpl.data", [])
        .sort((a, b) => a.urutan_di_skpi - b.urutan_di_skpi)
        .reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id_cpl]: { ...curr, is_expanded: false }
          }),
          {}
        )
    );
  };

  const expandRow = id_bidang_cpl => {
    let tmp = { ...cplDatas };
    tmp[id_bidang_cpl].is_expanded = true;
    setCplDatas(tmp);
  };

  const collapseRow = id_bidang_cpl => {
    let tmp = { ...cplDatas };
    tmp[id_bidang_cpl].is_expanded = false;
    setCplDatas(tmp);
  };

  useEffect(() => {
    let cpls = [];
    let idx = (page - 1) * ITEM_PER_PAGE;

    let elements = [];
    for (let key in cplDatas) {
      elements.push(cplDatas[key]);
    }
    elements.sort((a, b) => a.urutan_di_skpi - b.urutan_di_skpi);

    for (let element of elements) {
      ++idx;
      cpls.push([
        <b key={idx}>{element.urutan_di_skpi}</b>,
        <b key={idx}>{element.judul_cpl_indonesia}</b>,
        <b key={idx}>{element.judul_cpl_inggris}</b>,
        <ButtonsManajemenCPL
          key={idx}
          handleChangeSelectedCPL={handleChangeSelectedCPL}
          handleChangeNewDialogType={handleChangeNewDialogType}
          handleFetchCPLData={fetchDataCPL}
          openSnackbar={props.openSnackbar}
          cplData={element}
          expandRow={expandRow}
          collapseRow={collapseRow}
        />
      ]);

      if (element.is_expanded) {
        for (let c of element.point_cpl) {
          cpls.push([
            <></>,
            `${element.urutan_di_skpi}.${c.urutan_di_skpi} ${c.point_cpl_indonesia}`,
            c.point_cpl_inggris,
            <>
              <ButtonsManajemenAnakCPL
                handleChangeSelectedCPL={handleChangeSelectedCPL}
                handleChangeSelectedAnakCPL={handleChangeSelectedAnakCPL}
                handleChangeNewDialogType={handleChangeNewDialogType}
                handleFetchCPLData={fetchDataCPL}
                openSnackbar={props.openSnackbar}
                cplData={c}
                parentCPL={element}
              />
            </>
          ]);
        }
      }
    }

    setTableData(cpls);
  }, [cplDatas]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <GridContainer justify={"space-between"} alignItems={"center"}>
              <GridItem>
                <h4 className={classes.cardTitleWhite}>
                  Manajemen Capaian Pembelajaran Lulusan
                </h4>
                <p className={classes.cardCategoryWhite}>
                  Bidang: {selectedCategory.nama_bidang_cpl_indonesia}
                </p>
              </GridItem>
              <GridItem>
                <GridContainer direction={"column"} alignItems={"flex-end"}>
                  <GridItem>
                    <Tooltip
                      id="tooltip-top"
                      title="Close"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label="Edit"
                        className={classes.tableActionButton}
                        onClick={handleBackButton}
                      >
                        <CloseIcon
                          className={
                            classes.tableActionButtonIcon +
                            " " +
                            classes.buttonClose
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </GridItem>
                  <GridItem>
                    <ButtonTambahManajemenCPL
                      id_prodi={selectedProdi}
                      selectedCategory={selectedCategory}
                      handleFetchCPLData={fetchDataCPL}
                      openSnackbar={openSnackbar}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={[
                "No",
                "Nama Item (Bahasa)",
                "Item Name (English)",
                "Opsi"
              ]}
              tableData={tableData}
            />
          </CardBody>
          <CardFooter>
            <GridContainer>
              <GridItem xs />
              <GridItem>
                <Pagination
                  count={pageCount}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  onChange={handleChangePage}
                />
              </GridItem>
            </GridContainer>
          </CardFooter>
        </Card>
      </GridItem>
      <DialogEditManajemenCPL
        selectedCPL={selectedCPL}
        newDialogType={newDialogType}
        handleChangeSelectedCPL={handleChangeSelectedCPL}
        handleChangeNewDialogType={handleChangeNewDialogType}
        handleFetchCPLData={fetchDataCPL}
        openSnackbar={props.openSnackbar}
      />
      <DialogTambahChildrenManajemenCPL
        parentCPL={selectedCPL}
        newDialogType={newDialogType}
        handleChangeSelectedCPL={handleChangeSelectedCPL}
        handleChangeNewDialogType={handleChangeNewDialogType}
        handleFetchCPLData={fetchDataCPL}
        openSnackbar={props.openSnackbar}
      />
      <DialogHapusManajemenCPL
        selectedCPL={selectedCPL}
        newDialogType={newDialogType}
        handleChangeSelectedCPL={handleChangeSelectedCPL}
        handleChangeNewDialogType={handleChangeNewDialogType}
        handleFetchCPLData={fetchDataCPL}
        openSnackbar={props.openSnackbar}
      />
      <DialogEditAnakCPL
        parentCPL={selectedCPL}
        selectedAnakCPL={selectedAnakCPL}
        newDialogType={newDialogType}
        handleChangeSelectedCPL={handleChangeSelectedAnakCPL}
        handleChangeNewDialogType={handleChangeNewDialogType}
        handleFetchCPLData={fetchDataCPL}
        openSnackbar={props.openSnackbar}
      />
      <DialogHapusAnakCPL
        parentCPL={selectedCPL}
        selectedAnakCPL={selectedAnakCPL}
        newDialogType={newDialogType}
        handleChangeSelectedCPL={handleChangeSelectedAnakCPL}
        handleChangeNewDialogType={handleChangeNewDialogType}
        handleFetchCPLData={fetchDataCPL}
        openSnackbar={props.openSnackbar}
      />
    </GridContainer>
  );
}

CardManajemenCPLCategory.propTypes = {
  handleBackButton: PropTypes.func,
  openSnackbar: PropTypes.func,
  selectedCategory: PropTypes.object,
  selectedProdi: PropTypes.number
};
