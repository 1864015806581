import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

// @material-ui/core components
import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// dep components
import Button from "dep/CustomButtons/Button";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";

// @material-ui/icons
import DialogContentText from "@material-ui/core/DialogContentText";
import EditIcon from "@material-ui/icons/Edit";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import formStyles from "assets/jss/jpt/formStyles";

//styling
const useStyles = makeStyles({
  ...formStyles,
  ...taskStyles
});

export default function DialogEditManajemenAdminProdi({
  listProdi,
  openSnackbar,
  handleFetchUserData,
  selectedUser,
  handleChangeNewDialogType,
  handleChangeSelectedUser,
  type
}) {
  const classes = useStyles();

  // var: values
  const [userState, setUserState] = useState({
    ...selectedUser,
    nim_lama: selectedUser.nim,
    password: "",
    password_confirmation: ""
  });

  // var: open-close
  const [openSelectProdi, setOpenSelectProdi] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (type === "edit") {
      setOpenDialog(true);
      setUserState({
        ...userState,
        nim_lama: selectedUser.nim,
        ...selectedUser
      });
    }
  }, [type, selectedUser]);

  //handle: [API] Submit
  const handleAPISubmit = async () => {
    try {
      openSnackbar("info", "Mengupdate..");

      let res = await Auth.fetch(BASE_URL + "/api/manajemen/akun/update", {
        method: "PUT",
        body: JSON.stringify(getPUTData(userState))
      });

      let data = await res.json();
      let errorMessages = "";
      if (typeof data.message === "string") {
        errorMessages = data.message;
      } else if (typeof data.message === "object") {
        errorMessages = "";
        if (Array.isArray(data.message)) {
          for (let err of data.message) {
            errorMessages +=
              typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
          }
        } else {
          for (let key in data.message) errorMessages += data.message[key][0];
        }
      }

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data user berhasil diupdate!");
        handleFetchUserData();
        handleCloseDialog();
      } else if (res.status >= 400 && res.status < 500) {
        openSnackbar("warning", errorMessages);
      } else {
        openSnackbar("error", errorMessages);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  const getPUTData = e => {
    return {
      nama: _.get(e, "nama"),
      nim: _.get(e, "nim_lama"),
      nim_baru:
        _.get(e, "nim") !== _.get(e, "nim_lama") ? _.get(e, "nim") : undefined,
      email: _.get(e, "email"),
      password: _.get(e, "password"),
      password_confirmation: _.get(e, "password_confirmation"),
      id_prodi: _.get(e, "id_prodi"),
      id: _.get(e, "id")
    };
  };

  const handleChangeUserState = e => {
    setUserState({ ...userState, [e.target.name]: e.target.value });
  };

  const handleCheckPassword = () => {
    return userState.password === userState.password_confirmation;
  };

  // handle: open-close
  const handleCloseSelectProdi = () => {
    setOpenSelectProdi(false);
  };

  const handleOpenSelectProdi = () => {
    setOpenSelectProdi(true);
  };

  const handleOpenEditPassword = () => {
    setEditPassword(true);
  };

  const handleCloseEditPassword = () => {
    setEditPassword(false);
    setUserState({ ...userState, password: "", password_confirmation: "" });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    handleChangeNewDialogType("");
    handleChangeSelectedUser("");
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Ubah Admin Program Studi"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"Silahkan masukkan informasi mengenai Admin Program Studi"}
          </DialogContentText>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <CustomInput
                labelText="Username"
                id="nim"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "nim",
                  value: userState.nim,
                  onChange: handleChangeUserState
                }}
              />
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <CustomInput
                labelText="Nama"
                id="nama"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "nama",
                  value: userState.nama,
                  onChange: handleChangeUserState
                }}
              />
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <CustomInput
                labelText="email"
                id="email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "email",
                  value: userState.email,
                  onChange: handleChangeUserState
                }}
              />
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <Collapse in={!editPassword}>
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: "********",
                    disabled: true,
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <Tooltip
                          id="tooltip-top"
                          title="Edit Password"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton
                            aria-label="Edit"
                            className={classes.tableActionButton}
                            onClick={handleOpenEditPassword}
                          >
                            <EditIcon
                              className={
                                classes.tableActionButtonIcon +
                                " " +
                                classes.edit
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                />
              </Collapse>
              <Collapse in={editPassword}>
                <CustomInput
                  labelText="New Password"
                  id="new-password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "password",
                    value: userState.password,
                    type: "password",
                    onChange: handleChangeUserState,
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <Tooltip
                          id="tooltip-top"
                          title="Close edit password"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton
                            aria-label="Edit"
                            className={classes.tableActionButton}
                            onClick={handleCloseEditPassword}
                          >
                            <EditIcon
                              className={
                                classes.tableActionButtonIcon +
                                " " +
                                classes.close
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Re-Enter New Password"
                  id="password_confirmation"
                  error={!handleCheckPassword()}
                  success={handleCheckPassword()}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "password_confirmation",
                    value: userState.password_confirmation,
                    type: "password",
                    onChange: handleChangeUserState
                  }}
                />
              </Collapse>
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <FormControl
                className={classes.formControl}
                fullWidth
                style={{ marginTop: "30px", marginBottom: "30px" }}
              >
                <InputLabel id="controlled-open-select-label">
                  Pilih Program Studi
                </InputLabel>
                <Select
                  labelId="controlled-open-select-label"
                  id="controlled-open-select"
                  open={openSelectProdi}
                  onClose={handleCloseSelectProdi}
                  onOpen={handleOpenSelectProdi}
                  value={userState.id_prodi}
                  onChange={handleChangeUserState}
                  name="id_prodi"
                >
                  {listProdi.map((row, key) => (
                    <MenuItem key={key} value={row.id_prodi}>
                      {row.nama_prodi + ` (${row.jenjang})`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="warning">
            Discard
          </Button>
          <Button
            onClick={handleAPISubmit}
            color="success"
            autoFocus
            disabled={
              !userState.id_prodi ||
              !userState.nim ||
              !userState.email ||
              editPassword
                ? !handleCheckPassword()
                : false
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DialogEditManajemenAdminProdi.propTypes = {
  listProdi: PropTypes.array,
  openSnackbar: PropTypes.func,
  handleFetchUserData: PropTypes.func,
  selectedUser: PropTypes.any,
  handleChangeNewDialogType: PropTypes.func,
  handleChangeSelectedUser: PropTypes.func,
  type: PropTypes.string
};
