import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

// dep components
import Button from "dep/CustomButtons/Button.js";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";

// @material-ui/icons
import IconAdd from "@material-ui/icons/Add";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function ButtonTambahManajemenCPL(props) {
  const {
    id_prodi,
    openSnackbar,
    handleFetchCPLData,
    selectedCategory
  } = props;

  // var: open-close
  const [openDialog, setOpenDialog] = useState(false);

  // var: values
  const [dataCPL, setDataCPL] = useState({
    urutan_di_skpi: "",
    judul_cpl_indonesia: "",
    judul_cpl_inggris: ""
  });

  // handle: API Submit
  const handleAPISubmit = async () => {
    try {
      openSnackbar("info", "Menambahkan..");
      let res = await Auth.fetch(BASE_URL + "/api/cpl/add", {
        method: "POST",
        body: JSON.stringify({
          id_prodi,
          id_bidang_cpl: selectedCategory.id_bidang_cpl,
          ...dataCPL
        })
      });

      let data = await res.json();
      let errorMessages = "";
      if (typeof data.message === "string") {
        errorMessages = data.message;
      } else if (typeof data.message === "object") {
        errorMessages = "";
        if (Array.isArray(data.message)) {
          for (let err of data.message) {
            errorMessages +=
              typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
          }
        } else {
          for (let key in data.message) errorMessages += data.message[key][0];
        }
      }

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data CPL berhasil ditambahkan!");
        handleFetchCPLData();
        handleCloseDialog();
      } else if (res.status >= 400 && res.status < 500) {
        openSnackbar("warning", errorMessages);
      } else {
        openSnackbar("error", errorMessages);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  const changeDataCPL = e => {
    setDataCPL({ ...dataCPL, [e.target.name]: e.target.value });
  };

  // handle: open-close
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    //reset
    setDataCPL({
      urutan_di_skpi: "",
      judul_cpl_indonesia: "",
      judul_cpl_inggris: ""
    });
  };

  return (
    <>
      <Button color={"white"} onClick={handleOpenDialog} round size={"sm"}>
        <IconAdd />
        Tambah
      </Button>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle id="dialog-title">Tambahkan Item CPL</DialogTitle>
        <DialogContent>
          <DialogContentText>{"Tambahkan Item CPL Baru"}</DialogContentText>
          <GridContainer justify={"center"}>
            <GridItem xs={10} md={8}>
              <CustomInput
                labelText="Urutan"
                id="urutan_di_skpi"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "urutan_di_skpi",
                  type: "number",
                  value: dataCPL.urutan_di_skpi,
                  onChange: changeDataCPL
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem xs={10} md={8}>
              <CustomInput
                labelText="Nama Item (Bahasa)"
                id="item-name-ID"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "judul_cpl_indonesia",
                  value: dataCPL.judul_cpl_indonesia,
                  onChange: changeDataCPL
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem xs={10} md={8}>
              <CustomInput
                labelText="Item Name (English)"
                id="item-name-EN"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "judul_cpl_inggris",
                  value: dataCPL.judul_cpl_inggris,
                  onChange: changeDataCPL
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="warning" round size={"sm"}>
            Discard
          </Button>
          <Button
            onClick={handleAPISubmit}
            color="success"
            autoFocus
            round
            size={"sm"}
            disabled={
              !dataCPL.urutan_di_skpi ||
              !dataCPL.judul_cpl_indonesia ||
              !dataCPL.judul_cpl_inggris
            }
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ButtonTambahManajemenCPL.propTypes = {
  handleFetchCPLData: PropTypes.func,
  id_prodi: PropTypes.any,
  openSnackbar: PropTypes.func,
  selectedCategory: PropTypes.object
};
