import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Collapse } from "@material-ui/core";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";
import CardBody from "dep/Card/CardBody";
import Button from "dep/CustomButtons/Button";

// API
import Auth from "helpers/auth";
import { BASE_URL, ROLE_SUPER_ADMIN } from "const";
import cardStyles from "assets/jss/jpt/cardStyles";

const useStyles = makeStyles(cardStyles);

export default function CardProdiSelectorPreview(props) {
  const classes = useStyles();

  // var: open-close
  const [openCard, setOpenCard] = useState(false);

  // var: values
  const [values, setValues] = useState({
    nama_prodi: "",
    nama_kaprodi: "",
    jenjang: "",
    nama_fakultas: "",
    jumlah_cpl: "",
    jumlah_rubrik: "",
    jumlah_mahasiswa: ""
  });

  useEffect(() => {
    if (props.selectedProdi) {
      handleFetchAll();
      setOpenCard(true);
    }
  }, [props.selectedProdi]);

  // handle: open-close
  const handleOpenDialogButton = () => {
    props.handleChangeOpenDialog(true);
  };

  const handleFetchAll = async () => {
    let [
      prodi,
      fakultas,
      bidang_cpl,
      rubrik,
      statistik
    ] = await Promise.allSettled([
      handleFetchDataProdi(),
      handleFetchFakultas(props.selectedFakultas),
      handleFetchCPL(),
      handleFetchRubrik(),
      handleFetchStatistik()
    ]);

    if (Object.keys(fakultas).length) {
      fakultas = await handleFetchFakultas(prodi.value.id_fakultas);
    }

    setValues({
      ...values,
      nama_fakultas: _.get(
        fakultas,
        "value.nama_fakultas",
        fakultas.nama_fakultas
      ),
      jumlah_cpl: bidang_cpl.value.length,
      jumlah_rubrik: rubrik.value.length,
      jumlah_mahasiswa: statistik.value.total_mahasiswa,
      ...prodi.value
    });
  };

  const handleFetchDataProdi = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/prodi/" + props.selectedProdi);
    let data = await res.json();
    return data.prodi || {};
  };

  const handleFetchFakultas = async id_fakultas => {
    let res = await Auth.fetch(BASE_URL + "/api/fakultas/" + id_fakultas);
    let data = await res.json();
    return data.fakultas || {};
  };

  const handleFetchCPL = async () => {
    let res = await Auth.fetch(
      BASE_URL + "/api/prodi/" + props.selectedProdi + "/bidangcpl"
    );
    let data = await res.json();
    return data.bidang_cpl || [];
  };

  const handleFetchRubrik = async () => {
    let res = await Auth.fetch(
      BASE_URL + "/api/prodi/" + props.selectedProdi + "/rubrik"
    );
    let data = await res.json();
    return data.rubrik || [];
  };

  const handleFetchStatistik = async () => {
    let res = await Auth.fetch(
      BASE_URL + "/api/statistik/kelulusan?id_prodi=" + props.selectedProdi
    );
    let data = await res.json();
    return data || {};
  };

  return (
    <GridContainer>
      <GridItem>
        <Card plain={!openCard}>
          <div
            onClick={() => {
              setOpenCard(!openCard);
            }}
          >
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>{values.nama_prodi}</h4>
              <p className={classes.cardCategoryWhite}>
                Informasi Mengenai Program Studi yang dipilih
              </p>
            </CardHeader>
          </div>
          <Collapse in={openCard}>
            <CardBody>
              <GridContainer justify={"space-between"}>
                <GridItem>
                  <p>Nama Program Studi</p>
                </GridItem>
                <GridItem>
                  <p className={classes.values}>{values.nama_prodi}</p>
                </GridItem>
              </GridContainer>
              <GridContainer justify={"space-between"}>
                <GridItem>
                  <p>Strata</p>
                </GridItem>
                <GridItem>
                  <p className={classes.values}>{values.jenjang}</p>
                </GridItem>
              </GridContainer>
              <GridContainer justify={"space-between"}>
                <GridItem>
                  <p>Fakultas</p>
                </GridItem>
                <GridItem>
                  <p className={classes.values}>{values.nama_fakultas}</p>
                </GridItem>
              </GridContainer>
              <GridContainer justify={"space-between"}>
                <GridItem>
                  <p>Nama Kepala Program Studi</p>
                </GridItem>
                <GridItem>
                  <p className={classes.values}>{values.nama_kaprodi}</p>
                </GridItem>
              </GridContainer>
              <GridContainer justify={"space-between"}>
                <GridItem>
                  <p>Jumlah Mahasiswa</p>
                </GridItem>
                <GridItem>
                  <p className={classes.values}>{values.jumlah_mahasiswa}</p>
                </GridItem>
              </GridContainer>
              <GridContainer justify={"space-between"}>
                <GridItem>
                  <p>Jumlah Item CPL</p>
                </GridItem>
                <GridItem>
                  <p className={classes.values}>{values.jumlah_cpl}</p>
                </GridItem>
              </GridContainer>
              <GridContainer justify={"space-between"}>
                <GridItem>
                  <p>Jumlah Rubrik Portofolio</p>
                </GridItem>
                <GridItem>
                  <p className={classes.values}>{values.jumlah_rubrik}</p>
                </GridItem>
              </GridContainer>
              {Auth.isRoleEqualTo(ROLE_SUPER_ADMIN) ? (
                <GridContainer justify={"center"}>
                  <GridItem>
                    <Button
                      color={"info"}
                      round
                      onClick={handleOpenDialogButton}
                    >
                      Ganti Prodi
                    </Button>
                  </GridItem>
                </GridContainer>
              ) : (
                <></>
              )}
            </CardBody>
          </Collapse>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

CardProdiSelectorPreview.propTypes = {
  handleChangeOpenDialog: PropTypes.func,
  selectedProdi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedFakultas: PropTypes.any
};
