import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// @material-ui/core components
import { Avatar, Divider, IconButton, Paper, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";
import CardBody from "dep/Card/CardBody";
import CardFooter from "dep/Card/CardFooter";
// components
import ProfileTimelineStepper from "components/Public/Profile/ProfileTimelineStepper";
import ProfileTimeline from "components/Public/Profile/ProfileTimeline";

import { BASE_URL, LANDING_PAGE_BG_DESKTOP } from "const";

import Auth from "helpers/auth";
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";
import cardStyles from "assets/jss/jpt/cardStyles";
import SettingsIcon from "@material-ui/icons/Settings";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle";

// Mboh
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CardIcon from "dep/Card/CardIcon";
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import Button from "dep/CustomButtons/Button";
import DialogChecklistRubrik from "components/User/CetakSKPI/Dialog/DialogChecklistRubrik";

const useStyles = makeStyles(theme => ({
  background: {
    backgroundImage: "url(" + LANDING_PAGE_BG_DESKTOP + ")",
    backgroundPosition: "left top",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  avatar: { width: theme.spacing(17), height: theme.spacing(17) },
  textSocmedValue: {
    margin: 0,
    fontSize: "13px",
    fontStyle: "italic",
    textAlign: "right"
  },
  textSocmed: {
    margin: 0,
    fontSize: "13px",
    color: "#4f4f4f"
  },
  textName: {
    textAlign: "center"
  },
  textNIM: {
    textAlign: "center",
    color: "#4f4f4f",
    fontSize: "18px",
    fontStyle: "italic"
  },
  bio: {
    margin: 0,
    fontSize: "13px",
    color: "#4f4f4f",
    textAlign: "center"
  },
  dataTitle: {
    margin: 0,
    fontSize: "16px",
    fontStyle: "italic",
    marginTop: "1vh",
    marginBottom: "1vh"
  },
  text: { margin: 0, fontSize: "13px", color: "#343434" },
  textValue: {
    margin: 0,
    fontSize: "13px",
    fontStyle: "italic",
    color: "#4f4f4f",
    textAlign: "right"
  },
  ...tasksStyle,
  ...cardStyles,
  ...dashboardStyle
}));

export default function Dashboard() {
  // const { nim } = props.match.params;
  const history = useHistory();
  const classes = useStyles();
  useEffect(() => {
    handleFetch();
    handleFetchInformationSKPI();
  }, []);

  // var: values
  const [data, setData] = useState({
    nama: "",
    NIM: "",
    bio: "",
    photo: ""
  });
  const [contactInfo, setContactInfo] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [timelineYears, setTimelineYears] = useState([]);
  const [timelineData, setTimelineData] = useState([]);
  const [timeline, setTimeline] = useState([]);
  const [openDialogChecklist, setOpenDialogChecklist] = useState(false);
  const [informationSKPI, setInformationSKPI] = useState({
    subrubrik_done: 0,
    portofolio: 0,
    point_skp: 0,
    tanggal: ""
  });

  //handle: open-close
  const handleOpenDialogChecklist = () => {
    setOpenDialogChecklist(true);
  };
  const handleCloseDialogChecklist = () => {
    setOpenDialogChecklist(false);
  };

  // handle: API
  const handleFetch = async () => {
    const nim = Auth.getToken().nim;
    let res = await Auth.fetch(`${BASE_URL}/api/mahasiswa/show?nim=${nim}`);
    let data = await res.json();

    setData({
      nama: _.get(data, "data.biodata.nama", ""),
      NIM: nim,
      bio: _.get(data, "data.biodata.bio", ""),
      photo: BASE_URL + "/" + _.get(data, "data.biodata.foto_profil", "")
    });

    setContactInfo(
      _.get(data, "data.sosmed", []).map(e => ({
        title: _.get(e, "jenis_sosmed", ""),
        name: _.get(e, "nama", ""),
        link: _.get(e, "link_sosmed", "")
      }))
    );

    const fetchTimelineData = _.get(data, "data.portofolio", [])
      .map(e => ({
        title_id: _.get(e, "judul", ""),
        title_en: _.get(e, "judul_inggris", ""),
        date: _.get(e, "tanggal_terbit", ""),
        year: _.get(e, "tahun", "") + ""
      }))
      .sort((a, b) => (a.date < b.date ? -1 : 1));

    setTimelineData(fetchTimelineData);
    setTimeline(fetchTimelineData);
    setTimelineYears(_.uniq(fetchTimelineData.map(value => value.year)).sort());
  };
  // handle: values
  const handleChangeTimelineYear = year => {
    if (year) setTimeline(timelineData.filter(value => value.year === year));
    else setTimeline(timelineData);
    setSelectedYear(year);
  };

  const handleFetchInformationSKPI = async () => {
    // Get Statistik Portofolio
    let res = await Auth.fetch(BASE_URL + "/api/statistik/user-portofolio");
    let json = await res.json();
    let jumlah_skp = 0;
    let jumlah_portofolio = 0;
    _.get(json, "bidang", []).forEach(function(bidang) {
      jumlah_skp += parseInt(_.get(bidang, "statistik.bobot_skp", 0));
      jumlah_portofolio += _.get(bidang, "statistik.portofolio_count", 0);
    });

    // Get Statistik SKPI
    let skpi = await Auth.fetch(BASE_URL + "/api/skpi");
    skpi = await skpi.json();
    let subrubrik_terpenuhi = 0;
    let subrubrik_jumlah = 0;
    let rubrik_terpenuhi = 0;
    let rubrik_jumlah = 0;

    _.get(skpi, "data", []).forEach(function(_rubrik) {
      let total_subrubrik_dari_rubrik = 0;
      let subrubrik_terpenuhi_dari_rubrik = 0;
      _.get(_rubrik, "sub_rubrik", []).forEach(function(sub_rubrik) {
        subrubrik_terpenuhi_dari_rubrik +=
          parseInt(sub_rubrik.point_sub_rubrik_yang_terkumpul) >=
          parseInt(sub_rubrik.syarat_minimal_point_skp_sub_rubrik)
            ? 1
            : 0;
        total_subrubrik_dari_rubrik += 1;
      });
      subrubrik_terpenuhi += subrubrik_terpenuhi_dari_rubrik;
      subrubrik_jumlah += total_subrubrik_dari_rubrik;
      rubrik_jumlah++;
      if (
        _rubrik.point_rubrik_yang_terkumpul >=
        _rubrik.syarat_minimal_point_skp_rubrik
      )
        rubrik_terpenuhi++;
    });

    // Get status pengajuan
    let status_pengajuan = await Auth.fetch(BASE_URL + "/api/skpi/pengajuan");
    status_pengajuan = await status_pengajuan.json();
    // let status = status_pengajuan.data
    //   ? status_pengajuan.data.is_downloaded
    //     ? 1
    //     : 0
    //   : "";
    const status = _.get(status_pengajuan, "data.is_downloaded", "");
    // let link = status_pengajuan.data
    //   ? BASE_URL + "/" + status_pengajuan.data.path
    //   : "";
    const link = BASE_URL + "/" + _.get(status_pengajuan, "data.path", "/");

    // Get latest portofolio
    let portofolio = await Auth.fetch(BASE_URL + "/api/portofolio/latest");
    portofolio = (await portofolio.json()).portofolio;
    let tanggal = portofolio ? portofolio.tanggal_terbit : "";

    //TODO : rubrik
    setInformationSKPI({
      rubrik_done: rubrik_terpenuhi, // jumlah subrubrik yang pointnya udah diatas minimum dari masuk sampe sekarang
      rubrik_jumlah: rubrik_jumlah, // jumlah subrubrik yang pointnya udah diatas minimum dari masuk sampe sekarang
      subrubrik_done: subrubrik_terpenuhi, // jumlah subrubrik yang pointnya udah diatas minimum dari masuk sampe sekarang
      subrubrik_jumlah: subrubrik_jumlah, //jml subrubrik di prodinya
      portofolio: jumlah_portofolio,
      point_skp: jumlah_skp, //point total skp dari masuk sampe sekarang
      tanggal: tanggal, //tanggal terakhir upload portofolio
      link: link, //kalo dah upload file skpi(bertandatangan)
      status: status //status: "" kalau belum pernah ngajuin, 0 kalau menunggu, 1 kalau diterima
    });
  };

  return (
    <GridContainer direction={"row-reverse"} justify={"flex-end"}>
      <GridItem xs={12} md={4}>
        <Paper elevation={2}>
          <GridContainer justify={"flex-end"}>
            <GridItem>
              <Tooltip
                id="tooltip-top"
                title="Profile Setting"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="Hapus Fakultas"
                  className={classes.tableActionButton}
                  onClick={event => {
                    history.push("profile");
                    event.preventDefault();
                  }}
                >
                  <SettingsIcon
                    className={
                      classes.tableActionButtonIcon + " " + classes.edit
                    }
                  />
                </IconButton>
              </Tooltip>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem>
              <Avatar
                alt={data.nama}
                src={data.photo}
                className={classes.avatar}
              />
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer justify={"center"}>
            <GridItem xs={9}>
              <h4 className={classes.textName}>{data.nama}</h4>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem>
              <p className={classes.textNIM}>{data.NIM}</p>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem xs={4}>
              <Divider />
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem>
              <p className={classes.dataTitle}>About me</p>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem xs={9}>
              <p className={classes.bio}>{data.bio}</p>
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer justify={"center"}>
            <GridItem xs={4}>
              <Divider />
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem>
              <p className={classes.dataTitle}>Social Media</p>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem xs={8}>
              {contactInfo.map((values, key) => (
                <GridContainer key={key} justify={"space-between"}>
                  <GridItem>
                    <p className={classes.textSocmed}>{values.title}</p>
                  </GridItem>
                  <GridItem>
                    <p className={classes.textSocmedValue}>
                      <a
                        target="_blank"
                        href={values.link}
                        rel="noopener noreferrer"
                      >
                        {values.name}
                      </a>
                    </p>
                  </GridItem>
                </GridContainer>
              ))}
            </GridItem>
          </GridContainer>
          <br />
        </Paper>
        <br />
        <Card>
          <CardHeader color={"info"}>
            <h4 className={classes.cardTitleWhite}>Informasi Pencapaian</h4>
          </CardHeader>
          <CardBody>
            <GridContainer justify={"space-between"}>
              <GridItem xs={12} md={6}>
                <p className={classes.text}>Portofolio</p>
              </GridItem>
              <GridItem xs={12} md={6}>
                <p className={classes.textValue}>
                  {informationSKPI.portofolio}
                </p>
              </GridItem>
            </GridContainer>
            <GridContainer justify={"space-between"}>
              <GridItem xs={12} md={6}>
                <p className={classes.text}>Point SKP</p>
              </GridItem>
              <GridItem xs={12} md={6}>
                <p className={classes.textValue}>{informationSKPI.point_skp}</p>
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter stats>
            <div className={classes.stats}>
              <EventOutlinedIcon />
              Portofolio terakhir diunggah pada tanggal{" "}
              {moment(informationSKPI.tanggal).format("DD-MM-YYYY")}
            </div>
          </CardFooter>
        </Card>
        <Card>
          <CardHeader stats icon>
            <CardIcon
              color={
                informationSKPI.subrubrik_jumlah >
                  informationSKPI.subrubrik_done ||
                informationSKPI.rubrik_jumlah > informationSKPI.rubrik_done
                  ? "danger"
                  : "success"
              }
            >
              <InfoOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>
              Rubrik Terpenuhi / Total Rubrik
            </p>
            <h3 className={classes.cardTitle}>
              {informationSKPI.rubrik_done || 0} / {informationSKPI.rubrik_jumlah || 0}
            </h3>
            <p className={classes.cardCategory}>
              Subrubrik Terpenuhi / Total Subrubrik
            </p>
            <h3 className={classes.cardTitle}>
              {informationSKPI.subrubrik_done || 0} / {informationSKPI.subrubrik_jumlah || 0}
            </h3>
          </CardHeader>
          <CardBody>
            <GridContainer justify={"center"}>
              <GridItem>
                <Button
                  fullWidth
                  color={"primary"}
                  size={"sm"}
                  round
                  onClick={handleOpenDialogChecklist}
                >
                  <DoneAllOutlinedIcon />
                  checklist
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} md={8}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Pencapaian</h4>
          </CardHeader>
          <CardBody>
            <ProfileTimelineStepper
              onChange={handleChangeTimelineYear}
              years={timelineYears}
              selectedYear={selectedYear}
            />
            <div style={{ maxHeight: "60vh", overflow: "auto" }}>
              <ProfileTimeline timeline={timeline} />
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <DialogChecklistRubrik
        open={openDialogChecklist}
        onClose={handleCloseDialogChecklist}
      />
    </GridContainer>
  );
}
