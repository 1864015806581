import React from "react";
// @material-ui/core components

// core components
import GridItem from "dep/Grid/GridItem.js";
import GridContainer from "dep/Grid/GridContainer.js";
import CardManajemenFakultas from "components/Admin/ManajemenUniversitas/Card/CardManajemenFakultas";
import CardManajemenUniversitas from "components/Admin/ManajemenUniversitas/Card/CardManajemenUniversitas";
import CardManajemenProdi from "components/Admin/ManajemenUniversitas/Card/CardManajemenProdi";
import Auth from "helpers/auth";
import { ROLE_SUPER_ADMIN } from "const";

export default function ManajemenCompany() {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          {Auth.isRoleEqualTo(ROLE_SUPER_ADMIN) ? (
            <>
              <CardManajemenUniversitas />
              <CardManajemenFakultas />
            </>
          ) : (
            <></>
          )}
          <CardManajemenProdi />
        </GridItem>
      </GridContainer>
    </div>
  );
}
