import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  IconButton,
  Container,
  AppBar,
  TextField,
  InputAdornment,
  Tabs,
  Tab
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import SearchIcon from "@material-ui/icons/Search";

// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";

const useStyles = makeStyles({
  containerAppBar: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  textFieldInput: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    color: "white"
  },
  textField: {
    backgroundColor: "rgba(255,255,255,0.2)",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white"
      },
      "&:hover fieldset": {
        borderColor: "white"
      },
      "&.Mui-focused fieldset": {
        borderColor: "white"
      }
    }
  },
  textMenuAppBar: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    lineHeight: "30px",
    fontSize: "16px",
    color: "#ffffff",
    textTransform: "none"
  },
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    width: "100%",
    paddingTop: "10px",
    border: "0",
    borderRadius: "3px",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block"
  }
});

export default function NavbarSearchPage(props) {
  const classes = useStyles();
  const queryParser = require("query-string");

  //var: values
  const [searchValue, setSearchValue] = React.useState(props.search);
  const filtersString = props.filters ? queryParser.parse(props.filters) : "";
  let filters = {
    category: filtersString.category ? filtersString.category : "profile"
  };

  //handle: values
  const handleChangeTab = (event, value) => {
    filters.category = value;
    handleSubmitFilters();
  };
  const handleChangeSearchValue = event => {
    setSearchValue(event.target.value);
  };

  //handle: hooks
  const handleSubmitFilters = () => {
    // eslint-disable-next-line react/prop-types
    props.handleChangeFilters(queryParser.stringify(filters));
  };
  const handleSubmitSearch = () => {
    // eslint-disable-next-line react/prop-types
    props.handleChangeSearch(searchValue);
  };

  return (
    <AppBar
      color={"transparent"}
      position={"static"}
      className={classes.appBar}
    >
      <Container maxWidth={"lg"} className={classes.containerAppBar}>
        <GridContainer justify={"center"}>
          <GridItem xs={10}>
            <TextField
              fullWidth
              className={classes.textField}
              placeholder={"Search"}
              variant={"outlined"}
              onChange={handleChangeSearchValue}
              defaultValue={props.search}
              onKeyPress={event => {
                if (event.key === "Enter") handleSubmitSearch();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <IconButton
                      onClick={handleSubmitSearch}
                      edge={"end"}
                      color={"inherit"}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                className: classes.textFieldInput
              }}
            />
          </GridItem>
        </GridContainer>
        <div style={{ padding: "1vh" }} />
        <Tabs
          className={classes.textMenuAppBar}
          value={filters.category}
          onChange={handleChangeTab}
          indicatorColor={"secondary"}
          centered
        >
          <Tab label="Profil" value="profile" />
          <Tab label="Portofolio" value="portofolio" />
        </Tabs>
      </Container>
    </AppBar>
  );
}

NavbarSearchPage.propTypes = {
  filters: PropTypes.string,
  handleChangeFilters: PropTypes.func,
  handleChangeSearch: PropTypes.func,
  search: PropTypes.string
};
