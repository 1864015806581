import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import Chip from "@material-ui/core/Chip";
// @material-ui/icons
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DescriptionIcon from "@material-ui/icons/Description";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

export default function ChipFileSingle(props) {
  const handleDiscardButton = () => {
    props.discardFile();
  };
  const link = props.link || "";
  let file_name =
    props.fileName || link.substring(link.lastIndexOf("/") + 1) || "";
  if (file_name.length > 10) {
    file_name = file_name.slice(0, 10) + "...";
  }
  const download = () => {
    let anchor = document.createElement("a");
    anchor.href = link;
    anchor.target = "_blank";
    anchor.download = "";
    anchor.click();
  };
  // const classes = useStyle();
  if (link !== "")
    return (
      <Chip
        color={"primary"}
        icon={<CloudDownloadIcon />}
        label={file_name}
        onClick={download}
        onDelete={handleDiscardButton}
        deleteIcon={<DeleteForeverIcon />}
      />
    );
  else if (props.fileName)
    return (
      <Chip
        icon={<DescriptionIcon />}
        label={file_name}
        onDelete={handleDiscardButton}
        deleteIcon={<DeleteForeverIcon />}
      />
    );

  return <></>; // in case props.fileName & link empty
}

ChipFileSingle.propTypes = {
  discardFile: PropTypes.func,
  fileName: PropTypes.string,
  link: PropTypes.string
};
