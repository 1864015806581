import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
// dep components
import Button from "dep/CustomButtons/Button";
// @material-ui/icons
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

//TODO: iframe load catch error(fail to load)
//cross-site setting https://www.chromestatus.com/feature/5088147346030592

export default function PdfViewer(props) {
  // handle: open-close -> hook
  const handleClose = () => {
    props.onClose();
  };
  // handle: button
  const handleDownload = () => {
    let anchor = document.createElement("a");
    anchor.href = props.link || "";
    anchor.target = "_blank";
    anchor.download = "";
    anchor.click();
  };
  return (
    <React.Fragment>
      <Dialog
        scroll="body"
        fullWidth={true}
        maxWidth={"lg"}
        open={props.open || false}
        onClose={handleClose}
      >
        <DialogContent>
          <iframe
            title={"PDF Viewer"}
            src={
              "https://docs.google.com/viewerng/viewer?url=" +
              props.link +
              "&embedded=true"
            }
            frameBorder="0"
            height="600px"
            width="100%"
          />
        </DialogContent>
        <DialogActions>
          <Button size="sm" round onClick={handleDownload} color="info">
            <CloudDownloadIcon />
            Download
          </Button>
          <Button size="sm" round onClick={handleClose} color="warning">
            <HighlightOffIcon />
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

PdfViewer.propTypes = {
  link: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
