import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import Joi from "joi";
import { Helmet } from "react-helmet";
// @material-ui/core components
import {
  Checkbox,
  CircularProgress,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";
import CardBody from "dep/Card/CardBody";
import CustomInput from "dep/CustomInput/CustomInput";
import Button from "dep/CustomButtons/Button";
// components
import Navbar from "components/Public/Navbar/Navbar";
// for API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
// styling
import userAccountStyles from "assets/jss/jpt/userAccountStyles";
// material-ui/icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles(userAccountStyles);

export default function Register() {
  const [page, setPage] = useState(0);
  // var: list
  const [listFakultas, setListFakultas] = useState([]);
  const [listProdi, setListProdi] = useState([]);
  // var: value
  const [state, setState] = useState({
    NIM: "",
    email: "",
    password: "",
    rePassword: "",
    fakultas: "",
    prodi: "",
    containerProvinsiStyle: {
      display: "none"
    },
    registrationStatus: "loading",
    token: "",
    agreement: false,
    showPassword: false,
    showRePassword: false,
    responseMessage: ""
  });

  const [errors, setErrors] = useState({
    NIMError: "",
    emailError: "",
    passwordError: "",
    rePasswordError: "",
    fakultasError: "",
    prodiError: "",
    agreementError: ""
  });

  // Hooks
  const classes = useStyles();
  const fakultasDidMountRef = useRef(false);
  useEffect(() => {
    if (fakultasDidMountRef.current) {
      // Component has just been updated
      handleFetchListProdi();
    } else {
      // onMount
      handleFetchListFakultas();
      fakultasDidMountRef.current = true;
    }
  }, [state.fakultas]);

  const agreementDidMountRef = useRef(false);
  useEffect(() => {
    if (agreementDidMountRef.current) {
      // Component has just been updated
      setErrors({ ...errors, agreementError: validateAgreement() });
    } else {
      // onMount
      setErrors({ ...errors, agreementError: "" });
      agreementDidMountRef.current = true;
    }
  }, [state.agreement]);

  const handlePrevPage = () => {
    setState({
      ...state,
      registrationStatus: "loading"
    });
    setPage(page - 1);
  };
  const handleNextPage = () => {
    if (page === 0) {
      let errorOnFirstPage = validateFirstPage();
      setErrors({ ...errors, ...errorOnFirstPage });
      let isValid = true;
      for (let err in errorOnFirstPage) {
        if (errorOnFirstPage[err]) {
          isValid = false;
          break;
        }
      }
      if (isValid) {
        setPage(page + 1);
      }
    } else if (page === 1) {
      let errorOnSecondPage = validateSecondPage();
      setErrors({ ...errors, ...errorOnSecondPage });
      let isValid = true;
      for (let err in errorOnSecondPage) {
        if (errorOnSecondPage[err]) {
          isValid = false;
          break;
        }
      }
      if (isValid) {
        setPage(page + 1);
        handleAPISubmit();
      }
    }
  };

  //handle : value
  const handleChangeSelectFakultas = event => {
    setState({ ...state, fakultas: event.target.value, prodi: "" });
  };
  const handleChangeSelectProdi = event => {
    setState({ ...state, prodi: event.target.value });
  };
  const handleChangeState = event => {
    setState({ ...state, [event.target.id]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };
  const handleClickShowRePassword = () => {
    setState({ ...state, showRePassword: !state.showRePassword });
  };
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  // handle: API fetch
  const handleFetchListFakultas = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/fakultas");
    let data = await res.json();
    setListFakultas(
      _.get(data, "fakultas", []).map(e => {
        return {
          id_fakultas: _.get(e, "id_fakultas"),
          nama_fakultas: _.get(e, "nama_fakultas")
        };
      })
    );
  };
  const handleFetchListProdi = async () => {
    let res = await Auth.fetch(
      BASE_URL + "/api/fakultas/" + state.fakultas + "/prodi"
    );
    let data = await res.json();
    setListProdi(
      _.get(data, "prodi", []).map(e => {
        return {
          jenjang: _.get(e, "jenjang"),
          id_prodi: _.get(e, "id_prodi"),
          nama_prodi: _.get(e, "nama_prodi")
        };
      })
    );
  };

  // handle: API submit
  const handleAPISubmit = async () => {
    try {
      let reqBody = {
        nim: state.NIM,
        email: state.email,
        id_prodi: state.prodi,
        password: state.password,
        password_confirmation: state.rePassword
      };

      let res = await Auth.fetch(BASE_URL + "/api/auth/register", {
        method: "POST",
        body: JSON.stringify(reqBody)
      });

      let data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        let token = data.token;
        localStorage.setItem("token", token);
        setState({
          ...state,
          token: data.token,
          registrationStatus: "success",
          responseMessage: ""
        });
      } else {
        let errorMessages = "";
        if (typeof data.message === "string") {
          errorMessages = data.message;
        } else if (typeof data.message === "object") {
          errorMessages = "";
          if (Array.isArray(data.message)) {
            for (let err of data.message) {
              errorMessages +=
                typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
            }
          } else {
            for (let key in data.message) errorMessages += data.message[key][0];
          }
        }
        setState({
          ...state,
          registrationStatus: "failed",
          responseMessage: errorMessages
        });
      }
    } catch (err) {
      setState({
        ...state,
        registrationStatus: "failed",
        responseMessage: "Terjadi kesalahan"
      });
    }
  };

  const validateNIM = () => {
    let { error } = Joi.string()
      .label("NIM")
      .alphanum()
      .validate(state.NIM);
    return _.get(error, "message", "").replace(/"/g, "");
  };

  const validateEmail = () => {
    let { error } = Joi.string()
      .label("Email")
      .email({ tlds: false })
      .validate(state.email);
    return _.get(error, "message", "").replace(/"/g, "");
  };

  const validatePassword = () => {
    let { error } = Joi.string()
      .label("Password")
      .min(8)
      .max(20)
      .validate(state.password);
    return _.get(error, "message", "").replace(/"/g, "");
  };

  const validateRePassword = () => {
    let { error } = Joi.string()
      .label("Password confirmation")
      .valid(state.password)
      .messages({
        "any.only": "Password confirmation do not match"
      })
      .validate(state.rePassword);
    return _.get(error, "message", "").replace(/"/g, "");
  };

  const validateFakultas = () => {
    let fakultasError = "";
    if (!state.fakultas) {
      fakultasError = "Please choose one of the options";
    }
    return fakultasError;
  };

  const validateProdi = () => {
    let prodiError = "";
    if (!state.prodi) {
      prodiError = "Please choose one of the options";
    }
    return prodiError;
  };

  const validateAgreement = () => {
    let agreementError = "";
    if (!state.agreement) {
      agreementError = "Please check this box";
    }
    return agreementError;
  };

  const validateFirstPage = () => {
    return {
      NIMError: validateNIM(),
      emailError: validateEmail(),
      passwordError: validatePassword(),
      rePasswordError: validateRePassword()
    };
  };

  const validateSecondPage = () => {
    return {
      fakultasError: validateFakultas(),
      prodiError: validateProdi(),
      agreementError: validateAgreement()
    };
  };

  const SubmitRegistrationData = () => {
    if (state.registrationStatus === "success") {
      return (
        <center>
          <h3 className={classes.success}>Registrasi Berhasil</h3>
          <p className={classes.successText}>
            Kami telah mengirimkan email berisi tautan untuk memverifikasi email
            Anda.
          </p>
          <p className={classes.successText}>
            Harap periksa email Anda untuk melanjutkan proses registrasi.
          </p>
        </center>
      );
    } else if (state.registrationStatus === "failed") {
      return (
        <center>
          <h3 className={classes.failed}>Registrasi Gagal</h3>
          <p>{state.responseMessage}</p>
        </center>
      );
    } else {
      return (
        <center>
          <CircularProgress />
        </center>
      );
    }
  };

  return (
    <div className={classes.background}>
      <Helmet>
        <title>Daftar - Janabadra Professional Tracker</title>
        <meta
          name="description"
          content="Janabadra Professional Tracker, Sistem Data Portofolio Pendamping Ijazah, Universitas Janabadra"
        />
      </Helmet>
      <Navbar />
      <Container>
        <GridContainer>
          <div style={{ padding: "3vh" }} />
        </GridContainer>
        <GridContainer justify={"center"} alignItems={"center"}>
          <GridItem xs={12} sm={12} md={10}>
            <GridContainer justify={"space-between"}>
              <GridItem xs={12} sm={12} md={5}>
                <div style={{ padding: "1vh" }} />
                <h1 className={classes.cardTitleWhite}>Information</h1>
                <div style={{ padding: "1vh" }} />
                <p className={classes.cardCategoryWhite}>
                  JanaProTrack adalah Sistem Data Portofolio Pendamping Ijazah
                  yang digunakan untuk melakukan proses input data kegiatan
                  mahasiswa yang dicatatkan dalam luaran berbentuk Surat
                  Keterangan Pendamping Ijazah (SKPI). Mahasiswa setiap Program
                  Studi harus mencermati rubrik SKPI yang diterbitkan oleh
                  masing-masing Program Studi agar mengetahui kegiatan apa saja
                  yang bisa masuk ke dalam rubrik dan bagaimana persyaratan
                  untuk mendapatkan dokumen rekognisinya. Setiap mahasiswa wajib
                  mengisi portofolio aktivitasnya dalam sistem ini untuk
                  mendapatkan Surat Keterangan Pendamping Ijazah (SKPI).
                </p>
                <div style={{ padding: "1vh" }} />
                <GridContainer justify={"space-around"}>
                  <GridItem>
                    <Button round color={"success"} href={"/"}>
                      Have an account
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      round
                      color={"info"}
                      href={"/guide"}
                      target="_blank"
                    >
                      <HelpOutlineIcon />
                      Guide
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <Card>
                  <CardHeader color={"info"}>
                    <h4 className={classes.cardTitleWhite}>Registrasi</h4>
                    <p className={classes.cardCategoryWhite}>Daftar di sini</p>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <Collapse in={page === 0}>
                        <GridContainer justify={"center"}>
                          <GridItem xs={10} sm={10} md={10}>
                            <CustomInput
                              labelText="Nomor Induk Mahasiswa"
                              id="NIM"
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={!!errors.NIMError}
                              helperText={errors.NIMError}
                              inputProps={{
                                name: "username",
                                value: state.NIM || "",
                                onChange: handleChangeState,
                                onBlur: () => {
                                  setErrors({
                                    ...errors,
                                    NIMError: validateNIM()
                                  });
                                },
                                onFocus: () => {
                                  setErrors({ ...errors, NIMError: "" });
                                }
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem xs={10} sm={10} md={10}>
                            <CustomInput
                              labelText="Email"
                              id="email"
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={!!errors.emailError}
                              helperText={errors.emailError}
                              inputProps={{
                                type: "email",
                                name: "email",
                                value: state.email || "",
                                onChange: handleChangeState,
                                onBlur: () => {
                                  setErrors({
                                    ...errors,
                                    emailError: validateEmail()
                                  });
                                },
                                onFocus: () => {
                                  setErrors({ ...errors, emailError: "" });
                                }
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem xs={10} sm={10} md={10}>
                            <CustomInput
                              labelText="Password"
                              id="password"
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={!!errors.passwordError}
                              helperText={errors.passwordError}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    style={
                                      errors.passwordError
                                        ? {
                                            marginRight: "20px",
                                            marginBottom: "5px"
                                          }
                                        : { marginBottom: "5px" }
                                    }
                                  >
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {state.showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                type: state.showPassword ? "text" : "password",
                                name: "new-password",
                                value: state.password || "",
                                onChange: handleChangeState,
                                onBlur: () => {
                                  setErrors({
                                    ...errors,
                                    passwordError: validatePassword()
                                  });
                                },
                                onFocus: () => {
                                  setErrors({ ...errors, passwordError: "" });
                                }
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem xs={10} sm={10} md={10}>
                            <CustomInput
                              labelText="Re-Enter Password"
                              id="rePassword"
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={!!errors.rePasswordError}
                              helperText={errors.rePasswordError}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    style={
                                      errors.rePasswordError
                                        ? {
                                            marginRight: "20px",
                                            marginBottom: "5px"
                                          }
                                        : { marginBottom: "5px" }
                                    }
                                  >
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowRePassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {state.showRePassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                type: state.showRePassword
                                  ? "text"
                                  : "password",
                                name: "new-password",
                                value: state.rePassword || "",
                                onChange: handleChangeState,
                                onBlur: () => {
                                  setErrors({
                                    ...errors,
                                    rePasswordError: validateRePassword()
                                  });
                                },
                                onFocus: () => {
                                  setErrors({ ...errors, rePasswordError: "" });
                                }
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <div style={{ padding: "1vh" }} />
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem>
                            <Button color={"info"} onClick={handleNextPage}>
                              Next
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </Collapse>
                      <Collapse in={page === 1}>
                        <GridContainer justify={"center"}>
                          <GridItem xs={10} sm={10} md={10}>
                            <div style={{ padding: "1vh" }} />
                            <FormControl
                              className={classes.formControl}
                              fullWidth
                              error={!!errors.fakultasError}
                            >
                              <InputLabel id="controlled-open-select-label">
                                Pilih Fakultas
                              </InputLabel>
                              <Select
                                labelId="controlled-open-select-label"
                                id="controlled-open-select"
                                inputProps={{
                                  onBlur: () => {
                                    setErrors({
                                      ...errors,
                                      fakultasError: validateFakultas()
                                    });
                                  },
                                  onFocus: () => {
                                    setErrors({ ...errors, fakultasError: "" });
                                  }
                                }}
                                value={state.fakultas}
                                onChange={handleChangeSelectFakultas}
                              >
                                {listFakultas.map((row, key) => (
                                  <MenuItem key={key} value={row.id_fakultas}>
                                    {row.nama_fakultas}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText style={{ color: "red" }}>
                                {errors.fakultasError}
                              </FormHelperText>
                            </FormControl>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem xs={10} sm={10} md={10}>
                            <div style={{ padding: "1vh" }} />
                            <FormControl
                              className={classes.formControl}
                              fullWidth
                              error={!!errors.prodiError}
                            >
                              <InputLabel id="controlled-open-select-label">
                                Pilih Program Studi
                              </InputLabel>
                              <Select
                                labelId="controlled-open-select-label"
                                id="controlled-open-select"
                                value={state.prodi}
                                onChange={handleChangeSelectProdi}
                                inputProps={{
                                  onBlur: () => {
                                    setErrors({
                                      ...errors,
                                      prodiError: validateProdi()
                                    });
                                  },
                                  onFocus: () => {
                                    setErrors({ ...errors, prodiError: "" });
                                  }
                                }}
                              >
                                {listProdi.map((row, key) => (
                                  <MenuItem key={key} value={row.id_prodi}>
                                    {row.jenjang + " - " + row.nama_prodi}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText style={{ color: "red" }}>
                                {errors.prodiError}
                              </FormHelperText>
                            </FormControl>
                          </GridItem>
                        </GridContainer>
                        <br />
                        <GridContainer justify={"center"}>
                          <GridItem xs={10} sm={10} md={10}>
                            <FormControl
                              className={classes.formControl}
                              error={!!errors.agreementError}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    size="small"
                                    inputProps={{
                                      "aria-label":
                                        "I agree to the Terms and Conditions"
                                    }}
                                    onChange={e => {
                                      setState({
                                        ...state,
                                        agreement: e.target.checked
                                      });
                                    }}
                                  />
                                }
                                label={
                                  <>
                                    {"I agree to the "}
                                    <a
                                      href="/terms-of-service"
                                      className="small"
                                      target="_blank"
                                    >
                                      Terms and Conditions
                                    </a>
                                  </>
                                }
                              />
                              <FormHelperText style={{ color: "red" }}>
                                {errors.agreementError}
                              </FormHelperText>
                            </FormControl>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <div style={{ padding: "1vh" }} />
                        </GridContainer>
                        <GridContainer justify={"space-around"}>
                          <GridItem>
                            <Button color={"info"} onClick={handlePrevPage}>
                              Prev
                            </Button>
                          </GridItem>
                          <GridItem>
                            <Button color={"success"} onClick={handleNextPage}>
                              Register
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </Collapse>
                      <Collapse in={page === 2}>
                        <GridContainer
                          justify={"space-around"}
                          alignItems={"center"}
                        >
                          <GridItem xs={10} sm={10} md={10}>
                            <SubmitRegistrationData
                              alignItems={"center"}
                              fullWidth
                            />
                          </GridItem>
                          <GridItem>
                            <Button color={"info"} onClick={handlePrevPage}>
                              Prev
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </Collapse>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Container>
    </div>
  );
}
