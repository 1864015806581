import React from "react";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CardProfile from "components/Admin/Profile/Card/CardProfile";

export default function Profile() {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <CardProfile />
        </GridItem>
      </GridContainer>
    </div>
  );
}
