import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @material-ui/core components
import { Stepper, Step, StepLabel, StepButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";

const useStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center"
  },
  active: {
    color: "#784af4"
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor"
  },
  infinite: {
    width: 16,
    height: 16
  }
});

// iconRender
function StepIcon(props) {
  const classes = useStepIconStyles();
  const { active } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      <div className={classes.circle} />
    </div>
  );
}
function InfiniteIcon(props) {
  const classes = useStepIconStyles();
  const { active } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      <AllInclusiveIcon className={classes.infinite} />
    </div>
  );
}

export default function ProfileTimelineStepper(props) {
  const handleChangeYear = year => {
    props.onChange(year);
  };

  return (
    <>
      <Stepper alternativeLabel nonLinear>
        <Step active={!props.selectedYear}>
          <StepButton
            onClick={event => {
              handleChangeYear("");
              event.preventDefault();
            }}
          >
            <StepLabel StepIconComponent={InfiniteIcon}>Semua</StepLabel>
          </StepButton>
        </Step>
        {props.years.map((value, index) => (
          <Step key={index} active={props.selectedYear === value}>
            <StepButton
              onClick={event => {
                handleChangeYear(value);
                event.preventDefault();
              }}
            >
              <StepLabel StepIconComponent={StepIcon}>{value}</StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </>
  );
}

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.any
};
ProfileTimelineStepper.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedYear: PropTypes.string.isRequired,
  years: PropTypes.array.isRequired
};
InfiniteIcon.propTypes = {
  active: PropTypes.bool
};
