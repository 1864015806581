import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// dep components
import Button from "dep/CustomButtons/Button.js";
import Card from "dep/Card/Card.js";
import CardBody from "dep/Card/CardBody.js";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
// styling
import CardHeader from "dep/Card/CardHeader";
import cardStyles from "assets/jss/jpt/cardStyles";

const useStyles = makeStyles({
  ...cardStyles,
  text: {
    margin: "0px",
    color: "#757575"
  }
});

export default function LittleCardPortofolioCategory(props) {
  const classes = useStyles();

  // Props
  const { selectedBidangPortofolio } = props;

  const handleChooseCategory = () => {
    props.handleChooseCategory(selectedBidangPortofolio);
  };

  return (
    <>
      <Card>
        <CardHeader color={"info"}>
          <h4 className={classes.cardTitleWhite}>
            {selectedBidangPortofolio.nama_bidang_portofolio}
          </h4>
          <p className={classes.cardCategoryWhite}>
            {selectedBidangPortofolio.nama_bidang_portofolio_inggris}
          </p>
        </CardHeader>
        <CardBody>
          <GridContainer justify={"space-between"} alignItems={"center"}>
            <GridItem>
              <p className={classes.text}>
                Portofolio : {selectedBidangPortofolio.jumlah_portofolio || 0}
              </p>
              <p className={classes.text}>
                SKP : {selectedBidangPortofolio.jumlah_skp || 0}
              </p>
            </GridItem>
            <GridItem>
              <Button
                size={"sm"}
                round
                color={"info"}
                onClick={handleChooseCategory}
              >
                Pilih
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
}

LittleCardPortofolioCategory.propTypes = {
  handleChooseCategory: PropTypes.func,
  selectedBidangPortofolio: PropTypes.object
};
