import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions
} from "@material-ui/core";
// dep components
import Button from "dep/CustomButtons/Button";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function DialogPembatalanCetakSKPI(props) {
  const { openSnackbar } = props;
  const handleClose = () => {
    props.onClose();
  };
  const handleSubmit = async () => {
    try {
      openSnackbar("info", "Membatalkan..");
      let res = await Auth.fetchMultipart(BASE_URL + "/api/skpi/cancel", {
        method: "GET"
      });
      let data = await res.json();

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Pembatalan pengajuan SKPI berhasil!");
        handleClose();
      } else {
        let errorMessages = "";
        if (typeof data.message === "string") {
          errorMessages = data.message;
        } else if (typeof data.message === "object") {
          errorMessages = "";
          if (Array.isArray(data.message)) {
            for (let err of data.message) {
              errorMessages +=
                typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
            }
          } else {
            for (let key in data.message) errorMessages += data.message[key][0];
          }
        }

        openSnackbar(
          res.status >= 400 && res.status < 500 ? "warning" : "error",
          errorMessages
        );
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };
  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      scroll={"body"}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle>
        Apakah Anda Yakin Akan Membatalkan Pengajuan SKPI?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Dengan membatalkan pengajuan cetak SKPI, anda akan membuka kembali
          data portofolio yang anda gunakan pada Surat Keterangan Pendamping
          Ijazah. Pastikan hal ini tidak mengganggu proses kelulusan atau
          yudisium anda.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color={"warning"} onClick={handleClose}>
          Tidak
        </Button>
        <Button color={"danger"} onClick={handleSubmit}>
          Ya
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogPembatalanCetakSKPI.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  openSnackbar: PropTypes.func
};
