const valuesStyles = {
  text: {
    color: "#626262"
  },
  textCheckbox: {
    fontSize: "11px",
    color: "#404040"
  },
  textWarning: {
    fontSize: "11px",
    color: "#ff3131"
  },
  center: {
    margin: 0,
    top: "50%"
  },
  values: {
    color: "#3d3d3d",
    fontStyle: "italic",
    textAlign: "right"
  }
};
export default valuesStyles;
