import React from "react";
import CardManajemenRubrikMain from "components/Admin/ManajemenRubrik/Card/CardManajemenRubrikMain";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";

export default function ManajemenRubrik() {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CardManajemenRubrikMain />
        </GridItem>
      </GridContainer>
    </div>
  );
}
