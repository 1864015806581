import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes/VerifikatorRoutes";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Global/Navbars/Navbar";
import Footer from "dep/Footer/Footer.js";
import Sidebar from "components/Global/Sidebar/Sidebar";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "assets/img/sidebar-2.jpg";

import Auth from "helpers/auth.js";

import { DASHBOARD_NAME, LOGO_ONLY } from "const";
import { Helmet } from "react-helmet";

let ps;

const useStyles = makeStyles(styles);

const User = ({ ...rest }) => {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  const switchRoutes = (
    <Switch {...rest}>
      {routes.map((prop, key) => {
        if (prop.layout === "/verifikator") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/verifikator" to="/verifikator/portofolio" />
    </Switch>
  );

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <title>Dashboard Verifikator - Janabadra Professional Tracker</title>
        <meta
          name="description"
          content="Janabadra Professional Tracker, Sistem Data Portofolio Pendamping Ijazah, Universitas Janabadra"
        />
      </Helmet>
      <Sidebar
        routes={routes}
        logoText={DASHBOARD_NAME}
        logo={LOGO_ONLY}
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"blue"}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>{switchRoutes}</div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Auth.withAuthVerifikator(User);
