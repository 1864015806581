import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip } from "@material-ui/core";
// jss
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle";
import buttonsStyles from "assets/jss/jpt/buttonsStyles";
// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const useStyles = makeStyles({
  ...taskStyles,
  ...buttonsStyles
});
export default function ButtonsRubrik(props) {
  const classes = useStyles();
  const handleClick = dialogType => event => {
    props.handleChangeRubrikData(props.rubrikData);
    props.onClick(dialogType);
    event.preventDefault();
  };
  return (
    <>
      <Tooltip
        id="tooltip-tambah-sub-rubrik"
        title="Tambah Sub-Rubrik"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="Add"
          className={classes.tableActionButton}
          onClick={handleClick("add-sub-rubrik")}
        >
          <AddIcon
            className={classes.tableActionButtonIcon + " " + classes.buttonAdd}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        id="tooltip-ubah-rubrik"
        title="Ubah Rubrik"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="edit"
          className={classes.tableActionButton}
          onClick={handleClick("edit-rubrik")}
        >
          <EditIcon
            className={classes.tableActionButtonIcon + " " + classes.buttonEdit}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        id="tooltip-hapus-rubrik"
        title="Hapus Rubrik"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="delete"
          className={classes.tableActionButton}
          onClick={handleClick("delete-rubrik")}
        >
          <DeleteIcon
            className={classes.tableActionButtonIcon + " " + classes.close}
          />
        </IconButton>
      </Tooltip>
      {props.rubrikData.is_expanded ? (
        <Tooltip
          id="tooltip-collapse-rubrik"
          title="Collapse"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            aria-label="collapse"
            className={classes.tableActionButton}
            onClick={() => props.collapseRow(props.rubrikData.id_rubrik)}
          >
            <ExpandLessIcon className={classes.tableActionButtonIcon} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          id="tooltip-expand-rubrik"
          title="Expand"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            aria-label="expand"
            className={classes.tableActionButton}
            onClick={() => props.expandRow(props.rubrikData.id_rubrik)}
          >
            <ExpandMoreIcon className={classes.tableActionButtonIcon} />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

ButtonsRubrik.propTypes = {
  handleChangeRubrikData: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  rubrikData: PropTypes.object.isRequired
};
