import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
// @material-ui/core components
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
// styling
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import valuesStyles from "assets/jss/jpt/valuesStyles";
import buttonsStyles from "assets/jss/jpt/buttonsStyles";

const useStyles = makeStyles(theme => ({
  avatar: { width: theme.spacing(10), height: theme.spacing(10) },
  ...valuesStyles,
  ...taskStyles,
  ...buttonsStyles
}));

export default function TabDialogManajemenMahasiswaProfil(props) {
  const classes = useStyles();

  // var: values->display
  const [listNegara, setListNegara] = useState([]);
  const [values, setValues] = useState({
    nama: "",
    email: "",
    telephone: "",
    phone: "",
    photo: "",
    TTL: "",
    alamat: "",
    kewarganegaraan: "",
    NIK: "",
    kelamin: "",
    agama: "",
    bio: "",
    socialMedia: ""
  });

  useEffect(() => {
    (async function() {
      await handleFetchListNegara();
    })();
  }, []);

  useEffect(() => {
    let kewarganegaraan = listNegara.find(
      e => e.id_negara === _.get(props.previewData, "kewarganegaraan")
    );
    setValues({
      nama: _.get(props.previewData, "nama"),
      email: _.get(props.previewData, "email"),
      telephone: _.get(props.previewData, "no_telepon_asal"),
      phone: _.get(props.previewData, "no_hp"),
      photo: BASE_URL + "/" + _.get(props.previewData, "foto_profil"),
      TTL: `${_.get(props.previewData, "tempat_lahir")}, ${new Date(
        _.get(props.previewData, "tanggal_lahir")
      )
        .toDateString()
        .split(" ")
        .slice(1, 4)
        .join(" ")}`,
      alamat: _.get(props.previewData, "alamat"),
      kewarganegaraan: _.get(kewarganegaraan, "nama_negara", ""),
      NIK: _.get(props.previewData, "nik"),
      kelamin: _.get(props.previewData, "jenis_kelamin")
        ? "Perempuan"
        : "Laki-laki",
      agama: _.get(props.previewData, "agama"),
      bio: _.get(props.previewData, "bio"),
      socialMedia: _.get(props.previewData, "sosmed", []).map(e => {
        return {
          title: _.get(e, "jenis_sosmed"),
          name: _.get(e, "nama"),
          link: _.get(e, "link_sosmed")
        };
      })
    });
  }, [props.dialogProfilID, props.previewData, listNegara]);

  const handleFetchListNegara = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/lokasi/negara");
    let data = await res.json();
    await setListNegara(_.get(data, "negara", []));
  };

  return (
    <>
      <GridContainer justify={"center"}>
        <GridItem>
          <Avatar
            alt={values.nama}
            src={values.photo}
            className={classes.avatar}
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify={"center"}>
        <GridItem>
          <h4 className={classes.values}>{values.nama}</h4>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <GridContainer justify={"space-between"}>
            <GridItem>
              <p>Alamat</p>
            </GridItem>
            <GridItem>
              <p className={classes.values}>{values.alamat}</p>{" "}
            </GridItem>
          </GridContainer>
          <GridContainer justify={"space-between"}>
            <GridItem>
              <p>Kewarganegaraan</p>
            </GridItem>
            <GridItem>
              <p className={classes.values}>{values.kewarganegaraan}</p>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"space-between"}>
            <GridItem>
              <p>NIK</p>
            </GridItem>
            <GridItem>
              <p className={classes.values}>{values.NIK}</p>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"space-between"}>
            <GridItem>
              <p>TTL</p>
            </GridItem>
            <GridItem>
              <p className={classes.values}>{values.TTL}</p>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"space-between"}>
            <GridItem>
              <p>Agama</p>
            </GridItem>
            <GridItem>
              <p className={classes.values}>{values.agama}</p>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"space-between"}>
            <GridItem>
              <p>Jenis Kelamin</p>
            </GridItem>
            <GridItem>
              <p className={classes.values}>{values.kelamin}</p>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <GridContainer justify={"space-between"}>
            <GridItem>
              <p>E-mail</p>
            </GridItem>
            <GridItem>
              <p className={classes.values}>{values.email}</p>
            </GridItem>
          </GridContainer>
          {values.telephone ? (
            <GridContainer justify={"space-between"}>
              <GridItem>
                <p>Nomor Telepon</p>
              </GridItem>
              <GridItem>
                <p className={classes.values}>{values.telephone}</p>
              </GridItem>
            </GridContainer>
          ) : (
            <></>
          )}
          {values.telephone ? (
            <GridContainer justify={"space-between"}>
              <GridItem>
                <p>Nomor HP</p>
              </GridItem>
              <GridItem>
                <p className={classes.values}>{values.phone}</p>
              </GridItem>
            </GridContainer>
          ) : (
            <></>
          )}
          <GridContainer justify={"space-between"}>
            <GridItem>
              <p>Bio</p>
            </GridItem>
            <GridItem>
              <p className={classes.values}>{values.bio}</p>
            </GridItem>
          </GridContainer>
          {values.socialMedia ? (
            <>
              <GridContainer justify={"center"}>
                <GridItem>
                  <p>Social Media</p>
                </GridItem>
              </GridContainer>
              {values.socialMedia.map((row, key) => (
                <GridContainer key={key} justify={"space-between"}>
                  <GridItem>
                    <p>{row.title}</p>
                  </GridItem>
                  <GridItem>
                    <p className={classes.values}>
                      <a href={row.link}>{row.name}</a>
                    </p>
                  </GridItem>
                </GridContainer>
              ))}
            </>
          ) : (
            <></>
          )}
        </GridItem>
      </GridContainer>
    </>
  );
}

TabDialogManajemenMahasiswaProfil.propTypes = {
  dialogProfilID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  previewData: PropTypes.object
};
