import React from "react";
import PropTypes from "prop-types";
import { ListItem, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";

export default function ItemSearch(props) {
  const { values } = props;
  return (
    <>
      <div>
        <ListItem>
          <ListItemText
            primary={
              <Link to={values.link}>
                <p
                  style={{
                    color: "#111111",
                    marginBottom: "0",
                    marginTop: "0"
                  }}
                >
                  {values.tittle}
                </p>
              </Link>
            }
            secondary={values.description}
          />
        </ListItem>
      </div>
    </>
  );
}

ItemSearch.propTypes = {
  values: PropTypes.object
};
