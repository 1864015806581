import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import Button from "dep/CustomButtons/Button";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function DialogDeleteKegiatanInternal(props) {
  const { openSnackbar } = props;

  const handleSubmit = async () => {
    try {
      openSnackbar("info", "Menghapus..");
      let res = await Auth.fetch(
        BASE_URL + "/api/ppk/delete/" + props.selectedKegiatan.id_kegiatan_ppk,
        {
          method: "DELETE"
        }
      );

      let data = await res.json();

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data kegiatan berhasil dihapus!");
        props.handleFetchKegiatan();
        props.onClose();
      } else {
        let errorMessages = "";
        if (typeof data.message === "string") {
          errorMessages = data.message;
        } else if (typeof data.message === "object") {
          errorMessages = "";
          if (Array.isArray(data.message)) {
            for (let err of data.message) {
              errorMessages +=
                typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
            }
          } else {
            for (let key in data.message) errorMessages += data.message[key][0];
          }
        }

        openSnackbar(
          res.status >= 400 && res.status < 500 ? "warning" : "error",
          errorMessages
        );
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Apakah Anda Yakin?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {'Anda akan menghapus kegiatan berjudul "' +
            props.selectedKegiatan.judul +
            '"'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color={"danger"}>
          Tidak
        </Button>
        <Button onClick={handleSubmit} color={"success"} autoFocus>
          Ya
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogDeleteKegiatanInternal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedKegiatan: PropTypes.object.isRequired,
  openSnackbar: PropTypes.func,
  handleFetchKegiatan: PropTypes.func
};
