import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";

// @material-ui/core components
import {
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Button from "dep/CustomButtons/Button";
import CustomInput from "dep/CustomInput/CustomInput";

// @material-ui/icons
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";

// @material-ui/pickers
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// styling
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import valuesStyles from "assets/jss/jpt/valuesStyles";
import buttonsStyles from "assets/jss/jpt/buttonsStyles";
import formStyles from "assets/jss/jpt/formStyles";

const useStyle = makeStyles({
  ...valuesStyles,
  ...taskStyles,
  ...buttonsStyles,
  ...formStyles
});
export default function TabDialogManajemenMahasiswaKemahasiswaan(props) {
  // Props
  const { openSnackbar } = props;

  const classes = useStyle();

  // var: values
  const [idProdi, setIdProdi] = useState("");
  const [NIM, setNIM] = useState("");
  const [statusKelulusan, setStatusKelulusan] = useState(false);
  const [tglMasuk, setTglMasuk] = useState(new Date());
  const [tglLulus, setTglLulus] = useState(new Date());
  // var: list
  const [listProdi, setListProdi] = useState([]);
  // var: values->display
  const [values, setValues] = useState({
    nama: "",
    NIM: "",
    prodi: "",
    prodiID: "",
    status: "",
    portofolio: "",
    SKP: "",
    tglMasuk: "",
    tglLulus: "",
    migrasi: false
  });

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line
  }, [props.dialogProfilID, props.previewData]);

  // var: open-close
  const [openSelectProdi, setOpenSelectProdi] = useState(false);
  const [edit, setEdit] = useState(false);

  // handle: values
  const handleChangeNama = e => {
    setValues({ ...values, nama: e.target.value });
  };

  const handleChangeNIM = event => {
    setNIM(event.target.value);
  };

  const handleChangeStatusKelulusan = value => {
    setStatusKelulusan(value);
  };

  const handleChangeTglMasuk = date => {
    setTglMasuk(date);
  };

  const handleChangeTglLulus = date => {
    setTglLulus(date);
  };

  const handleChangeSelectProdi = event => {
    setIdProdi(event.target.value);
    event.preventDefault();
  };

  // handle: open-close
  const handleCloseSelectProdi = () => {
    setOpenSelectProdi(false);
  };

  const handleOpenSelectProdi = () => {
    setOpenSelectProdi(true);
  };

  // handle: API fetch
  const handleFetchListProdi = () => {
    setListProdi(
      _.get(props, "listProdi", []).map(e => {
        return {
          id: _.get(e, "id_prodi"),
          prodi: `${_.get(e, "nama_prodi")} (${_.get(e, "jenjang")})`
        };
      })
    );
  };

  const handleFetchData = () => {
    if (props.previewData) {
      setValues({
        nama: _.get(props.previewData, "nama"),
        NIM: _.get(props.previewData, "nim"),
        prodi: _.get(props.previewData, "nama_prodi"),
        prodiID: _.get(props.previewData, "id_prodi"),
        SKP: _.get(props.previewData, "skp"),
        portofolio: _.get(props.previewData, "jumlah_portofolio", 0),
        status: _.get(props.previewData, "is_graduated")
          ? "Alumni"
          : "Mahasiswa",
        tglMasuk: _.get(props.previewData, "tanggal_masuk", "-").split(" ")[0],
        tglLulus: (_.get(props.previewData, "tanggal_lulus", "-") || "-").split(
          " "
        )[0],
        migrasi: false
      });
    }
  };

  // handle : open-close(init)
  const handleEditOpen = () => {
    setEdit(true);
    handleFetchListProdi();
    setNIM(values.NIM);
    setStatusKelulusan(values.tglLulus !== "-");
    setIdProdi(values.prodiID);
    setTglMasuk(new Date(values.tglMasuk));
    if (values.tglLulus !== "-") setTglLulus(new Date(values.tglLulus));
  };

  const handleEditClose = () => {
    setEdit(false);
    setNIM("");
    setStatusKelulusan(false);
    setIdProdi("");
    setTglMasuk(new Date());
    setTglLulus(new Date());
  };

  // handle: API submit
  const handleSaveButton = async () => {
    openSnackbar("info", "Mengupdate..");

    let res = await Auth.fetch(BASE_URL + "/api/manajemen/akun/update", {
      method: "PUT",
      body: JSON.stringify(getPUTData())
    });

    let data = await res.json();
    let errorMessages = "";
    if (typeof data.message === "string") {
      errorMessages = data.message;
    } else if (typeof data.message === "object") {
      errorMessages = "";
      if (Array.isArray(data.message)) {
        for (let err of data.message) {
          errorMessages +=
            typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
        }
      } else {
        for (let key in data.message) errorMessages += data.message[key][0];
      }
    }

    if (res.status >= 200 && res.status < 300) {
      openSnackbar("success", "Data user berhasil diupdate!");

      // Biar re fetch dashboard dan previewData keupdate
      // Zz ribet kali ini komponennya
      props.setToggleReFetchMahasiswa(!props.toggleReFetchMahasiswa);
      props.setPreviewData({
        ...props.previewData,
        nama: values.nama,
        nim: NIM !== values.NIM ? NIM : values.NIM,
        id_prodi: idProdi,
        is_graduated: statusKelulusan,
        tanggal_masuk: moment(tglMasuk).format("YYYY-MM-DD"),
        tanggal_lulus: moment(tglLulus).format("YYYY-MM-DD")
      });
      if (props.previewID)
        props.setPreviewID(NIM !== values.NIM ? NIM : values.NIM);
      props.handleChangeDialogProfilID(NIM !== values.NIM ? NIM : values.NIM);

      handleEditClose();
    } else if (res.status >= 400 && res.status < 500) {
      openSnackbar("warning", errorMessages);
    } else {
      openSnackbar("error", errorMessages);
    }
  };

  const getPUTData = () => {
    return {
      nama: values.nama,
      nim: values.NIM,
      nim_baru: NIM !== values.NIM ? NIM : undefined,
      id_prodi: idProdi,
      is_graduated: statusKelulusan,
      tanggal_masuk: moment(tglMasuk).format("YYYY-MM-DD"),
      tanggal_lulus: moment(tglLulus).format("YYYY-MM-DD") //TODO ini kalo is_graduated:false ngga bakal masuk DB kan?
    };
  };

  return (
    <>
      <GridContainer justify={"flex-end"}>
        <GridItem>
          {edit ? (
            <Tooltip
              id="tooltip-top"
              title="close"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                aria-label="close"
                className={classes.tableActionButton}
                onClick={event => {
                  handleEditClose();
                  event.preventDefault();
                }}
              >
                <CloseIcon
                  className={
                    classes.tableActionButtonIcon + " " + classes.close
                  }
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              id="tooltip-top"
              title="Edit"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                aria-label="close"
                className={classes.tableActionButton}
                onClick={event => {
                  handleEditOpen();
                  event.preventDefault();
                }}
              >
                <EditIcon
                  className={
                    classes.tableActionButtonIcon + " " + classes.buttonEdit
                  }
                />
              </IconButton>
            </Tooltip>
          )}
        </GridItem>
      </GridContainer>
      <Collapse in={!edit}>
        <GridContainer>
          <GridItem xs={1} md={3} />
          <GridItem xs={10} md={6}>
            <GridContainer justify={"space-between"}>
              <GridItem>
                <p>Nama</p>
              </GridItem>
              <GridItem>
                <p className={classes.values}>{values.nama}</p>
              </GridItem>
            </GridContainer>
            <GridContainer justify={"space-between"}>
              <GridItem>
                <p>NIM</p>
              </GridItem>
              <GridItem>
                <p className={classes.values}>{values.NIM}</p>
              </GridItem>
            </GridContainer>
            <GridContainer justify={"space-between"}>
              <GridItem>
                <p>Prodi</p>
              </GridItem>
              <GridItem>
                <p className={classes.values}>{values.prodi}</p>
              </GridItem>
            </GridContainer>
            <GridContainer justify={"space-between"}>
              <GridItem>
                <p>Status Kemahasiswaan</p>
              </GridItem>
              <GridItem>
                <p className={classes.values}>{values.status}</p>
              </GridItem>
            </GridContainer>
            <GridContainer justify={"space-between"}>
              <GridItem>
                <p>Jumlah Portofolio</p>
              </GridItem>
              <GridItem>
                <p className={classes.values}>{values.portofolio}</p>
              </GridItem>
            </GridContainer>
            <GridContainer justify={"space-between"}>
              <GridItem>
                <p>Total SKP</p>
              </GridItem>
              <GridItem>
                <p className={classes.values}>{values.SKP}</p>
              </GridItem>
            </GridContainer>
            <GridContainer justify={"space-between"}>
              <GridItem>
                <p>Tanggal Masuk</p>
              </GridItem>
              <GridItem>
                <p className={classes.values}>
                  {moment(values.tglMasuk).format("DD-MM-YYYY")}
                </p>
              </GridItem>
            </GridContainer>
            {values.tglLulus ? (
              <GridContainer justify={"space-between"}>
                <GridItem>
                  <p>Tanggal Lulus</p>
                </GridItem>
                <GridItem>
                  <p className={classes.values}>
                    {values.tglLulus !== "-"
                      ? moment(values.tglLulus).format("DD-MM-YYYY")
                      : "-"}
                  </p>
                </GridItem>
              </GridContainer>
            ) : (
              <></>
            )}
            {values.migrasi ? (
              <GridContainer justify={"space-between"} alignItems={"center"}>
                <GridItem>
                  <p>Migrasi</p>
                </GridItem>
                <GridItem>
                  <Button
                    size={"sm"}
                    color={"primary"}
                    onClick={() => {
                      /*TODO: Dialog buat migrasi*/
                    }}
                  >
                    verifikasi migrasi
                  </Button>
                </GridItem>
              </GridContainer>
            ) : (
              <></>
            )}
          </GridItem>
          <GridItem xs={1} md={3} />
        </GridContainer>
      </Collapse>
      <Collapse in={edit}>
        <GridContainer>
          <GridItem xs={1} md={3} />
          <GridItem xs={10} md={6}>
            <CustomInput
              labelText="Nama"
              id="nama"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: values.nama,
                type: "text",
                onChange: handleChangeNama
              }}
            />
            <CustomInput
              labelText="NIM"
              id="nim"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: NIM,
                type: "number",
                onChange: handleChangeNIM
              }}
            />
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel id="controlled-open-select-label">Prodi</InputLabel>
              <Select
                labelId="controlled-open-select-label"
                id="controlled-open-select"
                open={openSelectProdi}
                onClose={handleCloseSelectProdi}
                onOpen={handleOpenSelectProdi}
                value={idProdi}
                onChange={handleChangeSelectProdi}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {listProdi.map((row, key) => (
                  <MenuItem key={key} value={row.id}>
                    {row.prodi}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <GridContainer justify={"space-between"} alignItems={"flex-end"}>
              <GridItem>
                <p>Status: </p>
              </GridItem>
              <GridItem>
                <GridContainer justify={"flex-end"}>
                  <GridItem>
                    <Button
                      color={statusKelulusan ? "danger" : "success"}
                      onClick={event => {
                        handleChangeStatusKelulusan(false);
                        event.preventDefault();
                      }}
                    >
                      Mahasiswa
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      color={statusKelulusan ? "success" : "danger"}
                      onClick={event => {
                        handleChangeStatusKelulusan(true);
                        event.preventDefault();
                      }}
                    >
                      Alumni
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer justify={"space-between"}>
              <GridItem xs={12} md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-masuk"
                    label="Tanggal Masuk"
                    value={tglMasuk}
                    onChange={handleChangeTglMasuk}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Collapse in={statusKelulusan}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-lulus"
                      label="Tanggal Lulus"
                      value={tglLulus}
                      onChange={handleChangeTglLulus}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Collapse>
              </GridItem>
            </GridContainer>
            <GridContainer justify={"space-between"}>
              <GridItem>
                <Button color={"warning"} onClick={handleEditClose}>
                  Discard
                </Button>
              </GridItem>
              <GridItem>
                <Button color={"primary"} onClick={handleSaveButton}>
                  Save
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={1} md={3} />
        </GridContainer>
      </Collapse>
    </>
  );
}

TabDialogManajemenMahasiswaKemahasiswaan.propTypes = {
  dialogProfilID: PropTypes.string,
  openSnackbar: PropTypes.func,
  previewData: PropTypes.object
};
