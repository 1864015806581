import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from "@material-ui/core";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";
import Button from "dep/CustomButtons/Button";
// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function DialogTambahSubRubrik(props) {
  // Props
  const { open, openSnackbar, handleFetchRubrikData } = props;
  const [rubrikData, setRubrikData] = useState({
    nama_anak_rubrik: "",
    bobot_maksimal_skp_tiap_portofolio: "",
    syarat_minimal_skp: ""
  });

  // handle: API Submit
  const handleAPISubmit = async () => {
    try {
      openSnackbar("info", "Menambahkan..");
      let res = await Auth.fetch(
        BASE_URL +
          "/api/rubrik/" +
          props.rubrikData.id_rubrik +
          "/add-anak-rubrik",
        {
          method: "POST",
          body: JSON.stringify(rubrikData)
        }
      );

      let data = await res.json();
      let errorMessages = "";
      if (typeof data.message === "string") {
        errorMessages = data.message;
      } else if (typeof data.message === "object") {
        errorMessages = "";
        if (Array.isArray(data.message)) {
          for (let err of data.message) {
            errorMessages +=
              typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
          }
        } else {
          for (let key in data.message) errorMessages += data.message[key][0];
        }
      }

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data rubrik berhasil ditambahkan!");
        handleFetchRubrikData();
        handleCloseDialog();
      } else if (res.status >= 400 && res.status < 500) {
        openSnackbar("warning", errorMessages);
      } else {
        openSnackbar("error", errorMessages);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  const handleCloseDialog = () => {
    props.onClose();
    setRubrikData({
      nama_anak_rubrik: "",
      bobot_maksimal_skp_tiap_portofolio: "",
      syarat_minimal_skp: ""
    });
  };
  const changeRubrikData = e => {
    setRubrikData({ ...rubrikData, [e.target.name]: e.target.value });
  };
  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="dialog-title"
      maxWidth={"xs"}
      fullWidth
    >
      <DialogTitle id="dialog-title">Tambahkan Rubrik</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {"Parent Rubrik"}
          <br />
          {"Nama Rubrik: " + props.rubrikData.nama_rubrik}
          <br />
          {"Max SKP: " + props.rubrikData.syarat_minimal_skp}
        </DialogContentText>
        <Divider />
        <DialogContentText>{"Tambahkan sub-Rubrik Baru"}</DialogContentText>
        <GridContainer>
          <GridItem xs={2} />
          <GridItem xs={8}>
            <CustomInput
              labelText="Nama Rubrik"
              id="nama_anak_rubrik"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "nama_anak_rubrik",
                value: rubrikData.nama_anak_rubrik,
                onChange: changeRubrikData
              }}
            />
          </GridItem>
          <GridItem xs={2} />
        </GridContainer>
        <GridContainer>
          <GridItem xs={2} />
          <GridItem xs={8}>
            <CustomInput
              labelText="Bobot Maksimal SKP tiap Portofolio"
              id="bobot_maksimal_skp_tiap_portofolio"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "bobot_maksimal_skp_tiap_portofolio",
                type: "number",
                value: rubrikData.bobot_maksimal_skp_tiap_portofolio,
                onChange: changeRubrikData
              }}
            />
          </GridItem>
          <GridItem xs={2} />
        </GridContainer>
        <GridContainer>
          <GridItem xs={2} />
          <GridItem xs={8}>
            <CustomInput
              labelText="Syarat Minimal SKP"
              id="syarat_minimal_skp"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "syarat_minimal_skp",
                type: "number",
                value: rubrikData.syarat_minimal_skp,
                onChange: changeRubrikData
              }}
            />
          </GridItem>
          <GridItem xs={2} />
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="warning">
          Discard
        </Button>
        <Button
          onClick={handleAPISubmit}
          color="success"
          autoFocus
          disabled={
            !rubrikData.syarat_minimal_skp ||
            !rubrikData.nama_anak_rubrik ||
            !rubrikData.bobot_maksimal_skp_tiap_portofolio
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogTambahSubRubrik.propTypes = {
  handleFetchRubrikData: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  openSnackbar: PropTypes.func,
  rubrikData: PropTypes.object
};
