import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

// @material-ui/core components
import { IconButton, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

// core components
import GridItem from "dep/Grid/GridItem.js";
import GridContainer from "dep/Grid/GridContainer.js";
import Table from "dep/Table/Table.js";
import Card from "dep/Card/Card.js";
import CardHeader from "dep/Card/CardHeader.js";
import CardBody from "dep/Card/CardBody.js";
import CustomInput from "dep/CustomInput/CustomInput";
import ButtonFilterManajemenMahasiswa from "components/Admin/ManajemenMahasiswa/Button/ButtonFilterManajemenMahasiswa";
import ButtonEditManajemenMahasiswa from "components/Admin/ManajemenMahasiswa/Button/ButtonEditManajemenMahasiswa";

// @material-ui/icons components
import IconSearch from "@material-ui/icons/Search";
import Button from "dep/CustomButtons/Button";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
// API
import Auth from "helpers/auth";
import { BASE_URL, ID_ROLE_MAHASISWA, ROLE_SUPER_ADMIN } from "const";
import cardStyles from "assets/jss/jpt/cardStyles";

//styling
const useStyles = makeStyles(cardStyles);

export default function CardManajemenMahasiswa(props) {
  const classes = useStyles();

  const { openSnackbar } = props;

  //var: values
  const [values, setValues] = useState({
    filter: {
      fakultas: [],
      prodi: [],
      angkatan: "",
      SKPmin: "",
      SKPmax: "",
      lulus: ""
    },
    search: ""
  });
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    const init = async () => {
      openSnackbar("info", "Memuat...");
      await handleFetchListMahasiswa();
      openSnackbar("success", "Ditemukan...");
    };
    init();
    // eslint-disable-next-line
  }, [page, props.listProdi, values, props.toggleReFetchMahasiswa]);

  //handle: [API]fetch
  const handleFetchListMahasiswa = async () => {
    let url;
    if (Auth.isRoleEqualTo(ROLE_SUPER_ADMIN)) {
      url = `${BASE_URL}/api/manajemen/akun?id_role=${ID_ROLE_MAHASISWA}&paginate=10&page=${page}`;
    } else {
      url = `${BASE_URL}/api/manajemen/akun?id_role=${ID_ROLE_MAHASISWA}&id_prodi=${Auth.getIdProdi()}&paginate=10&page=${page}`;
    }

    url = values.filter.SKPmin
      ? (url += "&skp_min=" + values.filter.SKPmin)
      : url;
    url = values.filter.SKPmax
      ? (url += "&skp_max=" + values.filter.SKPmax)
      : url;
    url = values.filter.angkatan
      ? (url += "&tahun_masuk=" + values.filter.angkatan.split("/")[0])
      : url;
    url = values.search ? (url += "&keyword=" + values.search) : url;
    url =
      values.filter.lulus !== ""
        ? (url += "&is_graduated=" + values.filter.lulus)
        : url;
    _.get(values, "filter.prodi", []).forEach((val, idx) => {
      url += `&id_prodi[${idx}]=${val}`;
    });
    _.get(values, "filter.fakultas", []).forEach((val, idx) => {
      url += `&id_fakultas[${idx}]=${val}`;
    });

    let resMahasiswa = await Auth.fetch(url);
    let mahasiswa = await resMahasiswa.json();

    setPageCount(_.get(mahasiswa, "users.last_page", 1));
    setTableData(
      _.get(mahasiswa, "users.data", []).map((e, key) => {
        let nama_prodi = _.get(
          props.listProdi[
            _.sortedIndexBy(
              props.listProdi,
              { id_prodi: _.get(e, "id_prodi") },
              "id_prodi"
            )
          ],
          "nama_prodi"
        );
        return [
          _.get(e, "nim"),
          _.get(e, "nama"),
          nama_prodi,
          _.get(e, "skp", 0),
          _.get(e, "tanggal_masuk", "").split("-")[0],
          _.get(e, "is_graduated", 0) ? "LULUS" : "BELUM LULUS",
          <ButtonEditManajemenMahasiswa
            key={key}
            NIM={_.get(e, "nim")}
            dataMahasiwa={{ ...e, nama_prodi }}
            {...props}
          />
        ];
      })
    );
  };

  //handle: values
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangePage = (_e, page) => {
    setPage(page);
  };

  const filterChange = (fakultas, prodi, angkatan, SKPmin, SKPmax, lulus) => {
    setValues({
      ...values,
      filter: {
        fakultas: fakultas,
        prodi: prodi,
        angkatan: angkatan,
        SKPmin: SKPmin,
        SKPmax: SKPmax,
        lulus: lulus
      }
    });
  };

  const handleChangeCard = () => {
    props.handleChangeCardType("migrasi");
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <GridContainer justify={"space-between"} alignItems={"flex-end"}>
              <GridItem>
                <h4 className={classes.cardTitleWhite}>Manajemen Mahasiswa</h4>
                <p className={classes.cardCategoryWhite}>Manajemen Mahasiswa</p>
              </GridItem>
              <GridItem>
                <Button
                  round
                  size="sm"
                  color={"white"}
                  onClick={handleChangeCard}
                >
                  <KeyboardArrowRightIcon />
                  Migrasi
                </Button>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridContainer alignItems="flex-end" justify={"center"}>
              <GridItem xs={12} md={8}>
                <CustomInput
                  onChange={handleChange("search")}
                  labelText="Cari mahasiswa"
                  id="mahasiswa"
                  onSubmit={handleFetchListMahasiswa}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleChange("search"),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          type={"submit"}
                          aria-label={"search"}
                          onClick={handleFetchListMahasiswa}
                        >
                          <IconSearch />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </GridItem>
              <GridItem md={4}>
                <GridContainer justify={"center"}>
                  <ButtonFilterManajemenMahasiswa
                    filterChange={filterChange}
                    filters={values.filter}
                    {...props}
                  />
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    "NIM",
                    "Nama",
                    "Program Studi",
                    "SKP",
                    "Angkatan",
                    "Kelulusan",
                    "OPSI"
                  ]}
                  tableData={tableData}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs />
              <GridItem>
                <Pagination
                  count={pageCount}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  onChange={handleChangePage}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

CardManajemenMahasiswa.propTypes = {
  handleChangeCardType: PropTypes.func,
  listProdi: PropTypes.array,
  openSnackbar: PropTypes.func
};
