import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

// styling
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";
import buttonsStyles from "assets/jss/jpt/buttonsStyles";

const useStyles = makeStyles({
  ...tasksStyle,
  ...buttonsStyles
});

export default function ButtonsManajemenAnakCPL(props) {
  const handleButtonEdit = () => {
    props.handleChangeSelectedAnakCPL(props.cplData);
    props.handleChangeSelectedCPL(props.parentCPL);
    props.handleChangeNewDialogType("edit-anak");
  };
  const handleButtonDelete = () => {
    props.handleChangeSelectedAnakCPL(props.cplData);
    props.handleChangeSelectedCPL(props.parentCPL);
    props.handleChangeNewDialogType("delete-anak");
  };
  const classes = useStyles();
  return (
    <>
      <Tooltip
        id="tooltip-top"
        title="Ubah CPL"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="Edit"
          className={classes.tableActionButton}
          onClick={handleButtonEdit}
        >
          <EditIcon
            className={classes.tableActionButtonIcon + " " + classes.buttonEdit}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        id="tooltip-top"
        title="Hapus Anak CPL"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="Delete"
          className={classes.tableActionButton}
          onClick={handleButtonDelete}
        >
          <DeleteForeverIcon
            className={classes.tableActionButtonIcon + " " + classes.close}
          />
        </IconButton>
      </Tooltip>
    </>
  );
}

ButtonsManajemenAnakCPL.propTypes = {
  handleChangeSelectedAnakCPL: PropTypes.func,
  cplData: PropTypes.object,
  handleChangeSelectedCPL: PropTypes.func,
  parentCPL: PropTypes.object,
  handleChangeNewDialogType: PropTypes.func
};
