import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from "@material-ui/core";

// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";
import Button from "dep/CustomButtons/Button";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function DialogEditManajemenCPL(props) {
  // props
  const {
    handleFetchCPLData,
    openSnackbar,
    parentCPL,
    selectedAnakCPL = {
      point_cpl_inggris: "",
      point_cpl_indonesia: ""
    }
  } = props;

  // state
  const [cplData, setCPLData] = useState(selectedAnakCPL);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (
      !!selectedAnakCPL &&
      !!parentCPL &&
      props.newDialogType === "edit-anak"
    ) {
      setOpenDialog(true);
      setCPLData(selectedAnakCPL);
    }
    // eslint-disable-next-line
  }, [selectedAnakCPL, parentCPL, props.newDialogType]);

  // handle: API submit
  const handleAPISubmit = async () => {
    try {
      openSnackbar("info", "Mengupdate..");
      let res = await Auth.fetch(
        BASE_URL +
          "/api/cpl/" +
          cplData.id_cpl +
          "/" +
          cplData.id_point_cpl +
          "/update",
        {
          method: "PUT",
          body: JSON.stringify(cplData)
        }
      );

      let data = await res.json();
      let errorMessages = "";
      if (typeof data.message === "string") {
        errorMessages = data.message;
      } else if (typeof data.message === "object") {
        errorMessages = "";
        if (Array.isArray(data.message)) {
          for (let err of data.message) {
            errorMessages +=
              typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
          }
        } else {
          for (let key in data.message) errorMessages += data.message[key][0];
        }
      }

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data cpl berhasil diupdate!");
        handleFetchCPLData();
        handleCloseDialog();
      } else if (res.status >= 400 && res.status < 500) {
        openSnackbar("warning", errorMessages);
      } else {
        openSnackbar("error", errorMessages);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  const changeCPLData = e => {
    setCPLData({ ...cplData, [e.target.name]: e.target.value });
  };

  // handle: open-close -> props
  const handleCloseDialog = () => {
    setOpenDialog(false);
    props.handleChangeSelectedCPL("");
    props.handleChangeNewDialogType("");
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="dialog-title"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle id="dialog-title">Edit Item CPL</DialogTitle>
      <DialogContent>
        {parentCPL ? (
          <>
            <DialogContentText>
              {"Parent Item Info"}
              <br />
              {"Nama Item (ID): " + parentCPL.judul_cpl_indonesia}
              <br />
              {"Item Name (EN): " + parentCPL.judul_cpl_inggris}
            </DialogContentText>
            <Divider />
          </>
        ) : (
          <></>
        )}
        <DialogContentText>
          {"Ubah Item Capaian Pembelajaran Lulusan"}
        </DialogContentText>
        <GridContainer justify={"center"}>
          <GridItem xs={10} md={8}>
            <CustomInput
              labelText="Urutan"
              id="cplData.urutan_di_skpi"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "urutan_di_skpi",
                type: "number",
                value: cplData.urutan_di_skpi,
                onChange: changeCPLData
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify={"center"}>
          <GridItem xs={10} md={8}>
            <CustomInput
              labelText="Nama Item (Bahasa)"
              id="item-name-ID"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "point_cpl_indonesia",
                value: cplData.point_cpl_indonesia,
                onChange: changeCPLData
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify={"center"}>
          <GridItem xs={10} md={8}>
            <CustomInput
              labelText="Item Name (English)"
              id="item-name-EN"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "point_cpl_inggris",
                value: cplData.point_cpl_inggris,
                onChange: changeCPLData
              }}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="warning" round size={"sm"}>
          Discard
        </Button>
        <Button
          onClick={handleAPISubmit}
          color="success"
          autoFocus
          round
          size={"sm"}
          disabled={
            !cplData.point_cpl_inggris ||
            !cplData.point_cpl_indonesia ||
            !cplData.urutan_di_skpi
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogEditManajemenCPL.propTypes = {
  handleChangeNewDialogType: PropTypes.func,
  handleChangeSelectedCPL: PropTypes.func,
  handleFetchCPLData: PropTypes.func,
  newDialogType: PropTypes.string,
  openSnackbar: PropTypes.func,
  parentCPL: PropTypes.any,
  selectedAnakCPL: PropTypes.object
};
