import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";
// dep components
import GridItem from "dep/Grid/GridItem";
import Table from "dep/Table/Table";
import GridContainer from "dep/Grid/GridContainer";

export default function DialogChecklistSubRubrik(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll={"body"}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogContent>
        <DialogContentText>
          Tabel mengenai pencapaian portofolio di setiap sub-Rubrik
        </DialogContentText>
        <GridContainer justify={"center"}>
          <GridItem xs={10}>
            <Table
              tableHeaderColor="primary"
              tableHead={["sub-Rubrik", "Point SKP", "Point SKP Min", "Status"]}
              tableData={props.selectedRubrikList}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
}

DialogChecklistSubRubrik.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selectedRubrikList: PropTypes.array
};
