const buttonsStyles = {
  buttonEdit: {
    backgroundColor: "transparent",
    color: "#ffbb00",
    boxShadow: "none"
  },
  buttonInfo: {
    backgroundColor: "transparent",
    color: "#009eff",
    boxShadow: "none"
  },
  buttonAdd: {
    backgroundColor: "transparent",
    color: "#0051ff",
    boxShadow: "none"
  },
  buttonPdf: {
    backgroundColor: "transparent",
    color: "#575757",
    boxShadow: "none"
  },
  buttonClose: {
    backgroundColor: "transparent",
    color: "#ffffff",
    boxShadow: "none"
  }
};
export default buttonsStyles;
