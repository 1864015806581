import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

// @material-ui/core components
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
// dep components
import Button from "dep/CustomButtons/Button";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";
// @material-ui/icon
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import IconAdd from "@material-ui/icons/Add";
import DialogContentText from "@material-ui/core/DialogContentText";

import Auth from "helpers/auth";
import { BASE_URL } from "const";
import formStyles from "assets/jss/jpt/formStyles";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ID_ROLE_VERIFIKATOR = 3;

//styling
const useStyles = makeStyles(formStyles);

export default function ButtonTambahManajemenAdminVerifikator(props) {
  const { openSnackbar, handleFetchUserData } = props;
  const classes = useStyles();

  const [selectedRubriks, setSelectedRubriks] = useState([]);
  const [listRubrik, setListRubrik] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [userState, setUserState] = useState({
    nim: "",
    email: "",
    nama: "",
    id_prodi: 1, // Ntar diganti, buat nyoba bisa ga doang
    password: "",
    password_confirmation: "",
    id_role: ID_ROLE_VERIFIKATOR,
    agama: "-",
    alamat: "-",
    jenis_kelamin: 1,
    kewarganegaraan: 102,
    id_provinsi: 12,
    id_kota: 1271,
    tanggal_lahir: "1900-01-01",
    tanggal_masuk: "1900-01-01",
    tempat_lahir: "-"
  });

  useEffect(() => {
    handleFetchListRubrik();
  }, []);

  // handle: Fetch List
  const handleFetchListRubrik = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/anak_rubrik");
    let data = await res.json();

    let listRubrik = [];

    for (let anak_rubrik of _.get(data, "anak_rubrik")) {
      listRubrik.push({
        id: _.get(anak_rubrik, "id_anak_rubrik"),
        rubrik: _.get(anak_rubrik, "nama_anak_rubrik"),
        id_prodi: _.get(anak_rubrik, "id_prodi"),
        prodi:
          _.get(anak_rubrik, "nama_prodi") +
          " (" +
          _.get(anak_rubrik, "jenjang") +
          ")"
      });
    }

    setListRubrik(listRubrik.sort((a, b) => a.id_prodi - b.id_prodi));
  };

  // handle: API Submit
  const handleAPISubmit = async () => {
    try {
      await addUser();
      await addListRubrik();
    } catch (err) {
      if (err.severity) {
        openSnackbar(err.severity, err.message);
      } else {
        openSnackbar("error", "Terjadi kesalahan");
      }
    }
  };

  const addListRubrik = async () => {
    openSnackbar("info", "Menambahkan list rubrik..");
    let res = await Auth.fetch(BASE_URL + "/api/manajemen/verifikator/update", {
      method: "PUT",
      body: JSON.stringify({
        nim_verifikator: userState.nim,
        id_anak_rubrik: selectedRubriks
      })
    });

    let data = await res.json();
    let errorMessages = "";
    if (typeof data.message === "string") {
      errorMessages = data.message;
    } else if (typeof data.message === "object") {
      errorMessages = "";
      if (Array.isArray(data.message)) {
        for (let err of data.message) {
          errorMessages +=
            typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
        }
      } else {
        for (let key in data.message) errorMessages += data.message[key][0];
      }
    }

    if (res.status >= 200 && res.status < 300) {
      openSnackbar("success", "Data user berhasil ditambahkan!");
      handleFetchUserData();
      handleCloseDialog();
    } else if (res.status >= 400 && res.status < 500) {
      throw { severity: "warning", message: errorMessages };
    } else {
      throw { severity: "error", message: errorMessages };
    }
  };

  const addUser = async () => {
    openSnackbar("info", "Menambahkan..");
    let res = await Auth.fetch(BASE_URL + "/api/manajemen/akun/add", {
      method: "POST",
      body: JSON.stringify({ ...userState, nik: userState.nim })
    });

    let data = await res.json();
    let errorMessages = "";
    if (typeof data.message === "string") {
      errorMessages = data.message;
    } else if (typeof data.message === "object") {
      errorMessages = "";
      if (Array.isArray(data.message)) {
        for (let err of data.message) {
          errorMessages +=
            typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
        }
      } else {
        for (let key in data.message) errorMessages += data.message[key][0];
      }
    }

    if (res.status >= 200 && res.status < 300) {
      openSnackbar("success", "Data user berhasil ditambahkan!");
      handleFetchUserData();
      handleCloseDialog();
    } else if (res.status >= 400 && res.status < 500) {
      throw { severity: "warning", message: errorMessages };
    } else {
      throw { severity: "error", message: errorMessages };
    }
  };

  const handleChangeUserState = e => {
    setUserState({ ...userState, [e.target.name]: e.target.value });
  };

  const handleChangeIdRubrik = (event, values) => {
    setSelectedRubriks(values.map(e => e.id));
  };

  const handleCheckPassword = () => {
    return userState.password === userState.password_confirmation;
  };

  // handle: open-close
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Button color="white" size={"sm"} round onClick={handleClickOpenDialog}>
        <IconAdd />
        Tambah
      </Button>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Tambah Verifikator</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"Silahkan masukkan informasi mengenai Verifikator"}
          </DialogContentText>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <CustomInput
                labelText="Username"
                id="username"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "nim",
                  value: userState.nim,
                  onChange: handleChangeUserState
                }}
              />
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <CustomInput
                labelText="Nama"
                id="nama"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "nama",
                  value: userState.nama,
                  onChange: handleChangeUserState
                }}
              />
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <CustomInput
                labelText="email"
                id="email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "email",
                  value: userState.email,
                  onChange: handleChangeUserState
                }}
              />
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <CustomInput
                labelText="Password"
                id="password"
                error={!handleCheckPassword()}
                success={handleCheckPassword()}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "password",
                  type: "password",
                  value: userState.password,
                  onChange: handleChangeUserState
                }}
              />
              <CustomInput
                labelText="Re-Enter New Password"
                id="reenter-password"
                error={!handleCheckPassword()}
                success={handleCheckPassword()}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "password_confirmation",
                  value: userState.password_confirmation,
                  type: "password",
                  onChange: handleChangeUserState
                }}
              />
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <FormControl
                className={classes.formControl}
                fullWidth
                style={{ marginTop: "30px", marginBottom: "30px" }}
              >
                <Autocomplete
                  onChange={handleChangeIdRubrik}
                  multiple
                  id="checkboxes-tags-rubrik"
                  options={listRubrik}
                  disableCloseOnSelect
                  groupBy={option => option.prodi}
                  getOptionLabel={option => option.rubrik}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.rubrik}
                    </React.Fragment>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Rubrik"
                      placeholder="Rubrik"
                    />
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="warning">
            Discard
          </Button>
          <Button
            onClick={handleAPISubmit}
            color="success"
            autoFocus
            disabled={
              !userState.nim ||
              !userState.email ||
              !selectedRubriks.length ||
              !handleCheckPassword()
            }
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ButtonTambahManajemenAdminVerifikator.propTypes = {
  handleFetchUserData: PropTypes.func,
  openSnackbar: PropTypes.func
};
