import React, { useEffect } from "react";

import _ from "lodash";
import { BASE_URL } from "const.js";
import moment from "moment";
import LandingPage from "layouts/LandingPage.js";
import jwt from "jsonwebtoken";

import {
  ROLE_SUPER_ADMIN,
  ROLE_ADMIN_JURUSAN,
  ROLE_MAHASISWA,
  ROLE_VERIFIKATOR,
  ROLE_PPK
} from "const";

class Auth {
  // Login Token
  getRawToken = () => {
    return localStorage.getItem("token");
  };

  getToken = () => {
    return jwt.decode(this.getRawToken()) || false;
  };

  isTokenValid = () => {
    let token = this.getToken();
    if (!token) return false;
    else return token.exp * 1000 >= Date.now();
  };

  deleteToken = () => {
    localStorage.removeItem("token");
  };

  // OTP
  getOTPToken = () => {
    return JSON.parse(localStorage.getItem("otpToken"));
  };

  checkOTPToken = () => {
    let otpToken = this.getOTPToken();
    if (!otpToken) return false;
    let { nim, expiry } = otpToken;
    if (nim && expiry > moment().format()) {
      return true;
    } else {
      localStorage.removeItem("otpToken");
      return false;
    }
  };

  deleteOTPToken = () => {
    localStorage.removeItem("otpToken");
  };

  fetch = async (url, options, contentType = "application/json") => {
    const headers = {
      Accept: "application/json",
      "Content-Type": contentType
    };

    if (this.isLoggedIn()) {
      headers["Authorization"] = "Bearer " + this.getRawToken();
    }

    return await fetch(url, {
      headers,
      ...options
    });
  };

  fetchMultipart = async (url, options) => {
    const headers = {
      Accept: "application/json"
    };

    if (this.isLoggedIn()) {
      headers["Authorization"] = "Bearer " + this.getRawToken();
    }

    return await fetch(url, {
      headers,
      ...options
    });
  };

  login = async (nim, password) => {
    let res = await this.fetch(BASE_URL + "/api/auth/login", {
      method: "POST",
      body: JSON.stringify({ nim, password })
    });

    if (res.status >= 200 && res.status < 300) {
      let { message } = await res.json();

      let otpToken = JSON.stringify({
        nim,
        password,
        message,
        expiry: moment()
          .add("30", "m")
          .format()
      });
      localStorage.setItem("otpToken", otpToken);
      return otpToken;
    } else {
      return false;
    }
  };

  logout = history => {
    this.deleteToken();
    history.push("/");
  };

  isLoggedIn = () => {
    try {
      return this.isTokenValid();
    } catch (err) {
      return false;
    }
  };

  submitOTP = async pin_otp => {
    let { nim, password } = this.getOTPToken();

    let res = await this.fetch(BASE_URL + "/api/auth/login/otp", {
      method: "POST",
      body: JSON.stringify({ nim, password, pin_otp })
    });

    if (res.status >= 200 && res.status < 300) {
      let body = await res.json();
      let token = body.token;
      localStorage.setItem("token", token);
      return true;
    } else {
      return false;
    }
  };

  withLogin = Component => props => {
    useEffect(() => {}, [this.checkOTPToken(), props]);

    return this.checkOTPToken() ? (
      <Component {...props} />
    ) : (
      <LandingPage {...props} />
    );
  };

  withAuthUser = Component => props => {
    const allowedUser = [
      ROLE_MAHASISWA,
      ROLE_VERIFIKATOR,
      ROLE_PPK,
      ROLE_ADMIN_JURUSAN,
      ROLE_SUPER_ADMIN
    ];
    useEffect(() => {
      if (!this.isTokenValid()) {
        this.deleteToken();
      }
    }, [this.isTokenValid(), props]);

    return allowedUser.includes((this.getToken().role || "").toLowerCase()) ? (
      <Component {...props} />
    ) : (
      <LandingPage {...props} />
    );
  };

  withAuthVerifikator = Component => props => {
    const allowedUser = [ROLE_VERIFIKATOR];
    useEffect(() => {
      if (!this.isTokenValid()) {
        this.deleteToken();
      }
    }, [this.isTokenValid(), props]);

    return allowedUser.includes((this.getToken().role || "").toLowerCase()) ? (
      <Component {...props} />
    ) : (
      <LandingPage {...props} />
    );
  };

  withAuthAdmin = Component => props => {
    const allowedUser = [ROLE_ADMIN_JURUSAN, ROLE_SUPER_ADMIN];
    useEffect(() => {
      if (!this.isTokenValid()) {
        this.deleteToken();
      }
    }, [this.isTokenValid(), props]);

    return allowedUser.includes((this.getToken().role || "").toLowerCase()) ? (
      <Component {...props} />
    ) : (
      <LandingPage {...props} />
    );
  };

  isRoleEqualTo = equalTo => {
    let token = this.getToken();
    let role = _.get(token, "role", "").toLowerCase();
    return role === equalTo;
  };

  getIdProdi = () => {
    if (this.isRoleEqualTo(ROLE_SUPER_ADMIN)) return "";
    return _.get(this.getToken(), "id_prodi", "");
  };
}

export default new Auth();
