import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Collapse,
  DialogActions
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DropzoneDialog } from "material-ui-dropzone";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
// dep compnents
import CustomInput from "dep/CustomInput/CustomInput.js";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomSelect from "dep/CustomInput/CustomSelect";
import Button from "dep/CustomButtons/Button";
import ChipFileSingle from "components/Global/Chip/ChipFileSingle";
// @material-ui/icons
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
//jss
import customStyles from "assets/jss/material-dashboard-react/components/customInputStyle";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

const useStyles = makeStyles(customStyles);
const MAX_FILE_SIZE = 2000000;
const ACCEPT_FILE_TYPE = ["application/pdf"];

const addKegiatanValidator = {
  id_bidang_portofolio: val => {
    if (!val) return "This field is required";
    else if (isNaN(parseInt(val))) return "This field must be number";
    return "";
  },
  id_rubrik: val => {
    if (!val) return "This field is required";
    else if (isNaN(parseInt(val))) return "This field must be number";
    return "";
  },
  id_anak_rubrik: val => {
    if (!val) return "This field is required";
    else if (isNaN(parseInt(val))) return "This field must be number";
    return "";
  },
  judul: val => {
    if (!val) return "This field is required";
    return "";
  },
  judul_inggris: () => {
    return "";
  },
  bobot_skp_portofolio: val => {
    if (!val) return "This field is required";
    else if (isNaN(parseInt(val))) return "This field must be number";
    return "";
  },
  tanggal_terbit: val => {
    if (!val) return "This field is required";
    return "";
  },
  file: val => {
    if (!val) return "This field is required";
    return "";
  }
};

export default function DialogAddKegiatanInternal(props) {
  const { openSnackbar } = props;
  const classes = useStyles();

  // var: values->list
  const [listKategori, setListKategori] = useState([]);
  const [listRubrik, setListRubrik] = useState([]);
  const [listSubRubrik, setListSubRubrik] = useState([]);

  // var: values
  const [values, setValues] = useState({
    id_bidang_portofolio: "", //id_bidang_portofolio
    id_rubrik: "",
    id_anak_rubrik: "",
    judul: "",
    judul_inggris: "",
    bobot_skp_portofolio: "",
    tanggal_terbit: new Date(),
    file: null
  });

  const [errors, setErrors] = useState({
    id_bidang_portofolio: "",
    id_rubrik: "",
    id_anak_rubrik: "",
    judul: "",
    judul_inggris: "",
    bobot_skp_portofolio: "",
    tanggal_terbit: "",
    file: ""
  });

  useEffect(() => {
    handleFetchKategori();
  }, [props.selectedProdi]);

  // var: open-close
  const [openDropzone, setOpenDropzone] = useState(false);

  const validateAll = () => {
    let isValid = 1;
    let validatedError = Object.keys(addKegiatanValidator).reduce(
      (acc, key) => {
        let error = addKegiatanValidator[key](values[key]);
        isValid &= !error;
        return {
          ...acc,
          [key]: error
        };
      },
      {}
    );
    setErrors({ ...errors, ...validatedError });
    return isValid;
  };

  // handle: API
  const handleFetchKategori = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/bidangportofolio");
    let data = await res.json();

    setListKategori(
      _.get(data, "bidang_portofolio", []).map(e => {
        return {
          value: e.id_bidang_portofolio,
          text: e.nama_bidang_portofolio
        };
      })
    );
  };

  const handleFetchRubrik = async id_bidang => {
    let res = await Auth.fetch(
      BASE_URL +
        "/api/prodi/" +
        props.selectedProdi +
        "/rubrik/bidang/" +
        id_bidang
    );
    let data = await res.json();
    setListRubrik(
      _.get(data, "rubrik", []).map(e => {
        return {
          value: e.id_rubrik,
          text: e.nama_rubrik,
          anak_rubrik: e.anak_rubrik
        };
      })
    );
  };

  const handleFetchSubRubrik = id_rubrik => {
    setListSubRubrik(
      _.get(listRubrik.find(e => e.value === id_rubrik), "anak_rubrik", []).map(
        e => {
          return {
            value: e.id_anak_rubrik,
            text: e.nama_anak_rubrik,
            max_point: e.bobot_maksimal_skp_tiap_portofolio
          };
        }
      )
    );
  };

  const handleSubmit = async () => {
    try {
      if (!validateAll()) {
        openSnackbar("warning", "Mohon mengisi data dengan benar");
        return;
      }
      openSnackbar("info", "Menambahkan..");
      let form = new FormData();
      form.append("judul", values.judul);
      form.append("judul_inggris", values.judul_inggris);
      form.append("id_anak_rubrik", values.id_anak_rubrik);
      form.append("bobot_skp_portofolio", values.bobot_skp_portofolio);
      form.append(
        "tanggal_terbit",
        moment(values.tanggal_terbit).format("YYYY-MM-DD")
      );
      form.append("dokumen_ppk", values.file);

      let res = await Auth.fetchMultipart(BASE_URL + "/api/ppk/add", {
        method: "POST",
        body: form
      });
      let data = await res.json();

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data kegiatan berhasil ditambahkan!");
        props.handleFetchKegiatan();
        handleCloseDialog();
      } else {
        let errorMessages = "";
        if (typeof data.message === "string") {
          errorMessages = data.message;
        } else if (typeof data.message === "object") {
          errorMessages = "";
          if (Array.isArray(data.message)) {
            for (let err of data.message) {
              errorMessages +=
                typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
            }
          } else {
            for (let key in data.message) errorMessages += data.message[key][0];
          }
        }

        openSnackbar(
          res.status >= 400 && res.status < 500 ? "warning" : "error",
          errorMessages
        );
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  //handle: open-close
  const handleOpenDropzone = () => {
    setOpenDropzone(true);
  };
  const handleCloseDropzone = () => {
    setOpenDropzone(false);
  };
  const handleCloseDialog = () => {
    props.onClose();
    setValues({
      id_bidang_portofolio: "",
      id_rubrik: "",
      id_anak_rubrik: "",
      judul: "",
      judul_inggris: "",
      bobot_skp_portofolio: "",
      tanggal_terbit: new Date(),
      file: null
    });
  };

  // handle: values
  const handleDiscardFile = () => {
    setValues({ ...values, file: null });
  };
  const handleSaveDropzone = files => {
    setValues({ ...values, file: files[0] });
    setOpenDropzone(false);
  };
  const handleChangeDate = date => {
    setValues({ ...values, tanggal_terbit: date });
  };
  const handleChangeValues = event => {
    const value = event.target.value;
    const name = event.target.name;

    if (name === "id_bidang_portofolio") {
      handleFetchRubrik(value);
      setValues({
        ...values,
        [name]: value,
        id_rubrik: "",
        id_anak_rubrik: ""
      });
    } else if (name === "id_rubrik") {
      handleFetchSubRubrik(value);
      setValues({ ...values, [name]: value, id_anak_rubrik: "" });
    } else setValues({ ...values, [name]: value });
  };

  const handleOnBlur = e => {
    setErrors({
      ...errors,
      [e.target.name]: addKegiatanValidator[e.target.name](e.target.value)
    });
  };

  const handleOnFocus = e => {
    setErrors({ ...errors, [e.target.name]: "" });
  };

  // handle: render
  const renderMaxPoint = idAnakRubrik => {
    return _.get(
      _.find(listSubRubrik, { value: idAnakRubrik }),
      "max_point",
      0
    );
  };

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={props.open}
      onClose={handleCloseDialog}
    >
      <DropzoneDialog
        open={openDropzone}
        onSave={handleSaveDropzone}
        onClose={handleCloseDropzone}
        filesLimit={1}
        maxFileSize={MAX_FILE_SIZE}
        acceptedFiles={ACCEPT_FILE_TYPE}
        showFileNames={true}
        dropzoneText={"Upload bukti kegiatan"}
      />
      <DialogTitle>Tambah Kegiatan Internal</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Harap isi informasi mengenai kegiatan yang diselenggarakan
        </DialogContentText>
        <GridContainer justify={"center"}>
          <GridItem xs={11} md={10}>
            <CustomSelect
              labelText="Kategori"
              id="id_bidang_portofolio"
              formControlProps={{
                fullWidth: true
              }}
              error={!!errors.id_bidang_portofolio}
              helperText={errors.id_bidang_portofolio}
              selectProps={{
                id: "id_bidang_portofolio",
                name: "id_bidang_portofolio",
                onChange: e => {
                  handleChangeValues(e);
                  handleOnBlur(e);
                },
                onBlur: handleOnBlur,
                onFocus: handleOnFocus
              }}
              selectItems={listKategori}
              selectValue={values.id_bidang_portofolio}
            />
          </GridItem>
        </GridContainer>
        <Collapse in={Boolean(values.id_bidang_portofolio)}>
          <GridContainer justify={"center"}>
            <GridItem xs={11} md={5}>
              <CustomSelect
                labelText="Rubrik"
                id="id_rubrik"
                formControlProps={{
                  fullWidth: true
                }}
                error={!!errors.id_rubrik}
                helperText={errors.id_rubrik}
                selectProps={{
                  id: "id_rubrik",
                  name: "id_rubrik",
                  onChange: e => {
                    handleChangeValues(e);
                    handleOnBlur(e);
                  },
                  onBlur: handleOnBlur,
                  onFocus: handleOnFocus
                }}
                selectItems={listRubrik}
                selectValue={values.id_rubrik}
              />
            </GridItem>
            <GridItem xs={11} md={5}>
              <CustomSelect
                labelText="Sub-Rubrik"
                id="id_anak_rubrik"
                formControlProps={{
                  fullWidth: true
                }}
                error={!!errors.id_anak_rubrik}
                helperText={errors.id_anak_rubrik}
                selectProps={{
                  id: "id_anak_rubrik",
                  name: "id_anak_rubrik",
                  onChange: e => {
                    handleChangeValues(e);
                    handleOnBlur(e);
                  },
                  onBlur: handleOnBlur,
                  onFocus: handleOnFocus
                }}
                selectItems={listSubRubrik}
                selectValue={values.id_anak_rubrik}
              />
            </GridItem>
          </GridContainer>
        </Collapse>
        <GridContainer justify={"center"}>
          <GridItem xs={11} md={10}>
            <CustomInput
              labelText="Nama Kegiatan (ID)"
              id="judul"
              formControlProps={{
                fullWidth: true
              }}
              error={!!errors.judul}
              helperText={errors.judul}
              inputProps={{
                name: "judul",
                type: "text",
                onChange: handleChangeValues,
                onBlur: handleOnBlur,
                onFocus: handleOnFocus
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify={"center"} alignItems={"center"}>
          <GridItem xs={11} md={10}>
            <CustomInput
              labelText="Activity Name (EN)"
              id="judul_inggris"
              formControlProps={{
                fullWidth: true
              }}
              error={!!errors.judul_inggris}
              helperText={errors.judul_inggris}
              inputProps={{
                name: "judul_inggris",
                type: "text",
                onChange: handleChangeValues,
                onBlur: handleOnBlur,
                onFocus: handleOnFocus
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify={"center"}>
          <GridItem xs={8} md={5}>
            <CustomInput
              labelText={`Point SKP (max ${renderMaxPoint(
                values.id_anak_rubrik
              )})`}
              id="bobot_skp_portofolio"
              formControlProps={{
                fullWidth: true
              }}
              error={!!errors.bobot_skp_portofolio}
              helperText={errors.bobot_skp_portofolio}
              inputProps={{
                name: "bobot_skp_portofolio",
                type: "number",
                onChange: handleChangeValues,
                onBlur: handleOnBlur,
                onFocus: handleOnFocus
              }}
            />
          </GridItem>
          <GridItem xs={8} md={5}>
            <FormControl
              className={classes.formControl}
              style={{ marginBottom: "20px" }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="tanggal"
                  label="Tanggal"
                  format="dd/MM/yyyy"
                  value={values.tanggal_terbit}
                  onChange={date => handleChangeDate(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer justify={"center"}>
          <GridItem>
            {values.file ? (
              <ChipFileSingle
                fileName={values.file.name}
                discardFile={handleDiscardFile}
              />
            ) : (
              <Button
                color={"info"}
                onClick={handleOpenDropzone}
                round
                size={"sm"}
              >
                <CloudUploadIcon />
                Upload Bukti
              </Button>
            )}
            {errors.file && !values.file ? (
              <>
                <br />
                <p
                  style={{
                    color: "#f44336",
                    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                    fontSize: "0.75rem",
                    fontWeight: 400,
                    lineHeight: 1.66
                  }}
                >
                  {errors.file}
                </p>
              </>
            ) : (
              <></>
            )}
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button color={"warning"} size={"sm"} onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button color={"success"} size={"sm"} onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogAddKegiatanInternal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedProdi: PropTypes.any.isRequired,
  openSnackbar: PropTypes.func,
  handleFetchKegiatan: PropTypes.func
};
