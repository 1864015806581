import React, { useState, useEffect } from "react";
import _ from "lodash";

// dep components
import GridItem from "dep/Grid/GridItem.js";
import GridContainer from "dep/Grid/GridContainer.js";

// components
import CardCetakSKPI from "components/Admin/CetakSKPI/Card/CardCetakSKPI";
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function CetakSKPI() {
  // var: values -> hook
  const [listProdi, setListProdi] = useState([]);
  const [listFakultas, setListFakultas] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: "",
    timeout: 2000
  });

  useEffect(() => {
    const fetchProdi = async () => {
      let res = await Auth.fetch(BASE_URL + "/api/prodi");
      let data = await res.json();
      setListProdi(_.get(data, "prodi", []));
    };

    const fetchListFakultas = async () => {
      let res = await Auth.fetch(BASE_URL + "/api/fakultas");
      let data = await res.json();
      setListFakultas(_.get(data, "fakultas", []));
    };

    fetchProdi();
    fetchListFakultas();
  }, []);

  const openSnackbar = (severity, message, timeout = 2000) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message, timeout });
  };

  return (
    <GridContainer>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
        timeout={snackbarData.timeout}
      />
      <GridItem xs={12} sm={12} xl={8}>
        <CardCetakSKPI
          listProdi={listProdi}
          listFakultas={listFakultas}
          openSnackbar={openSnackbar}
        />
      </GridItem>
    </GridContainer>
  );
}
