import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Table from "dep/Table/Table.js";
import Card from "dep/Card/Card.js";
import CardHeader from "dep/Card/CardHeader.js";
import CardBody from "dep/Card/CardBody.js";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import cardStyles from "assets/jss/jpt/cardStyles";

const STATUS_DITERIMA = 2;

const useStyles = makeStyles(cardStyles);

export default function CardPortofolioDiterima(props) {
  const classes = useStyles();

  const [tableDatas, changeTableDatas] = useState([]);

  useEffect(() => {
    fetchAPI();
  }, [props.doRefresh]);

  const fetchAPI = async (page = 1) => {
    let url =
      BASE_URL +
      "/api/portofolio?paginate=5&page=" +
      page +
      "&status=" +
      STATUS_DITERIMA;
    let res = await Auth.fetch(url);
    let data = await res.json();

    let newData = _.get(data, "portofolio.data", []).map((e, i) => {
      return [
        i + 1,
        _.get(e, "judul"),
        _.get(e, "nim"),
        _.get(e, "nama_mahasiswa", "Nama Mahasiswa"),
        _.get(e, "nama_anak_rubrik", "Nama Anak Rubrik"),
        _.get(e, "bobot_skp_portofolio"),
        moment(_.get(e, "tanggal_terbit")).format("DD-MM-YYYY")
      ];
    });

    changeTableDatas(newData);
  };

  return (
    <Card>
      <CardHeader color="success">
        <h4 className={classes.cardTitleWhite}>Diterima</h4>
        <p className={classes.cardCategoryWhite}>
          Portofolio yang baru-baru ini diterima
        </p>
      </CardHeader>
      <CardBody>
        <Table
          tableHeaderColor="success"
          tableHead={[
            "No",
            "Judul",
            "NIM",
            "Nama Mahasiswa",
            "Rubrik",
            "SKP",
            "Tanggal"
          ]}
          tableData={tableDatas}
        />
      </CardBody>
    </Card>
  );
}
