import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";
import Button from "dep/CustomButtons/Button";
// @material-ui/icons
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
// styling
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";
// for API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

const useStyles = makeStyles(tasksStyle);
export default function TabProfileAccount(props) {
  useEffect(() => {
    handleFetchAccount();
  }, []);
  const classes = useStyles();

  // var: values
  const [email, setEmail] = useState("");
  const [oldEmail, setOldEmail] = useState("");
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  // var: open-close
  const [edit, setEdit] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleDiscardButton = () => {
    setEmail(oldEmail);
    setPassword("");
    setRePassword("");
    setOldPassword("");
    setEdit(false);
    setEditPassword(false);
  };

  // handle: API fetch
  const handleFetchAccount = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/akun");
    let data = await res.json();
    if (res.status === 200) {
      const account = {
        email: _.get(data, "akun.email")
      };
      setEmail(account.email);
      setOldEmail(account.email);
    } else {
      let errors = _.get(data, "message", []);
      props.openSnackbar("warning", errors);
    }
  };

  const handleSubmitButton = async () => {
    try {
      props.openSnackbar("info", "Updating...");
      var form = {
        email: email,
        old_password: oldPassword
      };
      if (editPassword) {
        form.password = password;
        form.password_confirmation = rePassword;
      }

      let res = await Auth.fetch(BASE_URL + "/api/akun/update/", {
        method: "PUT",
        body: JSON.stringify(form)
      });
      let data = await res.json();

      if (res.status === 200) {
        props.openSnackbar("success", "Profil berhasil diubah!");
        handleDiscardButton();
        handleEditPassword();
        handleCloseDialog();
        handleFetchAccount();
      } else {
        let result =
          typeof _.get(data, "message", "Terjadi kesalahan") === "string"
            ? data.message
            : Object.values(data.message).join("");
        props.openSnackbar("warning", result);
      }
    } catch (err) {
      let result =
        typeof err.message === "string"
          ? err.message
          : Object.values(err.message).join("");
      props.openSnackbar("error", result);
    }
  };

  // handle: values
  const handleChangeEmail = event => {
    setEmail(event.target.value);
  };
  const handleChangePassword = event => {
    setPassword(event.target.value);
  };
  const handleChangeRePassword = event => {
    setRePassword(event.target.value);
  };
  const handleChangeOldPassword = event => {
    setOldPassword(event.target.value);
  };
  const handleCheckPassword = () => {
    //TODO: check password
    return Boolean(password);
  };

  // handle: open-close
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleEdit = () => {
    setEdit(true);
  };
  const handleEditPassword = () => {
    setEditPassword(!editPassword);
  };
  return (
    <div>
      <GridContainer justify={"flex-end"}>
        <GridItem>
          {edit ? (
            <Tooltip
              id="tooltip-top"
              title="Discard"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                aria-label="Edit"
                className={classes.tableActionButton}
                onClick={handleDiscardButton}
              >
                <CloseIcon
                  className={
                    classes.tableActionButtonIcon + " " + classes.close
                  }
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              id="tooltip-top"
              title="Ubah Nama"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                aria-label="Edit"
                className={classes.tableActionButton}
                onClick={handleEdit}
              >
                <EditIcon
                  className={classes.tableActionButtonIcon + " " + classes.edit}
                />
              </IconButton>
            </Tooltip>
          )}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={2} />
        <GridItem xs={7}>
          <CustomInput
            labelText="E-Mail"
            id="email"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: email,
              disabled: true /*!edit*/,
              onChange: handleChangeEmail
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={2} />
        <GridItem xs={7}>
          <Collapse in={!editPassword}>
            <CustomInput
              labelText="Password"
              id="password"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: "********",
                disabled: true,
                endAdornment: edit ? (
                  <InputAdornment position={"end"}>
                    <Tooltip
                      id="tooltip-top"
                      title="Edit Category"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label="Edit"
                        className={classes.tableActionButton}
                        onClick={handleEditPassword}
                      >
                        <EditIcon
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ) : (
                  <></>
                )
              }}
            />
          </Collapse>
          <Collapse in={editPassword}>
            <CustomInput
              labelText="New Password"
              id="new-password"
              error={!handleCheckPassword()}
              success={handleCheckPassword()}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: password,
                type: "password",
                onChange: handleChangePassword
              }}
            />
            <CustomInput
              labelText="Re-Enter New Password"
              id="reenter-password"
              error={password !== rePassword}
              success={password === rePassword}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: rePassword,
                type: "password",
                onChange: handleChangeRePassword
              }}
            />
          </Collapse>
        </GridItem>
      </GridContainer>
      <Collapse in={edit || editPassword}>
        <GridContainer justify={"space-around"}>
          <GridItem>
            <Button color={"warning"} onClick={handleDiscardButton}>
              Discard
            </Button>
          </GridItem>
          <GridItem>
            <Button color={"primary"} onClick={handleOpenDialog}>
              Submit
            </Button>
          </GridItem>
        </GridContainer>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Apakah anda yakin?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Masukkan password yang sedang digunakan atau password lama anda
            </DialogContentText>
            <CustomInput
              labelText="Old Password"
              id="old-password"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: oldPassword,
                type: "password",
                onChange: handleChangeOldPassword
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button color={"warning"} onClick={handleCloseDialog}>
              Tidak
            </Button>
            <Button color={"primary"} onClick={handleSubmitButton}>
              Ya
            </Button>
          </DialogActions>
        </Dialog>
      </Collapse>
    </div>
  );
}

TabProfileAccount.propTypes = {
  openSnackbar: PropTypes.func
};
