import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
// @material-ui/core components
import { Collapse, IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";
import Button from "dep/CustomButtons/Button";
import CustomSelect from "dep/CustomInput/CustomSelect";
// @material-ui/icons
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
// styling
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";
// for API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import formStyles from "assets/jss/jpt/formStyles";

const useStyles = makeStyles({
  ...formStyles,
  ...tasksStyle
});

export default function TabProfileAbout(props) {
  //TODO:
  // ---------------BUG Warning-------------
  // "social_media" array di var:values kalau diganti(ada yang dihapus) -> "social_media" array di var:oldValues ikut keganti
  // reason: mungkin "social_media" array di var:values sama var:oldValues dialokasiin ke memory yang sama, soalnya belum ada perbedaan(dari var:dummy). trus var:tmp(di func:handleDiscardButton) ngambil address memory yang sama. trus di-splice (array.splice) akhirnya begitu.
  // Chromium Version 81.0.4044.138 (Official Build) Arch Linux (64-bit), Mozilla Firefox for Manjaro Linux 76.0.1 (64-bit)
  // STEPS TO REPRODUCE:
  // 1. delete satu social media (optional: edit "no telpon" or "bio" biar lebih jelas)
  // 1.1 jangan tambah social media (social_media di var:values bakal dianggap beda sama yang di var:oldValues)
  // 2. discard
  // 3. voila
  // ternyata emang dari JS sejak udah lama
  // setiap var:array yang dibuat didalem func:(var:array from parents) yang diclone pointernya bukan isinya njir;

  useEffect(() => {
    handleFetchProfileAbout();
  }, []);

  const classes = useStyles();
  // var: values
  const [values, setValues] = useState({
    no_telepon: "",
    no_hp: "",
    bio: "",
    social_media: []
  });
  const [errors, setErrors] = useState({
    no_telepon_error: "",
    no_hp_error: "",
    bio_error: "",
    social_media_link_error: ""
  });
  const [oldValues, setOldValues] = useState({});
  // var: open-close
  const [editValue, setEditValue] = useState(false);
  const [editSocialMedia, setEditSocialMedia] = useState(false);
  const [addSocMed, setAddSocMed] = useState(false);
  // var: temporary for adding social media
  const [socialMedia, setSocialMedia] = useState({
    title: "",
    name: "",
    link: ""
  });
  const [customSocmed, setCustomSocmed] = useState("");

  // Validation
  const validateNoTelp = () => {
    const reg = /^(\(?\+?[0-9]*\)?)?[0-9_\- ()]*$/;
    let noTelpError = "";
    if (!reg.test(values.no_telepon)) {
      noTelpError = "Please input a valid phone number";
    }
    return noTelpError;
  };

  const validateNoHp = () => {
    const reg = /^(\(?\+?[0-9]*\)?)?[0-9_\- ()]*$/;
    let noTelpError = "";
    if (!reg.test(values.no_hp)) {
      noTelpError = "Please input a valid phone number";
    }
    return noTelpError;
  };

  const validateSocmedLink = () => {
    const reg = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    if (!socialMedia.link) {
      return "This field is required";
    }
    if (!reg.test(socialMedia.link)) {
      return "Please enter a valid link";
    }
    return "";
  };

  const validateBio = () => {
    return "";
  };

  const validateAllInputs = () => {
    return {
      no_telepon_error: validateNoTelp(),
      no_hp_error: validateNoHp(),
      bio_error: validateBio()
    };
  };

  // handle: API fetch
  //Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
  const handleFetchProfileAbout = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/profile");
    let data = await res.json();
    const about = {
      no_telepon: _.get(data, "user.no_telepon_asal"),
      no_hp: _.get(data, "user.no_hp"),
      bio: _.get(data, "user.bio"),
      social_media: _.get(data, "user.sosmed", []).map(row => {
        return {
          title: row.jenis_sosmed,
          name: row.nama,
          link: row.link_sosmed
        };
      })
    };

    setValues(about);
    // create new array social media
    setOldValues({ ...about, social_media: [...about.social_media] });
  };
  // handle: API submit
  const handleSubmit = async () => {
    if (editValue || editSocialMedia) {
      let allErrors = validateAllInputs();
      setErrors({ ...errors, ...allErrors });
      let isValid = true;
      for (var err in allErrors) {
        if (allErrors[err]) {
          isValid = false;
          break;
        }
      }
      if (isValid) {
        const formData = new FormData();

        formData.append("no_telepon_asal", values.no_telepon);
        formData.append("no_hp", values.no_hp);
        formData.append("bio", values.bio);

        formData.append("sosmed", JSON.stringify(values.social_media));

        let res = await Auth.fetchMultipart(BASE_URL + "/api/profile/update", {
          method: "POST",
          body: formData
        });
        let data = await res.json();
        if (res.status >= 200 && res.status < 300) {
          const about = {
            no_telepon: _.get(data, "user.no_telepon_asal"),
            no_hp: _.get(data, "user.no_hp"),
            bio: _.get(data, "user.bio"),
            social_media: _.get(data, "user.sosmed", []).map(row => {
              return {
                title: row.jenis_sosmed,
                name: row.nama,
                link: row.link_sosmed
              };
            })
          };

          setValues(about);
          setOldValues(about);
          props.openSnackbar(
            "success",
            "Berhasil memperbarui informasi kontak"
          );
        } else {
          props.openSnackbar("error", "Gagal memperbarui informasi kontak");
        }
        //if submitted
        setEditSocialMedia(false);
        setEditValue(false);
      }
    }
  };
  // handle: open-close
  const handleOpenCloseAddSocMed = () => {
    setAddSocMed(!addSocMed);
    setSocialMedia({
      title: "",
      name: "",
      link: ""
    });
  };
  const handleEditButton = () => {
    setEditValue(true);
  };
  const handleDiscardButton = () => {
    setErrors({
      no_telepon_error: "",
      no_hp_error: "",
      bio_error: "",
      social_media_link_error: ""
    });
    setEditValue(false);
    setValues(oldValues);
    setEditSocialMedia(false);
  };
  // handle: values
  const handleChangeValues = event => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  const handleChangeSocialMedia = value => event => {
    setSocialMedia({ ...socialMedia, [value]: event.target.value });
  };
  const handleDeleteSocMed = index => {
    let tmp = values.social_media;
    tmp.splice(index, 1);
    setValues({
      ...values,
      social_media: tmp
    });
    setEditSocialMedia(true);
  };
  const handleSaveNewSocMed = () => {
    if (!validateSocmedLink() && !errors.socmed_link_error) {
      setValues({
        ...values,
        social_media: [
          ...values.social_media,
          socialMedia.title === "Other"
            ? {
                ...socialMedia,
                title: customSocmed
              }
            : socialMedia
        ]
      });
      setSocialMedia({
        title: "",
        name: "",
        link: ""
      });
      setCustomSocmed("");
      handleOpenCloseAddSocMed();
      setEditSocialMedia(true);
    }
  };
  const TooltipDeleteSocMed = index => {
    return !editValue ? (
      <></>
    ) : (
      <Tooltip
        id="tooltip-top"
        title="Hapus Social Media"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="Hapus Social Media"
          className={classes.tableActionButton}
          onClick={event => {
            handleDeleteSocMed(index);
            event.preventDefault();
          }}
        >
          <CloseIcon
            className={classes.tableActionButtonIcon + " " + classes.close}
          />
        </IconButton>
      </Tooltip>
    );
  };
  return (
    <div>
      <GridContainer justify={"flex-end"}>
        <GridItem>
          {editValue ? (
            <Tooltip
              id="tooltip-top"
              title="Discard"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                aria-label="Edit"
                className={classes.tableActionButton}
                onClick={handleDiscardButton}
              >
                <CloseIcon
                  className={
                    classes.tableActionButtonIcon + " " + classes.close
                  }
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              id="tooltip-top"
              title="Ubah"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                aria-label="Edit"
                className={classes.tableActionButton}
                onClick={handleEditButton}
              >
                <EditIcon
                  className={classes.tableActionButtonIcon + " " + classes.edit}
                />
              </IconButton>
            </Tooltip>
          )}
        </GridItem>
      </GridContainer>
      <GridContainer justify={"center"}>
        <GridItem xs={8}>
          <CustomInput
            labelText="No Telepon"
            id="telephone"
            name="telephone"
            formControlProps={{
              fullWidth: true
            }}
            error={!!errors.no_telepon_error}
            helperText={errors.no_telepon_error}
            inputProps={{
              disabled: !editValue,
              name: "no_telepon",
              value: values.no_telepon,
              onChange: handleChangeValues,
              onBlur: () => {
                setErrors({
                  ...errors,
                  no_telepon_error: validateNoTelp()
                });
              },
              onFocus: () => {
                setErrors({
                  ...errors,
                  no_telepon_error: ""
                });
              }
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify={"center"}>
        <GridItem xs={8}>
          <CustomInput
            labelText="No Handphone"
            id="phone"
            name="phone"
            formControlProps={{
              fullWidth: true
            }}
            error={!!errors.no_hp_error}
            helperText={errors.no_hp_error}
            inputProps={{
              disabled: !editValue,
              name: "no_hp",
              value: values.no_hp,
              onChange: handleChangeValues,
              onBlur: () => {
                setErrors({
                  ...errors,
                  no_hp_error: validateNoHp()
                });
              },
              onFocus: () => {
                setErrors({
                  ...errors,
                  no_hp_error: ""
                });
              }
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify={"center"}>
        <GridItem xs={8}>
          <CustomInput
            labelText="Bio"
            id="bio"
            name="bio"
            formControlProps={{
              fullWidth: true
            }}
            error={!!errors.bio_error}
            helperText={errors.bio_error}
            inputProps={{
              disabled: !editValue,
              name: "bio",
              value: values.bio,
              multiline: true,
              rows: 2,
              onChange: handleChangeValues,
              onBlur: () => {
                setErrors({
                  ...errors,
                  bio_error: validateBio()
                });
              },
              onFocus: () => {
                setErrors({
                  ...errors,
                  bio_error: ""
                });
              }
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify={"center"}>
        <GridItem>
          <div style={{ padding: "2vh" }} />
          <p className={classes.successText}>Social Media</p>
        </GridItem>
      </GridContainer>
      <GridContainer justify={"center"}>
        <GridItem xs={8}>
          {values.social_media.map((value, index) => (
            <React.Fragment key={index}>
              <GridContainer justify={"space-between"}>
                <GridItem>
                  <p className={classes.successText}>{value.title}</p>
                </GridItem>
                <GridItem>
                  <a
                    href={value.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {value.name}
                  </a>
                  {TooltipDeleteSocMed(index)}
                </GridItem>
              </GridContainer>
            </React.Fragment>
          ))}
        </GridItem>
      </GridContainer>
      <GridContainer justify={"center"}>
        <GridItem xs={8}>
          <Collapse in={!addSocMed && editValue}>
            <GridContainer justify={"center"}>
              <GridItem>
                <Button
                  round
                  size={"sm"}
                  color={"info"}
                  onClick={handleOpenCloseAddSocMed}
                >
                  add
                </Button>
              </GridItem>
            </GridContainer>
          </Collapse>
          <Collapse in={addSocMed && editValue}>
            <GridContainer justify={"center"}>
              <GridItem xs={10} md={4}>
                <CustomSelect
                  labelText="Social Media"
                  id="socmed_title"
                  formControlProps={{
                    fullWidth: true
                  }}
                  selectProps={{
                    id: "socmed_title",
                    name: "socmed_title",
                    onChange: handleChangeSocialMedia("title")
                  }}
                  selectItems={[
                    { value: "Facebook", text: "Facebook" },
                    { value: "Twitter", text: "Twitter" },
                    { value: "LinkedIn", text: "LinkedIn" },
                    { value: "Instagram", text: "Instagram" },
                    { value: "Other", text: "Other" }
                  ]}
                  selectValue={socialMedia.title}
                />
              </GridItem>
              <GridItem
                xs={10}
                md={6}
                style={
                  socialMedia.title === "Other"
                    ? { visibility: "hidden" }
                    : { visibility: "visible" }
                }
              >
                <CustomInput
                  labelText="Nama"
                  id="socmed_name"
                  name="socmed_name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "socmed_name",
                    value: socialMedia.name,
                    onChange: handleChangeSocialMedia("name")
                  }}
                />
              </GridItem>
            </GridContainer>
            {socialMedia.title === "Other" ? (
              <GridContainer justify={"center"}>
                <GridItem xs={10} md={4}>
                  <CustomInput
                    labelText="Title"
                    id="socmed_title"
                    name="socmed_title"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "socmed_title",
                      value: customSocmed,
                      onChange: e => setCustomSocmed(e.target.value)
                    }}
                  />
                </GridItem>
                <GridItem xs={10} md={6}>
                  <CustomInput
                    labelText="Nama"
                    id="socmed_name"
                    name="socmed_name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "socmed_name",
                      value: socialMedia.name,
                      onChange: handleChangeSocialMedia("name")
                    }}
                  />
                </GridItem>
              </GridContainer>
            ) : (
              <></>
            )}
            <GridContainer justify={"center"}>
              <GridItem xs={10}>
                <CustomInput
                  labelText="Link"
                  id="socmed_link"
                  name="socmed_link"
                  formControlProps={{
                    fullWidth: true
                  }}
                  error={!!errors.socmed_link_error}
                  helperText={errors.socmed_link_error}
                  inputProps={{
                    name: "socmed_link",
                    value: socialMedia.link,
                    onChange: handleChangeSocialMedia("link"),
                    onBlur: () => {
                      setErrors({
                        ...errors,
                        socmed_link_error: validateSocmedLink()
                      });
                    },
                    onFocus: () => {
                      setErrors({
                        ...errors,
                        socmed_link_error: ""
                      });
                    }
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify={"space-around"}>
              <GridItem>
                <Button
                  round
                  size={"sm"}
                  color={"warning"}
                  onClick={handleOpenCloseAddSocMed}
                >
                  discard
                </Button>
              </GridItem>
              <GridItem>
                <Button
                  round
                  size={"sm"}
                  color={"success"}
                  disabled={
                    !socialMedia.link || !socialMedia.title || !socialMedia.name
                  }
                  onClick={handleSaveNewSocMed}
                >
                  save
                </Button>
              </GridItem>
            </GridContainer>
          </Collapse>
        </GridItem>
      </GridContainer>
      <Collapse in={editValue || editSocialMedia}>
        <GridContainer justify={"space-around"}>
          <GridItem>
            <Button color={"danger"} onClick={handleDiscardButton}>
              Discard
            </Button>
          </GridItem>
          <GridItem>
            <Button color={"success"} onClick={handleSubmit}>
              Save
            </Button>
          </GridItem>
        </GridContainer>
      </Collapse>
    </div>
  );
}

TabProfileAbout.propTypes = {
  openSnackbar: PropTypes.func
};
