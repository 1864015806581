import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Table from "dep/Table/Table";
import DialogActions from "@material-ui/core/DialogActions";

import { BASE_URL } from "const";
import Auth from "helpers/auth";

export default function DialogDetailPencapaian(props) {
  useEffect(() => {
    handleFetch();
  }, [props.selectedProdi]);
  const [tableData, setTableData] = useState([]);

  const handleFetch = async () => {
    let res = await Auth.fetch(
      `${BASE_URL}/api/statistik/anak-rubrik?prodi=${props.selectedProdi}`
    );
    let json = await res.json();

    const data = (json.data || []).map(e => {
      return [
        e.nama_anak_rubrik,
        e.mahasiswa_aktif_memenuhi_skp,
        (e.mahasiswa_aktif_memenuhi_skp / e.mahasiswa_aktif_total) * 100
      ];
    });
    data.sort((previous, current) => previous[2] - current[2]);
    setTableData(data.map(value => [value[0], value[1], value[2] + "%"]));
  };
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={"md"} fullWidth>
      <DialogTitle>Detail Pencapaian</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Banyaknya Mahasiswa yang berhasil memenuhi point minimum SKP
        </DialogContentText>
        <GridContainer justify={"center"}>
          <GridItem xs={10}>
            <Table
              tableHeaderColor="primary"
              tableHead={["SubRubrik", "Mahasiswa Memenuhi", "Persentase"]}
              tableData={tableData}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color={"primary"}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogDetailPencapaian.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selectedProdi: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
