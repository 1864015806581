import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
// @material-ui/core components
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
// dep components
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";
import CardFooter from "dep/Card/CardFooter";
import CardBody from "dep/Card/CardBody";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Table from "dep/Table/Table";
// components
import ButtonTambahRubrik from "components/Admin/ManajemenRubrik/Button/ButtonTambahRubrik";
import DialogProdiSelector from "components/Admin/ProdiSelector/Dialog/DialogProdiSelector";
import CardProdiSelectorPreview from "components/Admin/ProdiSelector/Card/CardProdiSelectorPreview";
import ButtonsRubrik from "components/Admin/ManajemenRubrik/Button/ButtonsRubrik";
import ButtonsAnakRubrik from "components/Admin/ManajemenRubrik/Button/ButtonsAnakRubrik";
import DialogUbahHapusRubrik from "components/Admin/ManajemenRubrik/Dialog/DialogUbahHapusRubrik";
import DialogTambahSubRubrik from "components/Admin/ManajemenRubrik/Dialog/DialogTambahSubRubrik";
import DialogUbahHapusSubRubrik from "components/Admin/ManajemenRubrik/Dialog/DialogUbahHapusSubRubrik";
// @material-ui/icons
import CloseIcon from "@material-ui/icons/Close";
// styling
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import cardStyles from "assets/jss/jpt/cardStyles";
import buttonsStyles from "assets/jss/jpt/buttonsStyles";
// API
import Auth from "helpers/auth";
import { BASE_URL, ROLE_SUPER_ADMIN } from "const";

const ITEM_PER_PAGE = 10;

const useStyles = makeStyles({
  ...cardStyles,
  ...buttonsStyles,
  ...taskStyles
});

export default function CardManajemenRubrikCategory(props) {
  const classes = useStyles();

  // Props
  const { selectedCategory } = props;

  // State
  const [rubrikDatas, setRubrikDatas] = useState({});
  const [tableData, setTableData] = useState([]);

  const [selectedProdi, setSelectedProdi] = useState(Auth.getIdProdi());
  const [selectedFakultas, setSelectedFakultas] = useState();
  const [selectedRubrik, setSelectedRubrik] = useState({});
  const [selectedParent, setSelectedParent] = useState({});
  // type:
  //    tambah sub rubrik = "add-sub-rubrik"
  //    edit rubrik = "edit-rubrik"
  //    hapus rubrik = "delete-rubrik"
  //    edit sub rubrik = "edit-sub-rubrik"
  //    hapus sub rubrik = "delete-sub-rubrik"
  const [dialogRubrikType, setDialogRubrikType] = useState("");

  // Pagination
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const [openProdiSelector, setOpenProdiSelector] = useState(
    Auth.isRoleEqualTo(ROLE_SUPER_ADMIN)
  );

  useEffect(() => {
    if (selectedProdi) handleFetchRubrikData();
  }, [selectedProdi, page]);

  // handle: hook
  const handleBackButton = () => {
    props.handleBackButton();
  };

  const handleChangeSelectedProdi = value => {
    setSelectedProdi(value);
    setOpenProdiSelector(false);
  };

  const handleChangeDialogProdiSelector = value => {
    setOpenProdiSelector(value);
  };

  const handleChangeSelectedRubrik = rubrikData => {
    setSelectedRubrik(rubrikData);
  };

  const handleChangeSelectedParent = parentData => {
    setSelectedParent(parentData);
  };

  const handleChangeDialogType = dialogType => {
    setDialogRubrikType(dialogType);
  };

  const handleCloseDialog = () => {
    setDialogRubrikType("");
    setSelectedRubrik({});
    setSelectedParent({});
  };

  const handleChangePage = (_e, page) => {
    setPage(page);
  };

  // handle: API fetch
  const handleFetchRubrikData = async () => {
    let res = await Auth.fetch(
      BASE_URL +
        "/api/prodi/" +
        selectedProdi +
        "/rubrik/bidang/" +
        selectedCategory.id_bidang_portofolio +
        "?paginate=" +
        ITEM_PER_PAGE +
        "&page=" +
        page
    );
    let data = await res.json();

    setPageCount(_.get(data, "rubrik.last_page"));

    setRubrikDatas(
      _.get(data, "rubrik.data", []).reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id_rubrik]: { ...curr, is_expanded: false }
        }),
        {}
      )
    );
  };

  const expandRow = id_rubrik => {
    let tmp = { ...rubrikDatas };
    tmp[id_rubrik].is_expanded = true;
    setRubrikDatas(tmp);
  };

  const collapseRow = id_rubrik => {
    let tmp = { ...rubrikDatas };
    tmp[id_rubrik].is_expanded = false;
    setRubrikDatas(tmp);
  };

  useEffect(() => {
    let rubriks = [];
    let idx = (page - 1) * ITEM_PER_PAGE;

    for (let key in rubrikDatas) {
      let element = rubrikDatas[key];
      ++idx;
      rubriks.push([
        <b key={idx}>{idx}</b>,
        <b key={idx}>{element.nama_rubrik}</b>,
        <b key={idx}>{element.syarat_minimal_skp}</b>,
        <b key={idx}>{element.anak_rubrik.length}</b>,
        <ButtonsRubrik
          key={idx}
          handleChangeRubrikData={handleChangeSelectedRubrik}
          onClick={handleChangeDialogType}
          rubrikData={element}
          expandRow={expandRow}
          collapseRow={collapseRow}
        />
      ]);

      if (element.is_expanded) {
        let idxInner = 0;
        for (let child of element.anak_rubrik) {
          rubriks.push([
            <></>,
            `${idx}.${++idxInner} ` + child.nama_anak_rubrik,
            child.syarat_minimal_skp,
            <></>,
            <ButtonsAnakRubrik
              key={`${idx}.${idxInner}`}
              handleChangeRubrikData={handleChangeSelectedRubrik}
              handleChangeParentData={handleChangeSelectedParent}
              onClick={handleChangeDialogType}
              parentData={element}
              rubrikData={child}
            />
          ]);
        }
      }
    }

    setTableData(rubriks);
  }, [rubrikDatas]);

  return (
    <GridContainer direction="row-reverse">
      <DialogTambahSubRubrik
        open={dialogRubrikType === "add-sub-rubrik"}
        onClose={handleCloseDialog}
        handleFetchRubrikData={handleFetchRubrikData}
        openSnackbar={props.openSnackbar}
        rubrikData={selectedRubrik}
      />
      <DialogUbahHapusRubrik
        handleFetchRubrikData={handleFetchRubrikData}
        hapus={dialogRubrikType === "delete-rubrik"}
        onClose={handleCloseDialog}
        open={
          dialogRubrikType === "delete-rubrik" ||
          dialogRubrikType === "edit-rubrik"
        }
        openSnackbar={props.openSnackbar}
        selectedRubrik={selectedRubrik}
      />
      <DialogUbahHapusSubRubrik
        onClose={handleCloseDialog}
        rubrikData={selectedRubrik}
        parentData={selectedParent}
        openSnackbar={props.openSnackbar}
        handleFetchRubrikData={handleFetchRubrikData}
        open={
          dialogRubrikType === "delete-sub-rubrik" ||
          dialogRubrikType === "edit-sub-rubrik"
        }
        hapus={dialogRubrikType === "delete-sub-rubrik"}
      />
      <GridItem xs={12} sm={12} md={4}>
        <CardProdiSelectorPreview
          selectedProdi={selectedProdi}
          handleChangeOpenDialog={handleChangeDialogProdiSelector}
          selectedFakultas={selectedFakultas}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={8}>
        <Card>
          <CardHeader color={"primary"}>
            <GridContainer justify={"space-between"} alignItems={"flex-start"}>
              <GridItem>
                <h4 className={classes.cardTitleWhite}>{"Manajemen Rubrik"}</h4>
              </GridItem>
              <GridItem>
                <Tooltip
                  id="tooltip-top"
                  title="Close"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton
                    aria-label="Edit"
                    className={classes.tableActionButton}
                    onClick={handleBackButton}
                  >
                    <CloseIcon
                      className={
                        classes.tableActionButtonIcon +
                        " " +
                        classes.buttonClose
                      }
                    />
                  </IconButton>
                </Tooltip>
              </GridItem>
            </GridContainer>
            <GridContainer justify={"space-between"}>
              <GridItem>
                <p className={classes.cardCategoryWhite}>
                  {"Kategori: "}
                  {selectedCategory.nama_bidang_portofolio}
                </p>
              </GridItem>
              <GridItem>
                {selectedProdi ? (
                  <ButtonTambahRubrik
                    id_prodi={selectedProdi}
                    id_bidang_portofolio={selectedCategory.id_bidang_portofolio}
                    openSnackbar={props.openSnackbar}
                    handleFetchRubrikData={handleFetchRubrikData}
                  />
                ) : (
                  <></>
                )}
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["No", "Nama Rubrik", "SKP", "AnakRubrik", "Opsi"]}
              tableData={tableData}
            />
          </CardBody>
          <CardFooter>
            <GridContainer>
              <GridItem xs />
              <GridItem>
                <Pagination
                  count={pageCount}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  onChange={handleChangePage}
                />
              </GridItem>
            </GridContainer>
          </CardFooter>
        </Card>
      </GridItem>
      <DialogProdiSelector
        openDialog={openProdiSelector}
        handleChangeOpenDialog={handleChangeDialogProdiSelector}
        selectedProdi={selectedProdi}
        handleChangeSelectedProdi={handleChangeSelectedProdi}
        handleChangeSelectedFakultas={setSelectedFakultas}
      />
    </GridContainer>
  );
}

CardManajemenRubrikCategory.propTypes = {
  handleBackButton: PropTypes.func,
  openSnackbar: PropTypes.func,
  selectedCategory: PropTypes.object
};
