import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";

// dep components
import CardBody from "dep/Card/CardBody";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";

// components
import LittleCardManajemenRubrikChooseCategory from "components/Admin/ManajemenRubrik/LittleCard/LittleCardManajemenRubrikChooseCategory";
import CardManajemenRubrikCategory from "components/Admin/ManajemenRubrik/Card/CardManajemenRubrikCategory";
import ButtonTambahKategoriRubrik from "components/Admin/ManajemenRubrik/Button/ButtonTambahKategoriRubrik";
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import cardStyles from "assets/jss/jpt/cardStyles";

// styling
const useStyles = makeStyles(cardStyles);

export default function CardManajemenRubrikMain() {
  const classes = useStyles();

  // var: open-close
  const [showCard, setShowCard] = useState(true);

  // var: values
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });

  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };

  useEffect(() => {
    handleFetchCategories();
  }, []);

  // handle: API fetch
  const handleFetchCategories = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/bidangportofolio");
    let json = await res.json();

    setCategories(
      (json.bidang_portofolio || []).sort(
        (a, b) => a.urutan_di_skpi - b.urutan_di_skpi
      )
    );
  };

  // handle: open-close
  const handleBack = () => {
    setShowCard(true);
  };

  // handle: values
  const handleChooseCategory = categoryId => {
    setShowCard(false);
    setSelectedCategory(categoryId);
  };

  return (
    <>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
      />
      {showCard ? (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color={"primary"}>
                <GridContainer
                  justify={"space-between"}
                  alignItems={"flex-end"}
                >
                  <GridItem>
                    <h4 className={classes.cardTitleWhite}>Manajemen Rubrik</h4>
                    <p className={classes.cardCategoryWhite}>
                      Ubah atau tambah Rubrik
                    </p>
                  </GridItem>
                  <GridItem>
                    <ButtonTambahKategoriRubrik
                      openSnackbar={openSnackbar}
                      handleFetchCategories={handleFetchCategories}
                    />
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {categories.map((value, index) => (
                    <GridItem key={index} xs={12} md={3}>
                      <LittleCardManajemenRubrikChooseCategory
                        selectedBidangPortofolio={value}
                        handleChooseCategory={handleChooseCategory}
                        handleFetchCategories={handleFetchCategories}
                        openSnackbar={openSnackbar}
                      />
                    </GridItem>
                  ))}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        <CardManajemenRubrikCategory
          selectedCategory={selectedCategory}
          handleBackButton={handleBack}
          openSnackbar={openSnackbar}
        />
      )}
    </>
  );
}
