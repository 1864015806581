import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";
import Button from "dep/CustomButtons/Button";
// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function DialogUbahHapusRubrik(props) {
  // Props
  const { openSnackbar, handleFetchRubrikData, open, hapus } = props;

  // State
  const [rubrikData, setRubrikData] = useState({
    nama_rubrik: "",
    syarat_minimal_skp: 0
  });

  useEffect(() => {
    if (open) setRubrikData(props.selectedRubrik);
  }, [props.selectedRubrik]);

  // handle: API Submit
  const handleAPISubmitHapus = async () => {
    try {
      openSnackbar("info", "Menghapus..");
      let res = await Auth.fetch(
        BASE_URL + "/api/rubrik/" + props.selectedRubrik.id_rubrik + "/delete",
        {
          method: "DELETE"
        }
      );

      let data = await res.json();
      let errorMessages = "";
      if (typeof data.message === "string") {
        errorMessages = data.message;
      } else if (typeof data.message === "object") {
        errorMessages = "";
        if (Array.isArray(data.message)) {
          for (let err of data.message) {
            errorMessages +=
              typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
          }
        } else {
          for (let key in data.message) errorMessages += data.message[key][0];
        }
      }

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data rubrik berhasil dihapus!");
        handleFetchRubrikData();
        handleCloseDialog();
      } else if (res.status >= 400 && res.status < 500) {
        openSnackbar("warning", errorMessages);
      } else {
        openSnackbar("error", errorMessages);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };
  const handleAPISubmitUbah = async () => {
    try {
      openSnackbar("info", "Mengupdate..");
      let res = await Auth.fetch(
        BASE_URL + "/api/rubrik/" + props.selectedRubrik.id_rubrik + "/update",
        {
          method: "PUT",
          body: JSON.stringify(rubrikData)
        }
      );

      let data = await res.json();
      let errorMessages = "";
      if (typeof data.message === "string") {
        errorMessages = data.message;
      } else if (typeof data.message === "object") {
        errorMessages = "";
        if (Array.isArray(data.message)) {
          for (let err of data.message) {
            errorMessages +=
              typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
          }
        } else {
          for (let key in data.message) errorMessages += data.message[key][0];
        }
      }

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data rubrik berhasil diupdate!");
        handleFetchRubrikData();
        handleCloseDialog();
      } else if (res.status >= 400 && res.status < 500) {
        openSnackbar("warning", errorMessages);
      } else {
        openSnackbar("error", errorMessages);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  const handleCloseDialog = () => {
    props.onClose();
    setRubrikData({
      nama_rubrik: "",
      syarat_minimal_skp: 0
    });
  };
  const changeRubrikData = e => {
    setRubrikData({ ...rubrikData, [e.target.name]: e.target.value });
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="dialog-title"
      maxWidth={"xs"}
      fullWidth
    >
      <DialogTitle id="dialog-title">
        {hapus ? "Hapus" : "Ubah"} Rubrik
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Anda akan {hapus ? "menghapus" : "mengubah"} Rubrik Dibawah ini
        </DialogContentText>
        <br />
        <GridContainer>
          <GridItem xs={2} />
          <GridItem xs={8}>
            <CustomInput
              labelText="Nama Rubrik"
              id="item-name-ID"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "nama_rubrik",
                value: rubrikData.nama_rubrik,
                onChange: changeRubrikData,
                disabled: hapus
              }}
            />
          </GridItem>
          <GridItem xs={2} />
        </GridContainer>
        <GridContainer>
          <GridItem xs={2} />
          <GridItem xs={8}>
            <CustomInput
              labelText="Syarat Minimal SKP"
              id="order"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "syarat_minimal_skp",
                type: "number",
                value: rubrikData.syarat_minimal_skp,
                onChange: changeRubrikData,
                disabled: hapus
              }}
            />
          </GridItem>
          <GridItem xs={2} />
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="warning">
          Cancel
        </Button>
        <Button
          onClick={hapus ? handleAPISubmitHapus : handleAPISubmitUbah}
          color="success"
          autoFocus
          disabled={
            (!rubrikData.syarat_minimal_skp && !hapus) ||
            !rubrikData.nama_rubrik
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogUbahHapusRubrik.propTypes = {
  handleFetchRubrikData: PropTypes.func.isRequired,
  hapus: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  openSnackbar: PropTypes.func.isRequired,
  selectedRubrik: PropTypes.object.isRequired
};
