import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip } from "@material-ui/core";
// jss
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle";
import buttonsStyles from "assets/jss/jpt/buttonsStyles";
// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
  ...taskStyles,
  ...buttonsStyles
});
export default function ButtonsAnakRubrik(props) {
  const classes = useStyles();
  const handleClick = dialogType => event => {
    props.handleChangeRubrikData(props.rubrikData);
    props.handleChangeParentData(props.parentData);
    props.onClick(dialogType);
    event.preventDefault();
  };
  return (
    <>
      <Tooltip
        id="tooltip-ubah-sub-rubrik"
        title="Ubah Sub-Rubrik"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="edit-sub"
          className={classes.tableActionButton}
          onClick={handleClick("edit-sub-rubrik")}
        >
          <EditIcon
            className={classes.tableActionButtonIcon + " " + classes.buttonEdit}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        id="tooltip-hapus-sub-rubrik"
        title="Hapus Sub-Rubrik"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="delete"
          className={classes.tableActionButton}
          onClick={handleClick("delete-sub-rubrik")}
        >
          <DeleteIcon
            className={classes.tableActionButtonIcon + " " + classes.close}
          />
        </IconButton>
      </Tooltip>
    </>
  );
}

ButtonsAnakRubrik.propTypes = {
  handleChangeParentData: PropTypes.func.isRequired,
  handleChangeRubrikData: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  parentData: PropTypes.object.isRequired,
  rubrikData: PropTypes.object.isRequired
};
