import React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Button,
  Container,
  Hidden,
  IconButton,
  InputAdornment,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
// components
import CarouselLandingPage from "components/Public/LandingPage/Carousel/CarouselLandingPage";
import Navbar from "components/Public/Navbar/Navbar";
import Footer from "components/Public/Footer/Footer";
// @material-ui/icons
import SearchIcon from "@material-ui/icons/Search";
import { IMAGE_CARTOON, LANDING_PAGE_BG_DESKTOP } from "const";

const useStyles = makeStyles({
  container: {
    paddingTop: "10vh",
    paddingBottom: "10vh"
  },
  divider: {
    //backgroundImage: "url(" + LANDING_PAGE_BG_DESKTOP + ")",
    backgroundImage: "linear-gradient(to bottom right, black, red)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "0.5vh",
    width: "8vh"
  },
  textSection: {
    whiteSpace: "wrap",
    maxWidth: "50ch"
  },
  titleSection: {},
  background: {
    backgroundImage: "url(" + LANDING_PAGE_BG_DESKTOP + ")",
    backgroundPosition: "center top",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "auto",
    minHeight: "60vh"
  },
  imageCartoon: {
    backgroundImage: "url(" + IMAGE_CARTOON + ")",
    backgroundSize: "auto",
    maxHeight: "30vh",
    maxWidth: "5vw",
    width: "auto",
    height: "auto",
    backgroundRepeat: "no-repeat"
  },
  textFieldInput: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    color: "white"
  },
  textField: {
    backgroundColor: "rgba(255,255,255,0.2)",
    borderRadius: "5px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white"
      },
      "&:hover fieldset": {
        borderColor: "white"
      },
      "&.Mui-focused fieldset": {
        borderColor: "white"
      }
    }
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,0.75)",
    margin: "0",
    fontSize: "20px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#ffffff",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    textAlign: "left",
    "& span": { color: "#a70710", fontWeight: "800" }
  },
  buttonSection: {
    color: "#FFFFFF",
    backgroundColor: "red",
    textTransform: "none",
    lineHeight: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    transition: "0.5s",
    "&:hover": {
      color: "red"
    }
  }
});

export default function LandingPage(props) {
  const history = useHistory();

  const classes = useStyles();
  const queryString = require("query-string");
  const [search, setSearch] = React.useState("");

  const signUp = () => {
    history.push("/register");
  };

  const guide = () => {
    history.push("/guide");
  };

  //handle: values
  const handleChangeSearch = event => {
    setSearch(event.target.value);
  };

  //handle: redirect
  const handleSubmit = () => {
    const values = {
      search: search
    };
    const query = queryString.stringify(values);
    history.push("/search/" + query);
  };

  return (
    <>
      <Helmet>
        <title>Janabadra Professional Tracker</title>
        <meta
          name="description"
          content="Janabadra Professional Tracker, Sistem Data Portofolio Pendamping Ijazah, Universitas Janabadra"
        />
        <meta
          name="appname"
          content="janaprotrack"
        />
        <meta
          name="teamname"
          content="janaprotrack-team"
        />
      </Helmet>
      <div className={classes.background}>
        <Navbar {...props} />
        <Container maxWidth={"lg"} className={classes.container}>
          <GridContainer justify={"center"} alignItems={"center"}>
            <GridItem xs={10} md={5}>
              <div style={{ padding: "1vh" }} />
              <h1 className={classes.cardTitleWhite}>
                <span>J</span>anabadra <span>P</span>rofessional <span>T</span>
                racker
              </h1>
              <div style={{ padding: "1vh" }} />
              <p className={classes.cardCategoryWhite}>
                Sistem Data Portofolio Pendamping Ijazah
              </p>
              <div style={{ padding: "2vh" }} />
              <form>
                <TextField
                  fullWidth
                  className={classes.textField}
                  placeholder={"Search"}
                  variant={"outlined"}
                  onChange={handleChangeSearch}
                  onKeyPress={event => {
                    if (event.key === "Enter") handleSubmit();
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <IconButton
                          onClick={handleSubmit}
                          edge={"end"}
                          color={"inherit"}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    className: classes.textFieldInput
                  }}
                />
              </form>
            </GridItem>
            <GridItem xs={10} md={5}>
              <Hidden mdUp implementation={"css"}>
                <div style={{ padding: "2vh" }} />
              </Hidden>
              <GridContainer justify={"center"} alignItems={"center"}>
                <img src={IMAGE_CARTOON} alt={"img"} width={"100%"} />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Container>
      </div>
      <div>
        <Container maxWidth={"lg"} className={classes.container}>
          <GridContainer justify={"center"}>
            <GridItem xs={10}>
              <Row>
                <Col className="d-none d-lg-block">
                  <CarouselLandingPage slideCount={3} />
                </Col>
                <Col className="d-none d-md-block d-lg-none">
                  <CarouselLandingPage slideCount={2} />
                </Col>
                <Col className="d-block d-md-none">
                  <CarouselLandingPage slideCount={1} />
                </Col>
              </Row>
            </GridItem>
          </GridContainer>
          <div style={{ padding: "2vh" }} />
          <GridContainer justify={"center"}>
            <GridItem>
              <Button className={classes.buttonSection} onClick={signUp}>
                Get Started
              </Button>
            </GridItem>
            <GridItem>
              <Button className={classes.buttonSection} onClick={guide}>
                Guide
              </Button>
            </GridItem>
          </GridContainer>
        </Container>
        <Footer />
      </div>
    </>
  );
}

LandingPage.propTypes = {
  history: PropTypes.any
};
