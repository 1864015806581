import React, { useEffect, useState } from "react";
import _ from "lodash";
// @material-ui/core components
import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";
import Button from "dep/CustomButtons/Button";
import CardBody from "dep/Card/CardBody";
// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";

// components
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

//styling
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle";
import cardStyles from "assets/jss/jpt/cardStyles";
const useStyles = makeStyles({
  ...cardStyles,
  ...taskStyles
});

export default function CardProfile() {
  const classes = useStyles();
  useEffect(() => {
    handleFetchProfile();
  }, []);

  // var: values
  const [name, setName] = useState("");
  const [oldState, setOldState] = useState({
    name: "",
    userName: "",
    email: ""
  });
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  // var: open-close
  const [edit, setEdit] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });
  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };

  // handle: API fetch
  const handleFetchProfile = async () => {
    try {
      let res = await Auth.fetch(BASE_URL + "/api/akun");
      let akun = (await res.json()).akun;

      const user = {
        name: _.get(akun, "nama", ""),
        userName: _.get(akun, "nim", ""),
        email: _.get(akun, "email", "")
      };
      setOldState(user);
      setName(user.name);
      setUsername(user.userName);
      setEmail(user.email);
      setPassword("");
      setOldPassword("");
    } catch (error) {
      let result =
        typeof error.message === "string"
          ? error.message
          : Object.values(error.message).join("");
      openSnackbar("error", result);
    }
  };

  // handle: API Submit
  const handleSubmitButton = async () => {
    try {
      openSnackbar("info", "Updating...");
      var form = {
        nim: username,
        nama: name,
        email: email,
        old_password: oldPassword
      };
      if (editPassword) {
        form.password = password;
        form.password_confirmation = rePassword;
      }

      let res = await Auth.fetch(BASE_URL + "/api/akun/update/", {
        method: "PUT",
        body: JSON.stringify(form)
      });
      let data = await res.json();

      if (res.status === 200) {
        openSnackbar("success", "Profil berhasil diubah!");
        handleDiscardButton();
        handleCloseDialog();
        handleFetchProfile();
      } else {
        let result =
          typeof data.message === "string"
            ? data.message
            : Object.values(data.message).join("");
        openSnackbar("warning", result);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  // handle: open-close
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCheckPassword = () => {
    //TODO: check password
    return Boolean(password);
  };
  const handleEdit = () => {
    setEdit(true);
  };
  const handleEditPassword = () => {
    setEditPassword(true);
  };

  // handle: values
  const handleChangeName = event => {
    setName(event.target.value);
  };
  const handleChangeUsername = event => {
    setUsername(event.target.value);
  };
  const handleChangeEmail = event => {
    setEmail(event.target.value);
  };
  const handleChangePassword = event => {
    setPassword(event.target.value);
  };
  const handleChangeRePassword = event => {
    setRePassword(event.target.value);
  };
  const handleChangeOldPassword = event => {
    setOldPassword(event.target.value);
  };
  const handleDiscardButton = () => {
    setEdit(false);
    setEditPassword(false);
    setName(oldState.name);
    setUsername(oldState.userName);
    setEmail(oldState.email);
    setPassword("");
    setRePassword("");
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color={"primary"}>
            <h4 className={classes.cardTitleWhite}>Profile</h4>
            <p className={classes.cardCategoryWhite}>Ubah Profil di sini</p>
          </CardHeader>
          <CardBody>
            <CustomSnackbar
              open={snackbarOpen}
              onClose={() => setSnackbarOpen(false)}
              message={snackbarData.message}
              severity={snackbarData.severity}
            />
            <GridContainer justify={"flex-end"}>
              <GridItem>
                {edit ? (
                  <Tooltip
                    id="tooltip-top"
                    title="Discard"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <IconButton
                      aria-label="Edit"
                      className={classes.tableActionButton}
                      onClick={handleDiscardButton}
                    >
                      <CloseIcon
                        className={
                          classes.tableActionButtonIcon + " " + classes.close
                        }
                      />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    id="tooltip-top"
                    title="Ubah Nama"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <IconButton
                      aria-label="Edit"
                      className={classes.tableActionButton}
                      onClick={handleEdit}
                    >
                      <EditIcon
                        className={
                          classes.tableActionButtonIcon + " " + classes.edit
                        }
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2} />
              <GridItem xs={7}>
                <CustomInput
                  labelText="username"
                  id="username"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: username,
                    disabled: !edit,
                    onChange: handleChangeUsername
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2} />
              <GridItem xs={7}>
                <CustomInput
                  labelText="Nama"
                  id="nama-profil"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: name,
                    disabled: !edit,
                    onChange: handleChangeName
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2} />
              <GridItem xs={7}>
                <CustomInput
                  labelText="E-Mail"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: email,
                    disabled: !edit,
                    onChange: handleChangeEmail
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={2} />
              <GridItem xs={7}>
                <Collapse in={!editPassword}>
                  <CustomInput
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: "********",
                      disabled: true,
                      endAdornment: edit ? (
                        <InputAdornment position={"end"}>
                          <Tooltip
                            id="tooltip-top"
                            title="Edit Category"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <IconButton
                              aria-label="Edit"
                              className={classes.tableActionButton}
                              onClick={handleEditPassword}
                            >
                              <EditIcon
                                className={
                                  classes.tableActionButtonIcon +
                                  " " +
                                  classes.edit
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ) : (
                        <></>
                      )
                    }}
                  />
                </Collapse>
                <Collapse in={editPassword}>
                  <CustomInput
                    labelText="New Password"
                    id="new-password"
                    error={!handleCheckPassword()}
                    success={handleCheckPassword()}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: password,
                      type: "password",
                      onChange: handleChangePassword
                    }}
                  />
                  <CustomInput
                    labelText="Re-Enter New Password"
                    id="reenter-password"
                    error={password !== rePassword}
                    success={password === rePassword}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: rePassword,
                      type: "password",
                      onChange: handleChangeRePassword
                    }}
                  />
                </Collapse>
              </GridItem>
            </GridContainer>
            <Collapse in={edit || editPassword}>
              <GridContainer justify={"space-around"}>
                <GridItem>
                  <Button color={"warning"} onClick={handleDiscardButton}>
                    Discard
                  </Button>
                </GridItem>
                <GridItem>
                  <Button color={"primary"} onClick={handleOpenDialog}>
                    Submit
                  </Button>
                </GridItem>
              </GridContainer>
              <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Apakah anda yakin?</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Masukkan password yang sedang digunakan atau password lama
                    anda
                  </DialogContentText>
                  <CustomInput
                    labelText="Old Password"
                    id="old-password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: oldPassword,
                      type: "password",
                      onChange: handleChangeOldPassword
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button color={"warning"} onClick={handleCloseDialog}>
                    Tidak
                  </Button>
                  <Button color={"primary"} onClick={handleSubmitButton}>
                    Ya
                  </Button>
                </DialogActions>
              </Dialog>
            </Collapse>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
