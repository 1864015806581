import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
// @material-ui/core components
import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  Tooltip
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Table from "dep/Table/Table";
import Button from "dep/CustomButtons/Button";
import CustomInput from "dep/CustomInput/CustomInput";
// @material-ui/icons
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
// jss
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

const useStyles = makeStyles({ ...taskStyles, ...customInputStyle });

export default function DialogPeriodeKelulusan(props) {
  const { openSnackbar } = props;
  const classes = useStyles();
  useEffect(() => {
    setTableData(
      props.listPeriode.map(value => [
        value[1] + "/" + (~~value[1] + 1),
        value[2],
        value[3],
        Auth.getIdProdi() ? <></> : renderDelete(value)
      ])
    );
  }, [props.listPeriode]);

  // var: values
  const [tableData, setTableData] = useState([]);
  const [selectedPeriode, setSelectedPeriode] = useState({
    id: "",
    tahun: "",
    periode: "",
    tanggal: ""
  });
  const [addPeriodeValues, setAddPeriodeValues] = useState({
    open: false,
    periode: "",
    date: new Date()
  });
  // var: open-close
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // handle: API
  const handleAddPeriode = async () => {
    try {
      openSnackbar("info", "Menambahkan..");
      let res = await Auth.fetchMultipart(
        BASE_URL +
          "/api/manajemen/periode/add?tanggal=" +
          moment(addPeriodeValues.date).format("YYYY-MM-DD") +
          "&periode=" +
          addPeriodeValues.periode,
        {
          method: "POST"
        }
      );
      let data = await res.json();

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data kegiatan berhasil ditambahkan!");
        props.handleFetchPeriode();
        handleCloseAddPeriode();
      } else {
        let errorMessages = "";
        if (typeof data.message === "string") {
          errorMessages = data.message;
        } else if (typeof data.message === "object") {
          errorMessages = "";
          if (Array.isArray(data.message)) {
            for (let err of data.message) {
              errorMessages +=
                typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
            }
          } else {
            for (let key in data.message) errorMessages += data.message[key][0];
          }
        }

        openSnackbar(
          res.status >= 400 && res.status < 500 ? "warning" : "error",
          errorMessages
        );
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  const handleDelete = async () => {
    try {
      openSnackbar("info", "Menghapus..");
      let res = await Auth.fetchMultipart(
        BASE_URL +
          "/api/manajemen/periode/delete?id_periode_kelulusan=" +
          selectedPeriode.id,
        {
          method: "DELETE"
        }
      );
      let data = await res.json();

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data kegiatan berhasil dihapus!");
        props.handleFetchPeriode();
        handleCloseDeleteDialog();
      } else {
        let errorMessages = "";
        if (typeof data.message === "string") {
          errorMessages = data.message;
        } else if (typeof data.message === "object") {
          errorMessages = "";
          if (Array.isArray(data.message)) {
            for (let err of data.message) {
              errorMessages +=
                typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
            }
          } else {
            for (let key in data.message) errorMessages += data.message[key][0];
          }
        }

        openSnackbar(
          res.status >= 400 && res.status < 500 ? "warning" : "error",
          errorMessages
        );
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  // handle: values
  const handleChangeAddPeriodeValues = event => {
    setAddPeriodeValues({
      ...addPeriodeValues,
      [event.target.name]: event.target.value
    });
  };
  const handleChangeAddPeriodeDate = date => {
    setAddPeriodeValues({ ...addPeriodeValues, date: date });
  };

  // handle: open-close
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedPeriode([]);
  };
  const handleCloseAddPeriode = () => {
    setAddPeriodeValues({ open: false, periode: "", date: new Date() });
  };

  // handle: button -> decision helper
  const handleButtonAdd = () => {
    if (addPeriodeValues.open) {
      //await
      handleAddPeriode();
      setAddPeriodeValues({ open: false, periode: "", date: new Date() });
    } else {
      setAddPeriodeValues({ ...addPeriodeValues, open: true });
    }
  };

  const RenderDeleteDialog = () => (
    <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
      <DialogTitle>Apakah anda yakin?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Anda akan menghapus periode kelulusan tahun ajaran{" "}
          {selectedPeriode.tahun} periode {selectedPeriode.periode}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color={"warning"}
          round
          size={"sm"}
          onClick={handleCloseDeleteDialog}
        >
          tidak
        </Button>
        <Button color={"success"} round size={"sm"} onClick={handleDelete}>
          Ya
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderDelete = periodeData => {
    return (
      <Tooltip
        id="tooltip-delete"
        title="hapus periode"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="hapus periode"
          className={classes.tableActionButton}
          onClick={event => {
            setOpenDeleteDialog(true);
            setSelectedPeriode({
              id: periodeData[0],
              tahun: periodeData[1] + "/" + (~~periodeData[1] + 1),
              periode: periodeData[2],
              tanggal: periodeData[3]
            });
            event.preventDefault();
          }}
        >
          <DeleteForeverIcon
            className={classes.tableActionButtonIcon + " " + classes.close}
          />
        </IconButton>
      </Tooltip>
    );
  };
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll={"body"}
      fullWidth
      maxWidth={"sm"}
    >
      <RenderDeleteDialog />
      <DialogTitle>Periode Kelulusan</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Informasi mengenai periode kelulusan atau kapan SKPI ini dicetak
        </DialogContentText>
        <GridContainer justify={"center"}>
          <GridItem xs={11} md={8}>
            <Table
              tableHeaderColor="primary"
              tableHead={["Tahun Ajaran", "Periode", "Tanggal", ""]}
              tableData={tableData}
            />
          </GridItem>
        </GridContainer>
        <Collapse in={addPeriodeValues.open}>
          <GridContainer justify={"center"}>
            <GridItem xs={4} md={3}>
              <CustomInput
                labelText="Periode"
                id="periode"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: addPeriodeValues.periode,
                  name: "periode",
                  type: "number",
                  onChange: handleChangeAddPeriodeValues
                }}
              />
            </GridItem>
            <GridItem xs={7} md={5}>
              <FormControl
                className={classes.formControl}
                style={{ marginBottom: "20px" }}
                fullWidth
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    id="tanggal"
                    label="Tanggal"
                    format="dd/MM/yyyy"
                    value={addPeriodeValues.date}
                    onChange={date => handleChangeAddPeriodeDate(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </GridItem>
          </GridContainer>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Collapse in={addPeriodeValues.open}>
          <Button
            color={"danger"}
            round
            size={"sm"}
            onClick={handleCloseAddPeriode}
          >
            Cancel
          </Button>
        </Collapse>
        {Auth.getIdProdi() ? (
          <></>
        ) : (
          <Button
            color={addPeriodeValues.open ? "success" : "info"}
            round
            size={"sm"}
            disabled={addPeriodeValues.open && !addPeriodeValues.periode}
            onClick={handleButtonAdd}
          >
            <AddCircleOutlineOutlinedIcon />
            Tambah
          </Button>
        )}
        <Button color={"warning"} round size={"sm"} onClick={props.onClose}>
          <HighlightOffIcon />
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogPeriodeKelulusan.propTypes = {
  handleFetchPeriode: PropTypes.func,
  listPeriode: PropTypes.array,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  openSnackbar: PropTypes.func
};
