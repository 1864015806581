import React from "react";
import { Link } from "react-router-dom";
import { Container } from "@material-ui/core";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  containerAppBar: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  footer: {
    backgroundColor: "#353535",
    color: "#e0e0e0"
  },
  footerTitle: {},
  footerText: {
    color: "#808080",
    marginBottom: "0",
    marginTop: "0"
  }
});

export default function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container
        maxWidth={"lg"}
        style={{ paddingTop: "1vh", paddingBottom: "1vh" }}
      >
        <GridContainer justify={"center"}>
          <GridItem xs={10}>
            <GridContainer justify={"space-between"}>
              <GridItem>
                <h4>Get Started</h4>
                <Link to={"/register"}>
                  <p className={classes.footerText}>Register</p>
                </Link>
                <Link to={"/terms-of-service"}>
                  <p className={classes.footerText}>Term and Condition</p>
                </Link>
              </GridItem>
              <GridItem>
                <h4>Get Help</h4>
                <Link to={"/guide"}>
                  <p className={classes.footerText}>Guide</p>
                </Link>
                <Link to={"/faq"}>
                  <p className={classes.footerText}>FAQ</p>
                </Link>
              </GridItem>
              <GridItem>
                <h4>About Us</h4>
                <Link to={"/about-us"}>
                  <p className={classes.footerText}>Universitas Janabadra</p>
                </Link>
                <Link to={"/about-us"}>
                  <p className={classes.footerText}>JPT 2020 Team</p>
                </Link>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Container>
    </footer>
  );
}
