import React, { useEffect, useState } from "react";
import ChartistGraph from "react-chartist";
import Chartist from "chartist";
import moment from "moment";
// @material-ui/core components
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import CardHeader from "dep/Card/CardHeader";
import CardBody from "dep/Card/CardBody";
import CardFooter from "dep/Card/CardFooter";
import Card from "dep/Card/Card";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
// @material-ui/icons
import Update from "@material-ui/icons/Update";
//jss
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle";
import formStyles from "assets/jss/jpt/formStyles";
// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

const useStyles = makeStyles({
  ...dashboardStyle,
  ...formStyles
});

const delays = 80;
const durations = 500;
const statistikLoginChart = {
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0
    }),
    scaleMinSpace: 40,
    onlyInteger: true,
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  },
  // for animation
  animation: {
    draw: function(data) {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease"
          }
        });
      }
    }
  }
};
export default function CardStatistikLogin() {
  const classes = useStyles();
  useEffect(() => {
    handleFetch();
  }, []);
  // var: values
  const [data, setData] = useState({ labels: [], series: [[]] });
  const [selectedUserType, setSelectedUserType] = useState("");
  const [fetchData, setFetchData] = useState({
    mahasiswa: [],
    admin_jurusan: [],
    verifikator: [],
    super_admin: [],
    total: []
  });
  const [dateLastUpdate, setDateLastUpdate] = useState(new Date());
  // handle: API
  const handleFetch = async () => {
    const dayLabels = ["M", "T", "W", "T", "F", "S", "S"];
    // Data from API today and 6 days prior
    let res = await Auth.fetch(BASE_URL + "/api/statistik/login/week");
    let json = await res.json();

    const data = json.data;
    let totals = [0, 0, 0, 0, 0, 0, 0];

    Object.keys(data).forEach(key => {
      data[key].forEach((value, index) => (totals[index] += value));
    });

    const dateNow = new Date();
    const dayLabelsSpliceEnd = dayLabels.splice(dateNow.getDay());

    setFetchData({
      ...data,
      total: totals
    });
    setData({
      labels: [...dayLabelsSpliceEnd, ...dayLabels],
      series: [totals]
    });
    setSelectedUserType("");
    setDateLastUpdate(dateNow);
  };
  // handle: values
  const handleChangeUserType = event => {
    const user = event.target.value;
    setSelectedUserType(user);
    if (user in fetchData) setData({ ...data, series: [fetchData[user]] });
    else setData({ ...data, series: [fetchData.total] });
  };
  return (
    <Card chart>
      <CardHeader color="success">
        <ChartistGraph
          className="ct-chart"
          data={data}
          type="Line"
          options={statistikLoginChart.options}
          listener={statistikLoginChart.animation}
        />
      </CardHeader>
      <CardBody>
        <GridContainer justify={"space-between"} alignItems={"center"}>
          <GridItem xs={12} md={8}>
            <h4 className={classes.cardTitle}>Jumlah Login</h4>
            <p className={classes.cardCategory}>Jumlah log in akun per hari</p>
          </GridItem>
          <GridItem xs={12} md={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel id="demo-simple-select-outlined-label">
                account
              </InputLabel>
              <Select
                labelId="select-account-type"
                id="select-account-type"
                value={selectedUserType}
                onChange={handleChangeUserType}
                label="account"
              >
                <MenuItem value="">
                  <em>Semua</em>
                </MenuItem>
                <MenuItem value={"mahasiswa"}>Mahasiswa</MenuItem>
                <MenuItem value={"verifikator"}>Verifikator</MenuItem>
                <MenuItem value={"admin_jurusan"}>Admin Jurusan</MenuItem>
                <MenuItem value={"super_admin"}>Super Admin</MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
      </CardBody>
      <CardFooter chart>
        <div className={classes.stats} onClick={handleFetch}>
          <Update /> updated at {moment(dateLastUpdate).format("HH:mm")}
        </div>
      </CardFooter>
    </Card>
  );
}
