import React, { useEffect, useState } from "react";
import _ from "lodash";
import Joi from "joi";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import DateFnsUtils from "@date-io/date-fns";
// @metrial-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Collapse,
  FormControl,
  FormHelperText,
  IconButton,
  Tooltip
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
// dep components
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";
import GridContainer from "dep/Grid/GridContainer";
import CardAvatar from "dep/Card/CardAvatar";
import CustomSelect from "dep/CustomInput/CustomSelect";
import Button from "dep/CustomButtons/Button";
// @material-ui/icons
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
// assets
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";
import avatar from "assets/img/profile_picture.png";
// for API
import Auth from "helpers/auth";
import { formatDate } from "helpers/inputFormatting";
import { BASE_URL } from "const";
import formStyles from "assets/jss/jpt/formStyles";

const useStyles = makeStyles({
  ...formStyles,
  ...tasksStyle
});
const MAX_IMAGE_SIZE = 2000000;
const ACCEPT_IMAGE_TYPE = "image/png, image/jpg, image/gif, image/jpeg";

export default function TabProfileBiodata(props) {
  const classes = useStyles();

  // var: open-close
  const [editImage, setEditImage] = useState(false);
  const [editValue, setEditValue] = useState(false);

  // var: list
  const [listNegara, setListNegara] = useState([]);
  const [listProvinsi, setListProvinsi] = useState([]);
  const [listKota, setListKota] = useState([]);

  // var: values
  const [previewImage, setPreviewImage] = useState(avatar);
  const [oldValues, setOldValues] = useState(); // usage: temporary data if edited values discarded
  const [values, setValues] = useState({
    profile_picture: null,
    name: "",
    kewarganegaraan: "",
    nik: "",
    agama: "",
    kelamin: "",
    alamat: "",
    kode_pos: "",
    provinsi: "",
    kota: "",
    tempat_lahir: "",
    tanggal_lahir: null
  });

  const [errors, setErrors] = useState({
    profile_picture_error: "",
    name_error: "",
    kewarganegaraan_error: "",
    nik_error: "",
    agama_error: "",
    kelamin_error: "",
    alamat_error: "",
    kode_pos_error: "",
    provinsi_error: "",
    kota_error: "",
    tempat_lahir_error: "",
    tanggal_lahir_error: ""
  });

  useEffect(() => {
    handleFetchListNegara();
    handleFetchListProvinsi();
    handleFetchBiodata();
  }, []);

  useEffect(() => {
    if (values.provinsi !== "") handleFetchListKota();
  }, [values.provinsi]);

  // handle: image
  const verifyImage = file => {
    if (!ACCEPT_IMAGE_TYPE.includes(file.type)) {
      props.openSnackbar(
        "warning",
        "Tipe file yang diupload tidak sesuai!\nTipe file yang diterima jpg/jpeg/png/gif"
      );
      return false;
    } else if (file.size > MAX_IMAGE_SIZE) {
      props.openSnackbar(
        "warning",
        "File yang diupload tidak boleh lebih dari 2MB!"
      );
      return false;
    }
    return true;
  };
  const handleDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      verifyImage(rejectedFiles[0]);
    }

    if (acceptedFiles.length > 0) {
      if (verifyImage(acceptedFiles[0])) {
        setValues({ ...values, profile_picture: acceptedFiles[0] });
        setEditImage(true);
        // Change to base64 to show preview
        const reader = new FileReader();
        reader.onload = event => {
          setPreviewImage(event.target.result);
        };
        reader.readAsDataURL(acceptedFiles[0]);
      }
    }
  };

  // handle: API fetch
  const handleFetchBiodata = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/profile");
    let data = await res.json();
    if (res.status === 200) {
      const biodata = {
        profile_picture: _.get(data, "user.foto_profil"),
        name: _.get(data, "user.nama"),
        kewarganegaraan: _.get(data, "user.kewarganegaraan"),
        nik: _.get(data, "user.nik"),
        agama: _.get(data, "user.agama"),
        kelamin: _.get(data, "user.jenis_kelamin"),
        alamat: _.get(data, "user.alamat"),
        kode_pos: _.get(data, "user.kode_pos"),
        provinsi: _.get(data, "user.id_provinsi"),
        kota: _.get(data, "user.id_kota"),
        tempat_lahir: _.get(data, "user.tempat_lahir"),
        tanggal_lahir: new Date(_.get(data, "user.tanggal_lahir"))
      };
      if (biodata.profile_picture)
        setPreviewImage(BASE_URL + "/" + biodata.profile_picture);
      else setPreviewImage(avatar);
      setValues(biodata);
      setOldValues(biodata);
    }
  };

  const handleFetchListNegara = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/lokasi/negara");
    let data = await res.json();
    setListNegara(
      _.get(data, "negara", []).map(row => {
        return { value: row.id_negara, text: row.nama_negara };
      })
    );
  };

  const handleFetchListProvinsi = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/lokasi/negara/102");
    let data = await res.json();
    setListProvinsi(
      _.get(data, "provinsi").map(row => {
        return {
          value: _.get(row, "id_provinsi"),
          text: _.get(row, "nama_provinsi")
        };
      })
    );
  };

  const handleFetchListKota = async () => {
    let res = await Auth.fetch(
      BASE_URL + "/api/lokasi/provinsi/" + values.provinsi
    );
    let data = await res.json();
    setListKota(
      _.get(data, "kota", []).map(row => {
        return {
          value: _.get(row, "id_kota"),
          text: _.get(row, "nama_kota")
        };
      })
    );
  };

  // handle: API submit
  const handleSubmit = async () => {
    let allErrors = validateAllInputs();
    setErrors({ ...errors, ...allErrors });
    let isValid = true;
    for (var err in allErrors) {
      if (allErrors[err]) {
        isValid = false;
        break;
      }
    }
    if (isValid) {
      const formData = new FormData();
      if (editImage) {
        formData.append("foto_profil", values.profile_picture);
      }
      if (editValue) {
        formData.append("nama", values.name);
        formData.append("jenis_kelamin", values.kelamin ? 1 : 0);
        formData.append("tempat_lahir", values.tempat_lahir);
        formData.append("tanggal_lahir", formatDate(values.tanggal_lahir));
        formData.append("nik", values.nik);
        formData.append("agama", values.agama);
        formData.append("kewarganegaraan", values.kewarganegaraan);
        formData.append("alamat", values.alamat);
        if (values.kewarganegaraan === 102)
          formData.append("id_provinsi", values.provinsi);
        if (values.kewarganegaraan === 102)
          formData.append("id_kota", values.kota);
        formData.append("kode_pos", values.kode_pos);
      }

      let res = await Auth.fetchMultipart(BASE_URL + "/api/profile/update", {
        method: "POST",
        body: formData
      });
      let data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        const biodata = {
          profile_picture: _.get(data, "user.foto_profil"),
          name: _.get(data, "user.nama"),
          kewarganegaraan: _.get(data, "user.kewarganegaraan"),
          nik: _.get(data, "user.nik"),
          agama: _.get(data, "user.agama"),
          kelamin: _.get(data, "user.jenis_kelamin"),
          alamat: _.get(data, "user.alamat"),
          kode_pos: _.get(data, "user.kode_pos"),
          provinsi: _.get(data, "user.id_provinsi"),
          kota: _.get(data, "user.id_kota"),
          tempat_lahir: _.get(data, "user.tempat_lahir"),
          tanggal_lahir: new Date(_.get(data, "user.tanggal_lahir"))
        };
        if (biodata.profile_picture)
          setPreviewImage(BASE_URL + "/" + biodata.profile_picture);
        else setPreviewImage(avatar);
        setValues(biodata);
        setOldValues(biodata);

        props.openSnackbar("success", "Berhasil memperbarui data profil");
      } else {
        setValues(oldValues);
        props.openSnackbar("error", "Gagal memperbarui data profil");
      }
      setEditImage(false);
      setEditValue(false);
    }
  };

  // handle: change values
  const handleChangeProvince = event => {
    setValues({ ...values, provinsi: event.target.value });
  };
  const handleChangeDate = date => {
    setValues({ ...values, tanggal_lahir: date });
  };
  const handleChangeValues = event => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  const handleChangeSelectKewarganegaraan = event => {
    if (event.target.value === 102) {
      setValues({
        ...values,
        kewarganegaraan: event.target.value
      });
      handleFetchListProvinsi();
    } else {
      setValues({
        ...values,
        kewarganegaraan: event.target.value,
        provinsi: "",
        kota: ""
      });
    }
  };

  // handle: open-close
  const handleDiscardButton = () => {
    setErrors({
      profile_picture_error: "",
      name_error: "",
      kewarganegaraan_error: "",
      nik_error: "",
      agama_error: "",
      kelamin_error: "",
      alamat_error: "",
      kode_pos_error: "",
      provinsi_error: "",
      kota_error: "",
      tempat_lahir_error: "",
      tanggal_lahir_error: ""
    });
    setValues(oldValues);
    if (oldValues.profile_picture)
      setPreviewImage(BASE_URL + "/" + oldValues.profile_picture);
    else setPreviewImage(avatar);
    setEditImage(false);
    setEditValue(false);
  };
  const handleEditButton = () => {
    setEditValue(true);
  };

  // Validation
  const validateName = () => {
    let { error } = Joi.string()
      .label("Name")
      .regex(/^([ \u00c0-\u01ffa-zA-Z'])+$/)
      .messages({
        "string.pattern.base": "Name cannot contain illegal character"
      })
      .validate(values.name);
    return _.get(error, "message", "").replace(/"/g, "");
  };

  const validateKewarganegaraan = () => {
    let kewarganegaraanError = "";
    if (!values.kewarganegaraan) {
      kewarganegaraanError = "Please choose one of the options";
    }
    return kewarganegaraanError;
  };

  const validateNik = () => {
    let { error } = Joi.string()
      .label("NIK / Passport Number")
      .validate(values.nik);
    return _.get(error, "message", "").replace(/"/g, "");
  };

  const validateAgama = () => {
    let agamaError = "";
    if (!values.agama) {
      agamaError = "Please choose one of the options";
    }
    return agamaError;
  };

  const validateKelamin = () => {
    let kelaminError = "";
    if (typeof values.kelamin !== "boolean") {
      kelaminError = "Please choose one of the option";
    }
    return kelaminError;
  };

  const validateTempatLahir = () => {
    let { error } = Joi.string()
      .label("Tempat Lahir")
      .validate(values.tempat_lahir);
    return _.get(error, "message", "").replace(/"/g, "");
  };

  const validateTanggalLahir = () => {
    let tanggalLahirError = "";
    if (!values.tanggal_lahir) {
      tanggalLahirError = "This field is required";
    }
    return tanggalLahirError;
  };

  const validateAlamat = () => {
    let { error } = Joi.string()
      .label("Alamat")
      .validate(values.alamat);
    return _.get(error, "message", "").replace(/"/g, "");
  };

  const validateKodePos = () => {
    return "";
  };

  const validateProvinsi = () => {
    let provinsiError = "";
    if (values.kewarganegaraan === 102 && !values.provinsi) {
      provinsiError = "Please choose one of the option";
    }
    return provinsiError;
  };

  const validateKota = () => {
    let kotaError = "";
    if (values.kewarganegaraan === 102 && !values.kota) {
      kotaError = "Please choose one of the option";
    }
    return kotaError;
  };

  const validateAllInputs = () => {
    return {
      profile_picture_error: "",
      name_error: validateName(),
      kewarganegaraan_error: validateKewarganegaraan(),
      nik_error: validateNik(),
      agama_error: validateAgama(),
      kelamin_error: validateKelamin(),
      alamat_error: validateAlamat(),
      kode_pos_error: validateKodePos(),
      provinsi_error: validateProvinsi(),
      kota_error: validateKota(),
      tempat_lahir_error: validateTempatLahir(),
      tanggal_lahir_error: validateTanggalLahir()
    };
  };

  return (
    <>
      <GridContainer>
        <div style={{ padding: "2vh" }} />
      </GridContainer>
      <GridContainer justify={"center"}>
        <GridItem style={{ marginTop: "20px" }}>
          <Dropzone
            disabled={!editValue}
            onDrop={handleDrop}
            accept={ACCEPT_IMAGE_TYPE}
            multiple={false}
            maxSize={MAX_IMAGE_SIZE}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <CardAvatar profile>
                  <img src={previewImage} alt="profile_picture" />
                </CardAvatar>
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </GridItem>
      </GridContainer>
      <GridContainer justify={"flex-end"}>
        <GridItem>
          {editValue ? (
            <Tooltip
              id="tooltip-top"
              title="Discard"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                aria-label="Edit"
                className={classes.tableActionButton}
                onClick={handleDiscardButton}
              >
                <CloseIcon
                  className={
                    classes.tableActionButtonIcon + " " + classes.close
                  }
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              id="tooltip-top"
              title="Ubah"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                aria-label="Edit"
                className={classes.tableActionButton}
                onClick={handleEditButton}
              >
                <EditIcon
                  className={classes.tableActionButtonIcon + " " + classes.edit}
                />
              </IconButton>
            </Tooltip>
          )}
        </GridItem>
      </GridContainer>
      <GridContainer justify={"center"}>
        <GridItem xs={10}>
          <CustomInput
            labelText="Nama"
            id="name"
            formControlProps={{
              fullWidth: true
            }}
            error={!!errors.name_error}
            helperText={errors.name_error}
            inputProps={{
              disabled: true /*!editValue*/,
              name: "name",
              value: values.name || "",
              onChange: handleChangeValues,
              onBlur: () => {
                setErrors({
                  ...errors,
                  name_error: validateName()
                });
              },
              onFocus: () => {
                setErrors({ ...errors, name_error: "" });
              }
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify={"center"}>
        <GridItem xs={10} md={4}>
          <CustomSelect
            labelText="Kewarganegaraan"
            id="kewarganegaraan"
            formControlProps={{
              fullWidth: true
            }}
            error={!!errors.kewarganegaraan_error}
            helperText={errors.kewarganegaraan_error}
            selectProps={{
              disabled: !editValue,
              id: "kewarganegaraan",
              name: "kewarganegaraan",
              onChange: handleChangeSelectKewarganegaraan,
              onBlur: () => {
                setErrors({
                  ...errors,
                  kewarganegaraan_error: validateKewarganegaraan()
                });
              },
              onFocus: () => {
                setErrors({ ...errors, kewarganegaraan_error: "" });
              }
            }}
            selectItems={listNegara}
            selectValue={values.kewarganegaraan}
          />
        </GridItem>
        <GridItem xs={10} md={6}>
          <Collapse in={Boolean(values.kewarganegaraan)}>
            <CustomInput
              labelText="NIK / Passport Number"
              id="nik"
              name="citizenshipID"
              formControlProps={{
                fullWidth: true
              }}
              error={!!errors.nik_error}
              helperText={errors.nik_error}
              inputProps={{
                disabled: !editValue,
                name: "nik",
                value: values.nik,
                onChange: handleChangeValues,
                onBlur: () => {
                  setErrors({
                    ...errors,
                    nik_error: validateNik()
                  });
                },
                onFocus: () => {
                  setErrors({ ...errors, nik_error: "" });
                }
              }}
            />
          </Collapse>
        </GridItem>
      </GridContainer>
      <GridContainer justify={"center"}>
        <GridItem xs={10} md={5}>
          <CustomSelect
            labelText="Agama"
            id="agama"
            formControlProps={{
              fullWidth: true
            }}
            toolt
            error={!!errors.agama_error}
            helperText={errors.agama_error}
            selectProps={{
              disabled: !editValue,
              id: "agama",
              name: "agama",
              onChange: handleChangeValues,
              onBlur: () => {
                setErrors({
                  ...errors,
                  agama_error: validateAgama()
                });
              },
              onFocus: () => {
                setErrors({ ...errors, agama_error: "" });
              }
            }}
            selectItems={[
              { value: "Islam", text: "Islam" },
              { value: "Kristen", text: "Kristen" },
              { value: "Katolik", text: "Katolik" },
              { value: "Hindu", text: "Hindu" },
              { value: "Buddha", text: "Buddha" },
              { value: "Konghucu", text: "Konghucu" }
            ]}
            selectValue={values.agama}
          />
        </GridItem>
        <GridItem xs={10} md={5}>
          <CustomSelect
            labelText="Jenis Kelamin"
            id="kelamin"
            formControlProps={{
              fullWidth: true
            }}
            error={!!errors.kelamin_error}
            helperText={errors.kelamin_error}
            selectProps={{
              disabled: !editValue,
              id: "kelamin",
              name: "kelamin",
              onChange: handleChangeValues,
              onBlur: () => {
                setErrors({
                  ...errors,
                  kelamin_error: validateKelamin()
                });
              },
              onFocus: () => {
                setErrors({ ...errors, kelamin_error: "" });
              }
            }}
            selectItems={[
              { value: false, text: "Laki-laki" },
              { value: true, text: "Perempuan" }
            ]}
            selectValue={values.kelamin}
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify={"center"}>
        <GridItem xs={10} md={6}>
          <CustomInput
            labelText="Tempat Lahir"
            id="tempat-lahir"
            name="tempat-lahir"
            formControlProps={{
              fullWidth: true
            }}
            error={!!errors.tempat_lahir_error}
            helperText={errors.tempat_lahir_error}
            inputProps={{
              disabled: !editValue,
              name: "tempat_lahir",
              value: values.tempat_lahir,
              onChange: handleChangeValues,
              onBlur: () => {
                setErrors({
                  ...errors,
                  tempat_lahir_error: validateTempatLahir()
                });
              },
              onFocus: () => {
                setErrors({ ...errors, tempat_lahir_error: "" });
              }
            }}
          />
        </GridItem>
        <GridItem xs={10} md={4}>
          <div style={{ padding: "13.5px" }} />
          <FormControl
            className={classes.formControl}
            error={!!errors.tanggal_lahir_error}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disabled={!editValue}
                id="tanggal_lahir"
                label="Tanggal Lahir"
                format="dd/MM/yyyy"
                value={values.tanggal_lahir}
                onChange={date => handleChangeDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </MuiPickersUtilsProvider>
            <FormHelperText style={{ color: "red" }}>
              {errors.tanggal_lahir_error}
            </FormHelperText>
          </FormControl>
        </GridItem>
      </GridContainer>
      <GridContainer justify={"center"}>
        <GridItem xs={10} md={7}>
          <CustomInput
            labelText="Alamat"
            id="alamat"
            name="alamat"
            formControlProps={{
              fullWidth: true
            }}
            error={!!errors.alamat_error}
            helperText={errors.alamat_error}
            inputProps={{
              disabled: !editValue,
              name: "alamat",
              value: values.alamat,
              multiline: true,
              rows: 3,
              onChange: handleChangeValues,
              onBlur: () => {
                setErrors({
                  ...errors,
                  alamat_error: validateAlamat()
                });
              },
              onFocus: () => {
                setErrors({ ...errors, alamat_error: "" });
              }
            }}
          />
        </GridItem>
        <GridItem xs={10} md={3}>
          <CustomInput
            labelText="Kode Pos"
            id="poscode"
            name="poscode"
            formControlProps={{
              fullWidth: true
            }}
            error={!!errors.kode_pos_error}
            helperText={errors.kode_pos_error}
            inputProps={{
              disabled: !editValue,
              name: "kode_pos",
              value: values.kode_pos,
              onChange: handleChangeValues,
              onBlur: () => {
                setErrors({
                  ...errors,
                  kode_pos_error: validateKodePos()
                });
              },
              onFocus: () => {
                setErrors({ ...errors, kode_pos_error: "" });
              }
            }}
          />
        </GridItem>
      </GridContainer>
      <Collapse in={values.kewarganegaraan === 102}>
        <GridContainer justify={"center"}>
          <GridItem xs={10} md={5}>
            <CustomSelect
              labelText="Provinsi"
              id="provinsi"
              formControlProps={{
                fullWidth: true
              }}
              error={!!errors.provinsi_error}
              helperText={errors.provinsi_error}
              selectProps={{
                disabled: !editValue,
                id: "provinsi",
                name: "provinsi",
                onChange: handleChangeProvince,
                onBlur: () => {
                  setErrors({
                    ...errors,
                    provinsi_error: validateProvinsi()
                  });
                },
                onFocus: () => {
                  setErrors({ ...errors, provinsi_error: "" });
                }
              }}
              selectItems={listProvinsi}
              selectValue={values.provinsi}
            />
          </GridItem>
          <GridItem xs={10} md={5}>
            <CustomSelect
              labelText="Kota"
              id="kota"
              formControlProps={{
                fullWidth: true
              }}
              error={!!errors.kota_error}
              helperText={errors.kota_error}
              selectProps={{
                disabled: !editValue,
                id: "kota",
                name: "kota",
                onChange: handleChangeValues,
                onBlur: () => {
                  setErrors({
                    ...errors,
                    kota_error: validateKota()
                  });
                },
                onFocus: () => {
                  setErrors({ ...errors, kota_error: "" });
                }
              }}
              selectItems={listKota}
              selectValue={values.kota}
            />
          </GridItem>
        </GridContainer>
      </Collapse>
      <GridContainer>
        <div style={{ padding: "2vh" }} />
      </GridContainer>
      <Collapse in={editValue || editImage}>
        <GridContainer justify={"space-around"}>
          <GridItem>
            <Button color={"danger"} onClick={handleDiscardButton}>
              Discard
            </Button>
          </GridItem>
          <GridItem>
            <Button color={"success"} onClick={handleSubmit}>
              Save
            </Button>
          </GridItem>
        </GridContainer>
      </Collapse>
    </>
  );
}

TabProfileBiodata.propTypes = {
  openSnackbar: PropTypes.func
};
