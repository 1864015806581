import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
// core components
import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";

// dep components
import Button from "dep/CustomButtons/Button.js";
import Card from "dep/Card/Card.js";
import CardBody from "dep/Card/CardBody.js";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";

// components
import DialogUbahKategoriRubrik from "components/Admin/ManajemenRubrik/Dialog/DialogUbahKategoriRubrik";
import DialogDeleteKategoriRubrik from "components/Admin/ManajemenRubrik/Dialog/DialogDeleteKategoriRubrik";

// @material-ui/icons
import SettingsIcon from "@material-ui/icons/Settings";

// styling
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import { cardTitle } from "assets/jss/material-dashboard-react.js";

const useStyles = makeStyles({
  cardTitle,
  textCenter: {
    textAlign: "center"
  },
  ...taskStyles
});

export default function LittleCardManajemenRubrikChooseCategory(props) {
  const classes = useStyles();

  // Props
  const { selectedBidangPortofolio } = props;

  // State
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [clickUbah, setClickUbah] = useState(false);
  const [clickDelete, setClickDelete] = useState(false);

  // Handler
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickUbah = () => {
    setClickUbah(true);
    handleClose();
  };

  const handleCloseUbah = () => {
    setClickUbah(false);
  };

  const handleClickDelete = () => {
    setClickDelete(true);
    handleClose();
  };

  const handleCloseDelete = () => {
    setClickDelete(false);
  };

  const handleChooseCategory = () => {
    props.handleChooseCategory(selectedBidangPortofolio);
  };

  return (
    <>
      <Card className={classes.textCenter}>
        <GridContainer justify={"flex-end"}>
          <GridItem>
            <Tooltip
              id="tooltip-top"
              title="Edit Category"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                aria-label="Edit"
                className={classes.tableActionButton}
                onClick={handleClick}
              >
                <SettingsIcon
                  className={classes.tableActionButtonIcon + " " + classes.edit}
                />
              </IconButton>
            </Tooltip>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClickUbah}>Ubah</MenuItem>
              <MenuItem onClick={handleClickDelete}>Hapus</MenuItem>
            </Menu>
          </GridItem>
        </GridContainer>
        <CardBody>
          <h4 className={classes.cardTitle}>
            {selectedBidangPortofolio.nama_bidang_portofolio}
          </h4>
          <p>{selectedBidangPortofolio.nama_bidang_portofolio_inggris}</p>
          <Button color="primary" onClick={handleChooseCategory}>
            Pilih
          </Button>
        </CardBody>
      </Card>
      {clickUbah ? (
        <DialogUbahKategoriRubrik
          selectedBidangPortofolio={selectedBidangPortofolio}
          handleCloseUbah={handleCloseUbah}
          {...props}
        />
      ) : (
        <></>
      )}

      {clickDelete ? (
        <DialogDeleteKategoriRubrik
          selectedBidangPortofolio={selectedBidangPortofolio}
          handleCloseUbah={handleCloseDelete}
          {...props}
        />
      ) : (
        <></>
      )}
    </>
  );
}

LittleCardManajemenRubrikChooseCategory.propTypes = {
  handleChooseCategory: PropTypes.func,
  selectedBidangPortofolio: PropTypes.any
};
