import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
// core components
import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";

// dep components
import Button from "dep/CustomButtons/Button.js";
import Card from "dep/Card/Card.js";
import CardBody from "dep/Card/CardBody.js";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";

// components
import DialogUbahHapusKategoriCPL from "components/Admin/ManajemenCPL/Dialog/DialogUbahHapusKategoriCPL";

// @material-ui/icons
import SettingsIcon from "@material-ui/icons/Settings";

// styling
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import { cardTitle } from "assets/jss/material-dashboard-react.js";

const useStyles = makeStyles({
  cardTitle,
  textCenter: {
    textAlign: "center"
  },
  ...taskStyles
});

export default function LittleCardManajemenCPLChooseCategory(props) {
  const classes = useStyles();

  // Props
  const { selectedBidangCPL, selectedProdi } = props;

  // State
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogType, setDialogType] = useState("");

  // Handler
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setDialogType("");
  };

  const handleClickUbah = () => {
    setDialogType("edit");
    handleClose();
  };

  const handleClickDelete = () => {
    setDialogType("delete");
    handleClose();
  };

  const handleChooseCategory = () => {
    props.handleChooseCategory(selectedBidangCPL);
  };

  return (
    <>
      <Card className={classes.textCenter}>
        <GridContainer justify={"flex-end"}>
          <GridItem>
            <Tooltip
              id="tooltip-top"
              title="Edit Category"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                aria-label="Edit"
                className={classes.tableActionButton}
                onClick={handleClick}
              >
                <SettingsIcon
                  className={classes.tableActionButtonIcon + " " + classes.edit}
                />
              </IconButton>
            </Tooltip>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClickUbah}>Ubah</MenuItem>
              <MenuItem onClick={handleClickDelete}>Hapus</MenuItem>
            </Menu>
          </GridItem>
        </GridContainer>
        <CardBody>
          <h4 className={classes.cardTitle}>
            {selectedBidangCPL.nama_bidang_cpl_indonesia}
          </h4>
          <p>{selectedBidangCPL.nama_bidang_cpl_inggris}</p>
          <Button color="primary" onClick={handleChooseCategory}>
            Pilih
          </Button>
        </CardBody>
      </Card>
      <DialogUbahHapusKategoriCPL
        open={!!dialogType}
        onClose={handleCloseDialog}
        hapus={dialogType === "delete"}
        openSnackbar={props.openSnackbar}
        selectedBidangCPL={selectedBidangCPL}
        selectedProdi={selectedProdi}
        handleFetchCategories={props.handleFetchCategories}
      />
    </>
  );
}

LittleCardManajemenCPLChooseCategory.propTypes = {
  handleChooseCategory: PropTypes.func,
  openSnackbar: PropTypes.func,
  selectedBidangCPL: PropTypes.object,
  selectedProdi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleFetchCategories: PropTypes.func
};
