import logoOnly from "assets/img/janaprotrack-logo-only.png";
import logoText from "assets/img/janaprotrack-logo-text-white.png";
// import imageCartoon from "assets/img/Portfolio Update-pana.svg"; //kopirekt: <a href="https://www.freepik.com/free-photos-vectors/design">Design vector created by stories - www.freepik.com</a>
// import testDrawing from "assets/img/drawing.svg";
import UJB_eagle_view from "assets/img/UJB_eagle_view.jpg";
import JPT_doodle from "assets/img/JPT_doodle_grayscale.png";
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const ROLE_SUPER_ADMIN = "super admin";
export const ROLE_ADMIN_JURUSAN = "admin jurusan";
export const ROLE_VERIFIKATOR = "verifikator";
export const ROLE_MAHASISWA = "mahasiswa";
export const ID_ROLE_SUPER_ADMIN = 1;
export const ID_ROLE_ADMIN_JURUSAN = 2;
export const ID_ROLE_VERIFIKATOR = 3;
export const ID_ROLE_MAHASISWA = 4;
export const ROLE_PPK = "ppk";
export const LOGO_TEXT = logoText; //TODO: ganti url
export const LOGO_ONLY = logoOnly; //TODO: ganti url
export const DASHBOARD_NAME = "JPT 2020";
//export const LANDING_PAGE_BG_DESKTOP = landingPageBgDesktop;
export const LANDING_PAGE_BG_DESKTOP = UJB_eagle_view;
export const IMAGE_CARTOON = JPT_doodle;
//export const LANDING_PAGE_BG_MOBILE = landingPageBgDesktop;
//TODO: buat logo yang beneran
