import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import moment from "moment";
// @material/core components
import { Avatar, Dialog, DialogContent, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Card from "dep/Card/Card";
import CardBody from "dep/Card/CardBody";
import Button from "dep/CustomButtons/Button";
// components
import PdfViewer from "components/Global/Dialog/PdfViewer";
// @material-ui/icons
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
// for API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

const useStyles = makeStyles(theme => ({
  dialogTitle: { fontSize: "24px" },
  dialogSubTitle: { fontSize: "14px", fontStyle: "italic", color: "#4f4f4f" },
  cardTitle: { margin: 0, fontSize: "18px" },
  cardSubTitle: { fontSize: "16px", fontStyle: "italic", color: "#4f4f4f" },
  littleTitle: { margin: 0, fontSize: "14px", fontWeight: 600 },
  date: { fontSize: "14px", fontStyle: "italic", color: "#428efd" },
  avatar: { width: theme.spacing(12), height: theme.spacing(12) },
  text: { margin: 0, fontSize: "13px", color: "#343434" },
  textValue: {
    margin: 0,
    fontSize: "13px",
    fontStyle: "italic",
    color: "#4f4f4f",
    textAlign: "right"
  },
  bio: {
    margin: 0,
    fontSize: "13px",
    fontStyle: "italic",
    color: "#4f4f4f",
    textAlign: "center",
    whiteSpace: "nowrap",
    maxWidth: "50ch",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

export default function DialogVerifikasiPortofolio(props) {
  const { openSnackbar } = props;
  const classes = useStyles();
  useEffect(() => {
    if (props.open) handleFetchPortofolioData();
  }, [props.portofolioData]);

  // var: values
  const { portofolioData } = props;
  const [additionalData, setAdditionalData] = useState({
    bidang: "",
    rubrik: "",
    anakRubrik: "",
    link_bukti: "",
    link_bukti_ppk: ""
  });
  const [userData, setUserData] = useState({
    nama: "",
    prodi: "",
    nim: "",
    avatar: "",
    bio: ""
  });
  const [pdfViewer, setPdfViewer] = useState({
    open: false,
    link: ""
  });
  // handle: API fetch
  const handleFetchPortofolioData = async () => {
    let res = await Auth.fetch(BASE_URL + `/api/profile/${portofolioData.nim}`);
    let data = await res.json();

    setUserData({
      nama: _.get(data, "user.nama"),
      nim: _.get(data, "user.nim"),
      prodi:
        _.get(data, "user.prodi.nama_prodi") +
        " (" +
        _.get(data, "user.prodi.jenjang") +
        ")",
      avatar: BASE_URL + `/${_.get(data, "user.foto_profil")}`,
      bio: _.get(data, "user.bio")
    });

    setAdditionalData({
      bidang: portofolioData.nama_bidang_portofolio,
      rubrik: portofolioData.nama_rubrik,
      anakRubrik: portofolioData.nama_anak_rubrik,
      link_bukti: portofolioData.dokumen_portofolio
        ? `${BASE_URL}/${portofolioData.dokumen_portofolio}`
        : portofolioData.dokumen_portofolio,
      link_bukti_ppk: portofolioData.dokumen_verifikasi
        ? `${BASE_URL}/${portofolioData.dokumen_verifikasi}`
        : portofolioData.dokumen_verifikasi
    });
  };
  // handle: API submit
  const handleSubmit = async value => {
    try {
      openSnackbar("info", "Verifying..");
      let URL =
        BASE_URL +
        `/api/portofolio/user/${portofolioData.nim}/${
          portofolioData.id_portofolio
        }/${value ? "verify" : "reject"}`;

      let res = await Auth.fetch(URL, {
        method: "PUT"
      });
      let data = await res.json();
      let errorMessages = "";
      if (typeof data.message === "string") {
        errorMessages = data.message;
      } else if (typeof data.message === "object") {
        errorMessages = "";
        if (Array.isArray(data.message)) {
          for (let err of data.message) {
            errorMessages +=
              typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
          }
        } else {
          for (let key in data.message) errorMessages += data.message[key][0];
        }
      }

      if (res.status >= 200 && res.status < 300) {
        openSnackbar(
          "success",
          `Berhasil ${value ? "melakukan verifikasi" : "menolak"} portofolio`
        );
        handleCloseDialog();
      } else if (res.status >= 400 && res.status < 500) {
        openSnackbar("warning", errorMessages);
      } else {
        openSnackbar("error", errorMessages);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  const handleClosePdfViewer = () => {
    setPdfViewer({ open: false, link: "" });
  };

  const handleCloseDialog = () => {
    props.onClose();
    setAdditionalData({
      bidang: "",
      rubrik: "",
      anakRubrik: "",
      link_bukti: "",
      link_bukti_ppk: ""
    });
    setUserData({ nama: "", prodi: "", nim: "", avatar: "", bio: "" });
  };
  const handleOpenPdfViewer = link => {
    setPdfViewer({ open: true, link: link });
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleCloseDialog}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogContent>
        <GridContainer justify={"space-between"} alignItems={"flex-end"}>
          <GridItem>
            <h4 className={classes.dialogTitle}>Verifikasi Portofolio</h4>
            <p className={classes.dialogSubTitle}>
              Harap periksa dokumen pendukung dengan benar
            </p>
          </GridItem>
          <GridItem>
            <p className={classes.date}>
              {portofolioData.updated_at
                ? moment(portofolioData.updated_at).format("DD-MM-YYYY")
                : ""}
            </p>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GridContainer justify={"space-between"}>
                  <GridItem>
                    <h4 className={classes.cardTitle}>
                      {portofolioData.judul}
                    </h4>
                    <p className={classes.cardSubTitle}>
                      {portofolioData.judul_inggris}
                    </p>
                  </GridItem>
                  <GridItem>
                    <Button
                      size={"sm"}
                      round
                      color={"info"}
                      onClick={event => {
                        handleOpenPdfViewer(additionalData.link_bukti);
                        event.preventDefault();
                      }}
                    >
                      <PictureAsPdfIcon /> Portofolio
                    </Button>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem>
                    <p className={classes.littleTitle}>
                      Informasi Portofolio :
                    </p>
                  </GridItem>
                </GridContainer>
                <GridContainer justify={"space-between"}>
                  <GridItem xs={12} md>
                    <p className={classes.text}>Tanggal Kegiatan</p>
                  </GridItem>
                  <GridItem xs={12} md>
                    <p className={classes.textValue}>
                      {moment(portofolioData.tanggal_terbit).format(
                        "DD-MM-YYYY"
                      )}
                    </p>
                  </GridItem>
                </GridContainer>
                <GridContainer justify={"space-between"}>
                  <GridItem xs={12} md>
                    <p className={classes.text}>Kategori</p>
                  </GridItem>
                  <GridItem xs={12} md>
                    <p className={classes.textValue}>{additionalData.bidang}</p>
                  </GridItem>
                </GridContainer>
                <GridContainer justify={"space-between"}>
                  <GridItem xs={12} md>
                    <p className={classes.text}>Rubrik</p>
                  </GridItem>
                  <GridItem xs={12} md>
                    <p className={classes.textValue}>{additionalData.rubrik}</p>
                  </GridItem>
                </GridContainer>
                <GridContainer justify={"space-between"}>
                  <GridItem xs={12} md>
                    <p className={classes.text}>Anak Rubrik</p>
                  </GridItem>
                  <GridItem xs={12} md>
                    <p className={classes.textValue}>
                      {additionalData.anakRubrik}
                    </p>
                  </GridItem>
                </GridContainer>
                <GridContainer justify={"space-between"}>
                  <GridItem xs={12} md>
                    <p className={classes.text}>Point SKP</p>
                  </GridItem>
                  <GridItem xs={12} md>
                    <p className={classes.textValue}>
                      {portofolioData.bobot_skp_portofolio}
                    </p>
                  </GridItem>
                </GridContainer>
                <GridContainer justify={"center"}>
                  <GridItem xs={10} style={{ margin: "2vh" }}>
                    <Divider />
                  </GridItem>
                </GridContainer>
                <GridContainer justify={"space-between"} alignItems={"center"}>
                  <GridItem zeroMinWidth>
                    <p className={classes.littleTitle}>Informasi Pemilik:</p>
                    <p className={classes.littleTitle}>{userData.nama}</p>
                    <p className={classes.text}>
                      {userData.prodi + " | " + userData.nim}
                    </p>
                    <p className={classes.bio}>{userData.bio}</p>
                  </GridItem>
                  <GridItem>
                    <Avatar
                      alt={userData.nama}
                      src={userData.avatar}
                      className={classes.avatar}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer justify={"center"}>
                  <GridItem>
                    {additionalData.link_bukti_ppk ? (
                      <Button
                        round
                        color={"warning"}
                        onClick={event => {
                          handleOpenPdfViewer(additionalData.link_bukti_ppk);
                          event.preventDefault();
                        }}
                      >
                        <PictureAsPdfIcon /> Bukti PPK
                      </Button>
                    ) : (
                      <></>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify={"space-around"}>
          <GridItem>
            <Button
              round
              color={"danger"}
              onClick={event => {
                handleSubmit(false);
                event.preventDefault();
              }}
            >
              Tolak
            </Button>
          </GridItem>
          <GridItem>
            <Button
              round
              color={"success"}
              onClick={event => {
                handleSubmit(true);
                event.preventDefault();
              }}
            >
              Verifikasi
            </Button>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <PdfViewer
        open={pdfViewer.open}
        link={pdfViewer.link}
        onClose={handleClosePdfViewer}
      />
    </Dialog>
  );
}

DialogVerifikasiPortofolio.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  portofolioData: PropTypes.object,
  portofolioID: PropTypes.number,
  openSnackbar: PropTypes.func
};
