import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";
import CardBody from "dep/Card/CardBody";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CardIcon from "dep/Card/CardIcon";
import CardFooter from "dep/Card/CardFooter";
import Button from "dep/CustomButtons/Button";
// components
import DialogChecklistRubrik from "components/User/CetakSKPI/Dialog/DialogChecklistRubrik";
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";
import PdfViewer from "components/Global/Dialog/PdfViewer";
import CardUnggahSKPI from "components/User/CetakSKPI/Card/CardUnggahSKPI";
import DialogPengajuanCetakSKPI from "components/User/CetakSKPI/Dialog/DialogPengajuanCetakSKPI";
// @material-ui/icons
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import PageviewIcon from "@material-ui/icons/Pageview";
import ClassIcon from "@material-ui/icons/Class";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import TimerOutlinedIcon from "@material-ui/icons/TimerOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle";
// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import DialogPembatalanCetakSKPI from "components/User/CetakSKPI/Dialog/DialogPembatalanCetakSKPI";

const useStyles = makeStyles({
  ...dashboardStyle,
  text: { margin: 0, fontSize: "13px", color: "#343434" },
  textNotice: {
    margin: 0,
    fontSize: "13px",
    color: "#ef5350",
    textAlign: "center"
  },
  textValue: {
    margin: 0,
    fontSize: "13px",
    fontStyle: "italic",
    color: "#4f4f4f",
    textAlign: "right"
  }
});

export default function CardCetakSKPI() {
  const classes = useStyles();
  useEffect(() => {
    handleFetchInformation();
  }, []);
  // var: values
  const [information, setInformation] = useState({
    rubrik_done: 0,
    rubrik_jumlah: 0,
    subrubrik_done: 0,
    subrubrik_jumlah: 0,
    portofolio: 0,
    point_skp: 0,
    tanggal: "",
    status: "",
    link: "",
    status_printed: false
  });
  const [linkPreview, setLinkPreview] = useState("");
  // var: open-close
  const [pdfOpen, togglePdf] = useState(false);
  const [openDialogChecklist, setOpenDialogChecklist] = useState(false);
  const [openDialogPengajuan, setOpenDialogPengajuan] = useState(false);
  const [openDialogPembatalan, setOpenDialogPembatalan] = useState(false);

  //handle: open-close
  const handleOpenDialogChecklist = () => {
    setOpenDialogChecklist(true);
  };
  const handleCloseDialogChecklist = () => {
    setOpenDialogChecklist(false);
  };
  const handleOpenDialogPengajuan = () => {
    setOpenDialogPengajuan(true);
  };
  const handleCloseDialogPengajuan = () => {
    handleFetchInformation();
    setOpenDialogPengajuan(false);
  };
  const handleOpenDialogPembatalan = () => {
    setOpenDialogPembatalan(true);
  };
  const handleCloseDialogPembatalan = () => {
    handleFetchInformation();
    setOpenDialogPembatalan(false);
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });
  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };
  // handle: API fetch
  const handleFetchInformation = async () => {
    // Get Statistik Portofolio
    let res = await Auth.fetch(BASE_URL + "/api/statistik/user-portofolio");
    let json = await res.json();
    let jumlah_skp = 0;
    let jumlah_portofolio = 0;
    _.get(json, "bidang", []).forEach(function(bidang) {
      jumlah_skp += parseInt(_.get(bidang, "statistik.bobot_skp", 0));
      jumlah_portofolio += _.get(bidang, "statistik.portofolio_count", 0);
    });

    // Get Statistik SKPI
    let skpi = await Auth.fetch(BASE_URL + "/api/skpi");
    skpi = await skpi.json();
    let subrubrik_terpenuhi = 0;
    let subrubrik_jumlah = 0;
    let rubrik_terpenuhi = 0;
    let rubrik_jumlah = 0;

    _.get(skpi, "data", []).forEach(function(_rubrik) {
      let total_subrubrik_dari_rubrik = 0;
      let subrubrik_terpenuhi_dari_rubrik = 0;
      _.get(_rubrik, "sub_rubrik", []).forEach(function(sub_rubrik) {
        subrubrik_terpenuhi_dari_rubrik +=
          parseInt(sub_rubrik.point_sub_rubrik_yang_terkumpul) >=
          parseInt(sub_rubrik.syarat_minimal_point_skp_sub_rubrik)
            ? 1
            : 0;
        total_subrubrik_dari_rubrik += 1;
      });
      subrubrik_terpenuhi += subrubrik_terpenuhi_dari_rubrik;
      subrubrik_jumlah += total_subrubrik_dari_rubrik;
      rubrik_jumlah++;
      if (
        _rubrik.point_rubrik_yang_terkumpul >=
        _rubrik.syarat_minimal_point_skp_rubrik
      )
        rubrik_terpenuhi++;
    });

    // Get status pengajuan
    let status_pengajuan = await Auth.fetch(BASE_URL + "/api/skpi/pengajuan");
    status_pengajuan = await status_pengajuan.json();
    // let status = status_pengajuan.data
    //   ? status_pengajuan.data.is_downloaded
    //     ? 1
    //     : 0
    //   : "";
    const status = _.get(status_pengajuan, "data.is_downloaded", "");
    // let link = status_pengajuan.data
    //   ? BASE_URL + "/" + status_pengajuan.data.path
    //   : "";
    const link = BASE_URL + "/" + _.get(status_pengajuan, "data.path", "/");

    // Get latest portofolio
    let portofolio = await Auth.fetch(BASE_URL + "/api/portofolio/latest");
    portofolio = (await portofolio.json()).portofolio;
    let tanggal = portofolio ? portofolio.tanggal_terbit : "";

    //TODO : rubrik
    setInformation({
      rubrik_done: rubrik_terpenuhi, // jumlah subrubrik yang pointnya udah diatas minimum dari masuk sampe sekarang
      rubrik_jumlah: rubrik_jumlah, // jumlah subrubrik yang pointnya udah diatas minimum dari masuk sampe sekarang
      subrubrik_done: subrubrik_terpenuhi, // jumlah subrubrik yang pointnya udah diatas minimum dari masuk sampe sekarang
      subrubrik_jumlah: subrubrik_jumlah, //jml subrubrik di prodinya
      portofolio: jumlah_portofolio,
      point_skp: jumlah_skp, //point total skp dari masuk sampe sekarang
      tanggal: tanggal, //tanggal terakhir upload portofolio
      link: link, //kalo dah upload file skpi(bertandatangan)
      status: status //status: "" kalau belum pernah ngajuin, 0 kalau menunggu, 1 kalau diterima
    });
  };
  const handleDownloadPreview = async () => {
    openSnackbar("info", "loading...");
    try {
      let res = await Auth.fetchMultipart(BASE_URL + "/api/skpi/preview", {
        method: "GET"
      });
      let data = await res.json();
      if (res.status === 200) {
        setLinkPreview(BASE_URL + "/" + data.message);
        togglePdf(true);
      } else {
        let result =
          typeof data.message === "string"
            ? data.message
            : Object.values(data.message).join("");
        openSnackbar("warning", result);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  // render
  const renderStatus = value => {
    let ret;
    switch (value) {
      case 0:
        ret = (
          <GridContainer alignItems={"center"}>
            <GridItem>
              <p className={classes.cardCategoryWhite}>Status Pengajuan : </p>
            </GridItem>
            <GridItem>
              <Button color={"warning"} round size={"sm"}>
                <TimerOutlinedIcon />
                Menunggu
              </Button>{" "}
            </GridItem>
          </GridContainer>
        );
        break;
      case 1:
        ret = (
          <GridContainer alignItems={"center"}>
            <GridItem>
              <p className={classes.cardCategoryWhite}>Status Pengajuan : </p>
            </GridItem>
            <GridItem>
              <Button color={"success"} round size={"sm"}>
                <CheckCircleOutlineOutlinedIcon />
                Diterima
              </Button>{" "}
            </GridItem>
          </GridContainer>
        );
        break;
      default:
        ret = <></>;
    }
    return ret;
  };
  const renderButton = value => {
    if (value === "")
      return (
        <Button color={"info"} round onClick={handleOpenDialogPengajuan}>
          Ajukan Cetak SKPI
        </Button>
      );
    else if (value === 0)
      return (
        <Button color={"danger"} round onClick={handleOpenDialogPembatalan}>
          Batalkan Cetak SKPI
        </Button>
      );
    else return <></>;
  };
  return (
    <GridContainer>
      <PdfViewer
        open={pdfOpen}
        link={linkPreview}
        onClose={() => togglePdf(false)}
      />
      <GridItem xs={12} xl={9}>
        <Card>
          <CardHeader color={"primary"}>
            <GridContainer justify={"space-between"} alignItems={"flex-end"}>
              <GridItem>
                <h4 className={classes.cardTitleWhite}>Pengajuan SKPI</h4>
                <p className={classes.cardCategoryWhite}>
                  Ajukan permintaan cetak SKPI
                </p>
              </GridItem>
              <GridItem>{renderStatus(information.status)}</GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <CustomSnackbar
              open={snackbarOpen}
              onClose={() => setSnackbarOpen(false)}
              message={snackbarData.message}
              severity={snackbarData.severity}
            />
            <GridContainer justify={"center"}>
              <GridItem xs={12} md={10}>
                <GridContainer justify={"space-between"}>
                  <GridItem xs={12} sm={6} md={7}>
                    <Card>
                      <CardHeader color={"info"}>
                        <h4 className={classes.cardTitleWhite}>
                          Informasi Pencapaian
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <GridContainer justify={"space-between"}>
                          <GridItem xs={12} md={6}>
                            <p className={classes.text}>Portofolio</p>
                          </GridItem>
                          <GridItem xs={12} md={6}>
                            <p className={classes.textValue}>
                              {information.portofolio}
                            </p>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"space-between"}>
                          <GridItem xs={12} md={6}>
                            <p className={classes.text}>Point SKP</p>
                          </GridItem>
                          <GridItem xs={12} md={6}>
                            <p className={classes.textValue}>
                              {information.point_skp}
                            </p>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"space-between"}>
                          <GridItem xs={12} md={6}>
                            <p className={classes.text}>Rubrik Terpenuhi</p>
                          </GridItem>
                          <GridItem xs={12} md={6}>
                            <p className={classes.textValue}>
                              {information.rubrik_done}
                            </p>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"space-between"}>
                          <GridItem xs={12} md={6}>
                            <p className={classes.text}>Sub-rubrik Terpenuhi</p>
                          </GridItem>
                          <GridItem xs={12} md={6}>
                            <p className={classes.textValue}>
                              {information.subrubrik_done}
                            </p>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          <EventOutlinedIcon />
                          Portofolio terakhir diunggah pada tanggal{" "}
                          {moment(information.tanggal).format("DD-MM-YYYY")}
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={5}>
                    <GridContainer>
                      <GridItem xs={12}>
                        <Card>
                          <CardHeader stats icon>
                            <CardIcon
                              color={
                                information.subrubrik_jumlah >
                                  information.subrubrik_done ||
                                information.rubrik_jumlah >
                                  information.rubrik_done
                                  ? "danger"
                                  : "success"
                              }
                            >
                              <InfoOutlinedIcon />
                            </CardIcon>
                            <p className={classes.cardCategory}>
                              Rubrik Terpenuhi / Total Rubrik
                            </p>
                            <h3 className={classes.cardTitle}>
                              {information.rubrik_done || 0} / {information.rubrik_jumlah || 0}
                            </h3>
                            <p className={classes.cardCategory}>
                              Subrubrik Terpenuhi / Total Subrubrik
                            </p>
                            <h3 className={classes.cardTitle}>
                              {information.subrubrik_done || 0} / {information.subrubrik_jumlah || 0}
                            </h3>
                          </CardHeader>
                          <CardBody>
                            <GridContainer justify={"center"}>
                              <GridItem>
                                <Button
                                  fullWidth
                                  color={"primary"}
                                  size={"sm"}
                                  round
                                  onClick={handleOpenDialogChecklist}
                                >
                                  <DoneAllOutlinedIcon />
                                  checklist
                                </Button>
                              </GridItem>
                            </GridContainer>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer justify={"center"}>
              <GridItem>
                <br></br>
                <p className={classes.textNotice}>
                  Anda dapat mengajukan permintaan untuk cetak SKPI jika
                  Portofolio yang anda kumpulan telah memenuhi syarat
                </p>
                <br></br>
              </GridItem>
            </GridContainer>
            <GridContainer justify={"center"}>
              <GridItem>
                <Button
                  fullWidth
                  color={"warning"}
                  size={"md"}
                  round
                  onClick={handleDownloadPreview}
                >
                  <PageviewIcon />
                  preview
                </Button>
              </GridItem>
              <GridItem>{renderButton(information.status)}</GridItem>
            </GridContainer>
          </CardBody>
          <DialogPengajuanCetakSKPI
            open={openDialogPengajuan}
            openSnackbar={openSnackbar}
            onClose={handleCloseDialogPengajuan}
          />
          <DialogChecklistRubrik
            open={openDialogChecklist}
            onClose={handleCloseDialogChecklist}
          />
          <DialogPembatalanCetakSKPI
            open={openDialogPembatalan}
            onClose={handleCloseDialogPembatalan}
            openSnackbar={openSnackbar}
          />
        </Card>
      </GridItem>
      {!!information.status && (
        <GridItem xs={12} xl={3}>
          <CardUnggahSKPI link={information.link} />
        </GridItem>
      )}
    </GridContainer>
  );
}
