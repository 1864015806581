/*eslint-disable*/
import React from "react";
//import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";
import {Link} from "react-router-dom";

const useStyles = makeStyles(styles);

export default function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Link to={"/"} className={classes.block}>
                Home
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="https://janabadra.ac.id/" target="_blank" className={classes.block}>
                Universitas Janabadra
              </a>
            </ListItem>
          </List>
        </div>
        <p className={classes.right}>
          <span>
            &copy; 2020{" "}
            <Link
              to="/about-us"
              target="_blank"
              className={classes.a}
            >
              JPT Team
            </Link>
          </span>
        </p>
      </div>
    </footer>
  );
}
