import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
// dep components
import GridItem from "dep/Grid/GridItem";
import Table from "dep/Table/Table";
import GridContainer from "dep/Grid/GridContainer";
import Button from "dep/CustomButtons/Button";
// @material-ui/icons
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import DialogChecklistSubRubrik from "components/User/CetakSKPI/Dialog/DialogChecklistSubRubrik";

export default function DialogChecklistRubrik(props) {
  useEffect(() => {
    //handle: API fetch
    const handleFetchData = async () => {
      //TODO: fetch data
      // preferredFormat: [
      //  ...,
      //  [<nama_Subrubrik>,<SKP_didapatkan>,<SKP minimum dari Subrubrik>],
      //  ...
      // ]

      let skpi = await Auth.fetch(BASE_URL + "/api/skpi");
      skpi = await skpi.json();
      let rubrik = [];

      _.get(skpi, "data", []).forEach(function(_rubrik) {
        let subRubrik = [];
        let subRubrikTerpenuhi = 0;
        _.get(_rubrik, "sub_rubrik", []).forEach(function(sub_rubrik) {
          subRubrik.push([
            sub_rubrik.sub_rubrik,
            sub_rubrik.point_sub_rubrik_yang_terkumpul,
            sub_rubrik.syarat_minimal_point_skp_sub_rubrik,
            renderStatus(
              sub_rubrik.point_sub_rubrik_yang_terkumpul >=
                sub_rubrik.syarat_minimal_point_skp_sub_rubrik,
              false
            )
          ]);
          if (
            sub_rubrik.point_sub_rubrik_yang_terkumpul >=
            sub_rubrik.syarat_minimal_point_skp_sub_rubrik
          )
            subRubrikTerpenuhi++;
        });
        subRubrik.sort(value => value[1] - value[2]);
        rubrik.push([
          _rubrik.rubrik,
          _rubrik.point_rubrik_yang_terkumpul,
          _rubrik.syarat_minimal_point_skp_rubrik,
          renderPercentage(subRubrikTerpenuhi, subRubrik.length),
          subRubrik
        ]);
      });

      rubrik.sort(value => value[1] - value[2]); //sort berdasarkan selisih dari skp didapatkan - skp minimun
      setTableData(
        rubrik.map(value => [
          value[0],
          value[1],
          value[2],
          value[3],
          <div style={{ textAlign: "center" }}>
            {renderStatus(
              value[1] >= value[2] && value[3] === "100%",
              value[4]
            )}
          </div>
        ])
      );
    };
    handleFetchData();
  }, []);
  //var: values
  const [tableData, setTableData] = useState([]);
  const [selectedSubRubrikList, setSelectedSubRubrikList] = useState([]);
  const [openDialogSubRubrik, setOpenDialogSubRubrik] = useState(false);

  //generator
  const renderStatus = (status, subRubrik) => {
    if (!subRubrik) {
      if (status)
        return (
          <Button color={"success"} size={"sm"} round>
            <CheckOutlinedIcon />
            Terpenuhi
          </Button>
        );
      else
        return (
          <Button color={"danger"} size={"sm"} round>
            <InfoOutlinedIcon />
            Kurang
          </Button>
        );
    } else {
      if (status)
        return (
          <Button
            color={"success"}
            size={"sm"}
            round
            onClick={event => {
              setSelectedSubRubrikList(subRubrik);
              setOpenDialogSubRubrik(true);
              event.preventDefault();
            }}
          >
            <CheckOutlinedIcon />
            Terpenuhi
          </Button>
        );
      else
        return (
          <Button
            color={"danger"}
            size={"sm"}
            round
            onClick={event => {
              setSelectedSubRubrikList(subRubrik);
              setOpenDialogSubRubrik(true);
              event.preventDefault();
            }}
          >
            <InfoOutlinedIcon />
            Kurang
          </Button>
        );
    }
  };
  const renderPercentage = (val, len) => {
    if (isNaN(val / len)) return "100%";
    else return Math.round((val / len) * 100) + "%";
  };
  // handle:open-close
  const handleClose = () => {
    props.onClose();
  };
  const handleCloseDialogSubRubrik = () => {
    setOpenDialogSubRubrik(false);
    setSelectedSubRubrikList([]);
  };
  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      scroll={"body"}
      fullWidth
      maxWidth={"md"}
    >
      <DialogChecklistSubRubrik
        open={openDialogSubRubrik}
        selectedRubrikList={selectedSubRubrikList}
        onClose={handleCloseDialogSubRubrik}
      />
      <DialogTitle>Checklist Pencapaian Portofolio</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Tabel mengenai pencapaian portofolio
        </DialogContentText>
        <GridContainer justify={"center"}>
          <GridItem xs={10}>
            <Table
              tableHeaderColor="primary"
              tableHead={[
                "Rubrik",
                "Point SKP",
                "Point SKP Min",
                "sub-Rubrik",
                <div style={{ textAlign: "center" }}>
                  Status <br /> (klik untuk detail)
                </div>
              ]}
              tableData={tableData}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
}

DialogChecklistRubrik.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
