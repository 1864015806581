import React from "react";
// dep components
import GridItem from "dep/Grid/GridItem.js";
import GridContainer from "dep/Grid/GridContainer.js";
// components
import CardPortofolioMain from "components/User/Portofolio/Card/CardPortofolioMain";

export default function Portofolio() {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardPortofolioMain />
      </GridItem>
    </GridContainer>
  );
}
