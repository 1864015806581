/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

// core components
import LandingPage from "layouts/LandingPage.js";
import OTP from "layouts/Otp.js";
import SearchPage from "layouts/SearchPage.js";
import Admin from "layouts/AdminDashboard.js";
import User from "layouts/UserDashboard.js";

import "assets/css/material-dashboard-react.css?v=1.8.0";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Register from "layouts/Register";
import Activation from "layouts/Activation";
import ForgotPassword from "layouts/ForgotPassword";
import ForgotPasswordNext from "layouts/ForgotPasswordNext";
import VerifikatorDashboard from "layouts/VerifikatorDashboard";
import Profile from "layouts/Profile";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/profile/:nim" component={Profile} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route path="/forgot-password/:token" component={ForgotPasswordNext} />
      <Route path="/activation/:token" component={Activation} />
      <Route path="/register" component={Register} />
      <Route path="/search/:query" component={SearchPage} />
      <Route path="/user" component={User} />
      <Route path="/admin" component={Admin} />
      <Route path="/verifikator" component={VerifikatorDashboard} />
      <Route path="/otp" component={OTP} />
      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);

// TODO: animation, tapi kegnya nyerah saia
// function AnimationRoutes() {
//   let location = useLocation();
//
//   return (
//     <>
//       <TransitionGroup>
//         <CSSTransition key={location.key} classNames="slide" timeout={500}>
//           <Switch location={location}>
//             <Route exact path="/user" component={User} />
//             <Route exact path="/admin" component={Admin} />
//             <Route exact path="/verifikator" component={VerifikatorDashboard} />
//             <Route exact path="/otp" component={OTP} />
//           </Switch>
//         </CSSTransition>
//       </TransitionGroup>
//       <Route path="/user/:a" component={User} />
//       <Route path="/admin/:a" component={Admin} />
//       <Route path="/verifikator/:a" component={VerifikatorDashboard} />
//     </>
//   );
// }
