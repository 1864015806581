import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// core components
import Button from "dep/CustomButtons/Button.js";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import auth from "helpers/auth";
import Avatar from "@material-ui/core/Avatar";
import Person from "@material-ui/icons/Person";
const useStyles = makeStyles(headerLinksStyle);

export default function AdminNavbarLinks(props) {
  const history = useHistory();
  const { avatar } = props;
  const classes = useStyles();
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const handleClickMenuItemProfile = () => {
    handleCloseProfile();
    //recreate onclick route
    history.push("profile");
  };
  const handleClickMenuDashboard = () => {
    history.push("dashboard");
  };
  const handleClickMenuItemLogout = () => {
    handleCloseProfile();
    auth.logout(history);
  };

  return (
    <div>
      <Button
        hidden={!(window.innerWidth > 959)}
        color="transparent"
        justIcon
        aria-label="Dashboard"
        className={classes.buttonLink}
        onClick={handleClickMenuDashboard}
      >
        <Dashboard className={classes.icons} />
      </Button>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Avatar
            hidden={!avatar}
            style={
              window.innerWidth > 959
                ? {
                    width: "24px",
                    height: "24px"
                  }
                : {
                    width: "24px",
                    height: "24px",
                    marginRight: "15px",
                    marginLeft: "-15px"
                  }
            }
            src={avatar}
          />
          <Person hidden={!!avatar} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleClickMenuItemProfile}
                      className={classes.dropdownItem}
                    >
                      Profile
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleClickMenuItemLogout}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
AdminNavbarLinks.propTypes = {
  avatar: PropTypes.string
};
