import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  IconButton,
  InputAdornment,
  Checkbox,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
// core components
import GridItem from "dep/Grid/GridItem.js";
import GridContainer from "dep/Grid/GridContainer.js";
import Table from "dep/Table/Table.js";
import Card from "dep/Card/Card.js";
import CardHeader from "dep/Card/CardHeader.js";
import CardBody from "dep/Card/CardBody.js";
import CustomInput from "dep/CustomInput/CustomInput";
import Button from "dep/CustomButtons/Button";
import ButtonFilterCetakSKPI from "components/Admin/CetakSKPI/Button/ButtonFilterCetakSKPI";
import DialogPrintSKPI from "components/Admin/CetakSKPI/Dialog/DialogPrintSKPI";
import DialogDeleteSKPI from "components/Admin/CetakSKPI/Dialog/DialogDeleteSKPI";
import DialogAdvancedPrintSKPI from "components/Admin/CetakSKPI/Dialog/DialogAdvancedPrintSKPI";
import DialogPeriodeKelulusan from "components/Admin/CetakSKPI/Dialog/DialogPeriodeKelulusan";
// @material-ui/icons components
import IconSearch from "@material-ui/icons/Search";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import PrintIcon from "@material-ui/icons/Print";
import Check from "@material-ui/icons/Check";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DeleteIcon from "@material-ui/icons/Delete";
// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle";
import cardStyles from "assets/jss/jpt/cardStyles";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import buttonsStyles from "assets/jss/jpt/buttonsStyles";
import PdfViewer from "../../../Global/Dialog/PdfViewer";

//styling
const useStyles = makeStyles({ ...cardStyles, ...buttonsStyles });
const styleCheckbox = makeStyles(checkboxAdnRadioStyle);

export default function CardCetakSKPI(props) {
  const classes = useStyles();
  const classesCheckbox = styleCheckbox();
  const { openSnackbar } = props;

  //var: values
  const [values, setValues] = useState({
    filter: {
      fakultas: [],
      prodi: Auth.getIdProdi() ? [Auth.getIdProdi()] : [],
      angkatan: "",
      lulus: 0
    },
    search: ""
  });
  const [checked, setChecked] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [listPeriode, setListPeriode] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [selectedToPrint, setSelectedToPrint] = useState([]);
  const [selectedToDelete, setSelectedToDelete] = useState();
  //var: open-close
  const [openPrintDialog, setOpenPrintDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openPeriodeKelulusanDialog, setOpenPeriodeKelulusanDialog] = useState(
    false
  );
  const [openAdvancedPrintDialog, setOpenAdvancedPrintDialog] = useState(false);
  const [previewPDF, setPreviewPDF] = useState({ link: "", open: false });

  useEffect(() => {
    const init = async () => {
      openSnackbar("info", "Memuat...");
      await handleFetchListMahasiswa();
      openSnackbar("success", "Ditemukan...");
    };
    init();
    // eslint-disable-next-line
  }, [page, props.listProdi, values]);

  useEffect(() => {
    handleFetchPeriode();
  }, []);

  const handleReFetch = () => {
    handleFetchListMahasiswa();
  };

  const handleFetchListMahasiswa = async () => {
    let url = `${BASE_URL}/api/manajemen/skpi/search?paginate=10&page=${page}`;

    url = values.filter.angkatan
      ? (url += "&angkatan=" + values.filter.angkatan.split("/")[0])
      : url;
    url = values.search ? (url += "&keyword=" + values.search) : url;
    url =
      values.filter.lulus !== ""
        ? (url += "&is_graduated=" + values.filter.lulus)
        : url;
    _.get(values, "filter.prodi", []).forEach((val, idx) => {
      url += `&id_prodi[${idx}]=${val}`;
    });
    _.get(values, "filter.fakultas", []).forEach((val, idx) => {
      url += `&id_fakultas[${idx}]=${val}`;
    });

    let resMahasiswa = await Auth.fetch(url);
    let mahasiswa = await resMahasiswa.json();

    setPageCount(_.get(mahasiswa, "users.last_page", 1));
    setTableData(
      _.uniqWith(
        checked
          .map(value => tableData.find(values => values[0] === value))
          .concat(
            _.get(mahasiswa, "users.data", []).map(e => {
              let nama_prodi =
                _.get(
                  props.listProdi[
                    _.sortedIndexBy(
                      props.listProdi,
                      { id_prodi: _.get(e, "user.id_prodi") },
                      "id_prodi"
                    )
                  ],
                  "nama_prodi",
                  "-"
                ) +
                " (" +
                _.get(
                  props.listProdi[
                    _.sortedIndexBy(
                      props.listProdi,
                      { id_prodi: _.get(e, "user.id_prodi") },
                      "id_prodi"
                    )
                  ],
                  "jenjang",
                  "-"
                ) +
                ")";
              return [
                _.get(e, "user.nim"),
                _.get(e, "user.nama"),
                nama_prodi,
                _.get(e, "user.tanggal_masuk", "").split("-")[0],
                _.get(e, "user.is_graduated", 0) ? "LULUS" : "BELUM LULUS"
              ];
            })
          ),
        _.isEqual
      )
    );
  };

  const handleFetchPeriode = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/manajemen/periode");
    let data = await res.json();

    setListPeriode(
      _.get(data, "periode", []).map(e => [
        e.id_periode_kelulusan,
        moment(e.tanggal, "YYYY-MM-DD").format("YYYY"),
        e.periode,
        moment(e.tanggal).format("DD-MM-YYYY")
      ])
    );
  };

  //handle: values
  const filterChange = (fakultas, prodi, angkatan, lulus) => {
    setValues({
      ...values,
      filter: {
        fakultas: fakultas,
        prodi: prodi,
        angkatan: angkatan,
        lulus: lulus
      }
    });
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangePage = (_e, page) => {
    setPage(page);
  };
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const handleToggleAll = () => {
    if (checkedAll()) setChecked([]);
    else {
      const newChecked = [];
      tableData.forEach(value => {
        newChecked.push(value[0]);
      });
      setChecked(newChecked);
    }
  };
  const checkedAll = () => {
    let ret = true;
    tableData.forEach(value => {
      if (checked.indexOf(value[0]) === -1) {
        ret = false;
        //break; //forEach ngga bisa break
      }
    });
    return ret;
  };
  const handlePrint = values => {
    setSelectedToPrint(values);
    setOpenPrintDialog(true);
  };
  const handleDelete = value => {
    setSelectedToDelete(value);
    setOpenDeleteDialog(true);
  };
  //handle: open-close
  const handleOpenAdvancedPrintDialog = () => {
    setOpenAdvancedPrintDialog(true);
  };
  const handleCloseAdvancedPrintDialog = () => {
    setOpenAdvancedPrintDialog(false);
  };
  const handleClosePrintDialog = () => {
    setOpenPrintDialog(false);
    setSelectedToPrint([]);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedToDelete([]);
  };
  const handleClosePeriodeKelulusanDialog = () => {
    setOpenPeriodeKelulusanDialog(false);
  };
  const handleClosePdfViewer = () => {
    setPreviewPDF({ link: "", open: false });
  };

  // helper:generator
  const GeneratePrintButton = value => (
    <Button
      size="sm"
      round
      color="success"
      onClick={event => {
        handlePrint([value]);
        event.preventDefault();
      }}
    >
      <PrintIcon />
      Cetak
    </Button>
  );

  const GenerateDeleteButton = value => (
    <Button
      size="sm"
      round
      onClick={event => {
        handleDelete(value);
        event.preventDefault();
      }}
      color="danger"
    >
      <DeleteIcon />
      Hapus
    </Button>
  );

  const handleDownloadPreview = async nim => {
    openSnackbar("info", "loading...");
    try {
      let res = await Auth.fetchMultipart(
        BASE_URL + "/api/manajemen/skpi/preview/" + nim,
        {
          method: "GET"
        }
      );
      let data = await res.json();
      if (res.status === 200) {
        return BASE_URL + "/" + data.message;
      } else {
        let result =
          typeof data.message === "string"
            ? data.message
            : Object.values(data.message).join("");
        openSnackbar("warning", result);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  const GeneratePreviewButton = nim => {
    return (
      <Button
        size="sm"
        color="primary"
        round
        onClick={async event => {
          let link = await handleDownloadPreview(nim);
          setPreviewPDF({ link: link, open: true });
          event.preventDefault();
        }}
      >
        <PictureAsPdfIcon />
        Preview
      </Button>
    );
  };
  const GenerateCheckBox = value => (
    <React.Fragment>
      <Checkbox
        checked={checked.indexOf(value) !== -1}
        // checked={false}
        tabIndex={-1}
        onClick={event => {
          handleToggle(value);
          event.preventDefault();
        }}
        checkedIcon={<Check className={classesCheckbox.checkedIcon} />}
        icon={<Check className={classesCheckbox.uncheckedIcon} />}
        classes={{
          checked: classesCheckbox.checked
        }}
      />
    </React.Fragment>
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <DialogPrintSKPI
            open={openPrintDialog}
            data={tableData}
            selectedValue={selectedToPrint}
            onClose={handleClosePrintDialog}
            onSuccess={handleReFetch}
            listPeriode={listPeriode}
            openSnackbar={openSnackbar}
          />
          <DialogDeleteSKPI
            open={openDeleteDialog}
            data={tableData}
            selectedValue={selectedToDelete}
            onClose={handleCloseDeleteDialog}
            onSuccess={handleReFetch}
            openSnackbar={openSnackbar}
          />
          <DialogAdvancedPrintSKPI
            open={openAdvancedPrintDialog}
            onClose={handleCloseAdvancedPrintDialog}
            onSuccess={handleReFetch}
            listFakultas={props.listFakultas}
            listProdi={props.listProdi}
            listPeriode={listPeriode}
            openSnackbar={openSnackbar}
          />
          <DialogPeriodeKelulusan
            open={openPeriodeKelulusanDialog}
            onClose={handleClosePeriodeKelulusanDialog}
            handleFetchPeriode={handleFetchPeriode}
            listPeriode={listPeriode}
            openSnackbar={openSnackbar}
          />
          <PdfViewer
            link={previewPDF.link}
            open={previewPDF.open}
            onClose={handleClosePdfViewer}
          />
          <CardHeader color="primary">
            <GridContainer justify={"space-between"} alignItems={"flex-end"}>
              <GridItem>
                <h4 className={classes.cardTitleWhite}>Cetak SKPI</h4>
                <p className={classes.cardCategoryWhite}>
                  Cetak Surat Keterangan Pendukung Ijazah
                </p>
              </GridItem>
              <GridItem>
                <Button
                  round
                  size={"sm"}
                  color={"white"}
                  onClick={handleOpenAdvancedPrintDialog}
                >
                  Advanced Print
                </Button>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridContainer alignItems="flex-end" justify={"center"}>
              <GridItem xs={12} md={8}>
                <CustomInput
                  onChange={handleChange("search")}
                  labelText="Cari mahasiswa"
                  id="mahasiswa"
                  onSubmit={handleFetchListMahasiswa}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleChange("search"),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          type={"submit"}
                          aria-label={"search"}
                          onClick={handleFetchListMahasiswa}
                        >
                          <IconSearch />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </GridItem>
              <GridItem>
                <GridContainer justify={"space-around"}>
                  <GridItem>
                    <ButtonFilterCetakSKPI
                      filterChange={filterChange}
                      filters={values.filter}
                      {...props}
                    />
                  </GridItem>
                  <GridItem>
                    <Button
                      color={"primary"}
                      onClick={event => {
                        setOpenPeriodeKelulusanDialog(true);
                        event.preventDefault();
                      }}
                    >
                      <AccessTimeIcon />
                      Periode
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    "Pilih",
                    "NIM",
                    "Nama",
                    "Program Studi",
                    "Angkatan",
                    "Kelulusan",
                    "Opsi"
                  ]}
                  // tableData={tableData}
                  tableData={tableData.map(value => [
                    GenerateCheckBox(value[0]),
                    ...value,
                    <>
                      {GeneratePreviewButton(value[0])}
                      {GeneratePrintButton(value[0])}
                      {GenerateDeleteButton(value[0])}
                    </>
                  ])}
                />
              </GridItem>
            </GridContainer>
            <GridContainer
              justify={window.innerWidth > 959 ? "space-between" : "center"}
            >
              <GridItem>
                <Button
                  round
                  color={checkedAll() ? "success" : "warning"}
                  onClick={handleToggleAll}
                  size={"sm"}
                >
                  <DoneAllIcon />
                  Pilih Semua
                </Button>
              </GridItem>
              <GridItem>
                <Button
                  //round
                  size={"sm"}
                  color={"info"}
                  disabled={checked.length === 0}
                  onClick={event => {
                    handlePrint(checked);
                    event.preventDefault();
                  }}
                >
                  <PrintIcon />
                  Cetak Pilihan
                </Button>
              </GridItem>
              <GridItem>
                <Pagination
                  count={pageCount}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  onChange={handleChangePage}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

CardCetakSKPI.propTypes = {
  listFakultas: PropTypes.array,
  listProdi: PropTypes.array,
  openSnackbar: PropTypes.func
};
