/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import PrintIcon from "@material-ui/icons/Print";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import SchoolIcon from '@material-ui/icons/School';

// core components/views/User for User layout
import DashboardPage from "views/User/Dashboard/Dashboard";
import Portofolio from "views/User/Portofolio/Portofolio";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Profile from "views/User/Profile/Profile";
import CetakSKPI from "views/User/CetakSKPI/CetakSKPI";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/user"
  },
  {
    path: "/portofolio",
    name: "Portofolio",
    icon: LibraryBooks,
    component: Portofolio,
    layout: "/user"
  },
  {
    path: "/skpi",
    name: "SKPI",
    icon: PrintIcon,
    component: CetakSKPI,
    layout: "/user"
  },
  {
    path: "/profile",
    name: "Profile",
    icon: AccountCircleIcon,
    component: Profile,
    layout: "/user"
  }
];

export default dashboardRoutes;
