import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import Dropzone from "react-dropzone";
// @material-ui/core components
import {
  CircularProgress,
  Collapse,
  Container,
  FormControl,
  FormHelperText,
  Hidden,
  IconButton,
  InputLabel,
  Tooltip
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";
import CardBody from "dep/Card/CardBody";
import CustomInput from "dep/CustomInput/CustomInput";
import Button from "dep/CustomButtons/Button";
import CustomSelect from "dep/CustomInput/CustomSelect";
import CardAvatar from "dep/Card/CardAvatar";
// components
import Navbar from "components/Public/Navbar/Navbar";
// styling
import userAccountStyles from "assets/jss/jpt/userAccountStyles";
// material-ui/icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import avatar from "assets/img/profile_picture.png";
import CloseIcon from "@material-ui/icons/Close";
// for API
import Auth from "helpers/auth";
import { formatDate } from "helpers/inputFormatting";
import { BASE_URL } from "const";

const useStyles = makeStyles(userAccountStyles);

const MAX_IMAGE_SIZE = 2000000;
const ACCEPT_IMAGE_TYPE = "image/png, image/jpg, image/gif, image/jpeg";

export default function Activation(props) {
  const history = useHistory();

  const { token } = props.match.params;
  const classes = useStyles();

  useEffect(() => {
    handleVerifyEmail();
    handleFetchListKewarganegaraan();
  }, []);

  // var: open-close
  const [page, setPage] = useState(0);
  const [addSocMed, setAddSocMed] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });

  // var: list
  const [listKewarganegaraan, setListKewarganegaraan] = useState([]);
  const [listProvince, setListProvince] = useState([]);
  const [listCity, setListCity] = useState([]);

  // var: values
  const [previewImage, setPreviewImage] = useState(avatar);
  const [customSocmed, setCustomSocmed] = useState("");
  const [socialMedia, setSocialMedia] = useState({
    title: "",
    name: "",
    link: ""
  });
  const [values, setValues] = useState({
    profile_picture: null,
    kewarganegaraan: "",
    nik: "",
    agama: "",
    gender: "",
    alamat: "",
    city: "",
    province: "",
    tempat_lahir: "",
    tanggal_lahir: null,
    kode_pos: "",
    no_telepon: "",
    no_hp: "",
    bio: "",
    containerProvinsiStyle: {
      display: "none"
    },
    social_media: [],
    loading: true,
    verify_email: false,
    message: "",
    requestStatus: true
  });

  const [errors, setErrors] = useState({
    profile_picture_error: "",
    kewarganegaraan_error: "",
    nik_error: "",
    agama_error: "",
    gender_error: "",
    alamat_error: "",
    city_error: "",
    province_error: "",
    tempat_lahir_error: "",
    tanggal_lahir_error: "",
    kode_pos_error: "",
    no_telepon_error: "",
    no_hp_error: "",
    bio_error: "",
    socmed_link_error: ""
  });

  // Validation
  const validateProfilePicture = () => {
    // TODO
    return "";
  };

  const validateKewarganegaraan = () => {
    let kewarganegaraanError = "";
    if (!values.kewarganegaraan) {
      kewarganegaraanError = "Please choose one of the options";
    }
    return kewarganegaraanError;
  };

  const validateNik = () => {
    let nikError = "";
    if (!values.nik) {
      nikError = "This field is required";
    }
    return nikError;
  };

  const validateAgama = () => {
    let agamaError = "";
    if (!values.agama) {
      agamaError = "Please choose one of the options";
    }
    return agamaError;
  };

  const validateGender = () => {
    let genderError = "";
    if (values.gender !== 0 && values.gender !== 1) {
      genderError = "Please choose one of the option";
    }
    return genderError;
  };

  const validateAlamat = () => {
    let alamatError = "";
    if (!values.alamat) {
      alamatError = "This field is required";
    }
    return alamatError;
  };

  const validateCity = () => {
    let cityError = "";
    if (values.kewarganegaraan === 102 && !values.city) {
      cityError = "Please choose one of the option";
    }
    return cityError;
  };

  const validateProvince = () => {
    let provinceError = "";
    if (values.kewarganegaraan === 102 && !values.province) {
      provinceError = "Please choose one of the option";
    }
    return provinceError;
  };

  const validateTempatLahir = () => {
    let tempatLahirError = "";
    if (!values.tempat_lahir) {
      tempatLahirError = "This field is required";
    }
    return tempatLahirError;
  };

  const validateTanggalLahir = () => {
    let tanggalLahirError = "";
    if (!values.tanggal_lahir) {
      tanggalLahirError = "This field is required";
    }
    return tanggalLahirError;
  };

  const validateKodePos = () => {
    return "";
  };

  const validateNoTelp = () => {
    const reg = /^(\(?\+?[0-9]*\)?)?[0-9_\- ()]*$/;
    let noTelpError = "";
    if (!reg.test(values.no_telepon)) {
      noTelpError = "Please input a valid phone number";
    }
    return noTelpError;
  };

  const validateNoHp = () => {
    const reg = /^(\(?\+?[0-9]*\)?)?[0-9_\- ()]*$/;
    let noTelpError = "";
    if (!reg.test(values.no_hp)) {
      noTelpError = "Please input a valid phone number";
    }
    return noTelpError;
  };

  const validateSocmedLink = () => {
    const reg = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    if (!socialMedia.link) {
      return "This field is required";
    }
    if (!reg.test(socialMedia.link)) {
      return "Please enter a valid link";
    }
    return "";
  };

  const validateBio = () => {
    return "";
  };

  const validateSecondPage = () => {
    return {
      profile_picture_error: validateProfilePicture(),
      kewarganegaraan_error: validateKewarganegaraan(),
      agama_error: validateAgama(),
      gender_error: validateGender(),
      province_error: validateProvince(),
      city_error: validateCity(),
      nik_error: validateNik(),
      tempat_lahir_error: validateTempatLahir(),
      tanggal_lahir_error: validateTanggalLahir(),
      alamat_error: validateAlamat(),
      kode_pos_error: validateKodePos(),
      no_telepon_error: validateNoTelp()
    };
  };

  const validateThirdPage = () => {
    return {
      no_hp_error: validateNoHp(),
      bio_error: validateBio()
    };
  };

  // handle: open-close
  const handleOpenCloseAddSocMed = () => {
    setAddSocMed(!addSocMed);
    setSocialMedia({
      title: "",
      name: "",
      link: ""
    });
  };
  const handleNextPage = () => {
    if (page === 1) {
      let errorsOnSecondPage = validateSecondPage();
      setErrors({ ...errors, ...errorsOnSecondPage });
      let isValid = true;
      for (let err in errorsOnSecondPage) {
        if (errorsOnSecondPage[err]) {
          isValid = false;
          break;
        }
      }
      if (isValid) {
        setPage(page + 1);
      }
    } else if (page === 2) {
      let errorsOnThirdPage = validateThirdPage();
      setErrors({ ...errors, ...errorsOnThirdPage });
      let isValid = true;
      for (let err in errorsOnThirdPage) {
        if (errorsOnThirdPage[err]) {
          isValid = false;
          break;
        }
      }
      if (isValid) {
        setPage(page + 1);
        handleSubmit();
      }
    } else {
      setPage(page + 1);
    }
  };
  const handlePrevPage = () => {
    setPage(page - 1);
  };
  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };

  const handleVerifyEmail = async () => {
    try {
      let url = atob(token.replace(/_/g, "/").replace(/-/g, "+"));
      let res = await Auth.fetch(url);
      if (res.status >= 200 && res.status < 300) {
        let data = await res.json();
        let bearerToken = _.get(data, "bearerToken", "");
        let message = _.get(data, "message", "");
        localStorage.setItem("token", bearerToken);
        setValues({
          ...values,
          verify_email: "success",
          loading: false,
          message,
          requestStatus: true
        });
      } else {
        let data = await res.json();
        let message = _.get(data, "message", "Terjadi kesalahan");
        if (message === "Akun telah diverifikasi") {
          let bearerToken = _.get(data, "bearerToken", "");
          localStorage.setItem("token", bearerToken);
          setValues({
            ...values,
            verify_email: "success",
            loading: false,
            message,
            requestStatus: true
          });
        } else {
          setValues({
            ...values,
            verify_email: "failed",
            loading: false,
            requestStatus: false,
            message
          });
        }
      }
    } catch (err) {
      setValues({
        ...values,
        verify_email: "failed",
        loading: false,
        requestStatus: false,
        message: "Terjadi kesalahan, pastikan token yang diberikan benar"
      });
    }
  };
  const handleFetchListKewarganegaraan = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/lokasi/negara");
    let data = await res.json();
    setListKewarganegaraan(
      _.get(data, "negara", []).map(e => {
        return {
          value: _.get(e, "id_negara"),
          text: _.get(e, "nama_negara")
        };
      })
    );
  };
  const handleFetchListProvince = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/lokasi/negara/102");
    let data = await res.json();
    setListProvince(
      _.get(data, "provinsi", []).map(e => {
        return {
          value: _.get(e, "id_provinsi"),
          text: _.get(e, "nama_provinsi")
        };
      })
    );
  };
  const handleFetchListCity = async id_provinsi => {
    let res = await Auth.fetch(
      BASE_URL + "/api/lokasi/provinsi/" + id_provinsi
    );
    let data = await res.json();
    setListCity(
      _.get(data, "kota", []).map(e => {
        return {
          value: _.get(e, "id_kota"),
          text: _.get(e, "nama_kota")
        };
      })
    );
  };

  // handle: API submit
  const handleSubmit = async () => {
    await setValues({
      ...values,
      loading: true,
      message: "Sedang mengirim data",
      requestStatus: true
    });
    const formData = new FormData();
    // Isian wajib
    formData.append("kewarganegaraan", values.kewarganegaraan);
    formData.append("nik", values.nik);
    formData.append("jenis_kelamin", values.gender);
    formData.append("tempat_lahir", values.tempat_lahir);
    formData.append("tanggal_lahir", formatDate(values.tanggal_lahir));
    formData.append("agama", values.agama);
    formData.append("alamat", values.alamat);

    // Isian opsional
    if (values.province != null) {
      formData.append("id_provinsi", values.province);
    }
    if (values.city != null) {
      formData.append("id_kota", values.city);
    }
    if (values.no_hp.length > 0) {
      formData.append("no_hp", values.no_hp);
    }
    if (values.no_telepon.length > 0) {
      formData.append("no_telepon_asal", values.no_telepon);
    }
    if (values.kode_pos.length > 0) {
      formData.append("kode_pos", values.kode_pos);
    }
    if (values.bio.length > 0) {
      formData.append("bio", values.bio);
    }
    if (values.social_media.length > 0) {
      formData.append("sosmed", JSON.stringify(values.social_media));
    }

    if (values.profile_picture !== null) {
      formData.append("foto_profil", values.profile_picture);
    }

    let res = await Auth.fetchMultipart(BASE_URL + "/api/auth/activate", {
      method: "POST",
      body: formData
    });

    if (res.status >= 200 && res.status < 300) {
      let data = await res.json();
      let user = data.user;
      setTimeout(() => {
        history.push("/user/dashboard");
      }, 5000);
      setValues({
        ...values,
        loading: false,
        message: JSON.stringify(user),
        requestStatus: true
      });
    } else {
      setValues({
        ...values,
        loading: false,
        message: "Terjadi kesalahan",
        requestStatus: false
      });
    }
  };

  // handle: image
  const verifyImage = file => {
    if (!ACCEPT_IMAGE_TYPE.includes(file.type)) {
      openSnackbar(
        "error",
        "Tipe file yang diupload tidak sesuai!\nTipe file yang diterima jpg/jpeg/png/gif"
      );
      return false;
    } else if (file.size > MAX_IMAGE_SIZE) {
      openSnackbar("error", "File yang diupload tidak boleh lebih dari 2MB!");
      return false;
    }
    return true;
  };
  const handleDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      verifyImage(rejectedFiles[0]);
    }

    if (acceptedFiles.length > 0) {
      if (verifyImage(acceptedFiles[0])) {
        setValues({ ...values, profile_picture: acceptedFiles[0] });

        // Change to base64 to show preview
        const reader = new FileReader();
        reader.onload = event => {
          setPreviewImage(event.target.result);
        };
        reader.readAsDataURL(acceptedFiles[0]);
      }
    }
  };

  // handle: values
  const handleChangeValues = event => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  const handleChangeDate = date => {
    setValues({ ...values, tanggal_lahir: date });
  };
  const handleChangeSocialMedia = value => event => {
    setSocialMedia({ ...socialMedia, [value]: event.target.value });
  };
  const handleChangeSelectKewarganegaraan = event => {
    if (event.target.value === 102) {
      setValues({
        ...values,
        kewarganegaraan: event.target.value,
        containerProvinsiStyle: {
          display: "flex"
        }
      });
      handleFetchListProvince();
    } else {
      setValues({
        ...values,
        kewarganegaraan: event.target.value,
        province: "",
        city: "",
        containerProvinsiStyle: {
          display: "none"
        }
      });
    }
  };
  const handleChangeSelectProvince = event => {
    setValues({ ...values, province: event.target.value });
    handleFetchListCity(event.target.value);
  };
  const handleChangeSelectCity = event => {
    setValues({ ...values, city: event.target.value });
  };
  const handleDeleteSocMed = index => {
    let tmp = values.social_media;
    tmp.splice(index, 1);
    setValues({
      ...values,
      social_media: tmp
    });
  };
  const handleSaveNewSocMed = () => {
    if (!validateSocmedLink() && !errors.socmed_link_error) {
      setValues({
        ...values,
        social_media: [
          ...values.social_media,
          socialMedia.title === "Other"
            ? {
                ...socialMedia,
                title: customSocmed
              }
            : socialMedia
        ]
      });
      setSocialMedia({
        title: "",
        name: "",
        link: ""
      });
      setCustomSocmed("");
      handleOpenCloseAddSocMed();
    }
  };

  const TooltipDeleteSocMed = index => {
    return (
      <Tooltip
        id="tooltip-top"
        title="Hapus Social Media"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="Hapus Social Media"
          className={classes.tableActionButton}
          onClick={event => {
            handleDeleteSocMed(index);
            event.preventDefault();
          }}
        >
          <CloseIcon
            className={classes.tableActionButtonIcon + " " + classes.close}
          />
        </IconButton>
      </Tooltip>
    );
  };

  const EmailVerification = () => {
    if (values.loading) {
      return (
        <div>
          <GridContainer justify={"center"}>
            <GridItem>
              <h3 className={classes.success}>Memeriksa tautan</h3>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem>
              <CircularProgress />
            </GridItem>
          </GridContainer>
        </div>
      );
    } else if (values.requestStatus) {
      return (
        <div>
          <GridContainer justify={"center"}>
            <GridItem>
              <h3 className={classes.success}>Aktivasi Akun</h3>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem>
              <center>
                <p className={classes.successText}>
                  Lanjutkan proses aktivasi akun dengan melengkapi profil Anda.
                  <br />
                  Silahkan klik tombol di bawah.
                </p>
              </center>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem>
              <Button color={"success"} onClick={handleNextPage}>
                Next
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      );
    } else {
      return (
        <div>
          <GridContainer justify={"center"}>
            <GridItem>
              <h3 className={classes.failed}>Failed</h3>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem>
              <p className={classes.successText}>{values.message}</p>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  };

  const UserProfile = () => {
    // TODO:
    // tampilkan profil user
    if (values.loading) {
      return (
        <div>
          <GridContainer justify={"center"}>
            <GridItem>
              <h3 className={classes.success}>{values.message}</h3>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem>
              <CircularProgress />
            </GridItem>
          </GridContainer>
        </div>
      );
    } else if (values.requestStatus) {
      return (
        <div>
          <GridContainer justify={"center"}>
            <GridItem>
              <h3 className={classes.success}>Success</h3>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem>
              <p className={classes.successText}>
                Akun Anda telah aktif dan dapat digunakan. Anda akan dialihkan
                ke halaman dashboard. Apabila tidak, klik{" "}
                <Link to="/user">di sini</Link>
              </p>
            </GridItem>
          </GridContainer>
        </div>
      );
    } else {
      return (
        <div>
          <GridContainer justify={"center"}>
            <GridItem>
              <h3 className={classes.failed}>Failed</h3>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem>
              <p className={classes.successText}>{values.message}</p>
            </GridItem>
          </GridContainer>
          <GridContainer justify={"center"}>
            <GridItem>
              <Button color={"success"} onClick={handlePrevPage}>
                Prev
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  };

  return (
    <div className={classes.background}>
      <Helmet>
        <title>Aktivasi Akun - Janabadra Professional Tracker</title>
        <meta
          name="description"
          content="Janabadra Professional Tracker, Sistem Data Portofolio Pendamping Ijazah, Universitas Janabadra"
        />
      </Helmet>
      <Navbar />
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
      />
      <Container>
        <GridContainer>
          <Hidden xsDown smDown>
            <div style={{ padding: "7vh" }} />
          </Hidden>
        </GridContainer>
        <GridContainer justify={"center"} alignItems={"center"}>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer justify={"space-between"}>
              <GridItem xs={12} sm={12} md={5}>
                <div style={{ padding: "1vh" }} />
                <h1 className={classes.cardTitleWhite}>Information</h1>
                <div style={{ padding: "1vh" }} />
                <p className={classes.cardCategoryWhite}>
                  JanaProTrack adalah Sistem Data Portofolio Pendamping Ijazah
                  yang digunakan untuk melakukan proses input data kegiatan
                  mahasiswa yang dicatatkan dalam luaran berbentuk Surat
                  Keterangan Pendamping Ijazah (SKPI). Mahasiswa setiap Program
                  Studi harus mencermati rubrik SKPI yang diterbitkan oleh
                  masing-masing Program Studi agar mengetahui kegiatan apa saja
                  yang bisa masuk ke dalam rubrik dan bagaimana persyaratan
                  untuk mendapatkan dokumen rekognisinya. Setiap mahasiswa wajib
                  mengisi portofolio aktivitasnya dalam sistem ini untuk
                  mendapatkan Surat Keterangan Pendamping Ijazah (SKPI).
                </p>
                <div style={{ padding: "1vh" }} />
                <GridContainer justify={"space-around"}>
                  <GridItem>
                    <Button round color={"success"} href={"/"}>
                      account already activated
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      round
                      color={"info"}
                      href={"/guide"}
                      target="_blank"
                    >
                      <HelpOutlineIcon />
                      Guide
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <CardHeader color={"info"}>
                    <h4 className={classes.cardTitleWhite}>
                      {page === 0 || page === 3 ? "Aktivasi" : "Biodata"}
                    </h4>
                    <p className={classes.cardCategoryWhite}>
                      {page === 0 || page === 3
                        ? "Aktivasi Akun"
                        : "Harap Isi Biodata"}
                    </p>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <Collapse in={page === 0}>
                        <EmailVerification />
                      </Collapse>
                      <Collapse in={page === 1}>
                        <GridContainer justify={"center"}>
                          <GridItem style={{ marginTop: "20px" }}>
                            <InputLabel
                              style={{ color: "#AAAAAA", marginBottom: "65px" }}
                            >
                              Upload Foto Profil
                            </InputLabel>
                            <Dropzone
                              onDrop={handleDrop}
                              accept={ACCEPT_IMAGE_TYPE}
                              multiple={false}
                              maxSize={MAX_IMAGE_SIZE}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                  <CardAvatar profile>
                                    <a
                                      href="#profile"
                                      onClick={e => e.preventDefault()}
                                    >
                                      <img
                                        src={previewImage}
                                        alt="profile_picture"
                                      />
                                    </a>
                                  </CardAvatar>
                                  <input {...getInputProps()} />
                                </div>
                              )}
                            </Dropzone>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem xs={10} md={4}>
                            <CustomSelect
                              labelText="Kewarganegaraan"
                              id="kewarganegaraan"
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={!!errors.kewarganegaraan_error}
                              helperText={errors.kewarganegaraan_error}
                              selectProps={{
                                id: "kewarganegaraan",
                                name: "kewarganegaraan",
                                onChange: handleChangeSelectKewarganegaraan,
                                onBlur: () => {
                                  setErrors({
                                    ...errors,
                                    kewarganegaraan_error: validateKewarganegaraan()
                                  });
                                },
                                onFocus: () => {
                                  setErrors({
                                    ...errors,
                                    kewarganegaraan_error: ""
                                  });
                                }
                              }}
                              selectItems={listKewarganegaraan}
                              selectValue={values.kewarganegaraan}
                            />
                          </GridItem>
                          <GridItem xs={10} md={6}>
                            <Collapse in={Boolean(values.kewarganegaraan)}>
                              <CustomInput
                                labelText="NIK / Passport Number"
                                id="nik"
                                name="citizenshipID"
                                formControlProps={{
                                  fullWidth: true
                                }}
                                error={!!errors.nik_error}
                                helperText={errors.nik_error}
                                inputProps={{
                                  name: "nik",
                                  value: values.nik,
                                  onChange: handleChangeValues,
                                  onBlur: () => {
                                    setErrors({
                                      ...errors,
                                      nik_error: validateNik()
                                    });
                                  },
                                  onFocus: () => {
                                    setErrors({ ...errors, nik_error: "" });
                                  }
                                }}
                              />
                            </Collapse>
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          style={values.containerProvinsiStyle}
                          justify={"center"}
                          alignItems={"flex-start"}
                        >
                          <GridItem xs={10} sm={10} md={5}>
                            <CustomSelect
                              labelText="Provinsi"
                              id="province"
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={!!errors.province_error}
                              helperText={errors.province_error}
                              selectProps={{
                                id: "province",
                                name: "province",
                                onChange: handleChangeSelectProvince,
                                onBlur: () => {
                                  setErrors({
                                    ...errors,
                                    province_error: validateProvince()
                                  });
                                },
                                onFocus: () => {
                                  setErrors({ ...errors, province_error: "" });
                                }
                              }}
                              selectItems={listProvince}
                              selectValue={values.province}
                            />
                          </GridItem>
                          <GridItem xs={10} sm={10} md={5}>
                            <CustomSelect
                              labelText="Kota"
                              id="city"
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={!!errors.city_error}
                              helperText={errors.city_error}
                              selectProps={{
                                id: "city",
                                name: "city",
                                onChange: handleChangeSelectCity,
                                onBlur: () => {
                                  setErrors({
                                    ...errors,
                                    city_error: validateCity()
                                  });
                                },
                                onFocus: () => {
                                  setErrors({ ...errors, city_error: "" });
                                }
                              }}
                              selectItems={listCity}
                              selectValue={values.city}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem xs={10} md={5}>
                            <CustomSelect
                              labelText="Agama"
                              id="agama"
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={!!errors.agama_error}
                              helperText={errors.agama_error}
                              selectProps={{
                                id: "agama",
                                name: "agama",
                                onChange: handleChangeValues,
                                onBlur: () => {
                                  setErrors({
                                    ...errors,
                                    agama_error: validateAgama()
                                  });
                                },
                                onFocus: () => {
                                  setErrors({ ...errors, agama_error: "" });
                                }
                              }}
                              selectItems={[
                                { value: "Islam", text: "Islam" },
                                { value: "Kristen", text: "Kristen" },
                                { value: "Katolik", text: "Katolik" },
                                { value: "Hindu", text: "Hindu" },
                                { value: "Buddha", text: "Buddha" },
                                { value: "Konghucu", text: "Konghucu" }
                              ]}
                              selectValue={values.agama}
                            />
                          </GridItem>
                          <GridItem xs={10} md={5}>
                            <CustomSelect
                              labelText="Jenis Kelamin"
                              id="gender"
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={!!errors.gender_error}
                              helperText={errors.gender_error}
                              selectProps={{
                                id: "gender",
                                name: "gender",
                                onChange: handleChangeValues,
                                onBlur: () => {
                                  setErrors({
                                    ...errors,
                                    gender_error: validateGender()
                                  });
                                },
                                onFocus: () => {
                                  setErrors({ ...errors, gender_error: "" });
                                }
                              }}
                              selectItems={[
                                { value: 0, text: "Laki-laki" },
                                { value: 1, text: "Perempuan" }
                              ]}
                              selectValue={values.gender}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem xs={10} md={5}>
                            <CustomInput
                              labelText="Tempat Lahir"
                              id="tempat-lahir"
                              name="tempat-lahir"
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={!!errors.tempat_lahir_error}
                              helperText={errors.tempat_lahir_error}
                              inputProps={{
                                name: "tempat_lahir",
                                value: values.tempat_lahir,
                                onChange: handleChangeValues,
                                onBlur: () => {
                                  setErrors({
                                    ...errors,
                                    tempat_lahir_error: validateTempatLahir()
                                  });
                                },
                                onFocus: () => {
                                  setErrors({
                                    ...errors,
                                    tempat_lahir_error: ""
                                  });
                                }
                              }}
                            />
                          </GridItem>
                          <GridItem xs={10} md={5}>
                            <div style={{ padding: "13.5px" }} />
                            <FormControl
                              className={classes.formControl}
                              error={!!errors.tanggal_lahir_error}
                            >
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  id="tanggal_lahir"
                                  label="Tanggal Lahir"
                                  format="dd/MM/yyyy"
                                  value={values.tanggal_lahir}
                                  onChange={date => handleChangeDate(date)}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date"
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                              <FormHelperText style={{ color: "red" }}>
                                {errors.tanggal_lahir_error}
                              </FormHelperText>
                            </FormControl>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem xs={10}>
                            <CustomInput
                              labelText="Alamat"
                              id="alamat"
                              name="alamat"
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={!!errors.alamat_error}
                              helperText={errors.alamat_error}
                              inputProps={{
                                name: "alamat",
                                value: values.alamat,
                                multiline: true,
                                rows: 3,
                                onChange: handleChangeValues,
                                onBlur: () => {
                                  setErrors({
                                    ...errors,
                                    alamat_error: validateAlamat()
                                  });
                                },
                                onFocus: () => {
                                  setErrors({ ...errors, alamat_error: "" });
                                }
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem xs={10} md={4}>
                            <CustomInput
                              labelText="Kode Pos"
                              id="kode_pos"
                              name="kode_pos"
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={!!errors.kode_pos_error}
                              helperText={errors.kode_pos_error}
                              inputProps={{
                                name: "kode_pos",
                                value: values.kode_pos,
                                onChange: handleChangeValues,
                                onBlur: () => {
                                  setErrors({
                                    ...errors,
                                    kode_pos_error: validateKodePos()
                                  });
                                },
                                onFocus: () => {
                                  setErrors({ ...errors, kode_pos_error: "" });
                                }
                              }}
                            />
                          </GridItem>
                          <GridItem xs={10} md={6}>
                            <CustomInput
                              labelText="No Telepon"
                              id="telephone"
                              name="telephone"
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={!!errors.no_telepon_error}
                              helperText={errors.no_telepon_error}
                              inputProps={{
                                name: "no_telepon",
                                value: values.no_telepon,
                                onChange: handleChangeValues,
                                onBlur: () => {
                                  setErrors({
                                    ...errors,
                                    no_telepon_error: validateNoTelp()
                                  });
                                },
                                onFocus: () => {
                                  setErrors({
                                    ...errors,
                                    no_telepon_error: ""
                                  });
                                }
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem>
                            <Button color={"success"} onClick={handleNextPage}>
                              Next
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </Collapse>
                      <Collapse in={page === 2}>
                        <GridContainer justify={"center"}>
                          <GridItem xs={8}>
                            <CustomInput
                              labelText="No Handphone"
                              id="phone"
                              name="phone"
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={!!errors.no_hp_error}
                              helperText={errors.no_hp_error}
                              inputProps={{
                                name: "no_hp",
                                value: values.no_hp,
                                onChange: handleChangeValues,
                                onBlur: () => {
                                  setErrors({
                                    ...errors,
                                    no_hp_error: validateNoHp()
                                  });
                                },
                                onFocus: () => {
                                  setErrors({ ...errors, no_hp_error: "" });
                                }
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem xs={8}>
                            <CustomInput
                              labelText="Bio"
                              id="bio"
                              name="bio"
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={!!errors.bio_error}
                              helperText={errors.bio_error}
                              inputProps={{
                                name: "bio",
                                value: values.bio,
                                multiline: true,
                                rows: 2,
                                onChange: handleChangeValues,
                                onBlur: () => {
                                  setErrors({
                                    ...errors,
                                    bio_error: validateBio()
                                  });
                                },
                                onFocus: () => {
                                  setErrors({ ...errors, bio_error: "" });
                                }
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem>
                            <div style={{ padding: "2vh" }} />
                            <p className={classes.successText}>Social Media</p>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem xs={8}>
                            {values.social_media.map((value, index) => (
                              <React.Fragment key={index}>
                                <GridContainer justify={"space-between"}>
                                  <GridItem>
                                    <p className={classes.successText}>
                                      {value.title}
                                    </p>
                                  </GridItem>
                                  <GridItem>
                                    <a
                                      href={value.link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {value.name}
                                    </a>
                                    {TooltipDeleteSocMed(index)}
                                  </GridItem>
                                </GridContainer>
                              </React.Fragment>
                            ))}
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem xs={10}>
                            <Collapse in={!addSocMed}>
                              <GridContainer justify={"center"}>
                                <GridItem>
                                  <Button
                                    round
                                    size={"sm"}
                                    color={"info"}
                                    onClick={handleOpenCloseAddSocMed}
                                  >
                                    add
                                  </Button>
                                </GridItem>
                              </GridContainer>
                            </Collapse>
                            <Collapse in={addSocMed}>
                              <GridContainer justify={"center"}>
                                <GridItem xs={10} md={4}>
                                  <CustomSelect
                                    labelText="Social Media"
                                    id="socmed_title"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    selectProps={{
                                      id: "socmed_title",
                                      name: "socmed_title",
                                      onChange: handleChangeSocialMedia("title")
                                    }}
                                    selectItems={[
                                      { value: "Facebook", text: "Facebook" },
                                      { value: "Twitter", text: "Twitter" },
                                      { value: "LinkedIn", text: "LinkedIn" },
                                      { value: "Instagram", text: "Instagram" },
                                      { value: "Other", text: "Other" }
                                    ]}
                                    selectValue={socialMedia.title}
                                  />
                                </GridItem>
                                <GridItem
                                  xs={10}
                                  md={6}
                                  style={
                                    socialMedia.title === "Other"
                                      ? { visibility: "hidden" }
                                      : { visibility: "visible" }
                                  }
                                >
                                  <CustomInput
                                    labelText="Nama"
                                    id="socmed_name"
                                    name="socmed_name"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      name: "socmed_name",
                                      value: socialMedia.name,
                                      onChange: handleChangeSocialMedia("name")
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                              {socialMedia.title === "Other" ? (
                                <GridContainer justify={"center"}>
                                  <GridItem xs={10} md={4}>
                                    <CustomInput
                                      labelText="Title"
                                      id="socmed_title"
                                      name="socmed_title"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        name: "socmed_title",
                                        value: customSocmed,
                                        onChange: e =>
                                          setCustomSocmed(e.target.value)
                                      }}
                                    />
                                  </GridItem>
                                  <GridItem xs={10} md={6}>
                                    <CustomInput
                                      labelText="Nama"
                                      id="socmed_name"
                                      name="socmed_name"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        name: "socmed_name",
                                        value: socialMedia.name,
                                        onChange: handleChangeSocialMedia(
                                          "name"
                                        )
                                      }}
                                    />
                                  </GridItem>
                                </GridContainer>
                              ) : (
                                <></>
                              )}
                              <GridContainer justify={"center"}>
                                <GridItem xs={10}>
                                  <CustomInput
                                    labelText="Link"
                                    id="socmed_link"
                                    name="socmed_link"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    error={!!errors.socmed_link_error}
                                    helperText={errors.socmed_link_error}
                                    inputProps={{
                                      name: "socmed_link",
                                      value: socialMedia.link,
                                      onChange: handleChangeSocialMedia("link"),
                                      onBlur: () => {
                                        setErrors({
                                          ...errors,
                                          socmed_link_error: validateSocmedLink()
                                        });
                                      },
                                      onFocus: () => {
                                        setErrors({
                                          ...errors,
                                          socmed_link_error: ""
                                        });
                                      }
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                              <GridContainer justify={"space-around"}>
                                <GridItem>
                                  <Button
                                    round
                                    size={"sm"}
                                    color={"warning"}
                                    onClick={handleOpenCloseAddSocMed}
                                  >
                                    discard
                                  </Button>
                                </GridItem>
                                <GridItem>
                                  <Button
                                    round
                                    size={"sm"}
                                    color={"success"}
                                    disabled={
                                      !socialMedia.link ||
                                      !socialMedia.title ||
                                      !socialMedia.name
                                    }
                                    onClick={handleSaveNewSocMed}
                                  >
                                    save
                                  </Button>
                                </GridItem>
                              </GridContainer>
                            </Collapse>
                          </GridItem>
                        </GridContainer>
                        <div style={{ padding: "3vh" }} />
                        <GridContainer justify={"space-around"}>
                          <GridItem>
                            <Button color={"info"} onClick={handlePrevPage}>
                              Prev
                            </Button>
                          </GridItem>
                          <GridItem>
                            <Button color={"success"} onClick={handleNextPage}>
                              Save
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </Collapse>
                      <Collapse in={page === 3}>
                        <UserProfile />
                      </Collapse>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Container>
    </div>
  );
}

Activation.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any
};
