import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
//@material-ui/core components
import { Avatar, Dialog, DialogContent, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Card from "dep/Card/Card";
import CardBody from "dep/Card/CardBody";
import Button from "dep/CustomButtons/Button";
// components
import PdfViewer from "components/Global/Dialog/PdfViewer";
// @material-ui/icons
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
// core components
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";

const useStyles = makeStyles(theme => ({
  dialogTitle: { fontSize: "24px" },
  dialogSubTitle: { fontSize: "14px", fontStyle: "italic", color: "#4f4f4f" },
  cardTitle: { margin: 0, fontSize: "18px" },
  cardSubTitle: { fontSize: "16px", fontStyle: "italic", color: "#4f4f4f" },
  littleTitle: { margin: 0, fontSize: "14px", fontWeight: 600 },
  date: { fontSize: "14px", fontStyle: "italic", color: "#428efd" },
  avatar: { width: theme.spacing(12), height: theme.spacing(12) },
  text: { margin: 0, fontSize: "13px", color: "#343434" },
  textValue: {
    margin: 0,
    fontSize: "13px",
    fontStyle: "italic",
    color: "#4f4f4f",
    textAlign: "right"
  },
  bio: {
    margin: 0,
    fontSize: "13px",
    fontStyle: "italic",
    color: "#4f4f4f",
    textAlign: "center",
    whiteSpace: "nowrap",
    maxWidth: "50ch",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

export default function DialogVerifikasMigrasiMahasiswa(props) {
  const classes = useStyles();
  const { userData, migrasiData } = props;
  // var: pdfViewer
  const [pdfViewer, setPdfViewer] = useState({
    open: false,
    link: ""
  });
  const handleCloseDialog = () => {
    props.handleChangeDialogMigrasiType("");
    props.handleChangeMigrasiData({});
  };

  //notifia
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });
  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };

  const handleSubmit = async value => {
    try {
      const url = value
        ? BASE_URL + "/api/manajemen/" + props.type + "/accept/" + userData.nim
        : BASE_URL + "/api/manajemen/" + props.type + "/reject/" + userData.nim;
      const snackbarValue = value
        ? "Berhasil menerima pengajuan " + props.type
        : "Berhasil menolak pengajuan " + props.type;
      openSnackbar("info", "Submitting...");

      const form = new FormData();
      form.append("_method", "PUT");
      let res = await Auth.fetchMultipart(url, {
        method: "POST",
        body: form
      });
      let data = await res.json();

      if (res.status === 200) {
        props.setToggleReFetchMigrasi(!props.toggleReFetchMigrasi);
        openSnackbar("success", snackbarValue);
        handleCloseDialog();
      } else {
        let result =
          typeof data.message === "string"
            ? data.message
            : Object.values(data.message).join("");
        openSnackbar("warning", result);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };
  // handle: open-close
  const handleOpenPdfViewer = link => {
    setPdfViewer({ open: true, link: link });
  };
  const handleClosePdfViewer = () => {
    setPdfViewer({ open: false, link: "" });
  };
  return (
    <Dialog
      open={props.type === "migrasi" || props.type === "readmisi"}
      onClose={handleCloseDialog}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
      />
      <DialogContent>
        <h4 className={classes.dialogTitle}>Verifikasi {props.type}</h4>
        <p className={classes.dialogSubTitle}>
          Harap periksa dokumen pendukung dengan benar
        </p>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GridContainer justify={"space-between"} alignItems={"center"}>
                  <GridItem>
                    <h4 className={classes.cardTitle}>
                      Informasi {props.type}
                    </h4>
                    <p className={classes.text}>
                      Nomor Ijazah: {migrasiData.nomor_ijazah || "-"}
                    </p>
                  </GridItem>
                  <GridItem>
                    <Button
                      size={"sm"}
                      round
                      color={"info"}
                      onClick={event => {
                        handleOpenPdfViewer(migrasiData.link_bukti);
                        event.preventDefault();
                      }}
                    >
                      <PictureAsPdfIcon /> Bukti
                    </Button>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem>
                    <p className={classes.littleTitle}>Informasi Perubahan :</p>
                  </GridItem>
                </GridContainer>
                <GridContainer justify={"space-between"}>
                  <GridItem xs={12} md>
                    <p className={classes.text}>Nomor Induk Mahasiswa</p>
                  </GridItem>
                  <GridItem xs={12} md>
                    <p className={classes.textValue}>{migrasiData.nim_baru}</p>
                  </GridItem>
                </GridContainer>
                <GridContainer justify={"space-between"}>
                  <GridItem xs={12} md>
                    <p className={classes.text}>Program Studi</p>
                  </GridItem>
                  <GridItem xs={12} md>
                    <p className={classes.textValue}>
                      {migrasiData.prodi_baru || "-"}
                    </p>
                  </GridItem>
                </GridContainer>
                <GridContainer justify={"space-between"}>
                  <GridItem xs={12} md>
                    <p className={classes.text}>Tanggal Masuk</p>
                  </GridItem>
                  <GridItem xs={12} md>
                    <p className={classes.textValue}>
                      {migrasiData.tanggal_masuk
                        ? moment(migrasiData.tanggal_masuk).format("DD-MM-YYYY")
                        : "-"}
                    </p>
                  </GridItem>
                </GridContainer>
                <GridContainer justify={"center"}>
                  <GridItem xs={10} style={{ margin: "2vh" }}>
                    <Divider />
                  </GridItem>
                </GridContainer>
                <GridContainer justify={"space-between"} alignItems={"center"}>
                  <GridItem zeroMinWidth>
                    <p className={classes.littleTitle}>Informasi Pengguna:</p>
                    <p className={classes.littleTitle}>{userData.nama}</p>
                    <p className={classes.text}>
                      {userData.nama_prodi + //todo: kasih jenjang
                        " | " +
                        userData.nim}
                    </p>
                    <p className={classes.bio}>{userData.bio}</p>
                  </GridItem>
                  <GridItem>
                    <Avatar
                      alt={userData.nama}
                      src={"userData.avatar"}
                      className={classes.avatar}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify={"space-around"}>
          <GridItem>
            <Button
              round
              color={"danger"}
              onClick={event => {
                handleSubmit(false);
                event.preventDefault();
              }}
            >
              Tolak
            </Button>
          </GridItem>
          <GridItem>
            <Button
              round
              color={"success"}
              onClick={event => {
                handleSubmit(true);
                event.preventDefault();
              }}
            >
              Verifikasi
            </Button>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <PdfViewer
        open={pdfViewer.open}
        link={pdfViewer.link}
        onClose={handleClosePdfViewer}
      />
    </Dialog>
  );
}

DialogVerifikasMigrasiMahasiswa.propTypes = {
  handleChangeDialogMigrasiType: PropTypes.func,
  handleChangeMigrasiData: PropTypes.func,
  migrasiData: PropTypes.object,
  type: PropTypes.string,
  userData: PropTypes.object
};
