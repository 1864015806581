import React from "react";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CardCetakSKPI from "components/User/CetakSKPI/Card/CardCetakSKPI";

export default function CetakSKPI() {
  return (
    <GridContainer>
      <GridItem xs={12}>
        <CardCetakSKPI />
      </GridItem>
    </GridContainer>
  );
}
