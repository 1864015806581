import React, { useEffect, useState } from "react";
// core components
import GridItem from "dep/Grid/GridItem.js";
import GridContainer from "dep/Grid/GridContainer.js";
// components
import CardStatistikLogin from "components/Admin/Dashboard/Card/CardStatistikLogin";
import CardStatistikKelulusan from "components/Admin/Dashboard/Card/CardStatistikKelulusan";
import DashboardLittleCards from "components/Admin/Dashboard/LittleCards/DashboardLittleCards";
import CardStatistikPencapaian from "components/Admin/Dashboard/Card/CardStatistikPencapaian";
import CardStatistikKinerjaAdmin from "components/Admin/Dashboard/Card/CardStatistikKinerjaAdmin";

import Auth from "helpers/auth";
import { BASE_URL, ROLE_SUPER_ADMIN } from "const";

export default function Dashboard() {
  useEffect(() => {
    //handle: API
    const fetchProdi = async () => {
      let res = await Auth.fetch(BASE_URL + "/api/prodi");
      let data = await res.json();
      setListProdi(data.prodi || []);
    };
    fetchProdi();
  }, []);
  // var: values
  const [listProdi, setListProdi] = useState([]);
  const isSuperAdmin = Auth.isRoleEqualTo(ROLE_SUPER_ADMIN);

  // const handle
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} md={isSuperAdmin ? 12 : 6}>
          <GridContainer>
            <DashboardLittleCards
              listProdi={listProdi}
              isSuperAdmin={isSuperAdmin}
            />
          </GridContainer>
        </GridItem>
        {isSuperAdmin && (
          <GridItem xs={12} sm={12} md={6}>
            <CardStatistikLogin />
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={6}>
          <CardStatistikKelulusan
            listProdi={listProdi}
            isSuperAdmin={isSuperAdmin}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CardStatistikPencapaian
            listProdi={listProdi}
            isSuperAdmin={isSuperAdmin}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CardStatistikKinerjaAdmin />
        </GridItem>
      </GridContainer>
    </div>
  );
}
