import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
// dep components
import GridItem from "dep/Grid/GridItem";
import GridContainer from "dep/Grid/GridContainer";
import CustomTable from "dep/Table/Table";
import CardHeader from "dep/Card/CardHeader";
import CardBody from "dep/Card/CardBody";
import CardFooter from "dep/Card/CardFooter";
import Card from "dep/Card/Card";
import Button from "dep/CustomButtons/Button.js";
// components
import CardProdiSelectorPreview from "components/Admin/ProdiSelector/Card/CardProdiSelectorPreview";
import DialogProdiSelector from "components/Admin/ProdiSelector/Dialog/DialogProdiSelector";
import DialogEditKegiatanInternal from "components/Admin/KegiatanInternal/Dialog/DialogEditKegiatanInternal";
import DialogAddKegiatanInternal from "components/Admin/KegiatanInternal/Dialog/DialogAddKegiatanInternal";
import PdfViewer from "components/Global/Dialog/PdfViewer";
import DialogDeleteKegiatanInternal from "components/Admin/KegiatanInternal/Dialog/DialogDeleteKegiatanInternal";
// @material-ui/icons
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
// jss
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle";

import Auth from "helpers/auth";
import { ROLE_SUPER_ADMIN, BASE_URL } from "const";
import cardStyles from "assets/jss/jpt/cardStyles";
import buttonsStyles from "assets/jss/jpt/buttonsStyles";

const ITEM_PER_PAGE = 10;

const useStyles = makeStyles({
  ...cardStyles,
  ...taskStyles,
  ...buttonsStyles
});

export default function CardKegiatanInternal(props) {
  const { openSnackbar } = props;
  const classes = useStyles();
  // var: values
  const [tableData, setTableData] = useState([]);
  const [selectedFakultas, setSelectedFakultas] = useState();
  const [selectedProdi, setSelectedProdi] = useState(Auth.getIdProdi());
  const [selectedKegiatan, setSelectedKegiatan] = useState({
    id_kegiatan_ppk: "",
    id_bidang_portofolio: "",
    id_rubrik: "",
    id_anak_rubrik: "",
    judul: "",
    judul_inggris: "",
    bobot_skp_portofolio: "",
    tanggal_terbit: "",
    dokumen_ppk: ""
  });

  // Pagination
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  // var: open-close
  const [openDialogProdi, setOpenDialogProdi] = useState(
    Auth.isRoleEqualTo(ROLE_SUPER_ADMIN)
  );
  const [openDialogType, setOpenDialogType] = useState(""); //ini untuk type dialog add, edit delete

  useEffect(() => {
    if (selectedProdi) handleFetch();
  }, [selectedProdi, page]);

  // handle: API
  const handleFetch = async () => {
    try {
      let res = await Auth.fetch(
        BASE_URL +
          "/api/ppk?id_prodi=" +
          selectedProdi +
          "&paginate=" +
          ITEM_PER_PAGE +
          "&page=" +
          page
      );
      let data = await res.json();

      setPageCount(_.get(data, "ppk.last_page"));

      setTableData(
        _.get(data, "ppk.data", []).map((element, idx) => [
          (page - 1) * ITEM_PER_PAGE + idx + 1,
          element.judul,
          element.judul_inggris,
          _.get(element, "nama_anak_rubrik"),
          element.bobot_skp_portofolio,
          moment(element.tanggal_terbit).format("DD-MM-YYYY"),
          renderTooltip({
            ...element,
            dokumen_ppk: BASE_URL + "/" + element.dokumen_ppk,
            tanggal_terbit: new Date(element.tanggal_terbit)
          })
        ])
      );
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan!");
    }
  };

  // handle: values
  const handleChangeSelectedKegiatan = kegiatanInternal => {
    setSelectedKegiatan(kegiatanInternal);
  };
  const handleChangeOpenDialogProdi = value => {
    setOpenDialogProdi(value);
  };
  const handleChangeSelectedProdi = value => {
    setSelectedProdi(value);
    setOpenDialogProdi(false);
  };
  // handle: open-close
  const handleOpenDialog = type => {
    setOpenDialogType(type);
  };
  const handleCloseDialog = () => {
    setOpenDialogType("");
  };

  const handleChangePage = (_e, page) => {
    setPage(page);
  };

  const renderTooltip = kegiatanInternal => {
    return (
      <React.Fragment>
        <Tooltip
          id="tooltip-pdf"
          title="lihat dokumen"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            aria-label="lihat dokumen"
            className={classes.tableActionButton}
            onClick={event => {
              setOpenDialogType("pdf");
              handleChangeSelectedKegiatan(kegiatanInternal);
              event.preventDefault();
            }}
          >
            <PictureAsPdfIcon
              className={
                classes.tableActionButtonIcon + " " + classes.buttonInfo
              }
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          id="tooltip-top"
          title="edit"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            aria-label="edit"
            className={classes.tableActionButton}
            onClick={event => {
              handleChangeSelectedKegiatan(kegiatanInternal);
              handleOpenDialog("edit");
              event.preventDefault();
            }}
          >
            <EditIcon
              className={
                classes.tableActionButtonIcon + " " + classes.buttonEdit
              }
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          id="tooltip-delete"
          title="hapus portofolio"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            aria-label="hapus portofolio"
            className={classes.tableActionButton}
            onClick={event => {
              handleChangeSelectedKegiatan(kegiatanInternal);
              handleOpenDialog("delete");
              event.preventDefault();
            }}
          >
            <DeleteForeverIcon
              className={classes.tableActionButtonIcon + " " + classes.close}
            />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  };

  return (
    <GridContainer direction="row-reverse">
      <DialogProdiSelector
        openDialog={openDialogProdi}
        handleChangeOpenDialog={handleChangeOpenDialogProdi}
        selectedProdi={selectedProdi}
        handleChangeSelectedProdi={handleChangeSelectedProdi}
        handleChangeSelectedFakultas={setSelectedFakultas}
      />
      <DialogAddKegiatanInternal
        selectedProdi={selectedProdi}
        onClose={handleCloseDialog}
        open={openDialogType === "add"}
        handleFetchKegiatan={handleFetch}
        {...props}
      />
      <DialogEditKegiatanInternal
        onClose={handleCloseDialog}
        open={openDialogType === "edit"}
        selectedProdi={selectedProdi}
        selectedKegiatan={selectedKegiatan}
        handleFetchKegiatan={handleFetch}
        {...props}
      />
      <DialogDeleteKegiatanInternal
        onClose={handleCloseDialog}
        open={openDialogType === "delete"}
        selectedKegiatan={selectedKegiatan}
        handleFetchKegiatan={handleFetch}
        {...props}
      />
      <PdfViewer
        open={openDialogType === "pdf"}
        link={selectedKegiatan.dokumen_ppk}
        onClose={handleCloseDialog}
      />
      <GridItem xs={12} sm={12} md={4}>
        <CardProdiSelectorPreview
          selectedProdi={selectedProdi}
          handleChangeOpenDialog={handleChangeOpenDialogProdi}
          selectedFakultas={selectedFakultas}
        />
      </GridItem>
      <GridItem xs={12} md={8}>
        <Card>
          <CardHeader color={"primary"}>
            <GridContainer justify={"space-between"} alignItems={"flex-end"}>
              <GridItem>
                <h4 className={classes.cardTitleWhite}>Kegiatan Internal</h4>
                <p className={classes.cardCategoryWhite}>
                  Ubah atau tambah Kegiatan Internal
                </p>
              </GridItem>
              <GridItem>
                <Button
                  color={"white"}
                  round
                  size={"sm"}
                  onClick={event => {
                    handleOpenDialog("add");
                    event.preventDefault();
                  }}
                >
                  <AddIcon />
                  Tambah
                </Button>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <CustomTable
              tableHeaderColor="primary"
              tableHead={[
                "ID",
                "Nama Kegiatan(ID)",
                "Activity Name(EN)",
                "sub-Rubrik",
                "SKP",
                "Tanggal",
                "opsi"
              ]}
              tableData={tableData}
            />
          </CardBody>
          <CardFooter>
            <GridContainer>
              <GridItem xs />
              <GridItem>
                <Pagination
                  count={pageCount}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  onChange={handleChangePage}
                />
              </GridItem>
            </GridContainer>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

CardKegiatanInternal.propTypes = {
  openSnackbar: PropTypes.func
};
