import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { Dialog, DialogContent } from "@material-ui/core";
// dep components
import Tabs from "dep/CustomTabs/CustomTabs.js";
// components
import TabDialogManajemenMahasiswaProfil from "components/Admin/ManajemenMahasiswa/Tabs/TabDialogManajemenMahasiswaProfil";
import TabDialogManajemenMahasiswaPortofolio from "components/Admin/ManajemenMahasiswa/Tabs/TabDialogManajemenMahasiswaPortofolio";
import TabDialogManajemenMahasiswaKemahasiswaan from "components/Admin/ManajemenMahasiswa/Tabs/TabDialogManajemenMahasiswaKemahasiswaan";
import TabDialogManajemenMahasiswaAkun from "components/Admin/ManajemenMahasiswa/Tabs/TabDialogManajemenMahasiswaAkun";
// @material-ui/icons
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import SettingsIcon from "@material-ui/icons/Settings";
import SchoolIcon from "@material-ui/icons/School";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

export default function DialogManajemenMahasiswaProfil(props) {
  const [openDialog, setOpenDialog] = useState(false);
  const [tabDefault, setTabDefault] = useState(0);

  useEffect(() => {
    tabSwitcher();
    // eslint-disable-next-line
  }, [props.dialogProfilID, props.dialogProfilEdit]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    props.handleChangeDialogProfilEdit("");
    props.handleChangeDialogProfilID("");
  };

  const tabSwitcher = () => {
    if (props.dialogProfilID) {
      handleOpenDialog();
    }
    switch (props.dialogProfilEdit) {
      case "profil":
        setTabDefault(0);
        break;
      case "portofolio":
        setTabDefault(1);
        break;
      case "kemahasiswaan":
        setTabDefault(2);
        break;
      case "akun":
        setTabDefault(3);
        break;
      default:
        setTabDefault(0);
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth={"md"}
      scroll={"paper"}
    >
      <DialogContent>
        <Tabs
          title={"NIM : " + props.dialogProfilID}
          headerColor={"primary"}
          defaultValue={tabDefault}
          plainTabs
          tabs={[
            {
              tabName: "Profil",
              tabIcon: PermIdentityIcon,
              tabContent: (
                <TabDialogManajemenMahasiswaProfil
                  dialogProfilID={props.dialogProfilID}
                  {...props}
                />
              )
            },
            {
              tabName: "Portofolio",
              tabIcon: LibraryBooksIcon,
              tabContent: (
                <TabDialogManajemenMahasiswaPortofolio
                  dialogProfilID={props.dialogProfilID}
                  {...props}
                />
              )
            },
            {
              tabName: "Kemahasiswaan",
              tabIcon: SchoolIcon,
              tabContent: (
                <TabDialogManajemenMahasiswaKemahasiswaan
                  dialogProfilID={props.dialogProfilID}
                  {...props}
                />
              )
            },
            {
              tabName: "Akun",
              tabIcon: SettingsIcon,
              tabContent: (
                <TabDialogManajemenMahasiswaAkun
                  dialogProfilID={props.dialogProfilID}
                  {...props}
                />
              )
            }
          ]}
        />
      </DialogContent>
    </Dialog>
  );
}

DialogManajemenMahasiswaProfil.propTypes = {
  dialogProfilEdit: PropTypes.string,
  dialogProfilID: PropTypes.string,
  handleChangeDialogProfilEdit: PropTypes.func,
  handleChangeDialogProfilID: PropTypes.func
};
