import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import Table from "dep/Table/Table";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Button from "dep/CustomButtons/Button";
import CustomSelect from "dep/CustomInput/CustomSelect";
// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import valuesStyles from "assets/jss/jpt/valuesStyles";

const useStyles = makeStyles(valuesStyles);
export default function DialogPrintSKPI(props) {
  const { openSnackbar } = props;
  const classes = useStyles();
  const [overrideNewPrint, setOverrideNewPrint] = useState(false);

  const [selectedPeriode, setSelectedPeriode] = useState("");

  const handleClose = () => {
    props.onClose();
  };

  const handleSubmit = async () => {
    try {
      if (overrideNewPrint) {
        if (!confirmOverride()) return;
      }
      if (props.selectedValue.length === 1) {
        let res = await Auth.fetch(
          `${BASE_URL}/api/manajemen/skpi/download/${
            props.selectedValue[0]
          }?periode=${selectedPeriode}&override=${overrideNewPrint ? 1 : 0}`
        );

        let data = await res.json();
        if (res.status >= 200 && res.status < 300) {
          // let res = await Auth.fetch(BASE_URL + "/" + data.message);
          const link = document.createElement("a");
          // link.href = window.URL.createObjectURL(await res.blob());
          // link.setAttribute("download", data.message.split("/").pop());
          link.href = BASE_URL + "/" + data.message;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          props.onSuccess();
          props.onClose();
        } else {
          let errorMessages = "";
          if (typeof data.message === "string") {
            errorMessages = data.message;
          } else if (typeof data.message === "object") {
            errorMessages = "";
            if (Array.isArray(data.message)) {
              for (let err of data.message) {
                errorMessages +=
                  typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
              }
            } else {
              for (let key in data.message)
                errorMessages += data.message[key][0];
            }
          }
          openSnackbar(
            res.status >= 400 && res.status < 500 ? "warning" : "error",
            errorMessages
          );
        }
      } else {
        let idx = 0;
        let res = await Auth.fetch(
          `${BASE_URL}/api/manajemen/skpi/download/bulk?${props.selectedValue.reduce(
            (acc, val) => `${acc}nim[${idx++}]=${val}&`,
            ""
          )}periode=${selectedPeriode}&override=${overrideNewPrint ? 1 : 0}`
        );

        if (res.status >= 200 && res.status < 300) {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(await res.blob());
          link.setAttribute("download", "skpi.zip");
          document.body.appendChild(link);
          link.click();
          link.href = "";
          props.onSuccess();
          props.onClose();
        } else {
          let data = await res.json();
          let errorMessages = "";
          if (typeof data.message === "string") {
            errorMessages = data.message;
          } else if (typeof data.message === "object") {
            errorMessages = "";
            if (Array.isArray(data.message)) {
              for (let err of data.message) {
                errorMessages +=
                  typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
              }
            } else {
              for (let key in data.message)
                errorMessages += data.message[key][0];
            }
          }
          openSnackbar(
            res.status >= 400 && res.status < 500 ? "warning" : "error",
            errorMessages
          );
        }
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  const confirmOverride = () => {
    return window.confirm("Apakah anda yakin akan override print?");
  };

  const handleChangeSelectedPeriode = event => {
    setSelectedPeriode(event.target.value);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      scroll={"body"}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle>Cetak SKPI</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Anda akan mencetak SKPI dari mahasiswa di bawah ini dan sistem akan
          menganggap mahasiswa di bawah sudah lulus
        </DialogContentText>
        <GridContainer justify={"center"}>
          <GridItem>
            <Table
              tableHeaderColor="primary"
              tableHead={["NIM", "Nama", "Angkatan", "Kelulusan"]}
              // tableData={tableData}
              tableData={props.selectedValue.map(value => {
                const tmp = props.data.find(values => values[0] === value);
                return [tmp[0], tmp[1], tmp[3], tmp[4]];
              })}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify={"center"}>
          <GridItem xs={10} md={5}>
            <CustomSelect
              labelText="Periode"
              id="periode"
              formControlProps={{
                fullWidth: true
              }}
              selectProps={{
                id: "periode",
                name: "periode",
                onChange: handleChangeSelectedPeriode
              }}
              selectItems={props.listPeriode.map(values => ({
                value: values[0],
                text:
                  values[1] + "/" + (~~values[1] + 1) + " periode " + values[2]
              }))}
              selectValue={selectedPeriode}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify={"flex-end"}>
          <GridItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={overrideNewPrint}
                  onChange={event => {
                    setOverrideNewPrint(event.target.checked);
                  }}
                  color="secondary"
                />
              }
              label={
                <p
                  className={
                    overrideNewPrint
                      ? classes.textWarning + " " + classes.center
                      : classes.textCheckbox + " " + classes.center
                  }
                >
                  Override New Print
                  <sup className={classes.textWarning}>*</sup>
                </p>
              }
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify={"space-between"}>
          <GridItem>
            <p
              className={
                overrideNewPrint ? classes.textWarning : classes.textCheckbox
              }
            >
              <sup>*</sup>Cetak SKPI terbaru meskipun sudah pernah cetak SKPI
            </p>
          </GridItem>
          <GridItem>
            <GridContainer justify={"center"}>
              <GridItem>
                <Button size={"sm"} color={"warning"} onClick={handleClose}>
                  Cancel
                </Button>
              </GridItem>
              <GridItem>
                <Button
                  size={"sm"}
                  color={"success"}
                  onClick={handleSubmit}
                  disabled={!selectedPeriode}
                >
                  Print
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
}

DialogPrintSKPI.propTypes = {
  data: PropTypes.array,
  listPeriode: PropTypes.array,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  selectedValue: PropTypes.array,
  openSnackbar: PropTypes.func
};
