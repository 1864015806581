import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
// @material-ui/core components
import {
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";
import Button from "dep/CustomButtons/Button";
// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit.js";
// styling
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import buttonsStyles from "assets/jss/jpt/buttonsStyles";
import valuesStyles from "assets/jss/jpt/valuesStyles";

const useStyle = makeStyles({
  ...taskStyles,
  ...buttonsStyles,
  ...valuesStyles
});
export default function TabDialogManajemenMahasiswaAkun(props) {
  const { openSnackbar } = props;

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line
  }, [props.dialogProfilID]);

  // var: values
  const [values, setValues] = useState({
    email: "",
    status: true
  });
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [status, setStatus] = useState(false);

  // var: open-close
  const [editEmail, setEditEmail] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [anchorElMenuStatus, setAnchorElMenuStatus] = useState(null);

  // handle: open-close
  const handleEditEmailOpen = () => {
    setEditEmail(true);
  };
  const handleEditPasswordOpen = () => {
    setEditPassword(true);
  };
  const handleClickMenuStatus = event => {
    setAnchorElMenuStatus(event.currentTarget);
  };
  const handleCloseMenuStatus = () => {
    setAnchorElMenuStatus(null);
  };

  // handle: checking
  const handleCheckPassword = () => {
    // TODO: mau gimana passwd formatnya
    return newPassword === rePassword;
  };

  // handle: values
  const handleChangeStatus = value => {
    setEditStatus(true);
    setStatus(value);
    setAnchorElMenuStatus(null);
    setValues({ ...values, status: value });
  };
  const handleChangeEmail = event => {
    setEmail(event.target.value);
  };
  const handleChangeNewPassword = event => {
    setNewPassword(event.target.value);
  };
  const handleChangeRePassword = event => {
    setRePassword(event.target.value);
  };

  // handle: API submit
  const handleSubmitButton = async () => {
    // TODO: submit data to API
    //  submit jika "edit"-nya hidup
    openSnackbar("info", "Mengupdate..");

    let res = await Auth.fetch(BASE_URL + "/api/manajemen/akun/update", {
      method: "PUT",
      body: JSON.stringify(getPUTData())
    });

    let data = await res.json();
    let errorMessages = "";
    if (typeof data.message === "string") {
      errorMessages = data.message;
    } else if (typeof data.message === "object") {
      errorMessages = "";
      if (Array.isArray(data.message)) {
        for (let err of data.message) {
          errorMessages +=
            typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
        }
      } else {
        for (let key in data.message) errorMessages += data.message[key][0];
      }
    }

    if (res.status >= 200 && res.status < 300) {
      openSnackbar("success", "Data user berhasil diupdate!");

      // Update previewData
      props.setToggleReFetchMahasiswa(!props.toggleReFetchMahasiswa);
      props.setPreviewData({
        ...props.previewData,
        is_active: status
      });
    } else if (res.status >= 400 && res.status < 500) {
      openSnackbar("warning", errorMessages);
    } else {
      openSnackbar("error", errorMessages);
    }
  };

  const getPUTData = () => {
    if (editEmail) {
      return {
        nim: _.get(props, "previewData.nim"),
        email: email
      };
    }
    if (editPassword) {
      return {
        nim: _.get(props, "previewData.nim"),
        password: newPassword,
        password_confirmation: rePassword
      };
    }
    if (editStatus) {
      return {
        nim: _.get(props, "previewData.nim"),
        is_active: status
      };
    }
  };

  const handleDiscardButton = () => {
    setEditStatus(false);
    setEditPassword(false);
    setEditEmail(false);
  };

  // handle: API fetch
  const handleFetchData = () => {
    if (props.previewData) {
      const dummy = {
        email: _.get(props, "previewData.email"),
        status: _.get(props, "previewData.is_active")
      };
      setValues(dummy);
      setEmail(dummy.email);
      setStatus(dummy.status);
      setNewPassword("");
      setRePassword("");
    } else {
      setValues({
        email: "",
        status: true
      });
      setStatus(false);
      setEmail("");
      setNewPassword("");
      setRePassword("");
    }
  };

  const classes = useStyle();
  return (
    <GridContainer>
      <GridItem xs={1} md={3} />
      <GridItem xs={10} md={6}>
        <Collapse in={!editEmail}>
          <GridContainer justify={"space-between"}>
            <GridItem>
              <p>E-mail</p>
            </GridItem>
            <GridItem>
              <p className={classes.values}>
                {values.email}
                <Tooltip
                  id="tooltip-top"
                  title="edit"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton
                    aria-label="edit"
                    className={classes.tableActionButton}
                    onClick={handleEditEmailOpen}
                  >
                    <EditIcon
                      className={
                        classes.tableActionButtonIcon + " " + classes.buttonEdit
                      }
                    />
                  </IconButton>
                </Tooltip>
              </p>
            </GridItem>
          </GridContainer>
        </Collapse>
        <Collapse in={editEmail}>
          <CustomInput
            labelText="email"
            id="email"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: email,
              //type: "email",
              onChange: handleChangeEmail
            }}
          />
        </Collapse>
        <Collapse in={!editPassword}>
          <GridContainer justify={"space-between"}>
            <GridItem>
              <p>Password</p>
            </GridItem>
            <GridItem>
              <p className={classes.values}>
                {"********"}
                <Tooltip
                  id="tooltip-top"
                  title="edit"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton
                    aria-label="edit"
                    className={classes.tableActionButton}
                    onClick={handleEditPasswordOpen}
                  >
                    <EditIcon
                      className={
                        classes.tableActionButtonIcon + " " + classes.buttonEdit
                      }
                    />
                  </IconButton>
                </Tooltip>
              </p>
            </GridItem>
          </GridContainer>
        </Collapse>
        <Collapse in={editPassword}>
          <CustomInput
            labelText="New Password"
            id="new-password"
            error={!handleCheckPassword()}
            success={handleCheckPassword()}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: newPassword,
              type: "password",
              onChange: handleChangeNewPassword
            }}
          />
          <CustomInput
            labelText="Re-Enter New Password"
            id="re-password"
            error={!handleCheckPassword()}
            success={handleCheckPassword()}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: rePassword,
              type: "password",
              onChange: handleChangeRePassword
            }}
          />
        </Collapse>
        <GridContainer justify={"space-between"}>
          <GridItem>
            <p>status</p>
          </GridItem>
          <GridItem>
            <p className={classes.values}>
              {values.status ? "aktif" : "non-aktif"}
              <Tooltip
                id="tooltip-top"
                title="edit"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="edit"
                  className={classes.tableActionButton}
                  onClick={handleClickMenuStatus}
                >
                  <EditIcon
                    className={
                      classes.tableActionButtonIcon + " " + classes.buttonEdit
                    }
                  />
                </IconButton>
              </Tooltip>
              <Menu
                id="simple-menu"
                anchorEl={anchorElMenuStatus}
                keepMounted
                open={Boolean(anchorElMenuStatus)}
                onClose={handleCloseMenuStatus}
              >
                <MenuItem
                  onClick={event => {
                    handleChangeStatus(true);
                    event.preventDefault();
                  }}
                >
                  Aktif
                </MenuItem>
                <MenuItem
                  onClick={event => {
                    handleChangeStatus(false);
                    event.preventDefault();
                  }}
                >
                  Non-aktif
                </MenuItem>
              </Menu>
            </p>
          </GridItem>
        </GridContainer>
        <Collapse in={editPassword || editEmail || editStatus}>
          <GridContainer justify={"space-around"}>
            <GridItem>
              <Button color={"warning"} onClick={handleDiscardButton}>
                Discard
              </Button>
            </GridItem>
            <GridItem>
              <Button color={"primary"} onClick={handleSubmitButton}>
                Save
              </Button>
            </GridItem>
          </GridContainer>
        </Collapse>
      </GridItem>
      <GridItem xs={1} md={3} />
    </GridContainer>
  );
}

TabDialogManajemenMahasiswaAkun.propTypes = {
  dialogProfilID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  openSnackbar: PropTypes.func,
  previewData: PropTypes.object
};
