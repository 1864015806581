import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

// dep components
import GridItem from "dep/Grid/GridItem";
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";
import CardBody from "dep/Card/CardBody";
import Table from "dep/Table/Table";
import GridContainer from "dep/Grid/GridContainer";

// components
import ButtonEditManajemenAdmin from "components/Admin/ManajemenAdmin/Button/ButtonEditManajemenAdmin";
import ButtonTambahManajemenAdminProdi from "components/Admin/ManajemenAdmin/Button/ButtonTambahManajemenAdminProdi";
import DialogEditManajemenAdminProdi from "components/Admin/ManajemenAdmin/Dialog/DialogEditManajemenAdminProdi";
import DialogDeleteManajemenAdmin from "components/Admin/ManajemenAdmin/Dialog/DialogDeleteManajemenAdmin";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import cardStyles from "assets/jss/jpt/cardStyles";

// Constant
import { ID_ROLE_ADMIN_JURUSAN } from "const";

//styling
const useStyles = makeStyles(cardStyles);

export default function CardManajemenAdminProdi({ listProdi, openSnackbar }) {
  const classes = useStyles();

  // var: values
  const [tableData, setTableData] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [newDialogType, setNewDialogType] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    count: 1
  });

  useEffect(() => {
    handleFetchData(); // eslint-disable-next-line
  }, []);

  // handle: Fetch
  const handleFetchData = async () => {
    let res = await Auth.fetch(
      `${BASE_URL}/api/manajemen/akun?id_role=${ID_ROLE_ADMIN_JURUSAN}&page=${pagination.page}&paginate=15`
    );
    let data = await res.json();
    setPagination({ ...pagination, count: _.get(data, "users.last_page", 0) });

    const users = [];
    _.get(data, "users.data", []).forEach(user => {
      users.push([
        user.nim,
        user.nama,
        _.get(
          listProdi.find(e => e.id_prodi === user.id_prodi),
          "nama_prodi",
          ""
        ),
        <ButtonEditManajemenAdmin
          key={user.nim}
          selectedUser={user}
          handleChangeSelectedUser={handleChangeSelectedUser}
          handleChangeNewDialogType={handleChangeNewDialogType}
        />
      ]);
    });

    setTableData(users);
  };

  // handle: values -> hook
  const handleChangeSelectedUser = value => {
    setSelectedUser(value);
  };

  const handleChangeNewDialogType = value => {
    setNewDialogType(value);
  };

  //handle: change page
  const handleChangePage = (event, page) => {
    pagination.page = page;
    handleFetchData("page");
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Manajemen Admin Prodi</h4>
            <GridContainer justify={"space-between"} alignItems={"flex-start"}>
              <GridItem>
                {/* <p className={classes.cardCategoryWhite}>
                  Here is a subtitle for this table
                </p> */}
              </GridItem>
              <GridItem>
                <ButtonTambahManajemenAdminProdi
                  listProdi={listProdi}
                  openSnackbar={openSnackbar}
                  handleFetchUserData={handleFetchData}
                />
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["ID", "Nama", "Prodi", "Opsi"]}
              tableData={tableData}
            />
            <GridContainer justify={"center"}>
              <GridItem>
                <Pagination
                  style={{
                    margin: "auto",
                    marginBottom: "20px",
                    marginTop: "10px"
                  }}
                  count={pagination.count}
                  size="large"
                  onChange={handleChangePage}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <DialogEditManajemenAdminProdi
        listProdi={listProdi}
        selectedUser={selectedUser}
        handleFetchUserData={handleFetchData}
        openSnackbar={openSnackbar}
        type={newDialogType}
        handleChangeSelectedUser={handleChangeSelectedUser}
        handleChangeNewDialogType={handleChangeNewDialogType}
      />
      <DialogDeleteManajemenAdmin
        listProdi={listProdi}
        selectedUser={selectedUser}
        openSnackbar={openSnackbar}
        handleFetchUserData={handleFetchData}
        type={newDialogType}
        handleChangeNewDialogType={handleChangeNewDialogType}
      />
    </GridContainer>
  );
}

CardManajemenAdminProdi.propTypes = {
  listProdi: PropTypes.array,
  openSnackbar: PropTypes.func,
  handleFetchUserData: PropTypes.func
};
