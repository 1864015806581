import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

// @material-ui/core components
import {
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
// dep components
import Button from "dep/CustomButtons/Button";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";
// @material-ui/icon
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DialogContentText from "@material-ui/core/DialogContentText";
import EditIcon from "@material-ui/icons/Edit";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import formStyles from "assets/jss/jpt/formStyles";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

//styling
const useStyles = makeStyles({
  ...taskStyles,
  ...formStyles
});

export default function DialogEditManajemenAdminVerifikator({
  openSnackbar,
  handleFetchUserData,
  selectedUser,
  handleChangeNewDialogType,
  handleChangeSelectedUser,
  type
}) {
  const classes = useStyles();

  // var: values
  const [selectedRubriks, setSelectedRubriks] = useState([]);
  const [listRubrik, setListRubrik] = useState([]);
  const [editPassword, setEditPassword] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [userState, setUserState] = useState({
    ...selectedUser,
    nim_lama: selectedUser.nim,
    password: "",
    password_confirmation: ""
  });

  useEffect(() => {
    handleFetchListRubrik();
  }, []);

  useEffect(() => {
    if (selectedUser !== "" && type === "edit") {
      setOpenDialog(true);
      setUserState({
        ...userState,
        nim_lama: selectedUser.nim,
        ...selectedUser
      });
    }
    // eslint-disable-next-line
  }, [selectedUser, type]);

  const handleFetchListRubrik = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/anak_rubrik");
    let data = await res.json();

    let listRubrik = [];

    for (let anak_rubrik of _.get(data, "anak_rubrik")) {
      listRubrik.push({
        id: _.get(anak_rubrik, "id_anak_rubrik"),
        rubrik: _.get(anak_rubrik, "nama_anak_rubrik"),
        id_prodi: _.get(anak_rubrik, "id_prodi"),
        prodi:
          _.get(anak_rubrik, "nama_prodi") +
          "(" +
          _.get(anak_rubrik, "jenjang") +
          ")"
      });
    }

    setListRubrik(listRubrik.sort((a, b) => a.id_prodi - b.id_prodi));
  };

  const handleAPISubmit = async () => {
    try {
      await updateUser();
      await addListRubrik();
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  const addListRubrik = async () => {
    openSnackbar("info", "Menambahkan list rubrik..");
    let res = await Auth.fetch(BASE_URL + "/api/manajemen/verifikator/update", {
      method: "PUT",
      body: JSON.stringify({
        nim_verifikator: userState.nim,
        id_anak_rubrik: selectedRubriks
      })
    });

    let data = await res.json();
    let errorMessages = "";
    if (typeof data.message === "string") {
      errorMessages = data.message;
    } else if (typeof data.message === "object") {
      errorMessages = "";
      if (Array.isArray(data.message)) {
        for (let err of data.message) {
          errorMessages +=
            typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
        }
      } else {
        for (let key in data.message) errorMessages += data.message[key][0];
      }
    }

    if (res.status >= 200 && res.status < 300) {
      openSnackbar("success", "Data user berhasil diupdate!");
      handleFetchUserData();
      handleCloseDialog();
    } else if (res.status >= 400 && res.status < 500) {
      openSnackbar("warning", errorMessages);
    } else {
      openSnackbar("error", errorMessages);
    }
  };

  const updateUser = async () => {
    openSnackbar("info", "Mengupdate..");

    let res = await Auth.fetch(BASE_URL + "/api/manajemen/akun/update", {
      method: "PUT",
      body: JSON.stringify(getPUTData(userState))
    });

    let data = await res.json();
    let errorMessages = "";
    if (typeof data.message === "string") {
      errorMessages = data.message;
    } else if (typeof data.message === "object") {
      errorMessages = "";
      if (Array.isArray(data.message)) {
        for (let err of data.message) {
          errorMessages +=
            typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
        }
      } else {
        for (let key in data.message) errorMessages += data.message[key][0];
      }
    }

    if (res.status >= 200 && res.status < 300) {
      openSnackbar("success", "Data user berhasil diupdate!");
      handleFetchUserData();
      handleCloseDialog();
    } else if (res.status >= 400 && res.status < 500) {
      openSnackbar("warning", errorMessages);
    } else {
      openSnackbar("error", errorMessages);
    }
  };

  const getPUTData = e => {
    return {
      nama: _.get(e, "nama"),
      nim: _.get(e, "nim_lama"),
      nim_baru:
        _.get(e, "nim") !== _.get(e, "nim_lama") ? _.get(e, "nim") : undefined,
      email: _.get(e, "email"),
      password: _.get(e, "password"),
      password_confirmation: _.get(e, "password_confirmation"),
      id: _.get(e, "id")
    };
  };

  const handleChangeIdRubrik = (event, values) => {
    let selectedRubriks = [];
    values.forEach(element => {
      selectedRubriks.push(element.id);
    });
    setSelectedRubriks(selectedRubriks);
  };

  const handleSetDefaultRubrik = () => {
    let tmp = _.get(userState, "rubrik", []).map(e => e.id_anak_rubrik);
    setSelectedRubriks(tmp);
    return tmp.map(id => {
      return listRubrik.find(val => val.id === id);
    });
  };

  const handleCheckPassword = () => {
    return userState.password === userState.password_confirmation;
  };

  const handleChangeUserState = e => {
    setUserState({ ...userState, [e.target.name]: e.target.value });
  };

  const handleOpenEditPassword = () => {
    setEditPassword(true);
  };

  const handleCloseEditPassword = () => {
    setEditPassword(false);
    setUserState({ ...userState, password: "", password_confirmation: "" });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    handleChangeSelectedUser("");
    handleChangeNewDialogType("");
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Ubah Verifikator"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {"Silahkan masukkan informasi mengenai Verifikator"}
        </DialogContentText>
        <GridContainer>
          <GridItem xs={2} />
          <GridItem xs={8}>
            <CustomInput
              labelText="Username"
              id="username"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "nim",
                value: userState.nim,
                onChange: handleChangeUserState
              }}
            />
          </GridItem>
          <GridItem xs={2} />
        </GridContainer>
        <GridContainer>
          <GridItem xs={2} />
          <GridItem xs={8}>
            <CustomInput
              labelText="Nama"
              id="nama"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "nama",
                value: userState.nama,
                onChange: handleChangeUserState
              }}
            />
          </GridItem>
          <GridItem xs={2} />
        </GridContainer>
        <GridContainer>
          <GridItem xs={2} />
          <GridItem xs={8}>
            <CustomInput
              labelText="email"
              id="email"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "email",
                value: userState.email,
                onChange: handleChangeUserState
              }}
            />
          </GridItem>
          <GridItem xs={2} />
        </GridContainer>
        <GridContainer>
          <GridItem xs={2} />
          <GridItem xs={8}>
            <Collapse in={!editPassword}>
              <CustomInput
                labelText="Password"
                id="password"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: "********",
                  disabled: true,
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <Tooltip
                        id="tooltip-top"
                        title="Edit Password"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label="Edit"
                          className={classes.tableActionButton}
                          onClick={handleOpenEditPassword}
                        >
                          <EditIcon
                            className={
                              classes.tableActionButtonIcon + " " + classes.edit
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />
            </Collapse>
            <Collapse in={editPassword}>
              <CustomInput
                labelText="New Password"
                id="new-password"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "password",
                  value: userState.password,
                  type: "password",
                  onChange: handleChangeUserState,
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <Tooltip
                        id="tooltip-top"
                        title="Close edit password"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label="Edit"
                          className={classes.tableActionButton}
                          onClick={handleCloseEditPassword}
                        >
                          <EditIcon
                            className={
                              classes.tableActionButtonIcon +
                              " " +
                              classes.close
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />
              <CustomInput
                labelText="Re-Enter New Password"
                id="reenter-password"
                error={!handleCheckPassword()}
                success={handleCheckPassword()}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "password_confirmation",
                  value: userState.password_confirmation,
                  type: "password",
                  onChange: handleChangeUserState
                }}
              />
            </Collapse>
          </GridItem>
          <GridItem xs={2} />
        </GridContainer>
        <GridContainer>
          <GridItem xs={2} />
          <GridItem xs={8}>
            <FormControl
              className={classes.formControl}
              fullWidth
              style={{ marginTop: "30px", marginBottom: "30px" }}
            >
              <Autocomplete
                onChange={handleChangeIdRubrik}
                multiple
                id="checkboxes-tags-rubrik"
                defaultValue={handleSetDefaultRubrik}
                options={listRubrik}
                disableCloseOnSelect
                groupBy={option => option.prodi}
                getOptionLabel={option => option.rubrik}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.rubrik}
                  </React.Fragment>
                )}
                renderInput={params => (
                  <TextField {...params} label="Rubrik" placeholder="Rubrik" />
                )}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={2} />
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="warning">
          Discard
        </Button>
        <Button
          onClick={handleAPISubmit}
          color="success"
          autoFocus
          disabled={
            !selectedRubriks.length ||
            !userState.nim ||
            !userState.email ||
            editPassword
              ? !handleCheckPassword()
              : false
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogEditManajemenAdminVerifikator.propTypes = {
  handleChangeNewDialogType: PropTypes.func,
  handleChangeSelectedUser: PropTypes.func,
  handleFetchUserData: PropTypes.func,
  openSnackbar: PropTypes.func,
  selectedUser: PropTypes.any,
  type: PropTypes.string
};
