import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip } from "@material-ui/core";
// @material-ui/lab
import Pagination from "@material-ui/lab/Pagination";
// @material-ui/icons
import CachedIcon from "@material-ui/icons/Cached";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
// dep components
import Table from "dep/Table/Table.js";
import Card from "dep/Card/Card.js";
import CardHeader from "dep/Card/CardHeader.js";
import CardBody from "dep/Card/CardBody.js";
import Button from "dep/CustomButtons/Button.js";
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
// components
import AddPortofolioModal from "components/User/Portofolio/Dialog/AddPortofolio";
import PdfViewer from "components/Global/Dialog/PdfViewer";
import DialogDeletePortofolio from "components/User/Portofolio/Dialog/DialogDeletePortofolio";
import DialogEditPortofolio from "components/User/Portofolio/Dialog/DialogEditPortofolio";
import DialogShowHidePortofolio from "components/User/Portofolio/Dialog/DialogShowHidePortofolio";
// for API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
// style
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle";
import cardStyles from "assets/jss/jpt/cardStyles";
import buttonsStyles from "assets/jss/jpt/buttonsStyles";

const ID_STATUS_PORTOFOLIO_TERVERIFIKASI = 2;

const useStyles = makeStyles({
  ...cardStyles,
  ...taskStyles,
  ...buttonsStyles
});

export default function CardPortofolio(props) {
  const classes = useStyles();
  useEffect(() => {
    fetchPortofolio();
  }, []); // eslint-disable-line

  // var: values
  const { selectedCategory } = props;
  const [selectedPortofolio, setSelectedPortofolio] = useState({});
  const [tableDatas, changeTableDatas] = useState([]);
  const [pageCount, changePageCount] = useState(0);
  const [page, changePage] = useState(1);
  const pagination = 10;

  // var: open-close
  const [openAddPortofolio, changeOpenAddPortofolio] = useState(false);
  const [dialogType, setDialogType] = useState("");
  // var: notif
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });

  // handle : API fetch
  const fetchPortofolio = async (page = 1, perPage = pagination) => {
    let nim = Auth.getToken().nim;
    let res;
    openSnackbar("info", "Loading portofolio data");
    if (selectedCategory.id_bidang_portofolio === null) {
      res = await Auth.fetch(
        BASE_URL +
          `/api/portofolio/user/${nim}?paginate=${perPage}&page=${page}`
      );
    } else {
      res = await Auth.fetch(
        BASE_URL +
          `/api/portofolio/user/${nim}?paginate=${perPage}&page=${page}&bidang=${selectedCategory.id_bidang_portofolio}`
      );
    }
    let data = await res.json();
    let portofolio = [];
    _.get(data, "portofolio.data", []).forEach(row => {
      portofolio.push([
        row.judul,
        row.judul_inggris,
        row.nama_anak_rubrik,
        row.bobot_skp_portofolio,
        moment(row.tanggal_terbit).format("DD-MM-YYYY"),
        row.id_status_portofolio,
        //object buat selected portofolio(portofolioObj)
        {
          ...row,
          //TODO: full link buat download pdf,done, tp gabisa preview mungkin gara2 CSP, ga ngebolehin http:// . fitur download normal
          link:
            BASE_URL +
            `/storage/portofolio/${nim}/${row.id_portofolio}/` +
            row.dokumen_portofolio.split("/").slice(-1)[0],
          showed: row.showed
        }
      ]);
    });
    setSnackbarOpen(false);
    changePageCount(_.get(data, "portofolio.last_page", 1));
    changeTableDatas(portofolio);
    changePage(page);
  };

  // handle: values
  const handleChangePage = (e, page) => {
    fetchPortofolio(page);
  };

  // handle: open-close
  const handleBackButton = () => {
    props.handleBack();
  };
  const handleButtonAddModal = () => {
    changeOpenAddPortofolio(true);
  };
  const handleCloseAddPortofolio = () => {
    changeOpenAddPortofolio(false);
  };
  const handleCloseNewDialog = () => {
    setSelectedPortofolio({});
    setDialogType("");
  };
  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };

  const generateStatusVerifikasi = status => {
    switch (status) {
      case 1: {
        return (
          <Button size={"sm"} color="info" round>
            <CachedIcon /> Menunggu
          </Button>
        );
      }
      case 2: {
        return (
          <Button size={"sm"} color="success" round>
            <CheckCircleOutlineIcon /> Terverifikasi
          </Button>
        );
      }
      case 3: {
        return (
          <Button size={"sm"} color="warning" round>
            <CheckCircleOutlineIcon /> Diubah
          </Button>
        );
      }
      case 4: {
        return (
          <Button size={"sm"} color="danger" round>
            <CancelIcon /> Tertolak
          </Button>
        );
      }
      default: {
        return (
          <Button size={"sm"} color="danger" round>
            ...
          </Button>
        );
      }
    }
  };

  const generateTooltip = portofolioObj => {
    return (
      <React.Fragment>
        <Tooltip
          id="tooltip-pdf"
          title="lihat dokumen"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            aria-label="lihat dokumen"
            className={classes.tableActionButton}
            onClick={event => {
              setSelectedPortofolio(portofolioObj);
              setDialogType("pdf");
              event.preventDefault();
            }}
          >
            <PictureAsPdfIcon
              className={
                classes.tableActionButtonIcon + " " + classes.buttonInfo
              }
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          id="tooltip-edit"
          title="edit portofolio"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            aria-label="edit portofolio"
            className={classes.tableActionButton}
            onClick={event => {
              setSelectedPortofolio(portofolioObj);
              setDialogType("edit");
              event.preventDefault();
            }}
          >
            <EditIcon
              className={
                classes.tableActionButtonIcon + " " + classes.buttonEdit
              }
            />
          </IconButton>
        </Tooltip>
        {portofolioObj.id_status_portofolio !==
        ID_STATUS_PORTOFOLIO_TERVERIFIKASI ? (
          <Tooltip
            id="tooltip-delete"
            title="hapus portofolio"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton
              aria-label="hapus portofolio"
              className={classes.tableActionButton}
              onClick={event => {
                setSelectedPortofolio(portofolioObj);
                setDialogType("delete");
                event.preventDefault();
              }}
            >
              <DeleteForeverIcon
                className={classes.tableActionButtonIcon + " " + classes.close}
              />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  };

  const generateShowedPortofolio = portofolioObj => {
    const handleOpenDialog = () => {
      setSelectedPortofolio(portofolioObj);
      setDialogType("show-hide");
    };

    if (portofolioObj.showed) {
      return (
        <Button size={"sm"} color="success" round onClick={handleOpenDialog}>
          <VisibilityIcon /> Ditampilkan
        </Button>
      );
    } else {
      return (
        <Button size={"sm"} color="warning" round onClick={handleOpenDialog}>
          <VisibilityOffIcon /> Disembunyikan
        </Button>
      );
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} md={10}>
        <Card>
          <CustomSnackbar
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
            message={snackbarData.message}
            severity={snackbarData.severity}
          />
          <AddPortofolioModal
            open={
              openAddPortofolio &&
              selectedCategory.id_bidang_portofolio !== null //optional
            }
            handleClose={handleCloseAddPortofolio}
            openSnackbar={openSnackbar}
            setSnackbarOpen={setSnackbarOpen}
            selectedCategory={selectedCategory}
            fetchPortofolio={fetchPortofolio}
          />
          <PdfViewer
            open={dialogType === "pdf"}
            link={selectedPortofolio.link}
            onClose={handleCloseNewDialog}
          />
          <DialogDeletePortofolio
            open={dialogType === "delete"}
            onClose={handleCloseNewDialog}
            judul={selectedPortofolio.judul}
            idPortofolio={selectedPortofolio.id_portofolio}
            fetchPortofolio={fetchPortofolio}
            setSnackbarOpen={setSnackbarOpen}
            openSnackbar={openSnackbar}
          />
          <DialogEditPortofolio
            open={dialogType === "edit"}
            onClose={handleCloseNewDialog}
            portofolio={selectedPortofolio}
            selectedCategory={selectedCategory}
            setSnackbarOpen={setSnackbarOpen}
            openSnackbar={openSnackbar}
            fetchPortofolio={fetchPortofolio}
          />
          <DialogShowHidePortofolio
            open={dialogType === "show-hide"}
            onClose={handleCloseNewDialog}
            judul={selectedPortofolio.judul}
            idPortofolio={selectedPortofolio.id_portofolio}
            showed={selectedPortofolio.showed}
            fetchPortofolio={fetchPortofolio}
            openSnackbar={openSnackbar}
            setSnackbarOpen={setSnackbarOpen}
          />
          <CardHeader color="primary">
            <GridContainer justify={"space-between"} alignItems={"flex-start"}>
              <GridItem>
                <h4 className={classes.cardTitleWhite}>
                  Portofolio {selectedCategory.nama_bidang_portofolio || ""}
                </h4>
              </GridItem>
              <GridItem>
                <Tooltip
                  id="tooltip-top"
                  title="Close"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton
                    aria-label="Edit"
                    className={classes.tableActionButton}
                    onClick={handleBackButton}
                  >
                    <CloseIcon
                      className={
                        classes.tableActionButtonIcon +
                        " " +
                        classes.buttonClose
                      }
                    />
                  </IconButton>
                </Tooltip>
              </GridItem>
            </GridContainer>
            <GridContainer justify={"space-between"} alignItems={"flex-start"}>
              <GridItem>
                <p className={classes.cardCategoryWhite}>
                  Portofolio
                  {" " + selectedCategory.nama_bidang_portofolio_inggris || ""}
                </p>
              </GridItem>
              <GridItem>
                {selectedCategory.id_bidang_portofolio === null ? (
                  <></>
                ) : (
                  <Button
                    color="white"
                    round
                    onClick={handleButtonAddModal}
                    size="sm"
                  >
                    <AddIcon /> Tambah
                  </Button>
                )}
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={[
                "No",
                "Judul",
                "Title",
                "Anak Rubrik",
                "SKP",
                "Tanggal",
                "Status Verifikasi",
                "Ditampilkan",
                "Opsi"
              ]}
              tableData={tableDatas.map((element, idx) => [
                (page - 1) * pagination + idx + 1,
                ...element.slice(0, 5),
                generateStatusVerifikasi(element[5]),
                generateShowedPortofolio(element[6]),
                generateTooltip(element[6])
              ])}
            />
          </CardBody>
          <Pagination
            style={{ margin: "auto", marginBottom: "20px", marginTop: "10px" }}
            count={pageCount}
            size="large"
            onChange={handleChangePage}
            page={page}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}

CardPortofolio.propTypes = {
  handleBack: PropTypes.func,
  selectedCategory: PropTypes.object
};
