import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

// dep components
import Button from "dep/CustomButtons/Button";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function DialogManajemenMahasiswaHapusPortofolio(props) {
  // props
  const { openSnackbar, handleFetchPortofolioData } = props;

  const handleClose = () => {
    props.handleCloseDialog();
  };

  const handleSubmitButton = async () => {
    openSnackbar("info", "Menghapus..");
    let res = await Auth.fetch(
      BASE_URL +
        "/api/portofolio/user/" +
        props.selectedUser +
        "/" +
        props.selectedPortofolioID +
        "/delete",
      {
        method: "DELETE"
      }
    );

    let data = await res.json();
    let errorMessages = "";
    if (typeof data.message === "string") {
      errorMessages = data.message;
    } else if (typeof data.message === "object") {
      errorMessages = "";
      if (Array.isArray(data.message)) {
        for (let err of data.message) {
          errorMessages +=
            typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
        }
      } else {
        for (let key in data.message) errorMessages += data.message[key][0];
      }
    }

    if (res.status >= 200 && res.status < 300) {
      openSnackbar("success", "Data portofolio berhasil dihapus!");
      handleFetchPortofolioData();
      props.handleCloseDialog();
    } else if (res.status >= 400 && res.status < 500) {
      openSnackbar("warning", errorMessages);
    } else {
      openSnackbar("error", errorMessages);
    }
  };

  return (
    <Dialog
      open={Boolean(props.selectedPortofolioID) && props.type === "hapus"}
      onClose={handleClose}
    >
      <DialogTitle>Apakah anda yakin?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Anda akan menghapus portofolio &quot;
          {props.portofolioInfo.judul_portofolio}&quot; milik mahasiswa{" "}
          {props.portofolioInfo.nama_mahasiswa} (NIM. {props.selectedUser})
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color={"warning"} onClick={handleClose}>
          Tidak
        </Button>
        <Button color={"primary"} onClick={handleSubmitButton}>
          Ya
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogManajemenMahasiswaHapusPortofolio.propTypes = {
  handleCloseDialog: PropTypes.func,
  selectedPortofolioID: PropTypes.number,
  selectedUser: PropTypes.any,
  type: PropTypes.string,
  openSnackbar: PropTypes.func,
  handleFetchPortofolioData: PropTypes.func,
  portofolioInfo: PropTypes.object
};
