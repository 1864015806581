import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
// Dep
import Auth from "helpers/auth.js";
import CustomInput from "dep/CustomInput/CustomInput.js";
import Button from "dep/CustomButtons/Button.js";
import Card from "dep/Card/Card.js";
import CardHeader from "dep/Card/CardHeader.js";
import CardBody from "dep/Card/CardBody.js";
import GridItem from "dep/Grid/GridItem.js";
import GridContainer from "dep/Grid/GridContainer.js";
// Components
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";
// Core
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";

import {
  ROLE_ADMIN_JURUSAN,
  ROLE_MAHASISWA,
  ROLE_SUPER_ADMIN,
  ROLE_VERIFIKATOR
} from "const";

const buttonStyle = {
  margin: "0 auto",
  display: "block",
  marginTop: "10px"
};

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  textCenter: {
    textAlign: "center"
  }
};

const useStyles = makeStyles(styles);

const OTP = () => {
  const history = useHistory();

  const classes = useStyles();
  const [otp, setOtp] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });

  useEffect(() => {
    openSnackbar("info", Auth.getOTPToken().message);
  }, []);

  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };

  const submitOTP = async () => {
    openSnackbar("info", "Logging in...");
    try {
      let isSubmitted = await Auth.submitOTP(otp);
      if (isSubmitted) {
        Auth.deleteOTPToken();
        let { role } = Auth.getToken();
        switch (role.toLowerCase()) {
          case ROLE_SUPER_ADMIN:
          case ROLE_ADMIN_JURUSAN:
            history.push("/admin");
            break;
          case ROLE_MAHASISWA:
            history.push("/user");
            break;
          case ROLE_VERIFIKATOR:
            history.push("/verifikator");
            break;
          default:
            history.push("/");
        }
      } else {
        openSnackbar("warning", "Kode OTP yang anda masukkan salah!");
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan!");
    }
  };

  const kirimUlang = async () => {
    openSnackbar("info", "Mengirim ulang OTP...");
    try {
      let { nim, password } = Auth.getOTPToken();
      let isSubmitted = await Auth.login(nim, password);
      if (isSubmitted) {
        openSnackbar("info", "Kode OTP berhasil dikirim ulang!");
      } else {
        openSnackbar("warning", "NIM atau password salah!");
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan!");
    }
  };

  return (
    <Container maxWidth="xs">
      <Helmet>
        <title>OTP - Janabadra Professional Tracker</title>
        <meta
          name="description"
          content="Janabadra Professional Tracker, Sistem Data Portofolio Pendamping Ijazah, Universitas Janabadra"
        />
      </Helmet>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
        timeout={600000}
      />
      <Card className={classes.textCenter}>
        <CardHeader color="rose">
          <h4 className={classes.cardTitleWhite}>One Time Pin</h4>
        </CardHeader>
        <CardBody>
          <GridContainer justify="center">
            <GridItem>
              <CustomInput
                labelText="Kode OTP"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: e => setOtp(e.target.value),
                  onKeyPress: e => {
                    if (e.key === "Enter") {
                      submitOTP();
                    }
                  },
                  style: {
                    height: "3rem",
                    fontSize: "20px",
                    textAlign: "center"
                  }
                }}
              />
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer justify="center">
            <GridItem>
              <Button
                type="submit"
                color="success"
                round
                style={buttonStyle}
                onClick={() => submitOTP()}
              >
                Masuk
              </Button>
            </GridItem>
            <GridItem>
              <Button
                type="button"
                color="rose"
                round
                style={buttonStyle}
                onClick={() => kirimUlang()}
              >
                Kirim Ulang
              </Button>
            </GridItem>
          </GridContainer>
          <br />
        </CardBody>
      </Card>
    </Container>
  );
};

export default Auth.withLogin(OTP);

OTP.propTypes = {
  history: PropTypes.any.isRequired
};
