import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ChartistGraph from "react-chartist";
import moment from "moment";
// @material-ui/core components
import {
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import CardHeader from "dep/Card/CardHeader";
import CardBody from "dep/Card/CardBody";
import CardFooter from "dep/Card/CardFooter";
import Card from "dep/Card/Card";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
// @material-ui/icons
import Update from "@material-ui/icons/Update";
import Auth from "helpers/auth";
//jss
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle";
import DialogDetailPencapaian from "components/Admin/Dashboard/Dialog/DialogDetailPencapaian";
import formStyles from "assets/jss/jpt/formStyles";
import { BASE_URL } from "const";

const useStyles = makeStyles({
  ...dashboardStyle,
  ...formStyles
});

const statistikPencapaianChart = {
  options: {
    axisX: {
      showGrid: false
    },
    low: 0,
    high: 100,
    chartPadding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 0
    }
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function(value) {
            return value[0];
          }
        }
      }
    ]
  ],
  animation: {
    draw: function(data) {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * 80,
            dur: 500,
            from: 0,
            to: 1,
            easing: "ease"
          }
        });
      }
    }
  }
};

export default function CardStatistikPencapaian(props) {
  const classes = useStyles();

  useEffect(() => {
    if (props.isSuperAdmin) handleFetchData("");
    else setSelectedProdi(Auth.getIdProdi());
  }, []);
  // var: values
  const [data, setData] = useState({ labels: [], series: [[]] });
  const [selectedProdi, setSelectedProdi] = useState("");
  const [avgPencapaian, setAvgPencapaian] = useState("");
  const [dateLastUpdate, setDateLastUpdate] = useState(new Date());
  const [openDialog, setOpenDialog] = useState(false);

  // handle: API
  const handleFetchData = async id_prodi => {
    let res = await Auth.fetch(
      `${BASE_URL}/api/statistik/bidang-portofolio?prodi=${id_prodi}`
    );
    let json = await res.json();

    const label = (json.data || []).map(e => {
      return e.nama_bidang;
    });
    const data = (json.data || []).map(e => {
      return (e.mahasiswa_aktif_memenuhi_skp / e.mahasiswa_aktif_total) * 100;
    });

    const sum = data.reduce((previous, current) => (current += previous), 0);
    const avg = sum / data.length;

    setData({
      labels: label,
      series: [data]
    });
    setDateLastUpdate(new Date());
    setAvgPencapaian(~~avg);
  };
  // handle: values
  const handleChangeProdi = event => {
    const id_prodi = event.target.value;
    setSelectedProdi(id_prodi);
    handleFetchData(id_prodi);
  };
  // handle: open-close
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  return (
    <Card chart>
      <DialogDetailPencapaian
        onClose={handleCloseDialog}
        open={openDialog}
        selectedProdi={selectedProdi}
      />
      <CardHeader color={"info"}>
        <ChartistGraph
          className="ct-chart"
          data={data}
          type="Bar"
          options={statistikPencapaianChart.options}
          responsiveOptions={statistikPencapaianChart.responsiveOptions}
          listener={statistikPencapaianChart.animation}
        />
      </CardHeader>
      <CardBody>
        <GridContainer justify={"space-between"} alignItems={"center"}>
          <GridItem xs={12} md={8}>
            <h4 className={classes.cardTitle}>Rata Rata Pencapaian</h4>
            <p className={classes.cardCategory}>
              Dengan rata keseluruhan mencapai {avgPencapaian}%
            </p>
          </GridItem>
          {props.isSuperAdmin && (
            <GridItem xs={12} md={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel id="select-prodi">Program Studi</InputLabel>
                <Select
                  labelId="select-prodi"
                  id="select-prodi"
                  value={selectedProdi}
                  onChange={handleChangeProdi}
                  label="Program Studi"
                >
                  <MenuItem value="">
                    <em>Semua</em>
                  </MenuItem>
                  {!!props.listProdi &&
                    props.listProdi.map((row, key) => (
                      <MenuItem key={key} value={row.id_prodi}>
                        {row.nama_prodi + ` (${row.jenjang})`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
          )}
        </GridContainer>
      </CardBody>
      <CardFooter chart>
        <div
          className={classes.stats}
          onClick={event => {
            handleFetchData(selectedProdi);
            event.preventDefault();
          }}
        >
          <Update /> updated at {moment(dateLastUpdate).format("HH:mm")}
        </div>
        <div className={classes.stats} style={{ textAlign: "right" }}>
          <Link component={"button"} onClick={handleOpenDialog}>
            detail
          </Link>
        </div>
      </CardFooter>
    </Card>
  );
}

CardStatistikPencapaian.propTypes = {
  isSuperAdmin: PropTypes.bool,
  listProdi: PropTypes.array
};
