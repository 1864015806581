import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @materila-ui/core components
import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import GridItem from "dep/Grid/GridItem";
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";
import CardIcon from "dep/Card/CardIcon";
import CardFooter from "dep/Card/CardFooter";
import Danger from "dep/Typography/Danger";
// components
import DialogOpsiStatMahasiswa from "components/Admin/Dashboard/Dialog/DialogOpsiStatMahasiswa";
import DialogOpsiStatPortofolio from "components/Admin/Dashboard/Dialog/DialogOpsiStatPortofolio";
// @material-ui/icons
import Warning from "@material-ui/icons/Warning";
import LocalOffer from "@material-ui/icons/LocalOffer";
import StorageIcon from "@material-ui/icons/Storage";
import LibraryBooksOutlinedIcon from "@material-ui/icons/LibraryBooksOutlined";
import SchoolOutlinedIcon from "@material-ui/icons/SchoolOutlined";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
// jss
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle";
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";
// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

const useStyles = makeStyles({
  ...dashboardStyle,
  ...tasksStyle,
  noBottom: { marginBottom: 0 }
});

export default function DashboardLittleCards(props) {
  const classes = useStyles();
  useEffect(() => {
    handleFetchStatMahasiswa("", "");
    handleFetchStatPortofolio("");
    handleFetchStatistikStorage();
  }, []);

  // var: values
  const [statMahasiswa, setStatMahasiswa] = useState({
    option: "",
    id_prodi: "",
    header: "",
    body: "",
    footer: ""
  });
  const [statMahasiswaFetchData, setStatMahasiswaFetchData] = useState({
    total_mahasiwa: "",
    total_mahasiswa_belum_lulus: "",
    mahasiswa_belum_lulus_dan_belum_memenuhi_skp: ""
  });
  const [statPortofolio, setStatPortofolio] = useState({
    id_prodi: "",
    header: "",
    body: "",
    footer: ""
  });
  const [statistikStorage, setStatistikStorage] = useState({
    disk_total_space: 0,
    disk_free_space: 0,
    format_disk_total_space: "GB"
  });
  // var: open-close
  const [dialogType, setDialogType] = useState("");

  // handle: open-close
  const handleCloseDialog = () => {
    setDialogType("");
  };
  const handleOpenDialog = type => {
    //type: ["mahasiswa","portofolio"]
    setDialogType(type);
  };
  // handle: API
  const handleFetchStatMahasiswa = async (option, id_prodi) => {
    // id_prodi beda dengan sebelumnya OR belum pernah fetch => fetchData . Intinya kalo cuma ganti opsi ngga fetch ulang
    let rawData;
    if (
      id_prodi === statMahasiswa.id_prodi &&
      statMahasiswaFetchData.total_mahasiwa
    )
      rawData = statMahasiswaFetchData;
    else {
      let res = await Auth.fetch(
        `${BASE_URL}/api/statistik/skpi?id_prodi=${id_prodi}`
      );
      const fetchData = await res.json();
      rawData = fetchData;
      setStatMahasiswaFetchData(fetchData);
    }
    let data;
    switch (option) {
      case "kelulusan":
        data = {
          header: "Jumlah Kelulusan",
          body: rawData.total_mahasiwa - rawData.total_mahasiswa_belum_lulus,
          footer: "Dari " + rawData.total_mahasiwa + " mahasiswa"
        };
        break;
      case "aktif":
        data = {
          header: "Jumlah Mahasiswa Aktif",
          body: rawData.total_mahasiswa_belum_lulus,
          footer: "Dari " + rawData.total_mahasiwa + " mahasiswa"
        };
        break;
      case "SKPI":
        data = {
          header: "Memenuhi Point SKP",
          body:
            rawData.total_mahasiswa_belum_lulus -
            rawData.mahasiswa_belum_lulus_dan_belum_memenuhi_skp,
          footer:
            "Dari " + rawData.total_mahasiswa_belum_lulus + " mahasiswa aktif"
        };
        break;
      default:
        data = {
          header: "Jumlah Mahasiswa Terdaftar",
          body: rawData.total_mahasiwa,
          footer: ""
        };
        break;
    }
    setStatMahasiswa({
      ...data,
      option: option,
      id_prodi: id_prodi
    });
  };
  const handleFetchStatPortofolio = async id_prodi => {
    let res = await Auth.fetch(
      `${BASE_URL}/api/statistik/portofolio?id_prodi=${id_prodi}`
    );
    const fetchData = await res.json();
    setStatPortofolio({
      id_prodi: id_prodi,
      header: "Portofolio belum diverifikasi",
      body: fetchData.belum_diverifikasi,
      footer: "dari " + fetchData.total + " portofolio"
    });
  };

  // helper: render
  const renderProdiName = id_prodi => {
    const values = props.listProdi.find(value => value.id_prodi === id_prodi);
    return values.nama_prodi + ` (${values.jenjang})`;
  };

  const handleFetchStatistikStorage = async () => {
    let res = await Auth.fetch(`${BASE_URL}/api/system/storage`);
    const json = await res.json();
    setStatistikStorage(json);
  };

  return (
    <React.Fragment>
      <DialogOpsiStatMahasiswa
        open={dialogType === "mahasiswa"}
        onClose={handleCloseDialog}
        listProdi={props.listProdi}
        isSuperAdmin={props.isSuperAdmin}
        selectedOption={{
          option: statMahasiswa.option,
          id_prodi: statMahasiswa.id_prodi
        }}
        onChange={handleFetchStatMahasiswa}
      />
      <DialogOpsiStatPortofolio
        listProdi={props.listProdi}
        onChange={handleFetchStatPortofolio}
        onClose={handleCloseDialog}
        open={dialogType === "portofolio"}
        selectedProdi={statPortofolio.id_prodi}
      />
      {props.isSuperAdmin && (
        <GridItem xs={12} md={4}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <StorageIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Used Space</p>
              <h3 className={classes.cardTitle}>
                {statistikStorage.disk_total_space -
                  statistikStorage.disk_free_space}
                /{statistikStorage.disk_total_space}{" "}
                <small>{statistikStorage.format_disk_total_space}</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                {/* <Danger>
                  <Warning />
                </Danger> */}
                {/* <a href="#" onClick={e => e.preventDefault()}>
                  Get more space
                </a> */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      )}

      <GridItem xs={12} md={props.isSuperAdmin ? 4 : 12}>
        <Card className={props.isSuperAdmin ? "" : classes.noBottom}>
          <CardHeader color="info" stats icon>
            <CardIcon color="info">
              <SchoolOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>{statMahasiswa.header}</p>
            <h3 className={classes.cardTitle}>{statMahasiswa.body}</h3>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              <AssignmentIndIcon />
              {statMahasiswa.footer}
              {statMahasiswa.id_prodi &&
                " | " + renderProdiName(statMahasiswa.id_prodi)}
            </div>
            <div className={classes.stats} style={{ textAlign: "right" }}>
              <Link
                component={"button"}
                onClick={event => {
                  handleOpenDialog("mahasiswa");
                  event.preventDefault();
                }}
              >
                opsi
              </Link>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} md={props.isSuperAdmin ? 4 : 12}>
        <Card className={props.isSuperAdmin ? "" : classes.noBottom}>
          <CardHeader color="danger" stats icon>
            <CardIcon color="danger">
              <LibraryBooksOutlinedIcon />
            </CardIcon>
            <p className={classes.cardCategory}>
              Portofolio belum diverifikasi
            </p>
            <h3 className={classes.cardTitle}>{statPortofolio.body}</h3>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              <LocalOffer />
              {statPortofolio.footer}
              {statPortofolio.id_prodi &&
                " | " + renderProdiName(statPortofolio.id_prodi)}
            </div>
            <div
              className={classes.stats}
              style={{ textAlign: "right" }}
              hidden={!props.isSuperAdmin}
            >
              <Link
                component={"button"}
                onClick={event => {
                  handleOpenDialog("portofolio");
                  event.preventDefault();
                }}
              >
                opsi
              </Link>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
    </React.Fragment>
  );
}

DashboardLittleCards.propTypes = {
  isSuperAdmin: PropTypes.bool,
  listProdi: PropTypes.array
};
