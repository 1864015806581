import React, { useEffect, useState } from "react";
import _ from "lodash";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";
// dep components
import CardBody from "dep/Card/CardBody";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Button from "dep/CustomButtons/Button";
// components
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";
import LittleCardPortofolio from "components/User/Portofolio/LiitleCard/LittleCardPortofolio";
import CardPortofolio from "components/User/Portofolio/Card/CardPortofolio";
// @material-ui/icons
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import cardStyles from "assets/jss/jpt/cardStyles";

// styling
const useStyles = makeStyles(cardStyles);

export default function CardPortofolioMain() {
  const classes = useStyles();
  useEffect(() => {
    handleFetchCategories();
  }, []);
  // var: open-close
  const [showCard, setShowCard] = useState(true);

  // var: values
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  // var: notif
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });

  // handle: API fetch
  const handleFetchCategories = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/statistik/user-portofolio");
    let json = await res.json();
    let newCategories = [];
    _.get(json, "bidang", []).map(row => {
      newCategories.push({
        ...row,
        jumlah_portofolio: _.get(row, "statistik.portofolio_count", 0),
        jumlah_skp: _.get(row, "statistik.bobot_skp", 0)
      });
    });
    setCategories(newCategories || []);
  };

  // handle: values
  const handleChooseAllCategories = () => {
    setSelectedCategory({
      id_bidang_portofolio: null,
      nama_bidang_portofolio: "Semua Bidang",
      nama_bidang_portofolio_inggris: "of All Fields"
    });
    setShowCard(false);
  };
  const handleChooseCategory = category => {
    setSelectedCategory(category);
    setShowCard(false);
  };

  // handle: open-close
  const handleBack = () => {
    setShowCard(true);
    setSelectedCategory({});
  };

  // handle: notif
  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };

  return (
    <>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
      />
      {showCard ? (
        <GridContainer>
          <GridItem xs={12} xl={10}>
            <Card>
              <CardHeader color={"primary"}>
                <GridContainer
                  justify={"space-between"}
                  alignItems={"flex-end"}
                >
                  <GridItem>
                    <h4 className={classes.cardTitleWhite}>
                      Bidang Portofolio
                    </h4>
                    <p className={classes.cardCategoryWhite}>
                      Pilih bidang yang diinginkan
                    </p>
                  </GridItem>
                  <GridItem>
                    <Button
                      color={"white"}
                      round
                      onClick={handleChooseAllCategories}
                      size={"sm"}
                    >
                      <AllInclusiveIcon />
                      Pilih Semua
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {categories.map((value, index) => (
                    <GridItem xs={12} md={4} key={index}>
                      <LittleCardPortofolio
                        selectedBidangPortofolio={value}
                        handleChooseCategory={handleChooseCategory}
                        openSnackbar={openSnackbar}
                      />
                    </GridItem>
                  ))}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        <CardPortofolio
          selectedCategory={selectedCategory}
          handleBack={handleBack}
        />
      )}
    </>
  );
}
