import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Button from "dep/CustomButtons/Button";
// for API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function DialogDeletePortofolio(props) {
  const handleCloseDialog = () => {
    props.onClose();
  };
  const handleAPIDelete = async () => {
    let nim = Auth.getToken().nim;

    props.openSnackbar("info", "Processing");
    let res = await Auth.fetchMultipart(
      BASE_URL + `/api/portofolio/user/${nim}/${props.idPortofolio}/delete`,
      {
        method: "DELETE"
      }
    );

    if (res.status >= 200 && res.status < 300) {
      props.setSnackbarOpen(false);
      props.openSnackbar("success", "Berhasil menghapus portofolio");
      props.fetchPortofolio();
      handleCloseDialog();
    } else {
      props.setSnackbarOpen(false);
      props.openSnackbar("error", (await res.json()).message);
    }
    handleCloseDialog();
  };

  return (
    <Dialog open={props.open || false} onClose={handleCloseDialog}>
      <DialogTitle>Apakah Anda Yakin?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Anda akan menghapus portofolio dengan judul {props.judul || ""}
        </DialogContentText>
        <GridContainer justify={"space-evenly"}>
          <GridItem>
            <Button color={"danger"} round onClick={handleCloseDialog}>
              Tidak
            </Button>
          </GridItem>
          <GridItem>
            <Button color={"success"} round onClick={handleAPIDelete}>
              Ya
            </Button>
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
}

DialogDeletePortofolio.propTypes = {
  idPortofolio: PropTypes.number,
  judul: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  openSnackbar: PropTypes.func,
  setSnackbarOpen: PropTypes.func,
  fetchPortofolio: PropTypes.func
};
