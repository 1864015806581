import React from "react";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CardProfile from "components/User/Profile/Card/CardProfile";

export default function Profile() {
  return (
    <GridContainer>
      <GridItem xs={12} lg={8}>
        <CardProfile />
      </GridItem>
    </GridContainer>
  );
}
