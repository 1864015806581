import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  Popover,
  Typography,
  Container,
  IconButton,
  Input,
  InputAdornment,
  Grid,
  Button,
  CircularProgress
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";

const PopoverLogin = props => {
  const [values, setValues] = React.useState({
    nim: "",
    password: "",
    showPassword: false,
    loggingIn: false
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      marginThreshold={8}
    >
      <Container>
        <Typography variant={"h5"} align={"center"} gutterBottom>
          Sign In
        </Typography>
        <Typography align={"center"} gutterBottom>
          Silahkan masukkan NIM dan Password
        </Typography>
        <div>
          <FormControl fullWidth={true} margin={"normal"}>
            <InputLabel>NIM atau email</InputLabel>
            <Input value={values.nim} onChange={handleChange("nim")} />
          </FormControl>
        </div>
        <div>
          <FormControl fullWidth={true} margin={"normal"}>
            <InputLabel>Password</InputLabel>
            <Input
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              onKeyDown={async e => {
                if (e.key === "Enter") {
                  await setValues({ ...values, loggingIn: true });
                  await props.handleLogin(values.nim, values.password);
                  await setValues({ ...values, loggingIn: false });
                  e.preventDefault();
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Typography align={"right"} style={{ marginBottom: 7 }}>
            Lupa password? klik <Link to="/forgot-password">di sini</Link>
          </Typography>
        </div>
        <Grid container spacing={3}>
          <Grid item xs />
          <Grid item xs>
            <Link to={"/register"} style={{ textDecoration: "none" }}>
              <Button size={"large"} variant={"contained"} color={"secondary"}>
                Daftar
              </Button>
            </Link>
          </Grid>
          <Grid item xs>
            <Button
              size={"large"}
              variant={"contained"}
              color={"primary"}
              onClick={async e => {
                await setValues({ ...values, loggingIn: true });
                await props.handleLogin(values.nim, values.password);
                await setValues({ ...values, loggingIn: false });
                e.preventDefault();
              }}
            >
              {values.loggingIn ? (
                <CircularProgress size={22} color="secondary" />
              ) : (
                "Login"
              )}
            </Button>
          </Grid>
          <Grid item xs />
        </Grid>
        <br />
      </Container>
    </Popover>
  );
};

export default PopoverLogin;

PopoverLogin.propTypes = {
  anchorEl: PropTypes.any,
  handleLogin: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
