const formStyles = {
  formControl: {
    color: "rgba(255,255,255,.75)",
    margin: "0",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
  },
  autocomplete: {
    minWidth: "500px",
    maxWidth: "600px",
    margin: "10px"
  },
  button: {
    marginBottom: "20px",
    marginTop: "20px"
  }
};
export default formStyles;
