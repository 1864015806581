import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Button from "dep/CustomButtons/Button";
// for API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function DialogShowHidePortofolio(props) {
  const handleCloseDialog = () => {
    props.onClose();
  };
  const handleAPISwitch = async () => {
    let nim = Auth.getToken().nim;

    props.openSnackbar("info", "Processing");
    let SWITCH_URL;
    if (props.showed) {
      SWITCH_URL =
        BASE_URL + `/api/portofolio/user/${nim}/${props.idPortofolio}/hide`;
    } else {
      SWITCH_URL =
        BASE_URL + `/api/portofolio/user/${nim}/${props.idPortofolio}/unhide`;
    }
    let res = await Auth.fetchMultipart(SWITCH_URL, {
      method: "PUT"
    });

    if (res.status >= 200 && res.status < 300) {
      props.setSnackbarOpen(false);
      props.openSnackbar("success", "Berhasil mengubah portofolio");
      props.fetchPortofolio();
      handleCloseDialog();
    } else {
      props.setSnackbarOpen(false);
      props.openSnackbar("error", (await res.json()).message);
    }
    handleCloseDialog();
  };

  return (
    <Dialog open={props.open || false} onClose={handleCloseDialog}>
      <DialogTitle>Apakah Anda Yakin?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Anda akan {props.showed ? "menyembunyikan" : "memperlihatkan"}{" "}
          Portofolio dengan judul {props.judul || ""}
        </DialogContentText>
        <GridContainer justify={"space-evenly"}>
          <GridItem>
            <Button color={"danger"} round onClick={handleCloseDialog}>
              Tidak
            </Button>
          </GridItem>
          <GridItem>
            <Button color={"success"} round onClick={handleAPISwitch}>
              Ya
            </Button>
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
}

DialogShowHidePortofolio.propTypes = {
  idPortofolio: PropTypes.number,
  judul: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  showed: PropTypes.bool,
  openSnackbar: PropTypes.func,
  setSnackbarOpen: PropTypes.func,
  fetchPortofolio: PropTypes.func
};
