import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

// core components
import Table from "dep/Table/Table.js";
import Card from "dep/Card/Card.js";
import CardHeader from "dep/Card/CardHeader.js";
import CardBody from "dep/Card/CardBody.js";
import Button from "dep/CustomButtons/Button.js";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
// components
//import VerifikasiModal from "components/Admin/Portofolio/Modal/VerifikasiModal";
import SearchMenungguVerifikasi from "components/Admin/Portofolio/Input/SearchMenungguVerifikasi";
import DialogVerifikasiPortofolio from "components/Admin/Portofolio/Dialog/DialogVerifikasiPortofolio";

// Import const
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import cardStyles from "assets/jss/jpt/cardStyles";

const STATUS_MENUNGGU_VERIFIKASI = 1;
const ITEM_PER_PAGE = 7;

const useStyles = makeStyles(cardStyles);

export default function CardMenungguVerifikasi(props) {
  const classes = useStyles();

  // props
  const { openSnackbar } = props;

  const [openVerifikasiModal, setOpenVerifikasiModal] = useState(false);
  const [tableDatas, setTableDatas] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPortofolio, setSelectedPortofolio] = useState({});
  const [page, setPage] = useState(1);

  useEffect(() => {
    async function init() {
      openSnackbar("info", "Memuat...");
      await fetchMenungguVerifikasi();
      openSnackbar("success", "Ditemukan...");
    }
    init();
    // eslint-disable-next-line
  }, [page, searchQuery]);

  const fetchMenungguVerifikasi = async () => {
    let url = `${BASE_URL}/api/portofolio?paginate=${ITEM_PER_PAGE}&page=${page}&status=${STATUS_MENUNGGU_VERIFIKASI}&verifiable=1`;
    if (searchQuery) url += "&judul=" + searchQuery + "&nim=" + searchQuery;
    let res = await Auth.fetch(url);
    let data = await res.json();

    setPageCount(_.get(data, "portofolio.last_page", 1));

    let newData = _.get(data, "portofolio.data", []).map((element, i) => {
      return [
        ITEM_PER_PAGE * (page - 1) + i + 1,
        _.get(element, "judul"),
        _.get(element, "nim"),
        _.get(element, "nama_mahasiswa", "Nama Mahasiswa"),
        _.get(element, "nama_anak_rubrik", "Nama Anak Rubrik"),
        _.get(element, "bobot_skp_portofolio"),
        moment(_.get(element, "tanggal_terbit")).format("DD-MM-YYYY"),
        generateButtonOpenModal(element)
      ];
    });

    setTableDatas(newData);
  };

  const handleChangePage = (_e, page = 1) => {
    setPage(page);
  };

  const handleOpenDialogVerifikasi = portofolioData => {
    setSelectedPortofolio(portofolioData);
    setOpenVerifikasiModal(true);
  };

  const handleCloseDialogVerifikasi = () => {
    setSelectedPortofolio({});
    setOpenVerifikasiModal(false);
    props.setDoRefresh(!props.doRefresh);
    fetchMenungguVerifikasi();
  };

  const handleSearch = async (query = "") => {
    setSearchQuery(query);
    setPage(1);
  };

  const generateButtonOpenModal = portofolioData => {
    return (
      <div style={{ marginRight: "0px" }}>
        <Button
          type="button"
          color="success"
          size="sm"
          round
          onClick={event => {
            handleOpenDialogVerifikasi(portofolioData);
            event.preventDefault();
          }}
        >
          Verifikasi
        </Button>
      </div>
    );
  };

  return (
    <Card>
      <DialogVerifikasiPortofolio
        onClose={handleCloseDialogVerifikasi}
        open={openVerifikasiModal}
        portofolioData={selectedPortofolio}
        openSnackbar={openSnackbar}
      />
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Menunggu Verifikasi</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={false} lg={9} />
          <GridItem xs={false} lg={3}>
            <SearchMenungguVerifikasi handleSearch={handleSearch} />
          </GridItem>
        </GridContainer>
        <Table
          tableHeaderColor="primary"
          tableHead={[
            "No",
            "Judul",
            "NIM",
            "Nama Mahasiswa",
            "Rubrik",
            "SKP",
            "Tanggal",
            "Opsi"
          ]}
          tableData={tableDatas}
        />
      </CardBody>
      <Pagination
        style={{ margin: "auto", marginBottom: "20px", marginTop: "10px" }}
        count={pageCount}
        size="large"
        onChange={handleChangePage}
      />
    </Card>
  );
}

CardMenungguVerifikasi.propTypes = {
  openSnackbar: PropTypes.func
};
