import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";
import Button from "dep/CustomButtons/Button";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function DialogTambahChildrenManajemenCPL(props) {
  // props
  const {
    parentCPL = {
      id_cpl: "",
      judul_cpl_indonesia: "",
      judul_cpl_inggris: "",
      urutan_di_skpi: ""
    },
    handleFetchCPLData,
    openSnackbar
  } = props;

  // state
  const [anakCPLData, setAnakCPLData] = useState({
    urutan_di_skpi: "",
    point_cpl_indonesia: "",
    point_cpl_inggris: ""
  });
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (props.parentCPL !== "" && props.newDialogType === "add") {
      setOpenDialog(true);
    }
    // eslint-disable-next-line
  }, [props.parentCPL, props.newDialogType]);

  // handle: API submit
  const handleAPISubmit = async () => {
    try {
      openSnackbar("info", "Menambahkan..");
      let res = await Auth.fetch(
        BASE_URL + "/api/cpl/" + props.parentCPL.id_cpl + "/add-point",
        {
          method: "POST",
          body: JSON.stringify(anakCPLData)
        }
      );

      let data = await res.json();
      let errorMessages = "";
      if (typeof data.message === "string") {
        errorMessages = data.message;
      } else if (typeof data.message === "object") {
        errorMessages = "";
        if (Array.isArray(data.message)) {
          for (let err of data.message) {
            errorMessages +=
              typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
          }
        } else {
          for (let key in data.message) errorMessages += data.message[key][0];
        }
      }

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data cpl berhasil ditambahkan!");
        handleFetchCPLData();
        handleCloseDialog();
      } else if (res.status >= 400 && res.status < 500) {
        openSnackbar("warning", errorMessages);
      } else {
        openSnackbar("error", errorMessages);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  const changeAnakCPLData = e => {
    setAnakCPLData({ ...anakCPLData, [e.target.name]: e.target.value });
  };

  // handle: open-close -> props
  const handleCloseDialog = () => {
    setOpenDialog(false);
    props.handleChangeSelectedCPL("");
    props.handleChangeNewDialogType("");
    //reset
    setAnakCPLData({
      urutan_di_skpi: "",
      point_cpl_indonesia: "",
      point_cpl_inggris: ""
    });
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="dialog-title"
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle id="dialog-title">Tambah Item CPL</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {"Parent Item Info"}
          <br />
          {"Nama Item (ID): " + parentCPL.judul_cpl_indonesia}
          <br />
          {"Item Name (EN): " + parentCPL.judul_cpl_inggris}
        </DialogContentText>
        <Divider />
        <DialogContentText>
          {"Tambah Item Capaian Pembelajaran Lulusan"}
        </DialogContentText>
        <GridContainer justify={"center"}>
          <GridItem xs={10} md={8}>
            <CustomInput
              labelText="Urutan"
              id="order"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "urutan_di_skpi",
                type: "number",
                value: anakCPLData.urutan_di_skpi,
                onChange: changeAnakCPLData
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify={"center"}>
          <GridItem xs={10} md={8}>
            <CustomInput
              labelText="Nama Item (Bahasa)"
              id="item-name-ID"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "point_cpl_indonesia",
                value: anakCPLData.point_cpl_indonesia,
                onChange: changeAnakCPLData
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify={"center"}>
          <GridItem xs={10} md={8}>
            <CustomInput
              labelText="Item Name (English)"
              id="item-name-EN"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "point_cpl_inggris",
                value: anakCPLData.point_cpl_inggris,
                onChange: changeAnakCPLData
              }}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="warning" round size={"sm"}>
          Discard
        </Button>
        <Button
          onClick={handleAPISubmit}
          color="success"
          autoFocus
          round
          size={"sm"}
          disabled={
            !anakCPLData.point_cpl_inggris ||
            !anakCPLData.point_cpl_indonesia ||
            !anakCPLData.urutan_di_skpi
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogTambahChildrenManajemenCPL.propTypes = {
  handleChangeNewDialogType: PropTypes.func,
  handleChangeSelectedCPL: PropTypes.func,
  handleFetchCPLData: PropTypes.func,
  newDialogType: PropTypes.string,
  openSnackbar: PropTypes.func,
  parentCPL: PropTypes.object
};
