import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  DialogContent
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import Tabs from "dep/CustomTabs/CustomTabs";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";
import Button from "dep/CustomButtons/Button";
import CustomSelect from "dep/CustomInput/CustomSelect";
// @material-ui/icons
import ContactsIcon from "@material-ui/icons/Contacts";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import formStyles from "assets/jss/jpt/formStyles";
import valuesStyles from "assets/jss/jpt/valuesStyles";

// API
import Auth from "helpers/auth";
import { BASE_URL, ROLE_SUPER_ADMIN } from "const";

const useStyles = makeStyles({
  ...formStyles,
  ...valuesStyles
});

export default function DialogAdvancedPrintSKPI(props) {
  //TODO: tolong kalo pas Dialog ini kebuka kasih notif berhati-hati
  const { openSnackbar } = props;
  const classes = useStyles();

  //var: values
  const [NIMValues, setNIMValues] = useState("");
  const [prodiValues, setProdiValues] = useState({
    prodi: "",
    angkatan: new Date().getFullYear().toString(),
    lulus: ""
  });
  useEffect(() => {
    handleFetchListAngkatan();
    setProdiValues({ ...prodiValues, prodi: Auth.getIdProdi() });
  }, []);
  const [selectedPeriode, setSelectedPeriode] = useState("");
  const [overrideNewPrint, setOverrideNewPrint] = useState(false);
  const [selectFakultas, setSelectFakultas] = useState("");
  //var: list
  const [listAngkatan, setListAngkatan] = useState([]);
  const [listProdi, setListProdi] = useState([]);
  const [listFakultas, setListFakultas] = useState([]);
  //var: open-close
  const [openSelectFakultas, setOpenSelectFakultas] = useState(false);
  const [openSelectProdi, setOpenSelectProdi] = useState(false);
  const [openSelectAngkatan, setOpenSelectAngkatan] = useState(false);
  const [openSelectLulus, setOpenSelectLulus] = useState(false);

  //handle: API submit and download
  const handleSubmitByProdi = async () => {
    try {
      if (overrideNewPrint) {
        if (!confirmOverride()) return;
      }
      let res = await Auth.fetch(
        `${BASE_URL}/api/manajemen/skpi/download/bulk/prodi?prodi=${
          prodiValues.prodi
        }&periode=${selectedPeriode}&override=${
          overrideNewPrint ? 1 : 0
        }&tahun_masuk=${prodiValues.angkatan}&lulus=${
          prodiValues.lulus ? 1 : 0
        }`
      );

      if (res.status >= 200 && res.status < 300) {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(await res.blob());
        link.setAttribute("download", "skpi.zip");
        document.body.appendChild(link);
        link.click();
        link.href = "";
      } else {
        let data = await res.json();
        let errorMessages = "";
        if (typeof data.message === "string") {
          errorMessages = data.message;
        } else if (typeof data.message === "object") {
          errorMessages = "";
          if (Array.isArray(data.message)) {
            for (let err of data.message) {
              errorMessages +=
                typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
            }
          } else {
            for (let key in data.message) errorMessages += data.message[key][0];
          }
        }
        openSnackbar(
          res.status >= 400 && res.status < 500 ? "warning" : "error",
          errorMessages
        );
      }

      props.onClose();
      props.onSuccess();
      handleResetData();
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  const handleSubmitByNIM = async () => {
    try {
      if (overrideNewPrint) {
        if (!confirmOverride()) return;
      }
      const values = NIMValues.split(/[, ]+/);
      if (values.length === 1) {
        let res = await Auth.fetch(
          `${BASE_URL}/api/manajemen/skpi/download/${
            values[0]
          }?periode=${selectedPeriode}&override=${overrideNewPrint ? 1 : 0}`
        );

        let data = await res.json();
        if (res.status >= 200 && res.status < 300) {
          let res = await Auth.fetch(BASE_URL + "/" + data.message);
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(await res.blob());
          link.setAttribute("download", data.message.split("/").pop());
          document.body.appendChild(link);
          link.click();
          props.onSuccess();
          props.onClose();
        } else {
          let errorMessages = "";
          if (typeof data.message === "string") {
            errorMessages = data.message;
          } else if (typeof data.message === "object") {
            errorMessages = "";
            if (Array.isArray(data.message)) {
              for (let err of data.message) {
                errorMessages +=
                  typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
              }
            } else {
              for (let key in data.message)
                errorMessages += data.message[key][0];
            }
          }
          openSnackbar(
            res.status >= 400 && res.status < 500 ? "warning" : "error",
            errorMessages
          );
        }
      } else {
        let idx = 0;
        let res = await Auth.fetch(
          `${BASE_URL}/api/manajemen/skpi/download/bulk?${values.reduce(
            (acc, val) => `${acc}nim[${idx++}]=${val}&`,
            ""
          )}periode=${selectedPeriode}&override=${overrideNewPrint ? 1 : 0}`
        );

        if (res.status >= 200 && res.status < 300) {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(await res.blob());
          link.setAttribute("download", "skpi.zip");
          document.body.appendChild(link);
          link.click();
          link.href = "";
          props.onSuccess();
          props.onClose();
        } else {
          let data = await res.json();
          let errorMessages = "";
          if (typeof data.message === "string") {
            errorMessages = data.message;
          } else if (typeof data.message === "object") {
            errorMessages = "";
            if (Array.isArray(data.message)) {
              for (let err of data.message) {
                errorMessages +=
                  typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
              }
            } else {
              for (let key in data.message)
                errorMessages += data.message[key][0];
            }
          }
          openSnackbar(
            res.status >= 400 && res.status < 500 ? "warning" : "error",
            errorMessages
          );
        }
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  const confirmOverride = () => {
    return window.confirm("Apakah anda yakin akan override print?");
  };

  //handle: fetch->list
  const handleFetchListFakultas = () => {
    setListFakultas(
      props.listFakultas.map(e => {
        return {
          id_fakultas: _.get(e, "id_fakultas"),
          nama_fakultas: _.get(e, "nama_fakultas")
        };
      })
    );
  };
  const handleFetchListAngkatan = () => {
    let listAngkatan = [];
    for (let i = 1958; i <= new Date().getFullYear() + 1; i++) {
      listAngkatan.push({ id: i.toString(), angkatan: i.toString() });
    }
    setListAngkatan(listAngkatan);
  };
  const handleFetchListProdi = fakultas => {
    let listProdi = props.listProdi
      .filter(element => element.id_fakultas === fakultas)
      .map(e => {
        return {
          id_prodi: _.get(e, "id_prodi"),
          nama_prodi: `${_.get(e, "nama_prodi")} (${_.get(e, "jenjang")})`
        };
      });

    setListProdi(listProdi);
  };
  //handle: open-close
  const handleCloseSelectFakultas = () => {
    setOpenSelectFakultas(false);
  };
  const handleOpenSelectFakultas = () => {
    handleFetchListFakultas();
    setOpenSelectFakultas(true);
  };
  const handleCloseSelectProdi = () => {
    setOpenSelectProdi(false);
  };
  const handleOpenSelectProdi = () => {
    setOpenSelectProdi(true);
  };
  const handleCloseSelectLulus = () => {
    setOpenSelectLulus(false);
  };
  const handleOpenSelectLulus = () => {
    setOpenSelectLulus(true);
  };
  const handleCloseSelectAngkatan = () => {
    setOpenSelectAngkatan(false);
  };
  const handleOpenSelectAngkatan = () => {
    setOpenSelectAngkatan(true);
  };
  const handleClose = () => {
    props.onClose();
    handleResetData();
  };
  //handle: values
  const handleChangeSelectFakultas = event => {
    setSelectFakultas(event.target.value);
    handleFetchListProdi(event.target.value);
    setProdiValues({ ...prodiValues, prodi: "" });
  };
  const handleChangeNIMValues = event => {
    setNIMValues(event.target.value);
  };
  const handleResetData = () => {
    setNIMValues("");
    setProdiValues({
      prodi: "",
      angkatan: "",
      lulus: ""
    });
    setOverrideNewPrint(false);
    setSelectFakultas("");
    setListProdi([]);
  };
  const handleChangeProdiValues = prop => event => {
    setProdiValues({ ...prodiValues, [prop]: event.target.value });
  };
  const handleChangeSelectedPeriode = event => {
    setSelectedPeriode(event.target.value);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth
      maxWidth={"md"}
      scroll={"paper"}
    >
      <DialogContent>
        <Tabs
          title={"Cetak SKPI"}
          headerColor={"primary"}
          plainTabs
          tabs={[
            {
              tabName: "Nomor Mahasiswa",
              tabIcon: ContactsIcon,
              tabContent: (
                <div>
                  <GridContainer justify={"center"}>
                    <GridItem>
                      <h4 className={classes.text}>
                        Cetak SKPI berdasarkan NIM
                      </h4>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify={"center"}>
                    <GridItem xs={10}>
                      <p className={classes.text}>
                        Masukkan Nomor Induk Mahasiswa yang akan dicetak secara
                        bersamaan. Gunakan spasi {'(" ")'} atau tanda koma{" "}
                        {'(",")'}
                        sebagai separator. Pastikan Nomor Induk Mahasiswa yang
                        dimasukkan merupakan nomor yang benar dan pastikan juga
                        mahasiswa tersebut memang berhak mendapatkannya.
                      </p>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify={"center"}>
                    <GridItem xs={10} md={8}>
                      <CustomInput
                        labelText="Nomor Induk Mahasiswa"
                        id="print-NIM"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: NIMValues,
                          onChange: handleChangeNIMValues,
                          multiline: true,
                          rows: 3
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify={"center"}>
                    <GridItem xs={10} md={4}>
                      <CustomSelect
                        labelText="Periode"
                        id="periode"
                        formControlProps={{
                          fullWidth: true
                        }}
                        selectProps={{
                          id: "periode",
                          name: "periode",
                          onChange: handleChangeSelectedPeriode
                        }}
                        selectItems={props.listPeriode.map(values => ({
                          value: values[0],
                          text:
                            values[1] +
                            "/" +
                            (~~values[1] + 1) +
                            " periode " +
                            values[2]
                        }))}
                        selectValue={selectedPeriode}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify={"flex-end"}>
                    <GridItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={overrideNewPrint}
                            onChange={event => {
                              setOverrideNewPrint(event.target.checked);
                            }}
                            color="secondary"
                          />
                        }
                        label={
                          <p
                            className={
                              overrideNewPrint
                                ? classes.textWarning + " " + classes.center
                                : classes.textCheckbox + " " + classes.center
                            }
                          >
                            Override New Print
                            <sup className={classes.textWarning}>*</sup>
                          </p>
                        }
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify={"space-between"}>
                    <GridItem xs={12} md>
                      <p
                        className={
                          overrideNewPrint
                            ? classes.textWarning
                            : classes.textCheckbox
                        }
                      >
                        <sup>*</sup>Cetak SKPI terbaru meskipun sudah pernah
                        cetak SKPI
                      </p>
                    </GridItem>
                    <GridItem xs={12} md={3}>
                      <GridContainer justify={"center"}>
                        <GridItem>
                          <Button
                            size={"sm"}
                            color={"warning"}
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button
                            size={"sm"}
                            color={"success"}
                            onClick={handleSubmitByNIM}
                            disabled={!NIMValues || !selectedPeriode}
                          >
                            Print
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </div>
              )
            },
            {
              tabName: "Program Studi",
              tabIcon: LocalLibraryIcon,
              tabContent: (
                <div>
                  <GridContainer justify={"center"}>
                    <GridItem>
                      <h4 className={classes.text}>
                        Cetak SKPI berdasarkan Program Studi
                      </h4>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify={"center"}>
                    <GridItem xs={10}>
                      <p className={classes.text}>
                        {Auth.isRoleEqualTo(ROLE_SUPER_ADMIN)
                          ? "Masukkan Fakultas, Prodi, Angkatan, Kelulusan yang akan dicetak. Pastikan telah disetujui oleh Program Studi yang bersangkutan"
                          : "Anda akan mencetak semua SKPI yang memenuhi opsi dibawah, pastikan opsi benar"}
                      </p>
                    </GridItem>
                  </GridContainer>
                  <div style={{ padding: "1vh" }} />
                  {Auth.isRoleEqualTo(ROLE_SUPER_ADMIN) && (
                    <>
                      <GridContainer justify={"center"}>
                        <GridItem xs={10} md={8}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <InputLabel id="controlled-open-select-label">
                              Pilih Fakultas
                            </InputLabel>
                            <Select
                              labelId="controlled-open-select-label"
                              id="controlled-open-select"
                              open={openSelectFakultas}
                              onClose={handleCloseSelectFakultas}
                              onOpen={handleOpenSelectFakultas}
                              value={selectFakultas}
                              onChange={handleChangeSelectFakultas}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {listFakultas.map((row, key) => (
                                <MenuItem key={key} value={row.id_fakultas}>
                                  {row.nama_fakultas}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <div style={{ padding: "1vh" }} />
                      <GridContainer justify={"center"}>
                        <GridItem xs={10} md={8}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                          >
                            <InputLabel id="controlled-open-select-label">
                              Pilih Program Studi
                            </InputLabel>
                            <Select
                              labelId="controlled-open-select-label"
                              id="controlled-open-select"
                              open={openSelectProdi}
                              onClose={handleCloseSelectProdi}
                              onOpen={handleOpenSelectProdi}
                              value={prodiValues.prodi}
                              onChange={handleChangeProdiValues("prodi")}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {listProdi.map((row, key) => (
                                <MenuItem key={key} value={row.id_prodi}>
                                  {row.nama_prodi}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <div style={{ padding: "1vh" }} />
                    </>
                  )}

                  <GridContainer justify={"center"}>
                    <GridItem xs={10} md={4}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="controlled-open-select-label">
                          Tahun Masuk
                        </InputLabel>
                        <Select
                          labelId="controlled-open-select-label"
                          id="controlled-open-select"
                          open={openSelectAngkatan}
                          onClose={handleCloseSelectAngkatan}
                          onOpen={handleOpenSelectAngkatan}
                          value={prodiValues.angkatan}
                          onChange={handleChangeProdiValues("angkatan")}
                        >
                          <MenuItem value={""}>
                            <em>None</em>
                          </MenuItem>
                          {listAngkatan.map((row, key) => (
                            <MenuItem key={key} value={row.id}>
                              {row.angkatan}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={10} md={4}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="controlled-open-select-label">
                          Kelulusan
                        </InputLabel>
                        <Select
                          labelId="controlled-open-select-label"
                          id="controlled-open-select"
                          open={openSelectLulus}
                          onClose={handleCloseSelectLulus}
                          onOpen={handleOpenSelectLulus}
                          value={prodiValues.lulus}
                          onChange={handleChangeProdiValues("lulus")}
                        >
                          <MenuItem value={""}>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={1}>sudah</MenuItem>
                          <MenuItem value={0}>belum</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify={"center"}>
                    <GridItem xs={10} md={4}>
                      <CustomSelect
                        labelText="Periode"
                        id="periode"
                        formControlProps={{
                          fullWidth: true
                        }}
                        selectProps={{
                          id: "periode",
                          name: "periode",
                          onChange: handleChangeSelectedPeriode
                        }}
                        selectItems={props.listPeriode.map(values => ({
                          value: values[0],
                          text:
                            values[1] +
                            "/" +
                            (~~values[1] + 1) +
                            " periode " +
                            values[2]
                        }))}
                        selectValue={selectedPeriode}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify={"flex-end"}>
                    <GridItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={overrideNewPrint}
                            onChange={event => {
                              setOverrideNewPrint(event.target.checked);
                            }}
                            color="secondary"
                          />
                        }
                        label={
                          <p
                            className={
                              overrideNewPrint
                                ? classes.textWarning + " " + classes.center
                                : classes.textCheckbox + " " + classes.center
                            }
                          >
                            Override New Print
                            <sup className={classes.textWarning}>*</sup>
                          </p>
                        }
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify={"space-between"}>
                    <GridItem>
                      <p
                        className={
                          overrideNewPrint
                            ? classes.textWarning
                            : classes.textCheckbox
                        }
                      >
                        <sup>*</sup>Cetak SKPI terbaru meskipun sudah pernah
                        cetak SKPI
                      </p>
                    </GridItem>
                    <GridItem>
                      <GridContainer>
                        <GridItem>
                          <Button
                            size={"sm"}
                            color={"warning"}
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button
                            size={"sm"}
                            color={"success"}
                            onClick={handleSubmitByProdi}
                            disabled={
                              !prodiValues.angkatan ||
                              !prodiValues.prodi ||
                              !selectedPeriode
                            }
                          >
                            Print
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </div>
              )
            }
          ]}
        />
      </DialogContent>
    </Dialog>
  );
}

DialogAdvancedPrintSKPI.propTypes = {
  listFakultas: PropTypes.array,
  listPeriode: PropTypes.array,
  listProdi: PropTypes.array,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  openSnackbar: PropTypes.func
};
