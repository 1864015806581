import React from "react";
import PropTypes from "prop-types";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomSnackbar(props) {
  return (
    <Snackbar autoHideDuration={props.timeout || 6000} {...props}>
      <Alert {...props}> {props.message} </Alert>
    </Snackbar>
  );
}

CustomSnackbar.propTypes = {
  timeout: PropTypes.number,
  message: PropTypes.string
};
