import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions
} from "@material-ui/core";
// dep components
import Button from "dep/CustomButtons/Button";
// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function DialogPengajuanCetakSKPI(props) {
  const { openSnackbar } = props;
  const handleClose = () => {
    props.onClose();
  };
  const handleSubmit = async () => {
    try {
      openSnackbar("info", "Mengajukan..");
      let res = await Auth.fetchMultipart(BASE_URL + "/api/skpi/request", {
        method: "POST"
      });
      let data = await res.json();

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "SKPI berhasil diajukan!");
        handleClose();
      } else {
        let errorMessages = "";
        if (typeof data.message === "string") {
          errorMessages = data.message;
        } else if (typeof data.message === "object") {
          errorMessages = "";
          if (Array.isArray(data.message)) {
            for (let err of data.message) {
              errorMessages +=
                typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
            }
          } else {
            for (let key in data.message) errorMessages += data.message[key][0];
          }
        }

        openSnackbar(
          res.status >= 400 && res.status < 500 ? "warning" : "error",
          errorMessages
        );
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };
  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      scroll={"body"}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle>Apakah Anda Yakin?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Dengan mengajukan permintaan ini, Anda akan mengunci data portofolio
          yang anda gunakan pada Surat Keterangan Pendamping Ijazah. Pastikan
          semua portofolio yang akan anda tampilkan sudah dikirimkan ke dalam
          sistem. Selama masa pengajuan cetak SKPI, maka portofolio Anda tidak
          bisa diubah.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color={"warning"} onClick={handleClose}>
          Tidak
        </Button>
        <Button color={"success"} onClick={handleSubmit}>
          Ya
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogPengajuanCetakSKPI.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  openSnackbar: PropTypes.func
};
