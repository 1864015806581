import React, { useState } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import _ from "lodash";
import Joi from "joi";
// @material-ui/core components
import {
  Collapse,
  Container,
  IconButton,
  InputAdornment
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";
import CardBody from "dep/Card/CardBody";
import CustomInput from "dep/CustomInput/CustomInput";
import Button from "dep/CustomButtons/Button";
// components
import Navbar from "components/Public/Navbar/Navbar";
// styling
import userAccountStyles from "assets/jss/jpt/userAccountStyles";
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";
// material-ui/icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CircularProgress from "@material-ui/core/CircularProgress";

import Auth from "helpers/auth";
import { BASE_URL } from "const";

const useStyles = makeStyles(userAccountStyles);

export default function ForgotPasswordNext(props) {
  const { token } = props.match.params;
  const classes = useStyles();

  // var: open-close
  const [submited, setSubmited] = useState(false);
  // var: values
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [submitButtonValue, setSubmitButtonValue] = useState("Submit");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });
  const [errors, setErrors] = useState({
    passwordError: "",
    rePasswordError: ""
  });

  const openSnackbar = (severity, message, timeout = 2000) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message, timeout });
  };

  const validatePassword = () => {
    let { error } = Joi.string()
      .label("Password")
      .min(8)
      .max(20)
      .validate(password);
    return _.get(error, "message", "").replace(/"/g, "");
  };

  const validateRePassword = () => {
    let { error } = Joi.string()
      .label("Password confirmation")
      .valid(password)
      .messages({
        "any.only": "Password confirmation do not match"
      })
      .validate(rePassword);
    return _.get(error, "message", "").replace(/"/g, "");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowRePassword = () => {
    setShowRePassword(!showRePassword);
  };
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  // handle: API
  const handleAPISubmit = async () => {
    try {
      let reqBody = {
        password,
        token
      };

      openSnackbar("info", "Mengirim..", 1000);
      setSubmitButtonValue(<CircularProgress size="1rem" />);

      let res = await Auth.fetch(BASE_URL + "/api/auth/password/reset", {
        method: "POST",
        body: JSON.stringify(reqBody)
      });

      let data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        setSubmited(true);

        // Un show the snackbar if finished
        openSnackbar("info", "Mengirim..", 1);
      } else {
        let errorMessages = "";
        if (typeof data.message === "string") {
          errorMessages = data.message;
        } else if (typeof data.message === "object") {
          errorMessages = "";
          if (Array.isArray(data.message)) {
            for (let err of data.message) {
              errorMessages +=
                typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
            }
          } else {
            for (let key in data.message) errorMessages += data.message[key][0];
          }
        }
        openSnackbar("warning", errorMessages);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan!");
    } finally {
      setSubmitButtonValue("Submit");
    }
  };

  const validate = () => {
    setErrors({
      passwordError: validatePassword(),
      rePasswordError: validateRePassword()
    });
  };

  const submit = () => {
    validate();
    let isValid = true;
    for (var key in errors) {
      if (errors[key]) {
        isValid = false;
        break;
      }
    }
    if (isValid) {
      handleAPISubmit();
    } else {
      openSnackbar("warning", "Mohon isi data dengan benar");
    }
  };

  // handle: values
  const handleChangePassword = event => {
    setPassword(event.target.value);
  };
  const handleChangeRePassword = event => {
    setRePassword(event.target.value);
  };

  return (
    <div className={classes.background}>
      <Helmet>
        <title>Reset Password - Janabadra Professional Tracker</title>
        <meta
          name="description"
          content="Janabadra Professional Tracker, Sistem Data Portofolio Pendamping Ijazah, Universitas Janabadra"
        />
      </Helmet>
      <Navbar />
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
        timeout={snackbarData.timeout}
      />
      <Container>
        <GridContainer>
          <div style={{ padding: "7vh" }} />
        </GridContainer>
        <GridContainer justify={"center"} alignItems={"center"}>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer justify={"space-between"}>
              <GridItem xs={12} sm={12} md={5}>
                <div style={{ padding: "1vh" }} />
                <h1 className={classes.cardTitleWhite}>Information</h1>
                <div style={{ padding: "1vh" }} />
                <p className={classes.cardCategoryWhite}>
                  JanaProTrack adalah Sistem Data Portofolio Pendamping Ijazah
                  yang digunakan untuk melakukan proses input data kegiatan
                  mahasiswa yang dicatatkan dalam luaran berbentuk Surat
                  Keterangan Pendamping Ijazah (SKPI). Mahasiswa setiap Program
                  Studi harus mencermati rubrik SKPI yang diterbitkan oleh
                  masing-masing Program Studi agar mengetahui kegiatan apa saja
                  yang bisa masuk ke dalam rubrik dan bagaimana persyaratan
                  untuk mendapatkan dokumen rekognisinya. Setiap mahasiswa wajib
                  mengisi portofolio aktivitasnya dalam sistem ini untuk
                  mendapatkan Surat Keterangan Pendamping Ijazah (SKPI).
                </p>
                <div style={{ padding: "1vh" }} />
                <GridContainer justify={"center"}>
                  <GridItem>
                    <Button
                      round
                      color={"info"}
                      href={"/guide"}
                      target="_blank"
                    >
                      <HelpOutlineIcon />
                      Guide
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <Card>
                  <CardHeader color={"info"}>
                    <h4 className={classes.cardTitleWhite}>Lupa Password</h4>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <Collapse in={!submited}>
                        <GridContainer justify={"center"}>
                          <GridItem xs={10}>
                            <div style={{ padding: "2vh" }} />
                            <p className={classes.successText}>
                              Silahkan masukkan password baru anda
                            </p>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem xs={10} sm={10} md={10}>
                            <CustomInput
                              labelText="Password"
                              id="password"
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={!!errors.passwordError}
                              helperText={errors.passwordError}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    style={
                                      errors.passwordError
                                        ? {
                                            marginRight: "20px",
                                            marginBottom: "5px"
                                          }
                                        : { marginBottom: "5px" }
                                    }
                                  >
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                type: showPassword ? "text" : "password",
                                name: "new-password",
                                value: password || "",
                                onChange: handleChangePassword,
                                onBlur: () => {
                                  setErrors({
                                    ...errors,
                                    passwordError: validatePassword()
                                  });
                                },
                                onFocus: () => {
                                  setErrors({ ...errors, passwordError: "" });
                                }
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem xs={10} sm={10} md={10}>
                            <CustomInput
                              labelText="Re-Enter Password"
                              id="rePassword"
                              formControlProps={{
                                fullWidth: true
                              }}
                              error={!!errors.rePasswordError}
                              helperText={errors.rePasswordError}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    style={
                                      errors.rePasswordError
                                        ? {
                                            marginRight: "20px",
                                            marginBottom: "5px"
                                          }
                                        : { marginBottom: "5px" }
                                    }
                                  >
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowRePassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showRePassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                type: showRePassword ? "text" : "password",
                                name: "new-password",
                                value: rePassword || "",
                                onChange: handleChangeRePassword,
                                onBlur: () => {
                                  setErrors({
                                    ...errors,
                                    rePasswordError: validateRePassword()
                                  });
                                },
                                onFocus: () => {
                                  setErrors({ ...errors, rePasswordError: "" });
                                },
                                onKeyDown: e => {
                                  if (e.key === "Enter") {
                                    submit();
                                    e.preventDefault();
                                  }
                                }
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem>
                            <div style={{ padding: "2vh" }} />
                            <Button color={"info"} onClick={submit}>
                              {submitButtonValue}
                            </Button>
                          </GridItem>
                          <div style={{ padding: "2vh" }} />
                        </GridContainer>
                      </Collapse>
                      <Collapse in={submited}>
                        <GridContainer justify={"center"}>
                          <GridItem>
                            <div style={{ padding: "1vh" }} />
                            <h2 className={classes.success}>Success</h2>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem>
                            <div style={{ padding: "1vh" }} />
                            <CheckCircleOutlineIcon
                              className={classes.successIcon}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify={"center"}>
                          <GridItem xs={8}>
                            <div style={{ padding: "1vh" }} />
                            <p className={classes.successText}>
                              Penggantian password berhasil
                            </p>
                            <div style={{ padding: "1vh" }} />
                          </GridItem>
                        </GridContainer>
                      </Collapse>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Container>
    </div>
  );
}

ForgotPasswordNext.propTypes = {
  match: PropTypes.object
};
