import React, { useState } from "react";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomTabs from "dep/CustomTabs/CustomTabs";
// @material-ui/icons
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
// components
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";
import TabProfileBiodata from "components/User/Profile/TabContents/TabProfileBiodata";
import TabProfileAbout from "components/User/Profile/TabContents/TabProfileAbout";
import TabProfileAccount from "components/User/Profile/TabContents/TabProfileAccount";
import TabProfileMigrationNew from "components/User/Profile/TabContents/TabProfileMigrationNew";

export default function CardProfile() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });

  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };

  return (
    <GridContainer>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
      />
      <GridItem xs={12}>
        <CustomTabs
          headerColor="primary"
          tabs={[
            {
              tabName: "Profile",
              tabIcon: AccountCircleIcon,
              tabContent: <TabProfileBiodata openSnackbar={openSnackbar} />
            },
            {
              tabName: "Kontak",
              tabIcon: ImportContactsIcon,
              tabContent: <TabProfileAbout openSnackbar={openSnackbar} />
            },
            {
              tabName: "Akun",
              tabIcon: AccountBalanceWalletIcon,
              tabContent: <TabProfileAccount openSnackbar={openSnackbar} />
            },
            {
              tabName: "Migrasi",
              tabIcon: CompareArrowsIcon,
              tabContent: <TabProfileMigrationNew openSnackbar={openSnackbar} />
            }
          ]}
        />
      </GridItem>
    </GridContainer>
  );
}
