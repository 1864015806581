import { LANDING_PAGE_BG_DESKTOP } from "const";
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";

const userAccountStyles = {
  formControl: {
    color: "rgba(255,255,255,.75)",
    margin: "0",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.75)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
    marginBottom: "3px",
    textDecoration: "none",
    textAlign: "justify"
  },
  background: {
    backgroundImage: "url(" + LANDING_PAGE_BG_DESKTOP + ")",
    backgroundPosition: "center top",
    backgroundSize: "cover",
    height: "auto",
    minHeight: "100vh"
  },
  success: {
    color: "#00c800",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
  },
  successText: {
    color: "#6D6D6D",
    textAlign: "center",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
    fontSize: "14px"
  },
  successIcon: {
    color: "#00c800",
    fontSize: "5em"
  },
  failed: {
    color: "#c80000"
  },
  ...tasksStyle
};

export default userAccountStyles;
