import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  Collapse,
  Typography,
  Tooltip,
  IconButton
} from "@material-ui/core";

// @material-ui/icons
import IconEdit from "@material-ui/icons/Edit";
import IconAdd from "@material-ui/icons/Add";
import IconDeleteForever from "@material-ui/icons/DeleteForever";

// dep components
import GridItem from "dep/Grid/GridItem.js";
import GridContainer from "dep/Grid/GridContainer.js";
import CustomInput from "dep/CustomInput/CustomInput.js";
import Button from "dep/CustomButtons/Button.js";
import Card from "dep/Card/Card.js";
import CardHeader from "dep/Card/CardHeader.js";
import CardBody from "dep/Card/CardBody.js";
import CardFooter from "dep/Card/CardFooter.js";

// components
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";

// styling
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";

// For API
import Auth from "helpers/auth";
import { BASE_URL, ROLE_ADMIN_JURUSAN, ROLE_SUPER_ADMIN } from "const";
import cardStyles from "assets/jss/jpt/cardStyles";
import formStyles from "assets/jss/jpt/formStyles";

const defaultProdiState = {
  id_prodi: null,
  nama_prodi: "",
  nama_prodi_inggris: "",
  nama_kaprodi: "",
  jenjang: "",
  gelar: "",
  status_akreditasi: "",
  no_sk_akreditasi_prodi: "",
  id_fakultas: null,
  is_active: undefined
};

//styling
const useStyles = makeStyles({
  ...formStyles,
  ...cardStyles,
  ...tasksStyle
});

export default function CardManajemenProdi() {
  const classes = useStyles();

  //var : list-select
  const [listFakultas, setListFakultas] = useState([]);
  const [listProdi, setListProdi] = useState([]);

  //var : values
  const [selectFakultas, setSelectFakultas] = useState("");
  const [selectProdi, setSelectProdi] = useState("");
  const [prodiState, setProdiState] = useState(defaultProdiState);

  //var : open-close
  const [showCardProdi, setShowCardProdi] = useState(false);
  const [openSelectFakultas, setOpenSelectFakultas] = useState(false);
  const [openSelectProdi, setOpenSelectProdi] = useState(false);
  const [openDialogUbah, setOpenDialogUbah] = useState(false);
  const [openDialogTambah, setOpenDialogTambah] = useState(false);
  const [openDialogHapus, setOpenDialogHapus] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });

  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };

  useEffect(() => {
    if (Auth.isRoleEqualTo(ROLE_SUPER_ADMIN)) handleFetchListFakultas();
    else handleFetchDataProdi(Auth.getIdProdi());
  }, []);

  // handle: [API] fetch
  const handleFetchListFakultas = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/fakultas/all");
    let data = await res.json();
    setListFakultas(data.fakultas || []);
  };

  const handleFetchListProdi = async id_fakultas => {
    let res = await Auth.fetch(
      BASE_URL + "/api/fakultas/" + id_fakultas + "/prodi/all"
    );
    let data = await res.json();
    setListProdi(data.prodi || []);
  };

  const handleFetchDataProdi = async id_prodi => {
    let res = await Auth.fetch(BASE_URL + "/api/prodi/" + id_prodi);
    let data = await res.json();
    setProdiState(data.prodi || defaultProdiState);
  };

  //handle : API [TO-DO]
  const handleAPITambah = async () => {
    try {
      openSnackbar("info", "Adding...");
      let res = await Auth.fetch(BASE_URL + "/api/prodi/add", {
        method: "POST",
        body: JSON.stringify({ ...prodiState, id_fakultas: selectFakultas })
      });

      let data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data prodi berhasil ditambahkan!");
        handleFetchListProdi(selectFakultas);
      } else {
        let errors = "";
        if (typeof data.message == "string") {
          errors += data.message;
        } else {
          for (let e of data.message) {
            for (let key in e) {
              for (let err of e[key]) errors += err;
            }
          }
        }
        openSnackbar(
          "warning",
          errors || "Terjadi kesalahan saat melakukan penambahan!"
        );
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan saat melakukan penambahan!");
    }
    handleCloseDialogTambah();
  };

  const handleAPIUbah = async () => {
    try {
      openSnackbar("info", "Updating...");
      let res = await Auth.fetch(
        BASE_URL + "/api/prodi/" + prodiState.id_prodi + "/update",
        {
          method: "PUT",
          body: JSON.stringify(prodiState)
        }
      );

      let data = await res.json();

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data prodi berhasil diubah!");
        handleFetchListProdi(selectFakultas);
      } else {
        let errors = "";
        if (typeof data.message == "string") {
          errors += data.message;
        } else {
          for (let e of data.message) {
            for (let key in e) {
              for (let err of e[key]) errors += err;
            }
          }
        }
        openSnackbar(
          "warning",
          errors || "Terjadi kesalahan saat melakukan update!"
        );
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan saat melakukan update!");
    }
    handleCloseDialogUbah();
  };

  const handleAPIHapus = async () => {
    try {
      openSnackbar("info", "Deleting...");
      let res = await Auth.fetch(
        BASE_URL + "/api/prodi/" + prodiState.id_prodi + "/delete",
        {
          method: "DELETE"
        }
      );

      let data = await res.json();

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data prodi berhasil dihapus!");
        handleFetchListFakultas();
      } else {
        let errors = "";
        if (typeof data.message == "string") {
          errors += data.message;
        } else {
          for (let e of data.message) {
            for (let key in e) {
              for (let err of e[key]) errors += err;
            }
          }
        }
        openSnackbar("warning", errors || "Terjadi kesalahan!");
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
    handleCloseDialogHapus();
  };

  //handle : values
  const handleChangeSelectFakultas = event => {
    setSelectFakultas(event.target.value);
    handleFetchListProdi(event.target.value);
    setSelectProdi("");
    // setProdiState({ ...prodiState, id_fakultas: event.target.value }); // edit reason: ganti fakultas state prodi selain id_fakultas masih ketinggal
    setProdiState({ defaultProdiState, id_fakultas: event.target.value });
  };

  const handleChangeSelectProdi = event => {
    setSelectProdi(event.target.value);
    handleFetchDataProdi(event.target.value);
  };

  const handleChangeStateProdi = e => {
    setProdiState({ ...prodiState, [e.target.name]: e.target.value });
  };

  const handleChangeStateStatusProdi = value => {
    setProdiState({ ...prodiState, is_active: value });
  };

  //handle : open-close
  const handleCloseSelectFakultas = () => {
    setOpenSelectFakultas(false);
  };

  const handleOpenSelectFakultas = () => {
    setOpenSelectFakultas(true);
  };

  const handleCloseSelectProdi = () => {
    setOpenSelectProdi(false);
  };

  const handleOpenSelectProdi = () => {
    setOpenSelectProdi(true);
  };

  const handleClickOpenDialogUbah = () => {
    setOpenDialogUbah(true);
  };

  const handleCloseDialogUbah = () => {
    setOpenDialogUbah(false);
  };

  const handleClickOpenDialogTambah = () => {
    setOpenDialogTambah(true);
  };

  const handleCloseDialogTambah = () => {
    setOpenDialogTambah(false);
  };

  const handleClickOpenDialogHapus = () => {
    setOpenDialogHapus(true);
  };

  const handleCloseDialogHapus = () => {
    setOpenDialogHapus(false);
  };

  return (
    <Card plain={!(showCardProdi || Auth.isRoleEqualTo(ROLE_ADMIN_JURUSAN))}>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
      />
      <div
        onClick={() => {
          setShowCardProdi(!showCardProdi);
        }}
      >
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Manajemen Program Studi</h4>
          <p className={classes.cardCategoryWhite}>
            Ubah atau tambah Program Studi di sini
          </p>
        </CardHeader>
      </div>
      <div>
        <Collapse in={showCardProdi || Auth.isRoleEqualTo(ROLE_ADMIN_JURUSAN)}>
          <CardBody>
            {Auth.isRoleEqualTo(ROLE_SUPER_ADMIN) && (
              <>
                <GridContainer justify={"space-between"} alignItems={"center"}>
                  <GridItem xs={12} sm={12} md={7}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel id="controlled-open-select-label">
                        Pilih Fakultas
                      </InputLabel>
                      <Select
                        labelId="controlled-open-select-label"
                        id="controlled-open-select"
                        open={openSelectFakultas}
                        onClose={handleCloseSelectFakultas}
                        onOpen={handleOpenSelectFakultas}
                        value={selectFakultas}
                        onChange={handleChangeSelectFakultas}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {listFakultas.map((row, key) => (
                          <MenuItem key={key} value={row.id_fakultas}>
                            {row.nama_fakultas}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <GridContainer justify={"flex-end"}>
                      <GridItem>
                        <Tooltip
                          hidden={!selectProdi}
                          id="tooltip-top"
                          title="Hapus Program Studi"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton
                            aria-label="Hapus Program Studi"
                            className={classes.tableActionButton}
                            onClick={handleClickOpenDialogHapus}
                          >
                            <IconDeleteForever
                              className={
                                classes.tableActionButtonIcon +
                                " " +
                                classes.close
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={7}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel id="controlled-open-select-label">
                        Pilih Program Studi
                      </InputLabel>
                      <Select
                        labelId="controlled-open-select-label"
                        id="controlled-open-select"
                        open={openSelectProdi}
                        onClose={handleCloseSelectProdi}
                        onOpen={handleOpenSelectProdi}
                        value={selectProdi}
                        onChange={handleChangeSelectProdi}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {listProdi.map((row, key) => (
                          <MenuItem key={key} value={row.id_prodi}>
                            {`${row.nama_prodi} (${row.jenjang})`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </>
            )}
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={7}>
                <GridContainer justify={"space-between"} alignItems={"center"}>
                  <GridItem>
                    <Typography>Status</Typography>
                  </GridItem>
                  <GridItem>
                    <GridContainer>
                      <GridItem>
                        <Button
                          color={
                            prodiState.is_active ||
                            prodiState.is_active === undefined
                              ? "danger"
                              : "success"
                          }
                          onClick={event => {
                            handleChangeStateStatusProdi(false);
                            event.preventDefault();
                          }}
                        >
                          Non-Aktif
                        </Button>
                      </GridItem>
                      <GridItem>
                        <Button
                          color={prodiState.is_active ? "success" : "danger"}
                          onClick={event => {
                            handleChangeStateStatusProdi(true);
                            event.preventDefault();
                          }}
                        >
                          Aktif
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={7}>
                <CustomInput
                  labelText="Nama Program Studi"
                  id="nama-prodi"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "nama_prodi",
                    value: prodiState.nama_prodi || "",
                    onChange: handleChangeStateProdi
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={7}>
                <CustomInput
                  labelText="Nama Program Studi (Inggris)"
                  id="nama_prodi_inggris"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "nama_prodi_inggris",
                    value: prodiState.nama_prodi_inggris || "",
                    onChange: handleChangeStateProdi
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={7}>
                <CustomInput
                  labelText="Nama Kepala Program"
                  id="nama-kaprodi"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "nama_kaprodi",
                    value: prodiState.nama_kaprodi || "",
                    onChange: handleChangeStateProdi
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={7}>
                <CustomInput
                  labelText="Jenjang"
                  id="jenjang"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "jenjang",
                    value: prodiState.jenjang || "",
                    onChange: handleChangeStateProdi
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={7}>
                <CustomInput
                  labelText="Gelar"
                  id="gelar"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "gelar",
                    value: prodiState.gelar || "",
                    onChange: handleChangeStateProdi
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={7}>
                <CustomInput
                  labelText="Status Akreditasi"
                  id="status-akreditasi"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "status_akreditasi",
                    value: prodiState.status_akreditasi || "",
                    onChange: handleChangeStateProdi
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={7}>
                <CustomInput
                  labelText="Nomor SK Akreditasi"
                  id="no-sk-akreditasi-prodi"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "no_sk_akreditasi_prodi",
                    value: prodiState.no_sk_akreditasi_prodi || "",
                    onChange: handleChangeStateProdi
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter hidden={!Auth.isRoleEqualTo(ROLE_SUPER_ADMIN)}>
            <Button
              color="danger"
              disabled={
                !selectFakultas || !selectProdi || !prodiState.nama_prodi
              }
              onClick={handleClickOpenDialogUbah}
            >
              <IconEdit />
              Ubah
            </Button>
            <Button
              color="danger"
              disabled={
                !!selectProdi ||
                !prodiState.nama_prodi ||
                prodiState.is_active === undefined
              }
              onClick={handleClickOpenDialogTambah}
            >
              <IconAdd />
              Tambah
            </Button>
          </CardFooter>
          <CardFooter hidden={!Auth.isRoleEqualTo(ROLE_ADMIN_JURUSAN)}>
            <Button
              color="danger"
              onClick={handleClickOpenDialogUbah}
              disabled={!prodiState.nama_prodi}
            >
              <IconEdit />
              Ubah
            </Button>
          </CardFooter>
          <Dialog
            open={openDialogUbah}
            onClose={handleCloseDialogUbah}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Apakah Anda Yakin?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {"Anda akan melakukan perubahan informasi Program Studi"}
                {Auth.isRoleEqualTo(ROLE_SUPER_ADMIN) && (
                  <>
                    <br />
                    {"ID Fakultas: " + selectFakultas}
                    <br />
                    {"ID Prodi: " + selectProdi}
                  </>
                )}
                <br />
                {"Status: "}
                {prodiState.is_active ? "Aktif" : "Non-Aktif"}
                <br />
                {"Nama prodi: " + prodiState.nama_prodi || ""}
                <br />
                {"Nama prodi (inggris): " + prodiState.nama_prodi_inggris || ""}
                <br />
                {"Nama kaprodi: " + prodiState.nama_kaprodi || ""}
                <br />
                {"Jenjang: " + prodiState.jenjang || ""}
                <br />
                {"Gelar: " + prodiState.gelar || ""}
                <br />
                {"Status akreditasi: " + prodiState.status_akreditasi || ""}
                <br />
                {"No. SK Akreditasi: " + prodiState.no_sk_akreditasi_prodi ||
                  ""}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogUbah} color="danger">
                Tidak
              </Button>
              <Button onClick={handleAPIUbah} color="success" autoFocus>
                Ya
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openDialogTambah}
            onClose={handleCloseDialogTambah}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Apakah Anda Yakin"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {"Anda akan melakukan penambahan Program Studi"}
                <br />
                {"ID Fakultas: " + selectFakultas}
                <br />
                {"Status: "}
                {prodiState.is_active ? "Aktif" : "Non-Aktif"}
                <br />
                {"Nama prodi: " + prodiState.nama_prodi || ""}
                <br />
                {"Nama kaprodi: " + prodiState.nama_kaprodi || ""}
                <br />
                {"Jenjang: " + prodiState.jenjang || ""}
                <br />
                {"Status akreditasi: " + prodiState.status_akreditasi || ""}
                <br />
                {"No. SK Akreditasi: " + prodiState.no_sk_akreditasi_prodi ||
                  ""}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogTambah} color="danger">
                Tidak
              </Button>
              <Button onClick={handleAPITambah} color="success" autoFocus>
                Ya
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openDialogHapus}
            onClose={handleCloseDialogHapus}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Apakah Anda Yakin?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {"Anda akan melakukan penghapusan Program Studi"}
                <br />
                {"Status : "}
                {prodiState.is_active ? "Aktif" : "Non-Aktif"}
                <br />
                {"Nama Program Studi: " + prodiState.nama_prodi}
                <br />
                {
                  "Pastikan tidak ada mahasiswa di Program Studi ini. Pastikan juga sudah ada keputusan Universitas mengenai penghapusan Program Studi ini"
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogHapus} color="danger">
                Tidak
              </Button>
              <Button onClick={handleAPIHapus} color="success" autoFocus>
                Ya
              </Button>
            </DialogActions>
          </Dialog>
        </Collapse>
      </div>
    </Card>
  );
}
