import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes/UserRoutes.js";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";
import Navbar from "components/Global/Navbars/Navbar";
import Footer from "dep/Footer/Footer.js";
import Sidebar from "components/Global/Sidebar/Sidebar";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";
import CardBody from "dep/Card/CardBody";
import Button from "dep/CustomButtons/Button";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import bgImage from "assets/img/sidebar-2.jpg";

import Auth from "helpers/auth.js";
import { BASE_URL, DASHBOARD_NAME, LOGO_ONLY } from "const";
import { Helmet } from "react-helmet";

let ps;

const useStyles = makeStyles(styles);
const unverifiedAccountStyles = makeStyles({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    textAlign: "justify"
  },
  titleRed: {
    color: "#ff0000",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    textAlign: "justify"
  },
  failed: {
    color: "#8C8C8C",
    textAlign: "center"
  },
  warningIcon: {
    color: "#ff5454",
    fontSize: "20vh"
  }
});

const User = ({ ...rest }) => {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [dataComplete, setDataComplete] = useState(true);
  const [emailVerified, setEmailVerified] = useState(true);
  const [userAvatar, setUserAvatar] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };

  // initialize and destroy the PerfectScrollbar plugin
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  useEffect(() => {
    // handle: API fetch
    const handleFetchBiodata = async () => {
      openSnackbar("info", "Loading data");
      let res = await Auth.fetch(BASE_URL + "/api/profile");
      let data = await res.json();
      setSnackbarOpen(false);
      setUserAvatar(BASE_URL + "/" + _.get(data, "user.foto_profil"));
      setDataComplete(_.get(data, "user.is_data_complete") === 1);
      setEmailVerified(_.get(data, "user.email_verified_at", false));
    };
    handleFetchBiodata();
  }, []);

  const ResendMailVerification = props => {
    const classes = unverifiedAccountStyles();
    const handleResendActivation = async () => {
      // eslint-disable-next-line react/prop-types
      props.openSnackbar("info", "Re-sending verification email");
      let res = await Auth.fetch(BASE_URL + "/api/email/resend");
      setSnackbarOpen(false);
      if (res.status === 200) {
        openSnackbar("success", "Email verifikasi berhasil dikirim ulang");
      } else {
        openSnackbar("error", "Terjadi kesalahan");
      }
    };
    return (
      <div>
        <div style={{ padding: "4vh" }} />
        <GridContainer justify={"center"}>
          <GridItem xs={10} md={8}>
            <Card>
              <CardHeader color={"danger"}>
                <h4 className={classes.cardTitleWhite}>Peringatan</h4>
              </CardHeader>
              <CardBody>
                <div style={{ padding: "2vh" }} />
                <GridContainer justify={"center"}>
                  <GridItem>
                    <h4 className={classes.titleRed}>PERINGATAN</h4>
                  </GridItem>
                </GridContainer>
                <div style={{ padding: "2vh" }} />
                <GridContainer justify={"center"}>
                  <GridItem>
                    <ErrorOutlineIcon className={classes.warningIcon} />
                  </GridItem>
                </GridContainer>
                <div style={{ padding: "2vh" }} />
                <GridContainer justify={"center"}>
                  <GridItem xs={8}>
                    <p className={classes.failed}>
                      Akun Anda belum aktif atau Anda belum melengkapi data yang
                      diperlukan. Harap periksa kembali email Anda untuk
                      mengaktifkan Akun Anda. Jangan lupa untuk melengkapi data
                      data yang diperlukan.
                    </p>
                  </GridItem>
                </GridContainer>
                <div style={{ padding: "1vh" }} />
                <GridContainer justify={"center"}>
                  <GridItem xs={5}>
                    <p className={classes.failed}>
                      Belum menerima email aktivasi atau belum melengkapi data?
                      Ajukan permintaan aktivasi akun dibawah ini
                    </p>
                  </GridItem>
                </GridContainer>
                <GridContainer justify={"center"}>
                  <GridItem>
                    <Button
                      round
                      color={"warning"}
                      onClick={handleResendActivation}
                    >
                      Ajukan Aktivasi
                    </Button>
                  </GridItem>
                </GridContainer>
                <div style={{ padding: "2vh" }} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  const switchRoutes = (
    <Switch {...rest}>
      {routes.map((prop, key) => {
        if (prop.layout === "/user") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/user" to="/user/dashboard" />
    </Switch>
  );

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <title>Dashboard Mahasiswa - Janabadra Professional Tracker</title>
        <meta
          name="description"
          content="Janabadra Professional Tracker, Sistem Data Portofolio Pendamping Ijazah, Universitas Janabadra"
        />
      </Helmet>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
      />
      <Sidebar
        routes={routes}
        logoText={DASHBOARD_NAME}
        logo={LOGO_ONLY}
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"blue"}
        avatar={userAvatar}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          avatar={userAvatar}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            {emailVerified && dataComplete ? (
              switchRoutes
            ) : (
              <ResendMailVerification openSnackbar={openSnackbar} />
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Auth.withAuthUser(User);
