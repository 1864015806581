import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
// @material-ui/core components
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
// core components
import Button from "dep/CustomButtons/Button.js";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
// @material-ui/icons components
import MenuIcon from "@material-ui/icons/Menu";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import auth from "helpers/auth";
import { ROLE_SUPER_ADMIN } from "const";
import formStyles from "assets/jss/jpt/formStyles";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

//styling
const useStyle = makeStyles(formStyles);

export default function ButtonFilterCetakSKPI(props) {
  const classes = useStyle();

  // onMount
  useEffect(() => {
    handleFetchListAngkatan();
    handleFetchListFakultas();
    handleFilterRootUpdate();
    // eslint-disable-next-line
  }, [props.listFakultas, props.listProdi]);

  // on props.filters update
  useEffect(() => {
    handleFilterRootUpdate();
    handleDefaultValue();
    // eslint-disable-next-line
  }, [props.filters]);

  //var: values
  const [filters, setFilters] = useState({
    fakultas: [],
    prodi: [],
    angkatan: "",
    lulus: ""
  });

  const [selectedFakultas, setSelectedFakultas] = useState([]);
  const [selectedProdi, setSelectedProdi] = useState([]);

  //var: listValues
  const [listAngkatan, setListAngkatan] = useState([]);
  const [listFakultas, setListFakultas] = useState([]);
  const [listProdi, setListProdi] = useState([]);

  //var: open-close
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSelectAngkatan, setOpenSelectAngkatan] = useState(false);
  const [openSelectLulus, setOpenSelectLulus] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //handle: values
  const handleChange = prop => event => {
    setFilters({ ...filters, [prop]: event.target.value });
  };

  const handleFilterProdiChange = (event, values) => {
    setSelectedProdi(values);
    let arr = [];
    values.forEach(element => {
      arr.push(element.id);
    });
    setFilters({ ...filters, prodi: arr });
  };

  const handleFilterFakultasChange = (event, values) => {
    setSelectedFakultas(values);
    let arr = [];
    values.forEach(element => {
      arr.push(element.id);
    });
    setFilters({ ...filters, fakultas: arr });
    handleFetchListProdi(arr);
  };

  const handleFilterRootUpdate = () => {
    setFilters(props.filters);
  };

  const handleOnChangeListProdi = listProdi => {
    let newSelectedProdi = [];
    selectedProdi.forEach(valueSelected => {
      const tmp = listProdi.find(
        valueList => valueList.id === valueSelected.id
      );
      if (tmp !== undefined) newSelectedProdi.push(tmp);
    });
    setSelectedProdi(newSelectedProdi);
  };

  const handleDefaultValue = () => {
    const selectedFakultas = props.filters.fakultas || [];
    const selectedProdi = props.filters.prodi || [];
    let defaultValueFakultas = [];
    let defaultValueProdi = [];
    selectedFakultas.forEach(id => {
      const tmp = listFakultas.find(value => value.id === id);
      if (tmp !== undefined) defaultValueFakultas.push(tmp);
      else defaultValueFakultas.push({ id: id, fakultas: "Missing" });
    });
    selectedProdi.forEach(id => {
      const tmp = listProdi.find(value => value.id === id);
      if (tmp !== undefined) defaultValueProdi.push(tmp);
      else defaultValueProdi.push({ id: id, fakultas: "Missing" });
    });
    setSelectedFakultas(defaultValueFakultas);
    setSelectedProdi(defaultValueProdi);
  };

  //handle: super props
  const handleSetFilter = () => {
    props.filterChange(
      filters.fakultas,
      filters.prodi,
      filters.angkatan,
      // filters.SKPmin,
      // filters.SKPmax,
      filters.lulus
    );
  };

  const handleResetFilter = () => {
    props.filterChange([], [], "", 0);
  };

  //handle: [API] fetch
  const handleFetchListAngkatan = () => {
    let listAngkatan = [];
    for (let i = 1958; i <= new Date().getFullYear() + 1; i++) {
      listAngkatan.push({ id: i.toString(), angkatan: i.toString() });
    }
    setListAngkatan(listAngkatan);
  };

  const handleFetchListFakultas = () => {
    setListFakultas(
      props.listFakultas.map(e => {
        return {
          id: _.get(e, "id_fakultas"),
          fakultas: _.get(e, "nama_fakultas")
        };
      })
    );
  };

  const handleFetchListProdi = fakultas => {
    let listProdi = props.listProdi
      .filter(e => {
        return fakultas.includes(e.id_fakultas);
      })
      .map(e => {
        return {
          id: _.get(e, "id_prodi"),
          prodi: `${_.get(e, "nama_prodi")} (${_.get(e, "jenjang")})`
        };
      });

    setListProdi(listProdi);
    handleOnChangeListProdi(listProdi);
  };

  //handle: open-close
  const handleCloseSelectAngkatan = () => {
    setOpenSelectAngkatan(false);
  };

  const handleOpenSelectAngkatan = () => {
    setOpenSelectAngkatan(true);
  };

  const handleCloseSelectLulus = () => {
    setOpenSelectLulus(false);
  };

  const handleOpenSelectLulus = () => {
    setOpenSelectLulus(true);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button color="primary" onClick={handleClick}>
        <MenuIcon />
        Filter
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <GridContainer style={{ minWidth: "500px" }}>
          <p hidden>untuk override popover styling:minWidth</p>
        </GridContainer>
        <GridContainer justify={"center"}>
          <GridItem>
            <Typography
              color={"primary"}
              variant={"h5"}
              style={{ margin: "10px" }}
            >
              Filter
            </Typography>
          </GridItem>
        </GridContainer>
        {auth.isRoleEqualTo(ROLE_SUPER_ADMIN) && (
          <React.Fragment>
            <GridContainer justify={"center"}>
              <GridItem xs={11} md={10}>
                <Autocomplete
                  fullWidth
                  onChange={handleFilterFakultasChange}
                  multiple
                  value={selectedFakultas}
                  id="checkboxes-tags-fakultas"
                  options={listFakultas}
                  disableCloseOnSelect
                  getOptionLabel={option => option.fakultas}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      />
                      {option.fakultas}
                    </React.Fragment>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Fakultas"
                      placeholder="Fakultas"
                    />
                  )}
                />
              </GridItem>
            </GridContainer>
            <div style={{ padding: "1vh" }} />
            <GridContainer justify={"center"}>
              <GridItem xs={11} md={10}>
                <Autocomplete
                  fullWidth
                  onChange={handleFilterProdiChange}
                  multiple
                  value={selectedProdi}
                  id="checkboxes-tags-prodi"
                  options={listProdi}
                  disableCloseOnSelect
                  getOptionLabel={option => option.prodi}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.prodi}
                    </React.Fragment>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Prodi"
                      placeholder="Prodi"
                    />
                  )}
                />
              </GridItem>
            </GridContainer>
          </React.Fragment>
        )}
        <div style={{ padding: "1vh" }} />
        <GridContainer justify={"center"}>
          <GridItem xs={11} md={10}>
            <GridContainer>
              <GridItem>
                <InputLabel>Mahasiswa</InputLabel>
              </GridItem>
            </GridContainer>
            <GridContainer justify={"center"}>
              <GridItem md={10}>
                <GridContainer justify={"space-between"}>
                  <GridItem xs={8}>
                    <Typography variant={"body1"}>Tahun Masuk</Typography>
                  </GridItem>
                  <GridItem xs={4}>
                    <FormControl fullWidth>
                      <Select
                        labelId="controlled-open-select-label"
                        id="controlled-open-select"
                        open={openSelectAngkatan}
                        onClose={handleCloseSelectAngkatan}
                        onOpen={handleOpenSelectAngkatan}
                        value={filters.angkatan}
                        onChange={handleChange("angkatan")}
                      >
                        <MenuItem value={""}>
                          <em>None</em>
                        </MenuItem>
                        {listAngkatan.map((row, key) => (
                          <MenuItem key={key} value={row.id}>
                            {row.angkatan}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer justify={"space-between"}>
                  <GridItem xs={5}>
                    <Typography variant={"body1"}>Kelulusan</Typography>
                  </GridItem>
                  <GridItem xs={5}>
                    <FormControl fullWidth>
                      <Select
                        labelId="controlled-open-select-label"
                        id="controlled-open-select"
                        open={openSelectLulus}
                        onClose={handleCloseSelectLulus}
                        onOpen={handleOpenSelectLulus}
                        value={filters.lulus}
                        onChange={handleChange("lulus")}
                      >
                        <MenuItem value={""}>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>sudah</MenuItem>
                        <MenuItem value={0}>belum</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer justify={"space-around"}>
              <GridItem>
                <Button
                  size={"sm"}
                  className={classes.button}
                  color={"warning"}
                  onClick={() => {
                    handleResetFilter();
                    handleClose();
                  }}
                >
                  Reset
                </Button>
              </GridItem>
              <GridItem md={3}>
                <Button
                  size={"sm"}
                  className={classes.button}
                  color={"success"}
                  onClick={() => {
                    handleSetFilter();
                    handleClose();
                  }}
                >
                  Terapkan
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Popover>
    </>
  );
}

ButtonFilterCetakSKPI.propTypes = {
  filterChange: PropTypes.func,
  filters: PropTypes.object,
  listFakultas: PropTypes.array,
  listProdi: PropTypes.array
};
