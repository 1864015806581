import React, { useState } from "react";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CardKegiatanInternal from "components/Admin/KegiatanInternal/Card/CardKegiatanInternal";
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";

export default function KegiatanInternal() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: "",
    timeout: 2000
  });

  const openSnackbar = (severity, message, timeout = 2000) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message, timeout });
  };

  return (
    <div>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
        timeout={snackbarData.timeout}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CardKegiatanInternal openSnackbar={openSnackbar} />
        </GridItem>
      </GridContainer>
    </div>
  );
}
