import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

// @material-ui/core components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";

// dep components
import Button from "dep/CustomButtons/Button";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function DialogDeleteManajemenAdmin(props) {
  const { openSnackbar, handleFetchUserData, selectedUser } = props;

  const handleCloseDialog = () => {
    props.handleChangeNewDialogType("");
  };

  const handleAPISubmit = async () => {
    try {
      openSnackbar("info", "Menghapus..");
      let res = await Auth.fetchMultipart(
        BASE_URL + "/api/manajemen/akun/delete?nim=" + selectedUser.nim,
        {
          method: "DELETE"
        }
      );
      let data = await res.json();

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Berhasil menghapus!");
        handleFetchUserData();
        handleCloseDialog();
      } else {
        let errorMessages = "";
        if (typeof data.message === "string") {
          errorMessages = data.message;
        } else if (typeof data.message === "object") {
          errorMessages = "";
          if (Array.isArray(data.message)) {
            for (let err of data.message) {
              errorMessages +=
                typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
            }
          } else {
            for (let key in data.message) errorMessages += data.message[key][0];
          }
        }

        openSnackbar(
          res.status >= 400 && res.status < 500 ? "warning" : "error",
          errorMessages
        );
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  return (
    <Dialog
      open={Boolean(props.selectedUser) && props.type === "delete"}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Apakah Anda Yakin?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {"Anda akan menghapus admin"}
          <br />
          {"Admin ID : " + _.get(props, "selectedUser.nim")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color={"danger"}>
          Tidak
        </Button>
        <Button onClick={handleAPISubmit} color={"success"} autoFocus>
          Ya
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogDeleteManajemenAdmin.propTypes = {
  adminID: PropTypes.any,
  handleChangeNewDialogType: PropTypes.func,
  handleChangeSelectedUser: PropTypes.func,
  type: PropTypes.string,
  selectedUser: PropTypes.any
};
