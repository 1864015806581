import React, { useState } from "react";
import PropTypes from "prop-types";

import CustomInput from "dep/CustomInput/CustomInput";
import { InputAdornment, IconButton } from "@material-ui/core";

import { Search } from "@material-ui/icons";

export default function SearchMenungguVerifikasi(props) {
  const [query, changeQuery] = useState("");

  const getInputAdornment = () => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => props.handleSearch(query)}
          onMouseDown={e => e.preventDefault()}
        >
          <Search />
        </IconButton>
      </InputAdornment>
    );
  };

  return (
    <CustomInput
      labelText="Cari"
      id="cari"
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        name: "password",
        value: query,
        onChange: e => changeQuery(e.target.value),
        type: "text",
        endAdornment: getInputAdornment()
      }}
    />
  );
}

SearchMenungguVerifikasi.propTypes = {
  handleSearch: PropTypes.func
};
