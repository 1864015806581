import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import Card from "components/Public/LandingPage/Card/CardLandingPage.js";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
export default function CarouselLandingPage(props) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: props.slideCount,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500
  };

  return (
    <Slider {...settings}>
      <Card
        icon={<PostAddOutlinedIcon />}
        title={"Input Portofolio"}
        text={
          "Input portofolio prestasi Anda sesuai dengan bidang portofolio Anda."
        }
      >
        <p>1</p>
      </Card>
      <Card
        icon={<AccessTimeIcon />}
        title={"Menunggu"}
        text={"Tunggu admin mengkonfirmasi portofolio Anda."}
      >
        <p>2</p>
      </Card>
      <Card
        icon={<AssignmentOutlinedIcon />}
        title={"SKPI"}
        text={
          "Ajukan cetak SKPI, dan cetak SKPI Anda jika sudah disetujui admin."
        }
      >
        <p>3</p>
      </Card>
    </Slider>
  );
}

CarouselLandingPage.propTypes = {
  slideCount: PropTypes.number
};
