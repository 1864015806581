import React, { useState } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons components
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";

// styling
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle";
import buttonsStyles from "assets/jss/jpt/buttonsStyles";
const useStyles = makeStyles({
  ...taskStyles,
  ...buttonsStyles
});

export default function ButtonEditManajemenMahasiswa(props) {
  const classes = useStyles();
  //var: hook

  //var: open-close
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  //handle: hook [TO-DO]
  const handleInfoButton = () => {
    props.handleChangePreviewID(props.NIM);
    props.setPreviewData(props.dataMahasiwa);
  };
  const handleEditButton = value => {
    props.handleChangeDialogProfilID(props.NIM);
    props.setPreviewData(props.dataMahasiwa);
    props.handleChangeDialogProfilEdit(value);
  };

  //handle: open-close
  const handleClickMenu = event => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return (
    <>
      <Tooltip
        id="tooltip-top"
        title="info"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="info"
          className={classes.tableActionButton}
          onClick={handleInfoButton}
        >
          <InfoIcon
            className={classes.tableActionButtonIcon + " " + classes.buttonInfo}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        id="tooltip-top"
        title="edit"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="Add"
          className={classes.tableActionButton}
          onClick={handleClickMenu}
        >
          <EditIcon
            className={classes.tableActionButtonIcon + " " + classes.buttonEdit}
          />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={event => {
            handleEditButton("profil");
            event.preventDefault();
          }}
        >
          Profil
        </MenuItem>
        <MenuItem
          onClick={event => {
            handleEditButton("portofolio");
            event.preventDefault();
          }}
        >
          Portofolio
        </MenuItem>
        <MenuItem
          onClick={event => {
            handleEditButton("kemahasiswaan");
            event.preventDefault();
          }}
        >
          Kemahasiswaan
        </MenuItem>
        <MenuItem
          onClick={event => {
            handleEditButton("akun");
            event.preventDefault();
          }}
        >
          Akun
        </MenuItem>
      </Menu>
    </>
  );
}

ButtonEditManajemenMahasiswa.propTypes = {
  NIM: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChangeDialogProfilEdit: PropTypes.func,
  handleChangeDialogProfilID: PropTypes.func,
  handleChangePreviewID: PropTypes.func,
  setPreviewID: PropTypes.func,
  dataMahasiwa: PropTypes.any,
  setPreviewData: PropTypes.func
};
