import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
// dep components
import Table from "dep/Table/Table";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Button from "dep/CustomButtons/Button";
// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function DialogDeleteSKPI(props) {
  const { openSnackbar } = props;

  const handleClose = () => {
    props.onClose();
  };

  const handleSubmit = async () => {
    try {
      let res = await Auth.fetch(
        `${BASE_URL}/api/manajemen/skpi/delete/${props.selectedValue}`,
        { method: "DELETE" }
      );

      let data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        props.onSuccess();
        props.onClose();
      } else {
        let errorMessages = "";
        if (typeof data.message === "string") {
          errorMessages = data.message;
        } else if (typeof data.message === "object") {
          errorMessages = "";
          if (Array.isArray(data.message)) {
            for (let err of data.message) {
              errorMessages +=
                typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
            }
          } else {
            for (let key in data.message) errorMessages += data.message[key][0];
          }
        }
        openSnackbar(
          res.status >= 400 && res.status < 500 ? "warning" : "error",
          errorMessages
        );
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      scroll={"body"}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle>Hapus SKPI</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Anda akan menghapus pengajuan SKPI dari mahasiswa di bawah ini.
        </DialogContentText>
        <GridContainer justify={"center"}>
          <GridItem>
            <Table
              tableHeaderColor="primary"
              tableHead={["NIM", "Nama", "Angkatan", "Kelulusan"]}
              tableData={(() => {
                const tmp = props.data.find(
                  values => values[0] === props.selectedValue
                );
                if (!!tmp) return [[tmp[0], tmp[1], tmp[3], tmp[4]]];
                else return [];
              })()}
            />
          </GridItem>
        </GridContainer>
        <br />
        <DialogContentText>
          Pastikan anda yakin. Aksi ini tidak bisa dikembalikan.
        </DialogContentText>
        <br />
        <GridContainer justify={"flex-end"}>
          <GridItem>
            <Button size={"sm"} color={"warning"} onClick={handleClose}>
              Cancel
            </Button>
          </GridItem>
          <GridItem>
            <Button size={"sm"} color={"danger"} onClick={handleSubmit}>
              Hapus
            </Button>
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
}

DialogDeleteSKPI.propTypes = {
  data: PropTypes.array,
  listPeriode: PropTypes.array,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  selectedValue: PropTypes.array,
  openSnackbar: PropTypes.func
};
