import React, { useState, useEffect } from "react";
import _ from "lodash";

// dep components
import GridItem from "dep/Grid/GridItem.js";
import GridContainer from "dep/Grid/GridContainer.js";

// components
import CardManajemenMahasiswa from "components/Admin/ManajemenMahasiswa/Card/CardManajemenMahasiswa";
import CardManajemenMahasiswaPreview from "components/Admin/ManajemenMahasiswa/Card/CardManajemenMahasiswaPreview";
import DialogManajemenMahasiswaProfil from "components/Admin/ManajemenMahasiswa/Dialog/DialogManajemenMahasiswaProfil";
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";
import CardMigrasiMahasiswa from "components/Admin/ManajemenMahasiswa/Card/CardMigrasiMahasiswa";
import DialogVerifikasiMigrasiMahasiswa from "components/Admin/ManajemenMahasiswa/Dialog/DialogVerifikasiMigrasiMahasiswa";
import CardMigrasiMahasiswaVerified from "components/Admin/ManajemenMahasiswa/Card/CardMigrasiMahasiswaVerified";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

// const ROLE_MAHASISWA = 4

export default function ManajemenMahasiswa() {
  // var: values -> hook
  const [previewID, setPreviewID] = useState("");
  const [previewData, setPreviewData] = useState({});
  const [migrasiData, setMigrasiData] = useState({});
  const [dialogProfilID, setDialogProfilID] = useState("");
  const [dialogProfilEdit, setDialogProfileEdit] = useState("");
  const [dialogMigrasiType, setDialogMigrasiType] = useState("");
  const [cardType, setCardType] = useState("");
  const [listProdi, setListProdi] = useState([]);
  const [listFakultas, setListFakultas] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: "",
    timeout: 2000
  });
  const [toggleReFetchMahasiswa, setToggleReFetchMahasiswa] = useState(false);
  const [toggleReFetchMigrasi, setToggleReFetchMigrasi] = useState(false);

  useEffect(() => {
    const fetchProdi = async () => {
      let res = await Auth.fetch(BASE_URL + "/api/prodi");
      let data = await res.json();
      setListProdi(_.get(data, "prodi", []));
    };

    const fetchListFakultas = async () => {
      let res = await Auth.fetch(BASE_URL + "/api/fakultas");
      let data = await res.json();
      setListFakultas(_.get(data, "fakultas", []));
    };

    fetchProdi();
    fetchListFakultas();
  }, []);

  // const setPreviewData = async (nim) => {
  //   let res = await Auth.fetch(BASE_URL + `/api/manajemen/akun?id_role=${ROLE_MAHASISWA}&nim=${nim}`);
  //   let data = await res.json();
  //   changePreviewData(_.get(data, "users.data[0]", []));
  // }

  const openSnackbar = (severity, message, timeout = 2000) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message, timeout });
  };

  // handle: values
  const handleChangePreviewID = value => {
    setPreviewID(value);
  };

  const handleChangeDialogProfilID = value => {
    setDialogProfilID(value);
  };

  const handleChangeDialogProfilEdit = value => {
    setDialogProfileEdit(value);
  };

  const handleChangeDialogMigrasiType = value => {
    setDialogMigrasiType(value);
  };
  const handleChangeMigrasiData = values => {
    setMigrasiData(values);
  };

  const handleChangeCardType = value => {
    setCardType(value);
  };
  return (
    <div>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
        timeout={snackbarData.timeout}
      />
      <GridContainer>
        <GridItem xs={12} lg={8}>
          {cardType === "migrasi" ? (
            <CardMigrasiMahasiswa
              listProdi={listProdi}
              listFakultas={listFakultas}
              handleChangePreviewID={handleChangePreviewID}
              handleChangeDialogProfilID={handleChangeDialogProfilID}
              setPreviewData={setPreviewData}
              openSnackbar={openSnackbar}
              handleChangeDialogMigrasiType={handleChangeDialogMigrasiType}
              handleChangeMigrasiData={handleChangeMigrasiData}
              handleChangeCardType={handleChangeCardType}
              toggleReFetchMigrasi={toggleReFetchMigrasi}
            />
          ) : (
            <CardManajemenMahasiswa
              listProdi={listProdi}
              listFakultas={listFakultas}
              handleChangePreviewID={handleChangePreviewID}
              handleChangeDialogProfilID={handleChangeDialogProfilID}
              handleChangeDialogProfilEdit={handleChangeDialogProfilEdit}
              handleChangeCardType={handleChangeCardType}
              setPreviewData={setPreviewData}
              openSnackbar={openSnackbar}
              toggleReFetchMahasiswa={toggleReFetchMahasiswa}
              setToggleReFetchMahasiswa={setToggleReFetchMahasiswa}
            />
          )}
        </GridItem>
        <GridItem xs={12} sm={12} lg={4}>
          <CardManajemenMahasiswaPreview
            handleChangePreviewID={handleChangePreviewID}
            handleChangeDialogProfilID={handleChangeDialogProfilID}
            previewID={previewID}
            previewData={previewData}
            openSnackbar={openSnackbar}
          />
          <CardMigrasiMahasiswaVerified
            open={cardType === "migrasi"}
            toggleReFetchMigrasi={toggleReFetchMigrasi}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} lg={8}>
          <DialogManajemenMahasiswaProfil
            dialogProfilID={dialogProfilID}
            dialogProfilEdit={dialogProfilEdit}
            handleChangeDialogProfilID={handleChangeDialogProfilID}
            handleChangeDialogProfilEdit={handleChangeDialogProfilEdit}
            openSnackbar={openSnackbar}
            previewID={previewID}
            previewData={previewData}
            setPreviewData={setPreviewData}
            setPreviewID={setPreviewID}
            listProdi={listProdi}
            listFakultas={listFakultas}
            toggleReFetchMahasiswa={toggleReFetchMahasiswa}
            setToggleReFetchMahasiswa={setToggleReFetchMahasiswa}
          />
          <DialogVerifikasiMigrasiMahasiswa
            handleChangeDialogMigrasiType={handleChangeDialogMigrasiType}
            handleChangeMigrasiData={handleChangeMigrasiData}
            userData={previewData}
            openSnackbar={openSnackbar}
            migrasiData={migrasiData}
            type={dialogMigrasiType}
            toggleReFetchMigrasi={toggleReFetchMigrasi}
            setToggleReFetchMigrasi={setToggleReFetchMigrasi}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
