/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SchoolIcon from "@material-ui/icons/School";

import NotesIcon from "@material-ui/icons/Notes";
import TableChartIcon from "@material-ui/icons/TableChart";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PrintIcon from "@material-ui/icons/Print";

// core components/views/Admin for Admin layout
import DashboardPage from "views/Admin/Dashboard/Dashboard.js";
import ManajemenCompany from "views/Admin/Manajemen/ManajemenCompany";
import ManajemenMahasiswa from "views/Admin/Manajemen/ManajemenMahasiswa";
import Portofolio from "views/Admin/Portofolio/Portofolio";
import ManajemenCPL from "views/Admin/Manajemen/ManajemenCPL";
import ManajemenRubrik from "views/Admin/Manajemen/ManajemenRubrik";
import Profile from "views/Admin/Profile/Profile";
import CetakSKPI from "views/Admin/CetakSKPI/CetakSKPI";
import HouseIcon from "@material-ui/icons/House";
import KegiatanInternal from "views/Admin/KegiatanInternal/KegiatanInternal";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/manajemen-prodi",
    name: "Manajemen Prodi",
    icon: SchoolIcon,
    layout: "/admin",
    component: ManajemenCompany
  },
  {
    path: "/manajemen-mahasiswa",
    name: "Manajemen Mahasiswa",
    icon: AssignmentIndIcon,
    layout: "/admin",
    component: ManajemenMahasiswa
  },
  {
    path: "/manajemen-cpl",
    name: "Manajemen CPL",
    icon: NotesIcon,
    layout: "/admin",
    component: ManajemenCPL
  },
  {
    path: "/manajemen-rubrik",
    name: "Manajemen Rubrik",
    icon: TableChartIcon,
    layout: "/admin",
    component: ManajemenRubrik
  },
  {
    path: "/kegiatan-internal",
    name: "Kegiatan Internal",
    icon: HouseIcon,
    layout: "/admin",
    component: KegiatanInternal
  },
  {
    path: "/portofolio",
    name: "Portofolio",
    icon: LibraryBooks,
    layout: "/admin",
    component: Portofolio
  },
  {
    path: "/cetak-skpi",
    name: "Cetak SKPI",
    icon: PrintIcon,
    layout: "/admin",
    component: CetakSKPI
  },
  {
    path: "/profile",
    name: "Profil Admin",
    icon: AccountCircleIcon,
    layout: "/admin",
    component: Profile
  }
];

export default dashboardRoutes;
