import React, { useEffect, useState } from "react";
import _ from "lodash";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import {
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";
// @date-io components
import DateFnsUtils from "@date-io/date-fns";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";
import Button from "dep/CustomButtons/Button";
import CardHeader from "dep/Card/CardHeader";
import CardBody from "dep/Card/CardBody";
import Card from "dep/Card/Card";
import CustomSelect from "dep/CustomInput/CustomSelect";
// @material-ui/icons
import SyncIcon from "@material-ui/icons/Sync";
import BlockIcon from "@material-ui/icons/Block";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
// core components
import ChipFileSingle from "components/Global/Chip/ChipFileSingle";
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import { formatDate } from "helpers/inputFormatting";
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";
import cardStyles from "assets/jss/jpt/cardStyles";

const useStyles = makeStyles({
  textStyle: {
    color: "#545454",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  ...cardStyles
});

const MAX_FILE_SIZE = 2000000;
const ACCEPT_FILE_TYPE = ["application/pdf"];

export default function TabProfileMigration() {
  useEffect(() => {
    handleFetchListProdi();
    handleFetchStatusMigrasi();
    handleFetchStatusReadmisi();
  }, []);
  const classes = useStyles();
  const [cancelType, setCancelType] = useState("");
  const handleOpenDialogCancel = cancleType => {
    setCancelType(cancleType);
  };
  const handleCloseDialogCancel = () => {
    setCancelType("");
  };

  // var: values
  const [listProdi, setListProdi] = useState([]);
  const [migrasi, setMigrasi] = useState({
    NIM: "",
    id_prodi: "",
    nomor_ijazah: "",
    tanggal_masuk: new Date(),
    file_bukti: null,
    link_bukti: "",
    status: ""
  });
  const [readmisi, setReadmisi] = useState({
    NIM: "",
    file_bukti: null,
    link_bukti: "",
    status: ""
  });

  const [migrasiEdited, setMigrasiEdited] = useState(false);
  const [readmisiEdited, setReadmisiEdited] = useState(false);

  const [openDialogMigrasiFile, setOpenDialogMigrasiFile] = useState(false);
  const [openDialogReadmisiFile, setOpenDialogReadmisiFile] = useState(false);

  const handleMigrasiEdited = () => {
    if (migrasi.link_bukti || migrasi.status === 1) setMigrasiEdited(true);
  };
  const handleReadmisiEdited = () => {
    if (readmisi.link_bukti || readmisi.status === 1) setReadmisiEdited(true);
  };

  //notifia
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });
  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };
  // handle: API fetch
  const handleFetchListProdi = async () => {
    let res = await Auth.fetch(BASE_URL + "/api/prodi");
    let data = await res.json();
    setListProdi(
      data.prodi.map(row => {
        return {
          value: row.id_prodi,
          text: row.jenjang + " " + row.nama_prodi
        };
      }) || []
    );
  };
  const handleCancle = async cancelType => {
    try {
      openSnackbar("info", "Deleting...");
      let res = await Auth.fetch(BASE_URL + "/api/" + cancelType + "/cancel/", {
        method: "DELETE"
      });
      let data = await res.json();

      if (res.status === 200) {
        openSnackbar("success", data.message);
        if (cancelType === "readmisi") {
          setReadmisiEdited(false);
          handleFetchStatusReadmisi();
        }
        if (cancelType === "migrasi") {
          handleFetchStatusMigrasi();
          setMigrasiEdited(false);
        }
        handleCloseDialogCancel();
      } else {
        let result =
          typeof data.message === "string"
            ? data.message
            : Object.values(data.message).join("");
        openSnackbar("warning", result);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };
  const handleFetchStatusMigrasi = async () => {
    try {
      let res = await Auth.fetch(BASE_URL + "/api/migrasi/");
      let resData = (await res.json()).data;

      setMigrasi({
        ...migrasi,
        NIM: _.get(resData, "nim_baru", ""),
        id_prodi: _.get(resData, "prodi", ""),
        nomor_ijazah: _.get(resData, "nomor_ijazah", ""),
        tanggal_masuk: _.has(resData, "tanggal_masuk")
          ? new Date(resData.tanggal_masuk)
          : new Date(),
        link_bukti: _.has(resData, "bukti")
          ? BASE_URL + "/" + resData.bukti
          : "",
        status: _.get(resData, "id_status_migrasi", "")
      });
    } catch (error) {
      //TODO: error -> ngga guna sih, soalnya default statenya udah rapi
    }
  };
  const handleFetchStatusReadmisi = async () => {
    try {
      let res = await Auth.fetch(BASE_URL + "/api/readmisi");
      let resData = (await res.json()).data;

      setReadmisi({
        ...readmisi,
        NIM: _.get(resData, "nim_baru", ""),
        link_bukti: _.has(resData, "bukti")
          ? BASE_URL + "/" + _.get(resData, "bukti", "")
          : "",
        status: _.get(resData, "id_status_readmisi", "")
      });
    } catch (error) {
      //TODO: if error -> ngga guna sih, soalnya default statenya udah rapi
    }
  };
  // handle: API submit
  const handleSubmitReadmisi = async () => {
    try {
      const url = readmisiEdited
        ? BASE_URL + "/api/readmisi/update/"
        : BASE_URL + "/api/readmisi/request/";
      const snackbarValue = readmisiEdited
        ? "Berhasil merubah pengajuan readmisi!"
        : "Berhasil mengajukan readmisi!";
      openSnackbar("info", "Submitting...");

      const form = new FormData();
      form.append("nim_baru", readmisi.NIM);
      if (readmisi.file_bukti) form.append("bukti", readmisi.file_bukti);
      if (readmisiEdited) form.append("_method", "PUT");
      let res = await Auth.fetchMultipart(url, {
        method: "POST",
        body: form
      });
      let data = await res.json();

      if (res.status === 200) {
        openSnackbar("success", snackbarValue);
        handleFetchStatusReadmisi();
      } else {
        let result =
          typeof data.message === "string"
            ? data.message
            : Object.values(data.message).join("");
        openSnackbar("warning", result);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };
  const handleSubmitMigrasi = async () => {
    const url = migrasiEdited
      ? BASE_URL + "/api/migrasi/update/"
      : BASE_URL + "/api/migrasi/request/";
    const snackbarValue = migrasiEdited
      ? "Berhasil merubah pengajuan readmisi!"
      : "Berhasil mengajukan migrasi!";
    openSnackbar("info", "Submitting...");
    try {
      const form = new FormData();
      form.append("nim_baru", migrasi.NIM);
      form.append("prodi", migrasi.id_prodi);
      form.append("nomor_ijazah", migrasi.nomor_ijazah);
      if (migrasi.file_bukti) form.append("bukti", migrasi.file_bukti);
      form.append("tanggal_masuk", formatDate(migrasi.tanggal_masuk));
      if (migrasiEdited) form.append("_method", "PUT");
      let res = await Auth.fetchMultipart(url, {
        method: "POST",
        body: form
      });
      let data = await res.json();

      if (res.status === 200) {
        openSnackbar("success", snackbarValue);
        handleFetchStatusMigrasi();
      } else {
        let result =
          typeof data.message === "string"
            ? data.message
            : Object.values(data.message).join("");
        openSnackbar("warning", result);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  // handle: values
  const handleChangeReadmisiNIM = event => {
    setReadmisi({ ...readmisi, NIM: event.target.value });
    handleReadmisiEdited();
  };
  const handleChangeMigrasiNIM = event => {
    setMigrasi({ ...migrasi, NIM: event.target.value });
    handleMigrasiEdited();
  };
  const handleChangeMigrasiIjazah = event => {
    setMigrasi({ ...migrasi, nomor_ijazah: event.target.value });
    handleMigrasiEdited();
  };
  const handleChangeMigrasiNewProdi = event => {
    setMigrasi({ ...migrasi, id_prodi: event.target.value });
  };
  const handleChangeMigrasiDateMasuk = date => {
    setMigrasi({ ...migrasi, tanggal_masuk: date });
  };

  // handle: values->file
  const handleDiscardMigrasiFile = () => {
    setMigrasi({ ...migrasi, file_bukti: null, link_bukti: "" });
    handleMigrasiEdited();
  };
  const handleDiscardReadmisiFile = () => {
    setReadmisi({ ...readmisi, file_bukti: null, link_bukti: "" });
    handleReadmisiEdited();
  };
  const handleSaveMigrasiFile = files => {
    setMigrasi({ ...migrasi, file_bukti: files[0] });
    setOpenDialogMigrasiFile(false);
  };
  const handleSaveReadmisiFile = files => {
    setReadmisi({ ...readmisi, file_bukti: files[0] });
    setOpenDialogReadmisiFile(false);
  };

  // handle: open-close
  const handleOpenDialogMigrasiFile = () => {
    setOpenDialogMigrasiFile(true);
  };
  const handleCloseDialogMigrasiFile = () => {
    setOpenDialogMigrasiFile(false);
  };
  const handleOpenDialogReadmisiFile = () => {
    setOpenDialogReadmisiFile(true);
  };
  const handleCloseDialogReadmisiFile = () => {
    setOpenDialogReadmisiFile(false);
  };

  //1 = menunggu
  //2 = acc
  //3 = ditolak
  const StatusComponent = status => {
    switch (status) {
      case 1:
        return (
          <React.Fragment>
            <GridContainer justify={"flex-end"} alignItems="center">
              <GridItem>
                <p className={classes.textStyle}>Status terakhir:</p>
              </GridItem>
              <GridItem>
                <Button round size={"sm"} color={"rose"}>
                  <SyncIcon />
                  menunggu
                </Button>
              </GridItem>
            </GridContainer>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <GridContainer justify={"flex-end"} alignItems="center">
              <GridItem>
                <p className={classes.textStyle}>Status terakhir:</p>
              </GridItem>
              <GridItem>
                <Button round size={"sm"} color={"success"}>
                  <SyncIcon />
                  diverifikasi
                </Button>
              </GridItem>
            </GridContainer>
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            <GridContainer justify={"flex-end"} alignItems="center">
              <GridItem>
                <p className={classes.textStyle}>Status terakhir:</p>
              </GridItem>
              <GridItem>
                <Button round size={"sm"} color={"danger"}>
                  <BlockIcon />
                  ditolak
                </Button>
              </GridItem>
            </GridContainer>
          </React.Fragment>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      <GridContainer justify={"center"}>
        <GridItem xs={10} md={8}>
          <Card>
            <CardHeader color={"info"}>
              <h4 className={classes.cardTitleWhite}>Readmisi Akun</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.textStyle}>
                Pengajuan Readmisi bagi Mahasiswa yang ingin melanjutkan SKPI
                dengan NIM terbaru. Mahasiswa menyiapkan Bukti Lama dan Bukti
                Baru sebagai syarat pengajuan Readmisi.
              </p>
              {StatusComponent(readmisi.status)}
              <GridContainer justify={"center"}>
                <GridItem xs={10}>
                  <CustomInput
                    labelText="Nomor Induk Mahasiswa Baru"
                    id="readmisiNIM"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "username",
                      value: readmisi.NIM || "",
                      onChange: handleChangeReadmisiNIM
                    }}
                  />
                </GridItem>
              </GridContainer>
              <div style={{ padding: "2vh" }} />
              <GridContainer justify={"space-around"} alignItems={"center"}>
                <GridItem>
                  {readmisi.file_bukti || readmisi.link_bukti ? (
                    <ChipFileSingle
                      fileName={_.get(readmisi, "file_bukti.name", "")}
                      link={_.get(readmisi, "link_bukti", "")}
                      discardFile={handleDiscardReadmisiFile}
                    />
                  ) : (
                    <Button
                      color={"info"}
                      onClick={handleOpenDialogReadmisiFile}
                      round
                      size={"sm"}
                    >
                      <CloudUploadIcon />
                      Upload Bukti
                    </Button>
                  )}
                </GridItem>
                <GridItem>
                  <Button
                    disabled={
                      readmisiEdited
                        ? !readmisi.NIM ||
                          (!readmisi.link_bukti && !readmisi.file_bukti)
                        : !readmisi.NIM || !readmisi.file_bukti
                    }
                    color={"success"}
                    round
                    onClick={handleSubmitReadmisi}
                  >
                    {readmisiEdited ? "Update" : "Submit"}
                  </Button>
                </GridItem>
              </GridContainer>
              <GridContainer justify={"center"}>
                <Button
                  hidden={readmisi.status !== 0 && readmisi.status !== 1}
                  color={"danger"}
                  round
                  onClick={event => {
                    handleOpenDialogCancel("readmisi");
                    event.preventDefault();
                  }}
                >
                  <DeleteSweepIcon />
                  Batalkan
                </Button>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <div style={{ padding: "2vh" }} />
      <GridContainer justify={"center"}>
        <GridItem xs={10} md={8}>
          <Card>
            <CardHeader color={"warning"}>
              <h4 className={classes.cardTitleWhite}>Migrasi Akun</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.textStyle}>
                Pengajuan Migrasi Akun untuk Mahasiswa yang melanjutkan studi
                dengan mengklaim akun sebelumnya. Mahasiswa menyiapkan Bukti
                kelulusan berupa ijazah dan KTM terbaru.
              </p>
              {StatusComponent(migrasi.status)}
              <GridContainer justify={"center"}>
                <GridItem xs={10}>
                  <CustomInput
                    labelText="Nomor Induk Mahasiswa Baru"
                    id="migrasiNIM"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "username",
                      value: migrasi.NIM || "",
                      onChange: handleChangeMigrasiNIM
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify={"center"}>
                <GridItem xs={10}>
                  <CustomInput
                    labelText="Nomor Ijazah"
                    id="migrasiIjazah"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "migrasiIjazah",
                      value: migrasi.nomor_ijazah || "",
                      onChange: handleChangeMigrasiIjazah
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justify={"center"}>
                <GridItem xs={10} md={5}>
                  <CustomSelect
                    labelText="Prodi Baru"
                    id="prodiBaru"
                    formControlProps={{
                      fullWidth: true
                    }}
                    selectProps={{
                      id: "prodi-baru",
                      name: "prodiBaru",
                      onChange: handleChangeMigrasiNewProdi
                    }}
                    selectItems={listProdi}
                    selectValue={migrasi.id_prodi}
                  />
                </GridItem>
                <GridItem xs={8} md={5}>
                  <div style={{ padding: "13.5px" }} />
                  <FormControl className={classes.formControl} fullWidth={true}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        id="tanggal-masuk"
                        label="Tanggal Masuk"
                        format="dd/MM/yyyy"
                        value={migrasi.tanggal_masuk}
                        onChange={date => handleChangeMigrasiDateMasuk(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <div style={{ padding: "2vh" }} />
              <GridContainer justify={"space-around"} alignItems={"center"}>
                <GridItem>
                  {migrasi.file_bukti || migrasi.link_bukti ? (
                    <ChipFileSingle
                      fileName={_.get(migrasi, "file_bukti.name", "")}
                      link={migrasi.link_bukti}
                      discardFile={handleDiscardMigrasiFile}
                    />
                  ) : (
                    <Button
                      color={"info"}
                      onClick={handleOpenDialogMigrasiFile}
                      round
                      size={"sm"}
                    >
                      <CloudUploadIcon />
                      Upload Bukti
                    </Button>
                  )}
                </GridItem>
                <GridItem>
                  <Button
                    color={"success"}
                    round
                    onClick={handleSubmitMigrasi}
                    disabled={
                      migrasiEdited
                        ? !migrasi.id_prodi ||
                          (!migrasi.link_bukti && !migrasi.file_bukti) ||
                          !migrasi.NIM ||
                          !migrasi.nomor_ijazah
                        : !migrasi.nomor_ijazah ||
                          !migrasi.NIM ||
                          !migrasi.id_prodi ||
                          !migrasi.file_bukti
                    }
                  >
                    {migrasiEdited ? "update" : "submit"}
                  </Button>
                </GridItem>
              </GridContainer>
              <GridContainer justify={"center"}>
                <Button
                  hidden={migrasi.status !== 0 && migrasi.status !== 1}
                  color={"danger"}
                  round
                  onClick={event => {
                    handleOpenDialogCancel("migrasi");
                    event.preventDefault();
                  }}
                >
                  <DeleteSweepIcon />
                  Batalkan
                </Button>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog open={Boolean(cancelType)} onClose={handleCloseDialogCancel}>
        <DialogTitle>Apakah Anda Yakin?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Anda akan membatalkan pengajuan {cancelType}. Apakah anda yakin?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={"warning"}
            size={"sm"}
            round
            onClick={handleCloseDialogCancel}
          >
            Tidak
          </Button>
          <Button
            color={"success"}
            size={"sm"}
            round
            onClick={event => {
              handleCancle(cancelType);
              event.preventDefault();
            }}
          >
            Ya
          </Button>
        </DialogActions>
      </Dialog>
      <DropzoneDialog
        open={openDialogMigrasiFile}
        onSave={handleSaveMigrasiFile}
        onClose={handleCloseDialogMigrasiFile}
        filesLimit={1}
        maxFileSize={MAX_FILE_SIZE}
        acceptedFiles={ACCEPT_FILE_TYPE}
        showFileNames={true}
        dropzoneText={"Upload bukti migrasi"}
      />
      <DropzoneDialog
        open={openDialogReadmisiFile}
        onSave={handleSaveReadmisiFile}
        onClose={handleCloseDialogReadmisiFile}
        filesLimit={1}
        maxFileSize={MAX_FILE_SIZE}
        acceptedFiles={ACCEPT_FILE_TYPE}
        showFileNames={true}
        dropzoneText={"Upload bukti readmisi"}
      />
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
      />
    </div>
  );
}
