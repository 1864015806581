import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";

export default function DialogOpsiStatPortofolio(props) {
  // var: values
  const [selectedProdi, setSelectedProdi] = useState(props.selectedProdi || "");
  // handle: values
  const handleChangeValues = event => {
    setSelectedProdi(event.target.value);
  };
  // handle: hook
  const handleSubmit = () => {
    props.onChange(selectedProdi);
    props.onClose();
  };
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={"sm"} fullWidth>
      <DialogTitle>Opsi</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Tentukan data yang ingin ditampilkan
        </DialogContentText>
        <GridContainer justify={"center"}>
          <GridItem xs={10} md={5}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="select-prodi">Program Studi</InputLabel>
              <Select
                labelId="select-prodi"
                id="id_prodi"
                name="id_prodi"
                value={selectedProdi}
                onChange={handleChangeValues}
                label="Program Studi"
              >
                <MenuItem value="">
                  <em>Semua</em>
                </MenuItem>
                {!!props.listProdi &&
                  props.listProdi.map((row, key) => (
                    <MenuItem key={key} value={row.id_prodi}>
                      {row.nama_prodi + ` (${row.jenjang})`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color={"primary"}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color={"primary"}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogOpsiStatPortofolio.propTypes = {
  listProdi: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedProdi: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
};
