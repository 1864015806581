import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Hidden,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";

export default function DialogOpsiStatMahasiswa(props) {
  // var: values
  const [values, setValues] = useState({
    option: props.selectedOption.option || "",
    id_prodi: props.selectedOption.id_prodi || ""
  });
  // handle: values
  const handleChangeValues = event => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  // handle: hook
  const handleSubmit = () => {
    props.onChange(values.option, values.id_prodi);
    props.onClose();
  };
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={"sm"} fullWidth>
      <DialogTitle>Opsi</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Tentukan data yang ingin ditampilkan
        </DialogContentText>

        <GridContainer justify={"center"}>
          {props.isSuperAdmin && (
            <GridItem xs={10} md={5}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-prodi">Program Studi</InputLabel>
                <Select
                  labelId="select-prodi"
                  id="id_prodi"
                  name="id_prodi"
                  value={values.id_prodi}
                  onChange={handleChangeValues}
                  label="Program Studi"
                >
                  <MenuItem value="">
                    <em>Semua</em>
                  </MenuItem>
                  {!!props.listProdi &&
                    props.listProdi.map((row, key) => (
                      <MenuItem key={key} value={row.id_prodi}>
                        {row.nama_prodi + ` (${row.jenjang})`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Hidden smUp>
                <div style={{ padding: "2vh" }} />
              </Hidden>
            </GridItem>
          )}
          <GridItem xs={10} md={5}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="select-option">Opsi</InputLabel>
              <Select
                labelId="option"
                id="option"
                name="option"
                value={values.option}
                onChange={handleChangeValues}
                label="opsi"
              >
                <MenuItem value="">Mahasiswa</MenuItem>
                <MenuItem value="aktif">Mahasiswa Aktif</MenuItem>
                <MenuItem value="SKPI">Mahasiswa Memenuhi SKPI</MenuItem>
                <MenuItem value="kelulusan">Mahasiswa Lulus</MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color={"primary"}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color={"primary"}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogOpsiStatMahasiswa.propTypes = {
  isSuperAdmin: PropTypes.bool,
  listProdi: PropTypes.array,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selectedOption: PropTypes.object
};
