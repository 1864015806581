import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";
import AppBar from "@material-ui/core/AppBar";
import { Button, Container, Hidden } from "@material-ui/core";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import {
  IMAGE_CARTOON,
  LOGO_ONLY,
  LOGO_TEXT,
  ROLE_ADMIN_JURUSAN,
  ROLE_MAHASISWA,
  ROLE_SUPER_ADMIN,
  ROLE_VERIFIKATOR
} from "const";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Auth from "helpers/auth";
import PopoverLogin from "components/Public/Login/Popover/PopoverLogin";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CreateIcon from "@material-ui/icons/Create";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  containerAppBar: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  imageCartoon: {
    backgroundImage: "url(" + IMAGE_CARTOON + ")",
    backgroundSize: "auto",
    maxHeight: "30vh",
    maxWidth: "5vw",
    width: "auto",
    height: "auto",
    backgroundRepeat: "no-repeat"
  },
  logo: {
    display: "inline-block",
    maxHeight: "40px",
    marginLeft: "10px",
    marginRight: "15px"
  },
  textMenuAppBar: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    lineHeight: "30px",
    fontSize: "16px",
    color: "#ffffff",
    textTransform: "none"
  },
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    width: "100%",
    paddingTop: "10px",
    border: "0",
    borderRadius: "3px",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block"
  }
});

export default function Navbar() {
  const classes = useStyles();
  const history = useHistory();

  // var: values
  const [loginPopover, setLoginPopover] = React.useState({
    open: false,
    anchorEl: null
  });
  const [mobileMenu, setMobileMenu] = React.useState({
    open: false,
    anchorEl: null
  });

  // notif
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });
  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };

  // handle: route
  const getDashboardLink = () => {
    if (Auth.isRoleEqualTo(ROLE_MAHASISWA)) return "/user";
    else if (
      Auth.isRoleEqualTo(ROLE_SUPER_ADMIN) ||
      Auth.isRoleEqualTo(ROLE_ADMIN_JURUSAN)
    )
      return "/admin";
    else if (Auth.isRoleEqualTo(ROLE_VERIFIKATOR)) return "/verifikator";
  };
  const toSignUp = () => {
    history.push("/register");
  };
  const toDashboard = () => {
    history.push(getDashboardLink());
  };

  // handle: open-close
  const handleOpenMobileMenu = event => {
    setMobileMenu({ open: true, anchorEl: event.currentTarget });
  };
  const handleCloseMobileMenu = () => {
    setMobileMenu({ open: false, anchorEl: null });
  };
  const handleOpenLoginPopover = event => {
    setLoginPopover({ open: true, anchorEl: event.currentTarget });
  };
  const handleCloseLoginPopover = () => {
    setLoginPopover({ open: false, anchorEl: null });
  };

  // handle: API submit
  const handleLogin = async (nim, password) => {
    try {
      openSnackbar("info", "Logging in..");
      const successLogin = await Auth.login(nim, password);
      //if (successLogin) window.location = "/otp";
      if (successLogin) history.push("/otp");
      else {
        openSnackbar("warning", "NIM atau password salah!");
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan!");
    }
  };

  // internal components
  const renderButtonDesktop = (
    <GridItem md={3}>
      <GridContainer justify={"space-evenly"}>
        <GridItem>
          <Button
            className={classes.textMenuAppBar}
            onClick={toSignUp}
            color={"inherit"}
          >
            Register
          </Button>
        </GridItem>
        <GridItem>
          <Button
            className={classes.textMenuAppBar}
            variant={"outlined"}
            color={"inherit"}
            onClick={handleOpenLoginPopover}
          >
            Sign In
          </Button>
        </GridItem>
      </GridContainer>
    </GridItem>
  );
  const renderButtonDesktopLogedIn = (
    <GridItem md={3}>
      <GridContainer justify={"space-evenly"}>
        <GridItem>
          <Button
            className={classes.textMenuAppBar}
            onClick={toDashboard}
            color={"inherit"}
          >
            Dashboard
          </Button>
        </GridItem>
        <GridItem>
          <Button
            className={classes.textMenuAppBar}
            variant={"outlined"}
            color={"inherit"}
            onClick={() => Auth.logout(history)}
          >
            Sign Out
          </Button>
        </GridItem>
      </GridContainer>
    </GridItem>
  );
  const renderMenuMobile = (
    <Menu
      anchorEl={mobileMenu.anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={mobileMenu.open}
      onClose={handleCloseMobileMenu}
    >
      <MenuItem onClick={toSignUp}>
        <IconButton color="inherit">
          <CreateIcon />
        </IconButton>
        <p>Register</p>
      </MenuItem>
      <MenuItem onClick={handleOpenLoginPopover}>
        <IconButton color="inherit">
          <AccountCircleIcon />
        </IconButton>
        <p>Sign In</p>
      </MenuItem>
    </Menu>
  );
  const renderMenuMobileLogedIn = (
    <Menu
      anchorEl={mobileMenu.anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={mobileMenu.open}
      onClose={handleCloseMobileMenu}
    >
      <MenuItem onClick={toDashboard}>
        <IconButton color="inherit">
          <DashboardIcon />
        </IconButton>
        <p>Dashboard</p>
      </MenuItem>
      <MenuItem onClick={() => Auth.logout(history)}>
        <IconButton color="inherit">
          <AccountCircleIcon />
        </IconButton>
        <p>Sign Out</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <PopoverLogin
        handleLogin={handleLogin}
        open={loginPopover.open}
        anchorEl={loginPopover.anchorEl}
        onClose={handleCloseLoginPopover}
      />
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
      />
      <AppBar
        color={"transparent"}
        position={"static"}
        className={classes.appBar}
      >
        <Container maxWidth={"lg"} className={classes.containerAppBar}>
          <Hidden mdUp implementation={"css"}>
            <GridContainer justify={"space-between"} alignItems={"center"}>
              <GridItem xs md>
                <Link to={"/"}>
                  <img
                    src={LOGO_ONLY}
                    alt={"Janapotrek"}
                    className={classes.logo}
                  />
                </Link>
              </GridItem>
              <GridItem>
                <IconButton
                  className={classes.textMenuAppBar}
                  onClick={handleOpenMobileMenu}
                >
                  <MenuIcon />
                </IconButton>
                {Auth.isLoggedIn() ? renderMenuMobileLogedIn : renderMenuMobile}
              </GridItem>
            </GridContainer>
          </Hidden>
          <Hidden smDown implementation={"css"}>
            <GridContainer justify={"space-between"} alignItems={"center"}>
              <GridItem xs md>
                <Link to={"/"}>
                  <img
                    src={LOGO_TEXT}
                    alt={"Janapotrek"}
                    className={classes.logo}
                  />
                </Link>
              </GridItem>
              {Auth.isLoggedIn()
                ? renderButtonDesktopLogedIn
                : renderButtonDesktop}
            </GridContainer>
          </Hidden>
        </Container>
      </AppBar>
    </>
  );
}
