import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import Button from "dep/CustomButtons/Button";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function DialogHapusManajemenCPL(props) {
  // props
  const {
    handleFetchCPLData,
    openSnackbar,
    selectedCPL = {
      id_prodi: "",
      id_bidang_cpl: "",
      id_cpl: "",
      urutan_di_skpi: "",
      judul_cpl_inggris: "",
      judul_cpl_indonesia: ""
    }
  } = props;

  // state
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (!!selectedCPL && props.newDialogType === "delete") {
      setOpenDialog(true);
    }
    // eslint-disable-next-line
  }, [selectedCPL, props.newDialogType]);

  // handle: API submit
  const handleAPISubmit = async () => {
    try {
      openSnackbar("info", "Menghapus..");
      let res = await Auth.fetch(
        BASE_URL + "/api/cpl/" + selectedCPL.id_cpl + "/delete",
        {
          method: "DELETE"
        }
      );

      let data = await res.json();
      let errorMessages = "";
      if (typeof data.message === "string") {
        errorMessages = data.message;
      } else if (typeof data.message === "object") {
        errorMessages = "";
        if (Array.isArray(data.message)) {
          for (let err of data.message) {
            errorMessages +=
              typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
          }
        } else {
          for (let key in data.message) errorMessages += data.message[key][0];
        }
      }

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data CPL berhasil dihapus!");
        handleFetchCPLData();
        handleCloseDialog();
      } else if (res.status >= 400 && res.status < 500) {
        openSnackbar("warning", errorMessages);
      } else {
        openSnackbar("error", errorMessages);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  // handle: open-close -> props
  const handleCloseDialog = () => {
    setOpenDialog(false);
    props.handleChangeSelectedCPL("");
    props.handleChangeNewDialogType("");
  };

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>Apakah Anda Yakin?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {"Anda akan menghapus item CPL di bawah"}
        </DialogContentText>
        <DialogContentText>
          {"Urutan: " + selectedCPL.urutan_di_skpi}
          <br />
          {"Nama Item (ID): " + selectedCPL.judul_cpl_indonesia}
          <br />
          {"Item Name (EN): " + selectedCPL.judul_cpl_inggris}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="warning" round>
          Tidak
        </Button>
        <Button onClick={handleAPISubmit} color="success" autoFocus round>
          Ya
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogHapusManajemenCPL.propTypes = {
  selectedCPL: PropTypes.object,
  handleChangeNewDialogType: PropTypes.func,
  handleChangeSelectedCPL: PropTypes.func,
  openSnackbar: PropTypes.func,
  handleFetchCPLData: PropTypes.func,
  newDialogType: PropTypes.string
};
