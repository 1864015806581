import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";

// dep components
import CardBody from "dep/Card/CardBody";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";

// components
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";
import CardManajemenCPLCategory from "components/Admin/ManajemenCPL/Card/CardManajemenCPLCategory";
import LittleCardManajemenCPLChooseCategory from "components/Admin/ManajemenCPL/LittleCard/LittleCardManajemenCPLChooseCategory";
import ButtonTambahKategoriCPL from "components/Admin/ManajemenCPL/Button/ButtonTambahKategoriCPL";
// API
import cardStyles from "assets/jss/jpt/cardStyles";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

// styling
const useStyles = makeStyles(cardStyles);

export default function CardManajemenCPLMain(props) {
  const classes = useStyles();

  const { selectedProdi } = props;
  // var: open-close
  const [showCard, setShowCard] = useState(true);

  // var: values
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });

  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };

  useEffect(() => {
    handleFetchCategories();
  }, [selectedProdi]);

  // handle: API fetch
  const handleFetchCategories = async () => {
    let res = await Auth.fetch(
      `${BASE_URL}/api/prodi/${selectedProdi}/bidangcpl`
    );
    let data = await res.json();
    setCategories(
      (data.bidang_cpl || []).sort(
        (a, b) => a.urutan_di_skpi - b.urutan_di_skpi
      )
    );
  };

  // handle: open-close
  const handleBack = () => {
    setShowCard(true);
  };

  // handle: values
  const handleChooseCategory = categoryId => {
    setShowCard(false);
    setSelectedCategory(categoryId);
  };

  return (
    <>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarData.message}
        severity={snackbarData.severity}
      />
      {showCard ? (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color={"primary"}>
                <GridContainer
                  justify={"space-between"}
                  alignItems={"flex-end"}
                >
                  <GridItem>
                    <h4 className={classes.cardTitleWhite}>Manajemen CPL</h4>
                    <p className={classes.cardCategoryWhite}>
                      Ubah atau tambah Bidang CPL
                    </p>
                  </GridItem>
                  <GridItem>
                    <ButtonTambahKategoriCPL
                      openSnackbar={openSnackbar}
                      handleFetchCategories={handleFetchCategories}
                      selectedProdi={selectedProdi}
                    />
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {categories.map((value, index) => (
                    <GridItem key={index} xs={12} md={3}>
                      <LittleCardManajemenCPLChooseCategory
                        selectedBidangCPL={value}
                        openSnackbar={openSnackbar}
                        handleChooseCategory={handleChooseCategory}
                        selectedProdi={selectedProdi}
                        handleFetchCategories={handleFetchCategories}
                      />
                    </GridItem>
                  ))}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        <CardManajemenCPLCategory
          selectedCategory={selectedCategory}
          handleBackButton={handleBack}
          openSnackbar={openSnackbar}
          selectedProdi={selectedProdi}
        />
      )}
    </>
  );
}

CardManajemenCPLMain.propTypes = {
  selectedProdi: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
