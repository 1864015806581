import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types"; // ES6
import moment from "moment";

// @material-ui/core components
import {
  Avatar,
  Collapse,
  Divider,
  IconButton,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";
import CardBody from "dep/Card/CardBody";
import Button from "dep/CustomButtons/Button";

// @material-ui/icons
import InfoIcon from "@material-ui/icons/Info";
import CancelIcon from "@material-ui/icons/Cancel";
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle";

import { BASE_URL } from "const";
import cardStyles from "assets/jss/jpt/cardStyles";
import valuesStyles from "assets/jss/jpt/valuesStyles";
import buttonsStyles from "assets/jss/jpt/buttonsStyles";

//styling
const useStyles = makeStyles(theme => ({
  ...cardStyles,
  ...valuesStyles,
  ...taskStyles,
  ...buttonsStyles,
  avatar: { width: theme.spacing(10), height: theme.spacing(10) }
}));

export default function CardManajemenMahasiswaPreview(props) {
  // ------NOTE------
  // Lihat "views/Admin/Manajemen/ManajemenMahasiswa" biar lebih jelas
  // props.previewID => ID or NIM mahasiswa
  // props.handleChangeDialogProfilID(<ID or NIM mhs>) => set ID mhs Dialog lihat profil (DialogManajemenMahasiswaProfil.js)
  // props.handleChangePreviewID(<ID or NIM mhs>) => set ID mhs untuk preview kecil di samping table (CardManajemenMahasiswaPreview,js)

  const classes = useStyles();
  useEffect(() => {
    if (props.previewData) handleFetchPreviewData();
    // eslint-disable-next-line
  }, [props.previewData]);

  // var: values
  const [values, setValues] = useState({
    NIM: "",
    nama: "",
    email: "",
    phone: "",
    prodi: "",
    photo: "",
    alamat: "",
    kelamin: "",
    tglMasuk: "",
    tglLulus: ""
  });

  // handle: open-close [hook]
  const handleViewProfilButton = () => {
    props.handleChangeDialogProfilID(props.previewID);
  };
  const handleCloseButton = () => {
    props.handleChangePreviewID(0);
  };

  // handle: API fetch
  const handleFetchPreviewData = () => {
    if (props.previewData) {
      setValues({
        NIM: _.get(props.previewData, "nim"),
        nama: _.get(props.previewData, "nama"),
        email: _.get(props.previewData, "email"),
        phone: _.get(props.previewData, "no_hp"),
        prodi: _.get(props.previewData, "nama_prodi"),
        photo: BASE_URL + "/" + _.get(props.previewData, "foto_profil"),
        alamat: _.get(props.previewData, "alamat"),
        kelamin: _.get(props.previewData, "jenis_kelamin")
          ? "Perempuan"
          : "Laki-laki",
        tglMasuk: _.get(props.previewData, "tanggal_masuk", "-").split(" ")[0],
        tglLulus: (_.get(props.previewData, "tanggal_lulus", "-") || "-").split(
          " "
        )[0]
      });
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Collapse in={Boolean(props.previewID)}>
          <Card>
            <CardHeader color={"primary"}>
              <GridContainer justify={"space-between"}>
                <GridItem>
                  <h4 className={classes.cardTitleWhite}>Profil Mahasiswa</h4>
                  <p className={classes.cardCategoryWhite}>{values.nama}</p>
                </GridItem>
                <GridItem>
                  <Tooltip
                    id="tooltip-top"
                    title="close"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <IconButton
                      aria-label="info"
                      className={classes.tableActionButton}
                      onClick={handleCloseButton}
                    >
                      <CancelIcon
                        className={
                          classes.tableActionButtonIcon +
                          " " +
                          classes.buttonClose
                        }
                      />
                    </IconButton>
                  </Tooltip>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer justify={"center"}>
                <GridItem>
                  <Avatar
                    alt={values.nama}
                    src={values.photo}
                    className={classes.avatar}
                  />
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer justify={"center"}>
                <GridItem xs={8}>
                  <Divider />
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer justify={"space-between"}>
                <GridItem>
                  <p>NIM</p>
                </GridItem>
                <GridItem>
                  <p className={classes.values}>{values.NIM}</p>
                </GridItem>
              </GridContainer>
              <GridContainer justify={"space-between"}>
                <GridItem>
                  <p>Prodi</p>
                </GridItem>
                <GridItem>
                  <p className={classes.values}>{values.prodi}</p>
                </GridItem>
              </GridContainer>
              <GridContainer justify={"space-between"}>
                <GridItem>
                  <p>email</p>
                </GridItem>
                <GridItem>
                  <p className={classes.values}>{values.email}</p>
                </GridItem>
              </GridContainer>
              {values.phone ? (
                <GridContainer justify={"space-between"}>
                  <GridItem>
                    <p>nomor HP</p>
                  </GridItem>
                  <GridItem>
                    <p className={classes.values}>{values.phone}</p>
                  </GridItem>
                </GridContainer>
              ) : (
                <></>
              )}
              <GridContainer justify={"space-between"}>
                <GridItem xs={4}>
                  <p>Alamat</p>
                </GridItem>
                <GridItem xs={8}>
                  <p className={classes.values}>{values.alamat}</p>
                </GridItem>
              </GridContainer>
              <GridContainer justify={"space-between"}>
                <GridItem>
                  <p>Jenis Kelamin</p>
                </GridItem>
                <GridItem>
                  <p className={classes.values}>{values.kelamin}</p>
                </GridItem>
              </GridContainer>
              <GridContainer justify={"space-between"}>
                <GridItem>
                  <p>Tanggal Masuk</p>
                </GridItem>
                <GridItem>
                  <p className={classes.values}>
                    {moment(values.tglMasuk).format("DD-MM-YYYY")}
                  </p>
                </GridItem>
              </GridContainer>
              {values.tglLulus ? (
                <GridContainer justify={"space-between"}>
                  <GridItem>
                    <p>Tanggal Lulus</p>
                  </GridItem>
                  <GridItem>
                    <p className={classes.values}>
                      {values.tglLulus !== "-"
                        ? moment(values.tglLulus).format("DD-MM-YYYY")
                        : "-"}
                    </p>
                  </GridItem>
                </GridContainer>
              ) : (
                <></>
              )}
              <GridContainer justify={"center"}>
                <GridItem>
                  <Button
                    color={"primary"}
                    round
                    onClick={handleViewProfilButton}
                  >
                    <InfoIcon />
                    Lihat Profil
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </Collapse>
      </GridItem>
    </GridContainer>
  );
}

CardManajemenMahasiswaPreview.propTypes = {
  handleChangeDialogProfilID: PropTypes.func,
  handleChangePreviewID: PropTypes.func,
  previewData: PropTypes.object,
  previewID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
