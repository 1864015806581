import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons components
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

// styling
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle";
import buttonsStyles from "assets/jss/jpt/buttonsStyles";

const useStyles = makeStyles({
  ...taskStyles,
  ...buttonsStyles
});

export default function ButtonEditManajemenAdmin(props) {
  const classes = useStyles();

  //handle: hook
  const handleEditButton = () => {
    props.handleChangeNewDialogType("edit");
    props.handleChangeSelectedUser(props.selectedUser);
  };

  const handleDeleteButton = () => {
    props.handleChangeNewDialogType("delete");
    props.handleChangeSelectedUser(props.selectedUser);
  };

  return (
    <>
      <Tooltip
        id="tooltip-top"
        title="Ubah"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="info"
          className={classes.tableActionButton}
          onClick={handleEditButton}
        >
          <EditIcon
            className={classes.tableActionButtonIcon + " " + classes.buttonEdit}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        id="tooltip-top"
        title="Hapus"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="Add"
          className={classes.tableActionButton}
          onClick={handleDeleteButton}
        >
          <DeleteForeverIcon
            className={classes.tableActionButtonIcon + " " + classes.close}
          />
        </IconButton>
      </Tooltip>
    </>
  );
}

ButtonEditManajemenAdmin.propTypes = {
  handleChangeNewDialogType: PropTypes.func,
  handleChangeSelectedUser: PropTypes.func,
  id: PropTypes.any,
  selectedUser: PropTypes.any
};
