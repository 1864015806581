import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
// @material-ui/core components
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/lab components TODO: update to stable release
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";

const useStyles = makeStyles({
  paper: {
    padding: "6px 16px"
  }
});
export default function ProfileTimeline(props) {
  const classes = useStyles();
  return (
    <Timeline align={"alternate"}>
      {props.timeline.map((value, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent>
            <Typography color="textSecondary">
              {moment(value.date, "YYYY-MM-DD").format("LL")}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1">
                {value.title_id}
              </Typography>
              <Typography>{value.title_en}</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}

ProfileTimeline.propTypes = {
  timeline: PropTypes.array
};
