import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";

// @material-ui/core components
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// dep components
import Table from "dep/Table/Table";

// components
// import DialogManajemenMahasiswaPortofolioPdf from "components/Admin/ManajemenMahasiswa/Dialog/DialogManajemenMahasiswaPortofolioPdf";
// import VerifikasiModal from "components/Admin/Portofolio/Modal/VerifikasiModal";
import DialogManajemenMahasiswaHapusPortofolio from "components/Admin/ManajemenMahasiswa/Dialog/DialogManajemenMahasiswaHapusPortofolio";
import DialogVerifikasiPortofolio from "components/Admin/Portofolio/Dialog/DialogVerifikasiPortofolio";
import PdfViewer from "components/Global/Dialog/PdfViewer";

// @material-ui/icons
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GavelIcon from "@material-ui/icons/Gavel";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

// styling
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";
import buttonsStyles from "assets/jss/jpt/buttonsStyles";

const useStyles = makeStyles({
  ...taskStyles,
  ...buttonsStyles
});

export default function TabDialogManajemenMahasiswaPortofolio(props) {
  const classes = useStyles();

  // var: values
  const [selectedPortofolioID, setSelectedPortofolioID] = useState(0);
  const [selectedPortofolio, setSelectedPortofolio] = useState({});
  const [linkPdf, setLinkPdf] = useState("");
  const [tableData, setTableData] = useState([]);
  const [newDialogType, setNewDialogType] = useState("");
  const [portofolioInfo, setPortofolioInfo] = useState({
    nama_mahasiswa: "",
    judul_portofolio: ""
  });

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line
  }, [props.dialogProfilID]);

  // handle: open-close -> reset value
  const handleCloseNewDialog = () => {
    setSelectedPortofolioID(0);
    setNewDialogType("");
    setPortofolioInfo({ nama_mahasiswa: "", judul_portofolio: "" });
    setSelectedPortofolio({});
    setLinkPdf("");
  };

  // handle: API fetch
  const handleFetchData = async () => {
    let url = BASE_URL + "/api/portofolio/user/" + props.dialogProfilID + "?paginate=9999&page=1";
    let res = await Auth.fetch(url);
    let data = await res.json();

    let newData = _.get(data, "portofolio.data", []).map((element, idx) => {
      return [
        idx + 1,
        _.get(element, "judul"),
        _.get(element, "nama_anak_rubrik", "Nama Anak Rubrik"),
        _.get(element, "bobot_skp_portofolio"),
        moment(_.get(element, "tanggal_terbit")).format("DD-MM-YYYY"),
        _.get(element, "status_portofolio"),
        createTooltip(
          _.get(element, "id_portofolio"),
          _.get(element, "nama_mahasiswa"),
          _.get(element, "judul"),
          _.get(
            element,
            BASE_URL +
              `/storage/portofolio/${element.nim}/${element.id_portofolio}/` +
              element.dokumen_portofolio.split("/").slice(-1)[0]
          ),
          element
        )
      ];
    });

    setTableData(newData);
  };

  const createTooltip = (
    id,
    nama_mahasiswa,
    judul_portofolio,
    link,
    element
  ) => {
    return (
      <>
        <Tooltip
          id="tooltip-top"
          title="pdf"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            aria-label="info"
            className={classes.tableActionButton}
            onClick={event => {
              setLinkPdf(link);
              setNewDialogType("pdf");
              event.preventDefault();
            }}
          >
            <PictureAsPdfIcon
              className={
                classes.tableActionButtonIcon + " " + classes.buttonPdf
              }
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          id="tooltip-top"
          title="verifikasi"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            aria-label="info"
            className={classes.tableActionButton}
            onClick={event => {
              setSelectedPortofolioID(id);
              setSelectedPortofolio(element);
              setNewDialogType("verifikasi");
              event.preventDefault();
            }}
          >
            <GavelIcon
              className={
                classes.tableActionButtonIcon + " " + classes.buttonInfo
              }
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          id="tooltip-top"
          title="hapus"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            aria-label="info"
            className={classes.tableActionButton}
            onClick={event => {
              setSelectedPortofolioID(id);
              setPortofolioInfo({ nama_mahasiswa, judul_portofolio });
              setNewDialogType("hapus");
              event.preventDefault();
            }}
          >
            <DeleteForeverIcon
              className={classes.tableActionButtonIcon + " " + classes.close}
            />
          </IconButton>
        </Tooltip>
      </>
    );
  };
  return (
    <>
      <Table
        tableHeaderColor="primary"
        tableHead={[
          "No",
          "Judul",
          "Rubrik",
          "SKP",
          "Tanggal",
          "Status",
          "opsi"
        ]}
        tableData={tableData}
      />

      {/*<DialogManajemenMahasiswaPortofolioPdf*/}
      {/*  selectedPortofolioID={selectedPortofolioID}*/}
      {/*  type={newDialogType}*/}
      {/*  handleCloseDialog={handleCloseNewDialog}*/}
      {/*/>*/}
      {/*<VerifikasiModal*/}
      {/*  open={newDialogType === "verifikasi"}*/}
      {/*  handleClose={() => handleCloseNewDialog()}*/}
      {/*  selectedUser={props.dialogProfilID}*/}
      {/*  selectedPortofolio={selectedPortofolioID}*/}
      {/*  fetchDataParent={handleFetchData}*/}
      {/*  {...props}*/}
      {/*/>*/}
      <PdfViewer
        open={newDialogType === "pdf" && !!linkPdf}
        onClose={handleCloseNewDialog}
        link={linkPdf}
      />
      <DialogVerifikasiPortofolio
        onClose={handleCloseNewDialog}
        open={newDialogType === "verifikasi"}
        portofolioID={selectedPortofolioID}
        portofolioData={selectedPortofolio}
        openSnackbar={props.openSnackbar}
      />
      <DialogManajemenMahasiswaHapusPortofolio
        selectedPortofolioID={selectedPortofolioID}
        type={newDialogType}
        selectedUser={props.dialogProfilID}
        handleCloseDialog={handleCloseNewDialog}
        portofolioInfo={portofolioInfo}
        handleFetchPortofolioData={handleFetchData}
        {...props}
      />
    </>
  );
}

TabDialogManajemenMahasiswaPortofolio.propTypes = {
  dialogProfilID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  openSnackbar: PropTypes.func
};
