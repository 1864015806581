import React, { useEffect, useState } from "react";
import _ from "lodash";
import reactStringReplace from "react-string-replace";
// @material-ui/core components
import Pagination from "@material-ui/lab/Pagination";
import { Container, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// dep components
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
// components
import Navbar from "components/Public/Navbar/Navbar";
import Footer from "components/Public/Footer/Footer";
import ItemSearch from "components/Public/SearchPage/ListItem/ItemSearch";
import NavbarSearchPage from "components/Public/SearchPage/Navbar/NavbarSearchPage.js";
// for API
import Auth from "helpers/auth";
import { BASE_URL, LANDING_PAGE_BG_DESKTOP } from "const";
import { Helmet } from "react-helmet";

const ITEM_PER_PAGE = 10;

const useStyles = makeStyles({
  background: {
    backgroundImage: "url(" + LANDING_PAGE_BG_DESKTOP + ")",
    backgroundPosition: "center top",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  container: {
    paddingTop: "10vh",
    minHeight: "70vh"
  }
});

export default function SearchPage(props) {
  const queryString = require("query-string");
  const classes = useStyles();

  // var: values
  // eslint-disable-next-line react/prop-types
  const { query } = props.match.params;
  const parsed = queryString.parse(query);
  const [search, setSearch] = useState(parsed.search ? parsed.search : "");
  const [filters, setFilters] = useState(
    parsed.filters ? parsed.filters : "category=profile"
  );
  const [page, setPage] = useState(parsed.page ? parseInt(parsed.page) : 1);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    // handle: [API] fetch
    const handleFetch = async () => {
      let pageCount, data;
      let filter = queryString.parse(filters) || { category: "profile" };
      if (filter.category === "portofolio") {
        let res = await Auth.fetch(
          BASE_URL +
            `/api/mahasiswa?page=${page}&paginate=${ITEM_PER_PAGE}&portofolio=${search}`
        );
        let dataProfile = await res.json();
        data = _.get(dataProfile, "users.data", []).map(user => {
          return {
            ...user,
            tittle: user.nama,
            description: (
              <div>
                {user.nim}
                <ul>
                  {_.get(user, "portofolio", []).map(e => {
                    const text = e.judul + " (" + e.judul_inggris + ")";
                    return (
                      <li>
                        {reactStringReplace(
                          text,
                          new RegExp(`(${search})`, "i"),
                          (match, i) => (
                            <b>{match}</b>
                          )
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ),
            link: `/profile/${user.nim}`
          };
        });
        pageCount = _.get(dataProfile, "users.last_page", 0);
      } else {
        let res = await Auth.fetch(
          BASE_URL +
            `/api/mahasiswa?page=${page}&paginate=${ITEM_PER_PAGE}&nim=${search}&nama=${search}`
        );
        let dataProfile = await res.json();

        data = _.get(dataProfile, "users.data", []).map(mahasiswa => {
          return {
            ...mahasiswa,
            tittle: mahasiswa.nama,
            description: mahasiswa.nim,
            link: `/profile/${mahasiswa.nim}`
          };
        });
        pageCount = _.get(dataProfile, "users.last_page", 0);
      }

      setPageCount(pageCount);
      setTableData(data);
    };
    handleFetch();
  }, [filters, page, search]);

  //handle: state
  const handleChangeSearch = search => {
    setTableData([]);
    setSearch(search);
  };
  const handleChangeFilters = filters => {
    setTableData([]);
    setFilters(filters);
  };
  const handleChangePage = (event, page) => {
    setTableData([]);
    setPage(page);
  };

  const SearchResult = () => {
    if (tableData.length === 0) {
      return "Kata kunci yang anda cari tidak ditemukan.";
    } else {
      return (
        <List>
          {tableData.map((row, key) => (
            <ItemSearch values={row} key={key} />
          ))}
        </List>
      );
    }
  };

  return (
    <>
      <div className={classes.background}>
        <Helmet>
          <title>Search - Janabadra Professional Tracker</title>
          <meta
            name="description"
            content="Janabadra Professional Tracker, Sistem Data Portofolio Pendamping Ijazah, Universitas Janabadra"
          />
        </Helmet>
        <Navbar {...props} />
        <NavbarSearchPage
          handleChangeFilters={handleChangeFilters}
          handleChangeSearch={handleChangeSearch}
          search={search}
          filters={filters}
        />
      </div>
      <Container maxWidth={"lg"} className={classes.container}>
        <GridContainer justify={"center"}>
          <GridItem xs={10}>
            <SearchResult />
          </GridItem>
        </GridContainer>
        <div style={{ padding: "2vh" }} />
      </Container>
      <Container maxWidth={"lg"}>
        <GridContainer justify={"center"}>
          <GridItem>
            <Pagination
              count={pageCount}
              page={page}
              onChange={handleChangePage}
            />
            <br />
          </GridItem>
        </GridContainer>
      </Container>
      <Footer />
    </>
  );
}
