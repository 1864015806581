import React, { useState } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

// dep components
import Button from "dep/CustomButtons/Button.js";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import CustomInput from "dep/CustomInput/CustomInput";

// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

export default function DialogUbahKategoriRubrik(props) {
  const { openSnackbar } = props;

  // State
  const [openDialog, setOpenDialog] = useState(true);
  const [dataBidangPortofolio, setDataBidangPortofolio] = useState(
    props.selectedBidangPortofolio
  );

  // handle: API Submit
  const handleAPISubmit = async () => {
    try {
      openSnackbar("info", "Mengupdate..");
      let res = await Auth.fetch(
        BASE_URL +
          "/api/bidangportofolio/" +
          props.selectedBidangPortofolio.id_bidang_portofolio +
          "/update",
        {
          method: "PUT",
          body: JSON.stringify(dataBidangPortofolio)
        }
      );

      let data = await res.json();
      let errorMessages = "";
      if (typeof data.message === "string") {
        errorMessages = data.message;
      } else if (typeof data.message === "object") {
        errorMessages = "";
        if (Array.isArray(data.message)) {
          for (let err of data.message) {
            errorMessages +=
              typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
          }
        } else {
          for (let key in data.message) errorMessages += data.message[key][0];
        }
      }

      if (res.status >= 200 && res.status < 300) {
        openSnackbar("success", "Data bidang portofolio berhasil diupdate!");
        props.handleFetchCategories();
        props.handleCloseUbah();
      } else if (res.status >= 400 && res.status < 500) {
        openSnackbar("warning", errorMessages);
      } else {
        openSnackbar("error", errorMessages);
      }
    } catch (err) {
      openSnackbar("error", "Terjadi kesalahan");
    }
  };

  const changeDataBidangPortofolio = e => {
    setDataBidangPortofolio({
      ...dataBidangPortofolio,
      [e.target.name]: e.target.value
    });
  };

  // handle: open-close
  const handleCloseDialog = () => {
    setOpenDialog(false);
    // eslint-disable-next-line react/prop-types
    props.handleCloseUbah();
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">Ubah Kategori Rubrik</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"Mengubah Informasi Kategori Rubrik"}
          </DialogContentText>
          <GridContainer style={{ minWidth: "400px" }}>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <CustomInput
                labelText="Urutan"
                id="order"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "urutan_di_skpi",
                  type: "number",
                  value: dataBidangPortofolio.urutan_di_skpi,
                  onChange: changeDataBidangPortofolio
                }}
              />
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
          <GridContainer style={{ minWidth: "400px" }}>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <CustomInput
                labelText="Nama Kategori"
                id="item-name-ID"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "nama_bidang_portofolio",
                  value: dataBidangPortofolio.nama_bidang_portofolio,
                  onChange: changeDataBidangPortofolio
                }}
              />
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
          <GridContainer style={{ minWidth: "400px" }}>
            <GridItem xs={2} />
            <GridItem xs={8}>
              <CustomInput
                labelText="Nama Kategori (EN)"
                id="nama-bidang-EN"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "nama_bidang_portofolio_inggris",
                  value: dataBidangPortofolio.nama_bidang_portofolio_inggris,
                  onChange: changeDataBidangPortofolio
                }}
              />
            </GridItem>
            <GridItem xs={2} />
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="warning">
            Discard
          </Button>
          <Button
            onClick={handleAPISubmit}
            color="success"
            autoFocus
            disabled={
              !dataBidangPortofolio.nama_bidang_portofolio ||
              !dataBidangPortofolio.id_bidang_portofolio
            }
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DialogUbahKategoriRubrik.propTypes = {
  openSnackbar: PropTypes.func,
  selectedBidangPortofolio: PropTypes.object,
  handleFetchCategories: PropTypes.func,
  handleCloseUbah: PropTypes.func
};
