import React, { useState } from "react";
import PropTypes from "prop-types";
//@material-ui/core components
import { Collapse } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DropzoneDialog } from "material-ui-dropzone";
// dep components
import Card from "dep/Card/Card";
import CardHeader from "dep/Card/CardHeader";
import CardBody from "dep/Card/CardBody";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";
import Button from "dep/CustomButtons/Button";
// components
import ChipFileSingle from "components/Global/Chip/ChipFileSingle";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle";
import CustomSnackbar from "components/Global/Snackbar/CustomSnackbar";
// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

const useStyles = makeStyles({
  ...dashboardStyle,
  text: { margin: 0, fontSize: "13px", color: "#343434" },
  textValue: {
    margin: 0,
    fontSize: "13px",
    fontStyle: "italic",
    color: "#4f4f4f",
    textAlign: "right"
  }
});
const MAX_FILE_SIZE = 2000000;
const ACCEPT_FILE_TYPE = ["application/pdf"];
export default function CardUnggahSKPI(props) {
  const classes = useStyles();

  //var: values
  const [data, setData] = useState({
    link: props.link,
    file: null
  });

  //var: open-close
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    severity: "",
    message: ""
  });
  const openSnackbar = (severity, message) => {
    setSnackbarOpen(true);
    setSnackbarData({ severity, message });
  };

  // handle: files
  const onSaveUpload = files => {
    setData({ link: "", file: files[0] });
    setUploadDialogOpen(false);
  };
  const handleDiscardFile = () => {
    setData({ link: "", file: null });
  };

  // handle: API Submit
  const handleSubmit = async () => {
    try {
      openSnackbar("info", "Submitting...");
      const form = new FormData();
      form.append("file", data.file);
      form.append("_method", "PUT");

      let res = await Auth.fetchMultipart(BASE_URL + "/api/skpi/upload/", {
        method: "POST",
        body: form
      });
      let result = await res.json();
      if (res.status === 200) {
        openSnackbar("success", result.message);
        setData({ file: data.file, link: BASE_URL + "/" + result.path });
      } else {
        let errors =
          typeof result.message === "string"
            ? result.message
            : Object.values(result.message).join("");
        openSnackbar("warning", errors);
      }
    } catch (err) {
      let errors =
        typeof err.message === "string"
          ? err.message
          : Object.values(err.message).join("");
      openSnackbar("error", errors);
    }
  };

  // handle: values
  const handleReset = () => {
    setData({
      link: props.link,
      file: null
    });
  };

  // handle: open-close
  const handleOpenDropzone = () => {
    setUploadDialogOpen(true);
  };
  const handleCloseDropzone = () => {
    setUploadDialogOpen(false);
  };

  return (
    <GridContainer justify={"center"}>
      <GridItem xs={12} md={5} xl={12}>
        <Card>
          <CardHeader color={"primary"}>
            <h4 className={classes.cardTitleWhite}>Unggah SKPI</h4>
          </CardHeader>
          <CardBody>
            <CustomSnackbar
              open={snackbarOpen}
              onClose={() => setSnackbarOpen(false)}
              message={snackbarData.message}
              severity={snackbarData.severity}
            />
            <GridContainer justify={"center"}>
              <GridItem xs={11}>
                <p className={classes.text}>
                  Unggah Surat Keterangan Pendamping Ijazah yang anda terima
                  sebagai bukti anda telah menerimanya
                </p>
              </GridItem>
            </GridContainer>
            <div style={{ padding: "1vh" }} />
            <GridContainer justify={"center"}>
              <GridItem>
                <p className={classes.cardTitle}>Dokumen SKPI</p>
              </GridItem>
            </GridContainer>
            <GridContainer justify={"center"}>
              <GridItem>
                {data.link === "" && data.file === null ? (
                  <Button
                    color={"info"}
                    round
                    size={"sm"}
                    onClick={handleOpenDropzone}
                  >
                    Unggah
                  </Button>
                ) : (
                  <ChipFileSingle
                    link={data.link}
                    fileName={data.file !== null ? data.file.name : ""}
                    discardFile={handleDiscardFile}
                  />
                )}
              </GridItem>
            </GridContainer>
            <div style={{ padding: "1vh" }} />
            <Collapse in={data.link === ""}>
              <GridContainer justify={"space-around"}>
                <GridItem>
                  <Button
                    disabled={props.link === ""}
                    color={"warning"}
                    size={"sm"}
                    onClick={handleReset}
                  >
                    Cancel
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    disabled={data.file === null}
                    color={"success"}
                    size={"sm"}
                    onClick={handleSubmit}
                  >
                    submit
                  </Button>
                </GridItem>
              </GridContainer>
            </Collapse>
            <div style={{ padding: "1vh" }} />
          </CardBody>
        </Card>
        <DropzoneDialog
          open={uploadDialogOpen}
          onSave={onSaveUpload}
          onClose={handleCloseDropzone}
          filesLimit={1}
          maxFileSize={MAX_FILE_SIZE}
          acceptedFiles={ACCEPT_FILE_TYPE}
          showFileNames={true}
          dropzoneText={"Upload bukti portofolio (pdf)"}
        />
      </GridItem>
    </GridContainer>
  );
}

CardUnggahSKPI.propTypes = {
  link: PropTypes.string
};
