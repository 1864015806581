import React, { useEffect, useState } from "react";
import CardHeader from "dep/Card/CardHeader";
import CardBody from "dep/Card/CardBody";
import Table from "dep/Table/Table";
import Card from "dep/Card/Card";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle";
// API
import Auth from "helpers/auth";
import { BASE_URL } from "const";

const useStyles = makeStyles(styles);
export default function CardStatistikKinerjaAdmin() {
  const classes = useStyles();
  useEffect(() => {
    handleFetch();
  }, []);
  const [tableData, setTableData] = useState([]);
  const handleFetch = async () => {
    let res = await Auth.fetch(`${BASE_URL}/api/statistik/portofoliosummary`);
    const data = await res.json();

    let tableData = data.map(e => {
      return [
        `${e.nama_prodi} (${e.jenjang})`,
        e.total - e.belum_diverifikasi,
        e.belum_diverifikasi
      ];
    });

    tableData.sort((a, b) => a[2] - b[2]);
    setTableData(tableData);
  };
  return (
    <Card>
      <CardHeader color="warning">
        <h4 className={classes.cardTitleWhite}>Admin Prodi</h4>
        <p className={classes.cardCategoryWhite}>Kinerja Admin Prodi</p>
      </CardHeader>
      <CardBody style={{ maxHeight: 226, overflow: "auto" }}>
        <Table
          tableHeaderColor="warning"
          tableHead={["Prodi", "Terverifikasi", "Menunggu"]}
          tableData={tableData}
        />
      </CardBody>
    </Card>
  );
}
