import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "dep/Card/Card.js";
import CardBody from "dep/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-react/cardImagesStyles.js";
import Avatar from "@material-ui/core/Avatar";
import GridContainer from "dep/Grid/GridContainer";
import GridItem from "dep/Grid/GridItem";

const useStyles = makeStyles({
  card: {
    width: "15rem",
    height: "15rem",
    margin: "auto",
    "&:hover": {
      "& $avatar": {
        transition: "0.5s",
        opacity: "1"
        // backgroundColor: "rgb(255,0,0)"
      }
    }
  },
  avatar: {
    transition: "0.5s",
    color: "white",
    opacity: "0.3",
    backgroundColor: "rgb(255,0,0)",
    width: "3rem",
    height: "3rem"
  },
  ...styles
});
const CardLandingPage = props => {
  const classes = useStyles();
  const { title, text, icon } = props;

  return (
    <Card className={classes.card}>
      <GridContainer
        alignItems={"center"}
        style={{ paddingBottom: "1vh", paddingTop: "1vh" }}
      >
        <GridItem xs={1} />
        <GridItem>
          <Avatar className={classes.avatar}>{icon}</Avatar>
        </GridItem>
      </GridContainer>
      <CardBody>
        <h4>{title}</h4>
        <p>{text}</p>
      </CardBody>
    </Card>
  );
};

export default CardLandingPage;

CardLandingPage.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string,
  title: PropTypes.string
};
